import { ClinicContext } from "@/App"
import { PrimaryHeaderPresenterType } from "@/components/Common/entities"
import { KebabMenu } from '@/feature/phr-28/components/parts/KebabMenu'
import { useContext } from "react"

interface TabBarType {
    tabTitles: string[]
    arrangement: boolean
    setTabName: (tabTitle: string) => void
    nowDate: Date | null
    setNowDate: React.Dispatch<React.SetStateAction<Date>> | null
    matches: boolean | null
}

export const PrimaryHeaderPresenter: React.FC<PrimaryHeaderPresenterType> = ({
    clinicList
}) => {
    const clinic = useContext(ClinicContext)
    const isLocal = location.origin.includes(':3000')

    return (
        <div className="flex items-end gap-2 text-sm">
            {/* <li className="text-gray-500 font-semibold">{clinic.clinic_name || clinic.clinic_label}</li> */}
            <p className="text-gray-500 font-semibold">{clinic.clinic_name}<span className="font-bold text-red-500">{isLocal && '(ローカル環境)'}</span></p>

            {/* 縦三点メニュー（検証用） */}
            {
                // process.env.VITE_AUTH0_DOMAIN === 'dev-auth.smartm.jp' ? 
                <KebabMenu clinicList={clinicList} />
                // : <></>
            }
        </div>
    )
}
