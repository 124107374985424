import { CommonHeaderViews } from "@/components/Common/components/Header/views/CommonHeaderViews"
import { Sidebar } from "@/components/Common/components/parts/Sidebar/Sidebar"
import { FacilityView } from "@/feature/phr-28/components/views/FacilityView"
import { SettingsPresenterType } from "@/components/Settings/entities"

export const SettingsPresenter: React.FC<SettingsPresenterType> = ({
    children
}) => {
    return (
        <div className="h-screen w-full">
            <Sidebar>
                <>
                    <CommonHeaderViews />
                    <div className="h-full w-full overflow-scroll">
                        {children}
                    </div>
                </>
            </Sidebar>
        </div>
    )
}
