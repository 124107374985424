import React, { useState } from 'react'
import { PlusIcon } from '@/feature/phr-28/components/parts/PlusIcon'
import { MinusIcon } from '@/feature/phr-28/components/parts/MinusIcon'
import { ShiftAddModal } from './ShiftAddModal'
import { ShiftDeleteModal } from './ShiftDeleteModal'
import { ShiftEditModal } from './ShiftEditModal'
import {
  unixTimeFromNowDate,
  shiftStartTime,
  shiftEndTime
} from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon'
import {
  listManegerType,
  shiftType
} from '@/feature/phr-28/components/common/type'
import {
  workingTimeAm,
  workingTimePm,
  emptyShiftData
} from '@/feature/phr-28/components/common/emptyData'
import { getDailyShift } from '@/feature/phr-28/components/common/Api'
import { useGraphqlClient } from '@/App'

interface ShiftRegistrationType {
  setShiftFix: (isShow: boolean) => void
  setShiftIdentificationNumber: (identification: string) => void
  nowDate: Date
  manegerLists: listManegerType[]
}

export const ShiftDaily: React.FC<ShiftRegistrationType> = ({
  setShiftFix,
  setShiftIdentificationNumber,
  nowDate,
  manegerLists
}) => {
  const graphqlClient = useGraphqlClient()
  const [show, setShow] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [modifyShift, setModifyShift] = useState(emptyShiftData)
  const [editElementsNumber, setEditElementsNumber] = useState(0)
  const tmpTodayShifts: shiftType[] = getDailyShift(graphqlClient, nowDate)
  const todayShifts: shiftType[] = createTodayShifts(
    manegerLists,
    nowDate,
    tmpTodayShifts
  )

  return (
    <>
      <h3 className="text-center">{nowDate.toDateString()}</h3>
      <div className="flex flex-auto flex-col h-80 box-border p-1 overflow-scroll">
        <div className="flex flex-row mt-5">
          {getTimes()}
          {todayShifts.map((shift: shiftType, index: number) => {
            const shiftFrames = []
            if (shift.shifts.length !== 0) {
              let tmpShiftFrames = []
              for (let i = shiftStartTime; i < shiftEndTime; i++) {
                for (let p = 0; p < 4; p++) {
                  let roopNumber = 0
                  if (i === 8) {
                    roopNumber = p
                  } else {
                    roopNumber = 4 * (i - 8) + p
                  }
                  tmpShiftFrames.push(
                    <div
                      key={roopNumber}
                      onClick={() => {
                        setModifyShift(shift)
                        setShowEditModal(true)
                        setEditElementsNumber(roopNumber)
                      }}
                      className={
                        shift.shifts[roopNumber]
                          ? 'bg-blue-300 h-4 w-full hover:bg-blue-400'
                          : 'bg-blue-50 h-4 w-full hover:bg-blue-400'
                      }
                    ></div>
                  )
                }

                shiftFrames.push(
                  <div className="h-20 border border-dotted border-gray-300 box-border p-1 flex flex-col justify-between">
                    {tmpShiftFrames}
                  </div>
                )
                tmpShiftFrames = []
              }
            }
            return (
              <div className="mx-1 w-full" key={index}>
                <div className="text-center border-b-2 h-5 flex justify-center">
                  <span className="text-xs mx-1">{shift.name}</span>
                  {shift.shifts.length !== 0 && (
                    <div
                      className="bg-gray-400 rounded-2xl cursor-pointer"
                      onClick={() => {
                        setModifyShift(shift)
                        setShowDeleteModal(true)
                      }}
                    >
                      <MinusIcon size={5} />
                    </div>
                  )}

                  {shift.shifts.length === 0 && (
                    <div
                      className="bg-yellow-200 rounded-2xl cursor-pointer"
                      onClick={() => {
                        setModifyShift(shift)
                        setShow(true)
                      }}
                    >
                      <PlusIcon size={5} />
                    </div>
                  )}
                </div>
                <div className="h-6 my-1 flex justify-center items-center">
                  <button
                    type="button"
                    onClick={() => {
                      setShiftFix(true)
                      setShiftIdentificationNumber(shift.id)
                    }}
                    className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-2 py-1 text-center"
                  >
                    まとめて登録
                  </button>
                </div>
                {shiftFrames}
              </div>
            )
          })}
        </div>
        <ShiftAddModal isOpen={show} setShow={setShow} shift={modifyShift} />
        <ShiftEditModal
          isOpen={showEditModal}
          setShowEditModal={setShowEditModal}
          shift={modifyShift}
          editElementsNumber={editElementsNumber}
        />
        <ShiftDeleteModal
          isOpen={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          shift={modifyShift}
        />
      </div>
    </>
  )
}

function createTodayShifts(
  manegerLists: listManegerType[],
  nowDate: Date,
  todayShifts: shiftType[]
): shiftType[] {
  const emptyShifts: shiftType[] = []
  // 担当者分の空シフト作成
  manegerLists.forEach((maneger: listManegerType) => {
    const emptyShift: shiftType = {
      date: unixTimeFromNowDate(nowDate),
      id: maneger.id,
      name: maneger.name,
      shifts: [],
      workingTimes: { am: workingTimeAm, pm: workingTimePm }
    }
    emptyShifts.push(emptyShift)
  })
  emptyShifts.forEach((emptyShift: shiftType) => {
    let isExistDate: boolean = false
    todayShifts.forEach((shift: shiftType) => {
      if (emptyShift.id === shift?.id) {
        isExistDate = true
      }
    })
    if (!isExistDate) {
      todayShifts.push(emptyShift)
    }
  })
  todayShifts.sort((a, b) => parseInt(a.id) - parseInt(b.id))

  return todayShifts
}

function getTimes(): JSX.Element {
  const items = []
  for (let i = shiftStartTime; i < shiftEndTime; i++) {
    items.push(
      <div className="h-20 box-border p-1" key={i}>
        {i}:00
      </div>
    )
  }
  return (
    <div className="w-12">
      <div className="text-center h-12" />
      {items}
    </div>
  )
}
