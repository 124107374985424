import { MultiSelectModalOpenerPropsType } from '@/components/FacilityGroup/entities'
import { MultiSelect } from '@mantine/core'

export const MultiSelectModalOpener: React.FC<MultiSelectModalOpenerPropsType> = ({
    editingGroupList,
    setIsOpenSelectModal,
}) => {
    return (
        <div className="cursor-pointer"
            onClick={() => { setIsOpenSelectModal() }}
        >
            <MultiSelect
                onClick={(e) => { e.preventDefault() }}
                data={editingGroupList}
                value={editingGroupList[0]?.value.length > 0 ? editingGroupList.map(group => group.value) : undefined}
                placeholder="施設グループ"
                className="pointer-events-none"
            />
        </div>
    )
}
