import { Appoint, ClinicTime } from "@/_graphql/graphql-client";
import { NavigateType, SearchType } from "@/components/Common/entities";
import { AddAppointType, DeleteAppointType, MutationsType, UpdateAppointInfoType, UpdateAppointType } from "@/components/Common/entities/common/types";
import { getEndTime, getStartTimeOfToDayTimestamp } from "@/components/Common/utils";
import { emptyAppoint } from "@/components/Common/utils/common/emptyData";
import { MenuType } from "@/components/Menu/entities";
import { AddAppointDefaultValuesType, AddAppointSetValuesType, ReserveType } from "@/components/Reservation/entities";
import { getMenuRole } from "@/feature/phr-28/components/common/medicalMenu";
import { addMinutes, differenceInMinutes, fromUnixTime, getMinutes, getUnixTime, isAfter, setHours, setMinutes, startOfDay } from "date-fns";

// 新規予約の初期化
export function initNewAppoint(appoint: Appoint, setValue: AddAppointSetValuesType, facilityId: string, startTime: number, endTime: number) {

    const baseAppointValue = appoint.id ? appoint : { ...emptyAppoint }
    const appointEndTime = appoint.id ? endTime : getEndTime(startTime, 15)

    const initialValue = {
        facilityId,
        startTime,
        endTime: appointEndTime
    }
    const defaultAppointValue = { ...baseAppointValue, ...initialValue }
    setValue('appointData', defaultAppointValue)
}

// 予約参照の初期化
export function initReferenceAppoint(formAppoint: Appoint, fetchedAppoint: ReserveType, setValue: AddAppointSetValuesType) {
    if (formAppoint.updated_at !== fetchedAppoint.updated_at) {
        setValue('appointData', fetchedAppoint)
        return
    }
    setValue('appointData', formAppoint)
}

function validateFormData(data: any) {
    const _endTime = data.endTime;
    return {
        ...data,
        endTime: Number(_endTime),
    };
}

// 予約の登録処理
export function onSubmit(data: AddAppointDefaultValuesType, oparation: 'add' | 'reference' | 'edit' | 'copy' | 'update' | undefined, mutates: MutationsType, clinic_id: string, menuList: MenuType[], nowDate: Date, navigate: NavigateType, setOpenCheckUpdateDialog: React.Dispatch<React.SetStateAction<boolean>>) {
    const { addAppoint, updateAppoint, updateAppointInfo } = mutates;

    switch (oparation) {
        case 'add':
            //新規予約の登録処理
            submitNewAppoint(addAppoint, data, clinic_id, nowDate, menuList)
            break;
        case 'reference':
            //予約参照の登録処理
            submitReferenceAppoint(updateAppointInfo, data, clinic_id, nowDate, menuList)
            break;
        case 'update':
            //予約編集の登録処理
            submitEditAppoint(updateAppoint, data, clinic_id, nowDate, menuList)
            break;
        case 'copy':
            //予約コピーの登録処理
            submitNewAppoint(addAppoint, data, clinic_id, nowDate, menuList)
        default:
            break;
    }

    setOpenCheckUpdateDialog(false)
    navigate({ to: `/main/?calendar-date=${getStartTimeOfToDayTimestamp(nowDate)}` })
}

// 新規予約の登録処理
function submitNewAppoint(addAppoint: AddAppointType, data: AddAppointDefaultValuesType, clinic_id: string, nowDate: Date, menuList: MenuType[]) {
    const _appoint = validateFormData(data.appointData);
    const appoint = addOptionalProperty(_appoint, nowDate, menuList)
    const newAppoint = { clinic_id, ...appoint }
    newAppoint.appointId = '0'

    addAppoint.mutate(newAppoint);
}

// 予約参照の登録処理
function submitReferenceAppoint(updateAppointInfo: UpdateAppointInfoType, data: AddAppointDefaultValuesType, clinic_id: string, nowDate: Date, menuList: MenuType[]) {
    const _appoint = validateFormData(data.appointData);
    const appoint = addOptionalProperty(_appoint, nowDate, menuList)
    const newAppoint = { clinic_id, ...appoint }

    updateAppointInfo.mutate(newAppoint);
}

// 予約編集の登録処理
function submitEditAppoint(updateAppoint: UpdateAppointType, data: AddAppointDefaultValuesType, clinic_id: string, nowDate: Date, menuList: MenuType[]) {
    console.log('submit')
    console.table(data.appointData)
    const _appoint = validateFormData(data.appointData);
    const appoint = addOptionalProperty(_appoint, nowDate, menuList)

    const basePrimaryKeys = data.ReferenceAppointKey;
    const newAppoint = { clinic_id, ...basePrimaryKeys, ...appoint }
    updateAppoint.mutate(newAppoint);
}

// 予約情報に必要なプロパティを追加する
function addOptionalProperty(appoint: ReserveType, nowDate: Date, menuList: MenuType[]) {
    const optionValue = {
        date: getUnixTime(nowDate),
        // 新規予約なら'予約済み', 予約更新ならappoint.appointの値をセット
        appoint: appoint.appoint !== '0' ? appoint.appoint : '予約済み',
        type: getMenuRole(menuList, appoint.menuId),
        confirmedTime: appoint.startTime,
        confirmedEndTime: appoint.endTime,
        price: 0,
        secondStartTime: 1,
        secondEndTime: 1,
        thirdStartTime: 1,
        thirdEndTime: 1,
    }
    const newAppoint = { ...appoint, ...optionValue };
    return newAppoint;
}

export function mutateSuccessAction(addAppoint: AddAppointType, updateAppoint: UpdateAppointType, updateAppointInfo: UpdateAppointInfoType, deleteAppoint: DeleteAppointType, navigate: NavigateType) {
    if (addAppoint.isSuccess) {
        const res = addAppoint.data.addAppoint;
        if (res.isDubbleBooking) {
            alert('ダブルブッキングしました')
            addAppoint.reset()
            return
        }
        console.log('新規予約-追加成功', res)
        addAppoint.reset()
        return
    }
    if (updateAppoint.isSuccess) {
        const res = updateAppoint.data.updateAppoint;
        console.log('予約日時-変更成功', res)
        // navigate({ to: `/main/appoint-info?calendar-date=${getStartTimeOfToDayTimestamp(new Date())}` })
        updateAppoint.reset()
        return
    }
    if (updateAppointInfo.isSuccess) {
        const res = updateAppointInfo.data.updateAppointInfo;
        console.log('予約更新-成功', res)
        updateAppointInfo.reset()
        return
    }
    if (deleteAppoint.isSuccess) {
        const res = deleteAppoint.data.deleteAppoint;
        console.log('予約削除-成功', res)
        deleteAppoint.reset()
        return
    }
}

export function mutateErrorAction(addAppoint: AddAppointType, updateAppoint: UpdateAppointType, updateAppointInfo: UpdateAppointInfoType, deleteAppoint: DeleteAppointType, navigate: NavigateType) {
    if (addAppoint.isError) {
        console.log('addAppoint失敗', addAppoint.error);
        alert('予約追加に失敗しました')
        addAppoint.reset()
        return
    }
    if (updateAppoint.isError) {
        console.log('予約日時-変更失敗', updateAppoint.error)
        alert('予約日時の変更に失敗しました')
        updateAppoint.reset()
        return
    }
    if (updateAppointInfo.isError) {
        console.log('予約更新-失敗', updateAppointInfo.error)
        alert('予約更新に失敗しました')
        updateAppointInfo.reset()
        return
    }
    if (deleteAppoint.isError) {
        console.log('予約削除-失敗', deleteAppoint.error)
        alert('予約削除に失敗しました')
        deleteAppoint.reset()
        return
    }
}

//
export function isWithoutEndTime(clinicTime: ClinicTime, endTime: number, currentDateTimestamp: number) {
    // currentDateTimestampの日付を使用してclinicTimeのendTimeをDateオブジェクトに変換
    const baseDate = startOfDay(fromUnixTime(currentDateTimestamp));
    const clinicEndTimeDate = setMinutes(setHours(baseDate, clinicTime.endTime.hours), clinicTime.endTime.minutes);

    // 引数として与えられたendTimeをDateオブジェクトに変換
    const endTimeDate = fromUnixTime(endTime);
    // 時刻のみを比較
    return isAfter(endTimeDate, clinicEndTimeDate);
};