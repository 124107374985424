import { listManegerType, MenuType, FacilityType, BlockType, CategoryType, MenuNameType, listManegerNameType } from '@/feature/phr-28/components/common/type'
import { unixTimeFromNowDate, getAddDay } from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon'
import { GraphQLClient } from 'graphql-request'
import {
  useListPatientsQuery,
  useListMenuQuery,
  useListManegerQuery,
  useGetTodayShiftQuery,
  useGetWeeklyShiftQuery,
  useGetPatientsForBackOfficeQuery,
  useListFacilitiesQuery,
  useListPatientsByNameQuery,
  useGetPatientsByNumberQuery,
  useScanPeriodBlockQuery,
  useListCategorysQuery,
  useListMenuNameQuery,
  useListFacilitieNamesQuery
} from '../../../../../.graphql/types'
import { PatientsType } from "@/components/Patient/components/util/common/type";

export const getPatientsByNumber = (graphqlClient: GraphQLClient, searchNum: number, subject: 'D' | 'S'): PatientsType[] => {
  const patientsList: any = []
  const { data, isSuccess } = useGetPatientsByNumberQuery(graphqlClient, { number: searchNum, type: subject })
  if (isSuccess) {
    patientsList.push(data.getPatientsByNumber)
  }
  return patientsList
}

export const getPatientsByName = (graphqlClient: GraphQLClient, searchName: string): PatientsType[] => {
  const patientsList: PatientsType[] = []
  const { data, isSuccess } = useListPatientsByNameQuery(graphqlClient, { name: searchName })
  if (isSuccess) {
    data.listPatientsByName.Patients.forEach((patient: any) => {
      patientsList.push(patient)
    })
  }
  return patientsList
}

export function getPatientsNameById(
  patientList: PatientsType[],
  id: string
) {
  let name
  patientList.forEach(patient => {
    if (patient.id === id) name = `${patient.name.last} ${patient.name.first}`
  })
  return name
}

export function getPatientsKanaById(
  patientList: PatientsType[],
  id: string
) {
  let kana
  patientList.forEach(patient => {
    if (patient.id === id) kana = `${patient.kana.last} ${patient.kana.first}`
  })
  return kana
}

export const getFacilityList = (graphqlClient: GraphQLClient, clinic_id: string, isAll: boolean = true): FacilityType[] => {
  const facilityList: FacilityType[] = []
  const { data, isSuccess } = useListFacilitiesQuery(graphqlClient, { clinic_id })
  if (isSuccess) {
    data.listFacilities.Facilities.forEach((facility: any) => {
      if ((facility.isDelete !== true)) {
        if (isAll) {
          facilityList.push(facility)
        } else if (facility.status === true) {
          facilityList.push(facility)
        }
      }
    })
  }

  return facilityList
}

export const getFacilityListAll = (graphqlClient: GraphQLClient, clinic_id: string) => {
  const facilityList: FacilityType[] = []
  const facilityListIncludeDelete: FacilityType[] = []
  const { data, isSuccess } = useListFacilitiesQuery(graphqlClient, { clinic_id })

  if (isSuccess) {
    data.listFacilities.Facilities.forEach((facility: any) => {
      if ((facility.isDelete !== true && facility.status === true)) {
        facilityList.push(facility)
      }
      facilityListIncludeDelete.push(facility)
    })
  }

  return {
    facilityList,
    facilityListIncludeDelete
  }
}

export const getFacilityNameList = (graphqlClient: GraphQLClient, clinic_id: string, isAll: boolean = true): FacilityType[] => {
  const facilityNameList: FacilityType[] = []
  const { data, isSuccess } = useListFacilitieNamesQuery(graphqlClient, { clinic_id })
  if (isSuccess) {
    data.listFacilities.Facilities.forEach((facility: any) => {
      if ((facility.isDelete !== true)) {
        if (isAll) {
          facilityNameList.push(facility)
        } else if (facility.status === true) {
          facilityNameList.push(facility)
        }
      }
    })
  }

  return facilityNameList
}

export const getMenuList = (graphqlClient: GraphQLClient, clinic_id: string, status: string = 'all'): MenuType[] => {
  const menuList: MenuType[] = []
  const { data, isSuccess } = useListMenuQuery(graphqlClient, { clinic_id })
  if (isSuccess) {
    data.listMenu.Menus.forEach((menu: any) => {
      if (menu.isDelete !== true) {
        if (status === 'all') {
          menuList.push(menu)
        } else if (menu.status === status) {
          menuList.push(menu)
        }
      }
    })
  }

  return menuList
}

export const getMenuListIncludeDelete = (graphqlClient: GraphQLClient, clinic_id: string): MenuType[] => {
  const menuList: MenuType[] = []
  const { data, isSuccess } = useListMenuQuery(graphqlClient, { clinic_id })
  if (isSuccess) {
    data.listMenu.Menus.forEach((menu: any) => {
      menuList.push(menu)
    })
  }

  return menuList
}

export const getMenuListAll = (graphqlClient: GraphQLClient, clinic_id: string) => {
  const menuList: MenuType[] = []
  const menuListIncludeDelete: MenuType[] = []
  const { data, isSuccess } = useListMenuQuery(graphqlClient, { clinic_id })

  if (isSuccess) {
    data.listMenu.Menus.forEach((menu: any) => {
      if (menu.isDelete !== true && menu.status === '有効') {
        menuList.push(menu)
      }
      menuListIncludeDelete.push(menu)
    })
  }

  return {
    menuList,
    menuListIncludeDelete
  }
}

export const getMenuNameList = (graphqlClient: GraphQLClient, clinic_id: string): MenuNameType[] => {
  const menuNameList: MenuNameType[] = [];
  const { data, isSuccess } = useListMenuNameQuery(graphqlClient, { clinic_id });
  if (isSuccess) {
    data.listMenu.Menus.forEach((menu: any) => {
      menuNameList.push(menu);
    })
  }
  return menuNameList;
}

export const getBlockingList = (
  graphqlClient: GraphQLClient,
  clinic_id: string,
  startOfDayTimestamp: number,
  endOfDayTimestamp: number
): BlockType[] => {
  const blockingLists: BlockType[] = [];
  const { data, isSuccess } = useScanPeriodBlockQuery(graphqlClient, {
    startTime: startOfDayTimestamp,
    endTime: endOfDayTimestamp,
    clinic_id
  })
  if (isSuccess) {
    console.log(data.scanPeriodBlock.AppointBlocks)
    data.scanPeriodBlock.AppointBlocks?.forEach(((block: any) => {
      if (block?.isDelete === false) {
        blockingLists.push(block)
      }
    }))
  }

  return blockingLists;
}

export const getBlockingListIncludeDelete = (
  graphqlClient: GraphQLClient,
  todayTimeStamp: number,
  oneDayTimestamp: number,
  clinic_id: string
): BlockType[] => {
  const blockingLists: BlockType[] = [];
  const { data, isSuccess } = useScanPeriodBlockQuery(graphqlClient, {
    startTime: todayTimeStamp - oneDayTimestamp * 90,
    endTime: todayTimeStamp + oneDayTimestamp * 90,
    clinic_id
  })
  if (isSuccess) {
    data.scanPeriodBlock.AppointBlocks?.forEach(((block: any) => {
      blockingLists.push(block)
    }))
  }

  return blockingLists;
}

export const getCategoryListIncludeDelete = (graphqlClient: GraphQLClient, clinic_id: string): CategoryType[] => {
  const categoryList: CategoryType[] = [];
  const { data, isSuccess } = useListCategorysQuery(graphqlClient, { clinic_id });
  if (isSuccess) {
    data.listCategorys.Categorys?.forEach((category: any) => {
      categoryList.push(category)
    })
  }
  return categoryList;
}

export const getCategoryList = (graphqlClient: GraphQLClient, clinic_id: string): CategoryType[] => {
  const categoryList: CategoryType[] = [];
  const { data, isSuccess } = useListCategorysQuery(graphqlClient, { clinic_id });
  if (isSuccess) {
    data.listCategorys.Categorys?.forEach((category: any) => {
      if (category?.isDelete === false) {
        categoryList.push(category)
      }
    })
  }
  return categoryList;
}

export const getManegerList = (graphqlClient: GraphQLClient, clinic_id: string): listManegerType[] => {
  const returnManegerList: listManegerType[] = []
  const { data, isSuccess } = useListManegerQuery(graphqlClient, { clinic_id })
  if (isSuccess) {
    data.listManeger.Manegers.forEach((maneger: any) => {
      if (maneger.isDelete === false) {
        returnManegerList.push(maneger)
      }
    })
  }
  return returnManegerList
}

// 論理削除した担当者も含む
export const getManegerListIncludeDelete = (graphqlClient: GraphQLClient, clinic_id: string): listManegerType[] => {
  const returnManegerList: listManegerType[] = []
  const { data, isSuccess } = useListManegerQuery(graphqlClient, { clinic_id })
  if (isSuccess) {
    data.listManeger.Manegers.forEach((maneger: any) => {
      returnManegerList.push(maneger)
    })
  }
  return returnManegerList
}

export const getManegerListAll = (graphqlClient: GraphQLClient, clinic_id: string) => {
  const manegerList: listManegerType[] = []
  const manegerListIncludeDelete: listManegerType[] = []
  const { data, isSuccess } = useListManegerQuery(graphqlClient, { clinic_id })

  if (isSuccess) {
    data.listManeger.Manegers.forEach((maneger: any) => {
      if (maneger.isDelete !== true) {
        manegerList.push(maneger)
      }
      manegerListIncludeDelete.push(maneger)
    })
  }

  return {
    manegerList,
    manegerListIncludeDelete
  }
}

export const getManegerNameList = (graphqlClient: GraphQLClient, clinic_id: string): listManegerNameType[] => {
  const returnManegerList: listManegerNameType[] = []
  const { data, isSuccess } = useListManegerQuery(graphqlClient, { clinic_id })
  if (isSuccess) {
    data.listManeger.Manegers.forEach((maneger: any) => {
      returnManegerList.push(maneger)
    })
  }
  return returnManegerList
}


export const getTodayShifts = (graphqlClient: GraphQLClient, nowDate: Date): [] => {
  let todayShifts: any = []
  const { data, isSuccess } = useGetTodayShiftQuery(graphqlClient, {
    date: unixTimeFromNowDate(nowDate)
  })
  if (isSuccess) {
    todayShifts = data.getTodayShift.Shifts
  }
  return todayShifts
}

export const getPatientsList = (graphqlClient: GraphQLClient, clinic_id: string): [] => {
  let returnPatientsList: any = []
  const { data, isSuccess } = useListPatientsQuery(graphqlClient, { clinic_id })
  if (isSuccess) {
    data.listPatients.Patients.forEach(patient => {
      if (patient?.status !== false) {
        returnPatientsList.push(patient);
      }
    })
  }
  return returnPatientsList
}

export const getPatientsListAll = (graphqlClient: GraphQLClient, clinic_id: string) => {
  let patientsList: any = []
  let patientsListIncludeDelete: any = []

  const { data, isSuccess } = useListPatientsQuery(graphqlClient, { clinic_id })
  if (isSuccess) {
    data.listPatients.Patients.forEach(patient => {
      if (patient?.status !== false) {
        patientsList.push(patient);
      }
      patientsListIncludeDelete.push(patient)
    })
  }

  return {
    patientsList,
    patientsListIncludeDelete
  }
}


export const getPatients = (graphqlClient: GraphQLClient, patientsId: string): [] => {
  let returnPatientsList: any = []
  const { data, isSuccess } = useGetPatientsForBackOfficeQuery(graphqlClient, {
    id: patientsId
  })
  if (isSuccess) {
    returnPatientsList = data.getPatientsForBackOffice
  }
  // console.log(isSuccess)
  return returnPatientsList
}

export const getDailyShift = (graphqlClient: GraphQLClient, nowDate: Date): [] => {
  let todayShifts: any = []
  const { data, isSuccess } = useGetTodayShiftQuery(graphqlClient, {
    date: unixTimeFromNowDate(nowDate)
  })
  if (isSuccess) {
    todayShifts = data.getTodayShift.Shifts
  }
  return todayShifts
}

export const getDailyWeekly = (graphqlClient: GraphQLClient, nowDate: Date, manegerId: string): [] => {
  let weekShifts: any = []
  const { data, isSuccess } = useGetWeeklyShiftQuery(graphqlClient, {
    id: manegerId,
    weekStart: unixTimeFromNowDate(nowDate),
    weekEnd: unixTimeFromNowDate(getAddDay(nowDate, 6))
  })
  if (isSuccess) {
    weekShifts = data.getWeeklyShift.Shifts
  }
  return weekShifts
}
