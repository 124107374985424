import { Dialog, DialogContent } from '@mui/material';
import type { PatientsType } from '@/components/Patient/components/util/common/type';
import { useContext, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { emptyPatients } from '../../../common/emptyData';
import { useOptionButtons } from '@/feature/phr-29/components/Patients/utils/searchFunction';
import { ScrollArea } from '@mantine/core';
import {
  dateFromUnixTime,
  japanTimeFormatDay,
} from '../../mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon';
import { ClinicContext, useGraphqlClient } from '@/App';
import { OrderAndFilterControllerForModal } from '@/components/Patient/components/views/OrderAndFilterController';
import { usePatientsPagination } from '@/components/Patient/components/views/hooks/usePatientsPagination';
import { is } from 'date-fns/locale';
import { Pagination } from '@/components/Patient/components/views/Pagination';

interface SearchPatientType {
  setOpenSearchPatientModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAddPatientModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function createHandleClose(
  setOpenSearchPanel: React.Dispatch<React.SetStateAction<boolean>>,
  setResult: React.Dispatch<React.SetStateAction<PatientsType[]>>
) {
  return function handleClose() {
    setResult([emptyPatients]);
    setOpenSearchPanel(false);
  };
}

const ITEMS_PER_PAGE = 50;

// Header Component
const Header: React.FC<any> = ({
  patients,
  setOpenSearchPatientModal,
  setOpenAddPatientModal,
  orderAndFilterController,
  setIsSearched,
}) => {
  return (
    <header className="w-full top-0 left-0 pt-8 bg-white bg-opacity-90 border-b border-gray-600 pb-2 mb-2">
      <p className="text-xl spacing-ls tracking-wider border-b border-gray-600 pb-2 mb-10">
        <span className="text-red-500">患者カード</span>を選択してください。
      </p>
      <div className="mt-8 flex justify-center">
        <OrderAndFilterControllerForModal
          result={patients}
          setOpenSearchPanel={setOpenSearchPatientModal}
          setOpenAddPatientModal={setOpenAddPatientModal}
          orderAndFilterController={orderAndFilterController}
          setIsSearched={setIsSearched}
        />
      </div>
    </header>
  );
};

// PatientLabel Component
const PatientLabel: React.FC<any> = ({
  getValues,
  patient,
  clinic,
  setOpenSearchPatientModal,
  isSearched,
  register,
  watch,
}) => {
  const patientNumber = getPatientNumber(patient, clinic.clinic_label);

  return (
    <label
      key={patient.id}
      className={`${
        getValues('appointData.id') === patient.id
          ? 'relative bg-gray-100 text-blue-500 font-bold'
          : 'bg-white'
      } justify-center tracking-wider text-xl relative flex items-center space-x-3 rounded border border-gray-300 bg-white px-6 py-6 shadow`}
    >
      <div className="flex w-full">
        <div className="w-1/3 text-right">{patientNumber}</div>
        <div className="w-1/3 text-center">
          {patient.name.last} {patient.name.first}
        </div>
        <div className="w-1/3 text-left">
          {patient?.birthday
            ? japanTimeFormatDay(dateFromUnixTime(patient.birthday))
            : '----年--月--日'}
        </div>
      </div>
      <input
        onClick={() => {
          setOpenSearchPatientModal(false);
        }}
        {...(isSearched ? register('appointData.id') : { onChange: () => {} })}
        className={`${
          getValues('appointData.id') === patient.id ? 'opacity-100' : 'opacity-0'
        } checked:bg-none h-4 w-4 absolute top-0 left-0`}
        checked={isSearched ? watch('appointData.id') === patient.id : false}
        type="radio"
        value={patient.id}
      />
    </label>
  );
};

function getPatientNumber(patient: PatientsType, clinic_label: string): string {
  let patientNumber = '';
  if (clinic_label === 'eldear') {
    patientNumber = `S-${patient?.SNumber || '---'} : D-${patient?.DNumber || '---'}`;
  } else {
    patientNumber = `${patient?.SNumber || '---'}`;
  }
  return patientNumber;
}

export const SearchPatientModal: React.FC<SearchPatientType> = ({
  setOpenSearchPatientModal,
  setOpenAddPatientModal,
}) => {
  const clinic = useContext(ClinicContext);
  const { register, getValues, watch } = useFormContext();
  //   const { AllSearchButtonsWrapper, isSearched } = useOptionButtons();

  const [isSearched, setIsSearched] = useState(false);

  const graphqlClient = useGraphqlClient();
  const clinic_id = useContext(ClinicContext).clinic_id;
  const [isOpen, setIsOpen] = useState(false);
  const { patients, setResult, orderAndFilterController } = usePatientsPagination(
    graphqlClient,
    clinic_id
  );

  const [currentPage, setCurrentPage] = useState(1);
  const filteredResults = patients
    .filter((obj) => obj.status !== 'true')
    .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  const {orderOption, filterOption} = orderAndFilterController;

  useEffect(()=>{
    // フィルター/ソートオプションが変更されたらページをリセット
    setCurrentPage(1);
  },[orderOption, filterOption])
  

  return (
    <>
      <Dialog
        transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
        onClose={createHandleClose(setOpenSearchPatientModal, setResult)}
        open={true}
        PaperProps={{
          style: {
            maxWidth: 'none',
            width: '66%',
            transition: 'all 0',
            zIndex: 9999,
          },
        }}
      >
        {/* content */}
        <DialogContent
          dividers
          style={{
            padding: '0px 0px 0px 0px',
            maxWidth: 'none',
            maxHeight: 'none',
            height: '70vh',
            position: 'relative',
          }}
        >
          <div className="wrapper h-full overflow-y-hidden">
            <div className="pb-8 px-8">
              <Header
                getValues={getValues}
                patients={patients}
                setOpenSearchPatientModal={setOpenSearchPatientModal}
                setOpenAddPatientModal={setOpenAddPatientModal}
                orderAndFilterController={orderAndFilterController}
                setIsSearched={setIsSearched}
              />
              <Pagination
                itemsPerPage={ITEMS_PER_PAGE}
                totalItems={patients.length}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />

              <ScrollArea
                style={{ height: 210 }}
                type="scroll"
                scrollbarSize={6}
                scrollHideDelay={300}
              >
                <div className={`${!isSearched ? 'pointer-events-none bg-gray-100' : ''}`}>
                  <div className={`${!isSearched ? 'opacity-40' : ''} grid grid-cols-1 gap-y-2`}>
                    {filteredResults.map((patient) => (
                      <PatientLabel
                        key={patient.id}
                        getValues={getValues}
                        patient={patient}
                        clinic={clinic}
                        setOpenSearchPatientModal={setOpenSearchPatientModal}
                        isSearched={isSearched}
                        register={register}
                        watch={watch}
                      />
                    ))}
                  </div>
                </div>
              </ScrollArea>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
