import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGraphqlClient } from '@/App';
import { useUpDateManegerMutation } from '.graphql/types';
import { ResponsibleEditModalViewsType } from '../utils/common/type';
import { ResponsibleEditModalPresenter } from '../presenter/ResponsibleEditModalPresenter';

export const ResponsibleEditModalViews: React.FC<ResponsibleEditModalViewsType> = ({
  setIsOpen,
  currentManeger,
}) => {
  const rights = ['管理者', '受付'];
  const medicalRole = [
    'ドクター(歯科)',
    'ドクター(医科)',
    '歯科衛生士',
    '歯科助手',
    '技工士',
    'ナース',
    'コンシェルジュ',
  ];
  const queryClient = useQueryClient();
  const graphqlClient = useGraphqlClient();
  const [updateManegerData, setUpdateManegerData] = useState(currentManeger);
  const tmpUpdateData = Object.assign({}, updateManegerData);
  const { mutate } = useUpDateManegerMutation(graphqlClient, {
    onSettled: () => {
      queryClient.invalidateQueries('listManeger').catch(() => console.log('on Settled'));
    },
    onSuccess: (res) => {
      console.log(res);
    },
    onError: (error) => {
      throw error;
    },
    retry: 3,
  });
  const updateManeger = (): void => {
    mutate(updateManegerData);
  };
  const determineRegisterable = (): boolean => {
    let result: boolean = true;
    if (
      updateManegerData.role !== '' &&
      updateManegerData.name !== '' &&
      updateManegerData.rights !== '' &&
      updateManegerData.email !== '' &&
      updateManegerData.color !== ''
    ) {
      result = false;
    }
    return result;
  };
  const registerCss =
    'w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center';
  const disableCss =
    'w-full text-white bg-blue-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center';

  return (
    <ResponsibleEditModalPresenter
      setIsOpen={setIsOpen}
      updateManegerData={updateManegerData}
      tmpUpdateData={tmpUpdateData}
      setUpdateManegerData={setUpdateManegerData}
      medicalRole={medicalRole}
      rights={rights}
      determineRegisterable={determineRegisterable}
      disableCss={disableCss}
      registerCss={registerCss}
      updateManeger={updateManeger}
    />
  );
};
