import { PermissionActionButtonProps } from "@/components/Common/entities";

export const PermissionActionButton: React.FC<PermissionActionButtonProps> = ({
    isAllowed, // 許可されたアクション
    action, // 実行するアクション
    label // ボタンのラベル
}) => {
    // 共通スタイル
    const commonStyle = "leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-700";
    // 活性・非活性スタイル
    const disabledStyle = "pointer-events-none";
    const enabledStyle = "cursor-pointer bg-red-400";
    // スタイル
    const style = `${commonStyle} ${isAllowed ? enabledStyle : disabledStyle}`;

    return (
        <button type="button"
            className={style}
            onClick={action}>
            {label}
        </button>
    );
};
