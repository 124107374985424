import { UseQueryResult } from "react-query";
import { ListFacilitiesQuery } from "@/domain/Facility/entities/types";
import { FacilityNameType, FacilityType } from "@/components/Facility/entities";

export const facilityListMapper = (
    facilities: UseQueryResult<ListFacilitiesQuery>
) => {
    const facilityList: FacilityType[] = []
    const facilityListIncludeDelete: FacilityType[] = []
    const facilityListIncludeInactive: FacilityType[] = []

    if (facilities.isSuccess) {
        const { data } = facilities;

        data.listFacilities.Facilities.forEach((facility: any) => {
            facilityListIncludeDelete.push(facility)
            if ((facility.isDelete !== true && facility.status === true)) {
                facilityList.push(facility)
            }
            if (facility.isDelete !== true) {
                facilityListIncludeInactive.push(facility)
            }
        })
    }

    return {
        facilityList,
        facilityListIncludeDelete,
        facilityListIncludeInactive
    }
}

export const facilityNameListMapper = (
    facilities: UseQueryResult<ListFacilitiesQuery>
) => {
    const facilityNameList: FacilityNameType[] = []
    const facilityNameListIncludeDelete: FacilityNameType[] = []

    if (facilities.isSuccess) {
        const { data } = facilities;

        data.listFacilities.Facilities.forEach((facility: any) => {
            facilityNameListIncludeDelete.push(facility);
            if (facility.isDelete !== true) {
                facilityNameList.push(facility);
            }
        })
    }

    return {
        facilityNameList,
        facilityNameListIncludeDelete
    }
}