import { useState } from 'react';
import { openEditForm, resetFormState } from '../../util';
import { ConfigHeader } from '../../../Common/components/Config/components/ConfigHeader';
import { MultiSelect, Table } from '@mantine/core';
import { Dialog, DialogContent } from '@mui/material'
import {
  $styledTR,
  _styledTH,
  _styledTBody,
  _styledTHead,
} from '@/components/Common/components/Config';
import { AddFacilityRow, EditFacilityRow, ReferenceFacilityRow } from '../parts';
import { EditingGroupsType, FacilityPresenterPropsType } from '../../entities';

const tableHeadData = [
  '',
  '',
  '施設',
  '所属グループ',
  'ステータス',
  '', // 編集・削除用カラム
];

// [施設]プレゼンター
export const FacilityPresenter: React.FC<FacilityPresenterPropsType> = ({
  clinic_id,
  facilityList,
  allGroupList,
  groupsObj,

  lastIndexNum,

  addFacilityMutation,
  updateFacilityMutation,
  deleteFacilityMutation,
  updateFacilityIdxMutation
}) => {
  // 新規登録行の開閉
  const [isOpenAddFacilityRow, setIsOpenAddFacilityRow] = useState(false);
  // 編集・参照行の切り替え・・・編集中の行のid
  const [editingFacilityId, setEditingFacilityId] = useState('');
  // フォームバリュー(編集中のグループ名)
  const [editingFacilityName, setEditingFacilityName] = useState('');
  const [editingStatus, setEditingStatus] = useState(true);

  // 施設グループ
  //FIXME
  const [selectedGroupOptions, setSelectedGroupOptions] = useState<any[]>([])
  const selectableGroupOptions = allGroupList?.map(group => ({
    value: group.group_id,
    label: group.name
  }));
  const editingGroupIds = selectedGroupOptions.map(group => group.value)


  // 削除許可フラグ・・・チェックボックスにチェックが入っている行のid
  const [allowedDeletes, setAllowedDeletes] = useState(['']);
  // 検索ワード
  const [searchWord, setSearchWord] = useState('');
  // マルチセレクトモーダル
  const [isOpenSelectModal, setIsOpenSelectModal] = useState(false)

  const filteredOptions = selectableGroupOptions?.filter(option => {
    // groupsObjのすべてのgroup_idを取得
    const allGroupIds = new Set(Object.values(groupsObj).flat().map(item => item.group_id));
    // オプションのvalueがセットに存在する場合、そのオプションを結果に含める
    return allGroupIds.has(option.value);
  }) || [];

  // 検索ワードでfacilityListをフィルタリング
  const filteredFacilityList = facilityList.filter((facility) => {
    return (
      facility.name.includes(searchWord) ||
      groupsObj[facility.id]?.some((group) => group.name.includes(searchWord))
    );
  })

  console.log('aa', groupsObj)

  return (
    <>
      {
        isOpenSelectModal &&
        <MultiSelectModal
          selectedGroupOptions={selectedGroupOptions}
          selectableGroupOptions={selectableGroupOptions}
          setSelectedGroupOptions={setSelectedGroupOptions}
          onClose={() => { setIsOpenSelectModal(false) }}
        />
      }
      {/* 設定画面共通ヘッダー */}
      <ConfigHeader
        searchBoxProps={{
          searchWord,
          setSearchWord,
        }}
        toggleButtonProps={{
          label: '施設追加',
          isOpenAddRow: isOpenAddFacilityRow,
          setIsOpenAddRow: setIsOpenAddFacilityRow,
          onToggle: () => {
            setIsOpenAddFacilityRow(!isOpenAddFacilityRow);
            resetFormState(setEditingFacilityId, setEditingFacilityName, setSelectedGroupOptions, setEditingStatus);
          },
        }}
        basicButtonProps={{
          onClick: () => { },
          disabled: allowedDeletes[0].length === 0
        }}
        //FIXME
        isTransactDelete={false}
      />

      {/* 施設テーブル */}
      <Table verticalSpacing="xs" fontSize="xs">
        {/* テーブルヘッダー */}
        <_styledTHead>
          {/* 新規追加フォーム */}
          {isOpenAddFacilityRow && (
            <AddFacilityRow
              editingGroupList={selectedGroupOptions}
              editingFacilityName={editingFacilityName}
              setEditingFacilityName={setEditingFacilityName}
              editingStatus={editingStatus}
              setEditingStatus={() => { setEditingStatus(!editingStatus) }}
              resetFormState={() => {
                resetFormState(setEditingFacilityId, setEditingFacilityName, setSelectedGroupOptions, setEditingStatus);
                setIsOpenAddFacilityRow(false);
              }}
              addFacilityMutation={() => {
                addFacilityMutation({ clinic_id, name: editingFacilityName, status: editingStatus, group_ids: editingGroupIds, })
                resetFormState(setEditingFacilityId, setEditingFacilityName, setSelectedGroupOptions, setEditingStatus);
                setIsOpenAddFacilityRow(false);
              }

              }
              setIsOpenSelectModal={() => setIsOpenSelectModal(true)}
            />
          )}
          <$styledTR border={false}>
            {tableHeadData.map((label, index) => (
              <_styledTH key={index}>{label}</_styledTH>
            ))}
          </$styledTR>
        </_styledTHead>

        {/* テーブルボディ */}
        <_styledTBody>
          {/* 施設一覧 */}
          {filteredFacilityList.map((facility) => (
            // 施設
            <>
              {editingFacilityId === facility.id ? (
                // 編集フォーム
                <EditFacilityRow
                  key={facility.id}
                  editingFacilityName={editingFacilityName}
                  setEditingFacilityName={setEditingFacilityName}
                  editingStatus={editingStatus}
                  setEditingStatus={() => { setEditingStatus(!editingStatus) }}
                  editingGroupList={selectedGroupOptions}
                  setIsOpenSelectModal={() => setIsOpenSelectModal(true)}
                  updateFacilityMutation={() => {
                    updateFacilityMutation({ clinic_id, id: facility.id, name: editingFacilityName, status: editingStatus, group_ids: editingGroupIds, index: facility.index, })
                    resetFormState(setEditingFacilityId, setEditingFacilityName, setSelectedGroupOptions, setEditingStatus);
                  }}
                  resetFormState={() => {
                    setIsOpenAddFacilityRow(false);
                    resetFormState(setEditingFacilityId, setEditingFacilityName, setSelectedGroupOptions, setEditingStatus);
                  }}
                />
              ) : (
                // 参照
                <ReferenceFacilityRow
                  key={facility.id}
                  facilityId={facility.id}
                  facilityName={facility.name}
                  facilityStatus={facility.status}
                  editingFacilityId={editingFacilityId}
                  groups={groupsObj[facility.id] || []}
                  openEditForm={() => {
                    //groupsObj[facility.id]から取り出した配列オブジェクトを{value: group_id, label: name}の形式に変換
                    const groupOptions = groupsObj[facility.id]?.map(group => ({
                      value: group.group_id,
                      label: group.name
                    })) || [];
                    setIsOpenAddFacilityRow(false);
                    openEditForm(
                      facility.id,
                      facility.name,
                      groupOptions,
                      facility.status,
                      setEditingFacilityName,
                      setEditingFacilityId,
                      setSelectedGroupOptions,
                      setEditingStatus
                    );
                  }}

                  deleteFacility={() => { deleteFacilityMutation({ clinic_id, id: facility.id }) }}

                  setAllowedDeletes={() => {
                    if (allowedDeletes.find((id) => id === facility.id)) {
                      setAllowedDeletes((prev) => prev.filter((id) => id !== facility.id));
                    } else {
                      setAllowedDeletes((prev) => [...prev, facility.id]);
                    }
                  }}
                  isAllowed={allowedDeletes.find((id) => id === facility.id) ? true : false}
                  updateFacilityIdxMutation={updateFacilityIdxMutation}
                  lastIndexNum={lastIndexNum}

                  //FIXME
                  facility={facility}
                  updateFacilityMutation={updateFacilityMutation}
                  facilityList={facilityList}
                />
              )}
            </>
          ))}
        </_styledTBody>
      </Table>
    </>
  );
};

interface MultiSelectModalPropsType {
  selectedGroupOptions: EditingGroupsType[],
  selectableGroupOptions: any[],
  setSelectedGroupOptions: React.Dispatch<React.SetStateAction<EditingGroupsType[]>>,
  onClose: () => void
}

const MultiSelectModal: React.FC<MultiSelectModalPropsType> = ({
  selectableGroupOptions,
  selectedGroupOptions,
  setSelectedGroupOptions,
  onClose
}) => {
  // selectedGroupOptionsからidを抽出した文字列配列を取得
  const value = selectedGroupOptions.map(option => option.value);

  const handleSelectionChange = (newSelection: string[]) => {
    // selectableGroupOptionsからnewSelectionに一致するidを持つものだけをフィルタリング
    const updatedSelection = selectableGroupOptions.filter(option => newSelection.includes(option.value));
    setSelectedGroupOptions(updatedSelection);
  };

  return (
    <>
      {/* ダイアログ */}
      <Dialog
        onClose={onClose}
        open={true}
        PaperProps={{
          style: {
            maxWidth: 'none',
            width: '20%',
            transition: 'all .2s'
          }
        }}
      >
        <DialogContent
          dividers
          style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', height: '20vh', position: 'relative' }}
        >
          <MultiSelect
            data={selectableGroupOptions}
            value={value}
            onChange={handleSelectionChange}
            placeholder="グループ選択"
          />
        </DialogContent>
      </Dialog>
    </>
  )
}