import { HolidaysType } from "@/domain/Common";
import { addDays, getUnixTime, startOfDay } from "date-fns";

export function getAddDay(date: Date, plusDays: number): Date {
    return addDays(date, plusDays)
}

export function getIsHoliday(timestamp: Date, holidays: HolidaysType | undefined) {
    if (holidays === undefined) return false;

    const holiday = holidays[getUnixTime(startOfDay(timestamp))]
    const isHoliday = holiday !== undefined
    return isHoliday;
}