import { UpsertRowType } from "@/components/ClinicSettings/entities";

// [テーブルパーツ]テーブル行
export function BodyRow({
    children,
}: UpsertRowType): JSX.Element {
    return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            {children}
        </tr>
    )
}