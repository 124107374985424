import classnames from 'classnames'
import { SidebarLink } from '@/feature/phr-29/components/Sidebar/SidebarLink'
import { SidebarLogo } from '@/feature/phr-29/components/Sidebar/SidebarLogo'
import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useMediaQuery } from '@mantine/hooks'
import { use100vh } from 'react-div-100vh'
import Div100vh from "react-div-100vh"
import { getStartTimeOfToDayTimestamp } from '@/components/Common/utils'

interface SidebarTypes {
  children: React.ReactElement
}

interface contentsType {
  text: string
  path: string
  disabled: boolean
}

const contents: contentsType[] = [
  {
    text: 'トップ',
    path: `/main/?calendar-date=${getStartTimeOfToDayTimestamp(new Date())}`,
    disabled: false
  },
  {
    text: 'クリニック設定',
    path: '/settings',
    disabled: false
  },
  {
    text: '配信設定',
    path: '/',
    disabled: true
  },
  {
    text: '支払管理',
    path: '/',
    disabled: true
  },
  {
    text: 'スタッフ',
    path: '/settings/staff',
    disabled: false
  },
  {
    text: '診療メニュー',
    path: '/settings/menu',
    disabled: false
  },
  {
    text: '施設管理',
    path: '/settings/facility',
    disabled: false
  }
]

export const Sidebar: React.FC<SidebarTypes> = ({ children }) => {

  const matches = useMediaQuery('(max-width: 982px)');
  const [isOpen, setIsOpen] = useState(true)
  const height = use100vh()

  // css
  const headerCommonStyle =
    `${matches ? 'hidden' : ''} bg-gray-50 rounded dark:bg-gray-800 overflow-hidden`
  const headerOpenStyle = `${headerCommonStyle} w-[16%] px-3`
  // const headerCloseStyle = `${headerCommonStyle} w-16`
  // const headerStyle = {
  //   [headerOpenStyle]: isOpen,
  //   [headerCloseStyle]: !isOpen
  // }

  return (
    <>
      <div className="flex" style={{ height: height || '100%' }}>
        <aside className={`${headerOpenStyle}`}>
          <div className="overflow-y-auto pt-12">
            <ul className={'min-w-[150px] h-[80vh] space-y-2 pt-8'}>
              {/* サイドバー */}
              <SidebarLogo isOpen={isOpen} />
              {contents
                .filter((content) => !content.disabled)
                .map(({ text, path }) => {
                  return (
                    <SidebarLink
                      text={text}
                      path={path}
                      key={text}
                      isOpen={isOpen}
                    />
                  )
                })}
            </ul>
          </div>
        </aside>
        {/* コンテンツ */}
        <div className="w-full origin-right">{children}</div>
      </div>
    </>
  )
}
