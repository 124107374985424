import { Badge } from "@mantine/core";
import { AppointmentStatusConfirm } from "../../AppointmentStatusConfirm";
import { useState } from "react";
import { AppointmentStatusBadgePropsType } from "@/components/Appointment/entities";

export const AppointmentStatusBadge: React.FC<AppointmentStatusBadgePropsType> = ({
    appoint,
    id,
    confirmed,
}) => {

    const [isConfirm, setIsConfirm] = useState(false);


    const appointStatusData = [
        {
            status: '予約済み',
            statusName: '予約',
            styles: 'bg-green-200 text-green-900 hover:bg-green-300 cursor-default'
        },
        {
            status: '受付済み',
            statusName: '受付',
            styles: 'bg-blue-300 text-blue-900 hover:bg-blue-400 cursor-default'
        },
        {
            status: '診療完了',
            statusName: '完了',
            styles: 'bg-yellow-200 text-yellow-900 hover:bg-yellow-300 cursor-default'
        },
        {
            status: 'キャンセル',
            statusName: 'キャンセル',
            styles: 'bg-red-300 text-red-900 hover:bg-red-400 cursor-default'
        }
    ]

    return (
        <>
            <>
                {appointStatusData?.map(({ status, statusName, styles }) => {
                    if (appoint === status) {
                        return (
                            //FIXME: eオブジェクトのany型を正しく定義する
                            <Badge
                                className={`${styles} font-bold`}
                                variant="filled"
                                onClick={(e: any) => { e.preventDefault(); setIsConfirm(true) }}>
                                {`${statusName}`}
                            </Badge>

                        )
                    }
                })}
            </>
            <AppointmentStatusConfirm
                id={id}
                confirmed={confirmed}
                isConfirm={isConfirm}
                setIsConfirm={setIsConfirm}
            />
        </>
    )
}

