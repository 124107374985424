import { Dialog, DialogContent } from '@mui/material'
import { CategoryType, MenuType } from '@/feature/phr-28/components/common/type'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { emptyMenu } from '../../../common/emptyData'
import { getTreatmentTime } from './utils/util'
import { getShiftEndTime } from '@/components/Common/utils'

interface SelectMenuModalType {
    currentShiftTimestamp: number
    setOpenMedicalCategory: React.Dispatch<React.SetStateAction<boolean>>
    currentMenuList: MenuType[],
    setCurrentMenuList: React.Dispatch<React.SetStateAction<MenuType[]>>,
    currentCategoryName: string,
    setCurrentCategoryName: React.Dispatch<React.SetStateAction<string>>,
    setOpenMedicalMenuModal: React.Dispatch<React.SetStateAction<boolean>>,
}

function getTreatmentTimeByMenuId(menuId: string, menuList: MenuType[] = []) {
    if (menuList?.length === 0) {
        alert('menu.length === 0')
        return 15;
    }
    const menu = menuList.find((menu) => menu.SDK_ID === menuId);
    return menu ? menu.time : 15;
}

function clearState(
    setCurrentMenuList: React.Dispatch<React.SetStateAction<MenuType[]>>,
    setCurrentCategoryName: React.Dispatch<React.SetStateAction<string>>,
    setOpenMedicalMenuModal: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenMedicalCategory: React.Dispatch<React.SetStateAction<boolean>>,
    clearType: 'close' | 'back'
) {
    switch (clearType) {
        case 'close':
            setCurrentMenuList([emptyMenu])
            setCurrentCategoryName('')
            setOpenMedicalMenuModal(false)
            setOpenMedicalCategory(false)
            break;

        case 'back':
            setCurrentMenuList([emptyMenu])
            setCurrentCategoryName('')
            setOpenMedicalMenuModal(false)
            break;
    }
}

function createHandleClose(
    setCurrentMenuList: React.Dispatch<React.SetStateAction<MenuType[]>>,
    setCurrentCategoryName: React.Dispatch<React.SetStateAction<string>>,
    setOpenMedicalMenuModal: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenMedicalCategory: React.Dispatch<React.SetStateAction<boolean>>,
    clearState: (setCurrentMenuList: React.Dispatch<React.SetStateAction<MenuType[]>>, setCurrentCategoryName: React.Dispatch<React.SetStateAction<string>>, setOpenMedicalMenuModal: React.Dispatch<React.SetStateAction<boolean>>, setOpenMedicalCategory: React.Dispatch<React.SetStateAction<boolean>>, clearType: 'close' | 'back') => void
) {
    return function handleClose() {
        const clearType = 'close'
        clearState(setCurrentMenuList, setCurrentCategoryName, setOpenMedicalMenuModal, setOpenMedicalCategory, clearType);
    }
}


export const MedicalMenuModal: React.FC<SelectMenuModalType> = ({
    currentShiftTimestamp,
    setOpenMedicalCategory,
    currentMenuList,
    setCurrentMenuList,
    currentCategoryName,
    setCurrentCategoryName,
    setOpenMedicalMenuModal,
}) => {

    const { register, getValues, setValue, watch } = useFormContext()

    return (
        <>
            <Dialog
                transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
                onClose={createHandleClose(setCurrentMenuList, setCurrentCategoryName, setOpenMedicalMenuModal, setOpenMedicalCategory, clearState)}
                open={true}
                PaperProps={{
                    style: {
                        maxWidth: 'none',
                        width: '80%',
                        transition: 'all 0',
                        zIndex: 9999
                    }
                }}
            >
                {/* content */}
                <DialogContent
                    dividers
                    style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', height: '90vh', position: 'relative' }}
                >
                    <div className="px-8">
                        <header className="sticky z-10 w-full top-0 pt-8 bg-white bg-opacity-90">
                            <p className="text-xl spacing-ls tracking-wider border-b border-gray-600 pb-2 mb-8"><span className="text-red-500">「{currentCategoryName}」</span>からメニューを選択してください。</p>

                            <div className="flex gap-4 justify-end mb-4">
                                <div className="flex justify-end">
                                    <button
                                        type="button"
                                        className="text-white w-48 bg-red-700 font-medium rounded-md inline-flex justify-center items-center text-xl h-12 mr-6"
                                        onClick={() => {
                                            const clearType = 'back'
                                            clearState(setCurrentMenuList, setCurrentCategoryName, setOpenMedicalMenuModal, setOpenMedicalCategory, clearType)
                                            // setOpen(false)
                                        }}
                                    >
                                        戻る
                                    </button>
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        type="button"
                                        className="text-white w-48 bg-red-700 font-medium rounded-md inline-flex justify-center items-center text-xl h-12 mr-6"
                                        onClick={() => {
                                            const clearType = 'close'
                                            clearState(setCurrentMenuList, setCurrentCategoryName, setOpenMedicalMenuModal, setOpenMedicalCategory, clearType)
                                            // setOpen(false)
                                        }}
                                    >
                                        閉じる
                                    </button>
                                </div>
                            </div>
                        </header>

                        <div className="menuList">
                            <div className="grid grid-cols-3 gap-4">
                                {currentMenuList.map(menu => {
                                    return (
                                        <div key={menu.SDK_ID} className="relative" >
                                            <label
                                                className={`${watch('appointData.menuId') === menu.SDK_ID ? 'bg-gray-100 text-blue-500 font-bold' : 'bg-white'} w-full h-12 inline-flex justify-center tracking-wider text-xl relative flex items-center rounded border border-gray-300 bg-white shadow`}
                                                onClick={() => {
                                                    setValue('appointData.menuId', menu.SDK_ID)
                                                    const treatmentMinutes = getTreatmentTimeByMenuId(menu.SDK_ID, currentMenuList);
                                                    const endTime = getShiftEndTime(currentShiftTimestamp, treatmentMinutes)
                                                    setValue('appointData.endTime', endTime)
                                                    const closeType = 'close'
                                                    clearState(setCurrentMenuList, setCurrentCategoryName, setOpenMedicalMenuModal, setOpenMedicalCategory, closeType)
                                                    // setOpen(false)
                                                }}
                                            >
                                                <input {...register('appointData.menuId')} className={`${getValues('appointData.menuId') === menu.SDK_ID ? '' : 'hidden'} checked:bg-none h-4 w-4 translate-y-[-50%] translate-x-[-50%] absolute top-0 left-0`} checked={watch('appointData.menuId') === menu.SDK_ID} type="radio" value={menu.SDK_ID} />
                                                <div className="w-full h-full flex items-center">
                                                    <p className={`${watch('appointData.menuId') === menu.SDK_ID ? 'bg-blue-500' : 'bg-gray-400'} text-white rounded-l inline-flex justify-center items-center h-full w-16`}>{menu.index}</p>
                                                    <p className="w-full px-4 text-center truncate">{menu.abbreviation || menu.name}</p>
                                                </div>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}