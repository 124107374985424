import { ClinicType, DeleteFacilityType, UpdateFacilityType } from '@/components/Common';
import { FacilityType } from '@/components/Facility/entities';
import { FloorType } from '@/components/Floor/entities';
import { useFacilityList_service } from '@/domain';
import { GraphQLClient } from 'graphql-request';

export function getLastIndexNumFromFacilityList(facilityList: FacilityType[]): number {
  let lastIndex: number = 0;
  facilityList.forEach((facility: FacilityType) => {
    if (facility.index !== null && facility.index !== undefined) {
      if (lastIndex < facility.index) {
        lastIndex = facility.index;
      }
    }
  });
  return lastIndex;
}

export function getOrderedFacilityList(tmpfacilityList: FacilityType[]) {
  // ソートした施設一覧の作成
  const facilityListIndex: FacilityType[] = [];
  const facilityListIndexNull: FacilityType[] = [];
  tmpfacilityList.forEach((facility: FacilityType) => {
    if (facility.index === null) {
      facilityListIndexNull.push(facility);
    } else {
      facilityListIndex.push(facility);
    }
  });
  facilityListIndex.sort((a, b) => a.index - b.index);
  facilityListIndexNull.sort((a, b) => a.id.localeCompare(b.id, 'ja'));
  const facilityList: FacilityType[] = facilityListIndex.concat(facilityListIndexNull);
  return facilityList;
}

export const useOrderedFacilityList = (graphqlClient: GraphQLClient, clinic_id: string) => {
  const allFacilityList = useFacilityList_service(graphqlClient, { clinic_id });
  const tmpfacilityList = allFacilityList.facilityList;

  const facilityListIndex: FacilityType[] = [];
  const facilityListIndexNull: FacilityType[] = [];
  const orderedFacilityList: FacilityType[] = facilityListIndex.concat(facilityListIndexNull);

  tmpfacilityList.forEach((facility: FacilityType) => {
    if (facility.index === null) {
      facilityListIndexNull.push(facility);
    } else {
      facilityListIndex.push(facility);
    }
  });

  facilityListIndex.sort((a, b) => a.index - b.index);
  facilityListIndexNull.sort((a, b) => a.id.localeCompare(b.id, 'ja'));

  orderedFacilityList;
};

export function getFloorName(floorList: FloorType[], floor_id: string): string {
  let floorName: string = '';
  floorList?.forEach((floor: FloorType) => {
    if (floor.floor_id === floor_id) {
      if (floor.isDelete) {
        floorName = `${floor.floorName}(削除済み)`;
        return;
      }
      floorName = floor.floorName;
    }
  });
  return floorName;
}

export function deleteFacilityExecute(
  deleteFacility: DeleteFacilityType,
  record: FacilityType,
  clinic: ClinicType
): void {
  // ここでdelete処理を実行
  deleteFacility.mutate({ id: record.id, clinic_id: clinic.clinic_id });
  // record.isDelete = true
  // updateFacility.mutate(record)
  // const tmpFacilityList: FacilityType[] = facilityList.filter((facility: FacilityType) => {
  //   return facility.id !== record.id
  // })
  // modifyIndexWhenDelete(tmpFacilityList)
}
// 削除した後にindexを再振り
// const modifyIndexWhenDelete = (tmpFacilityList: FacilityType[]): void => {
//   let tmpIndex: number = 0
//   tmpFacilityList.forEach((facility: FacilityType, index: number) => {
//     facility.index = ++tmpIndex
//     updateFacility.mutate(facility)
//   })
// }

export function deletingPressingDown(
  isCheck: boolean,
  setIsCheck: (value: React.SetStateAction<boolean>) => void,
  deleteFacility: DeleteFacilityType,
  record: FacilityType,
  clinic: ClinicType
): void {
  if (isCheck) {
    setIsCheck(false);
    deleteFacilityExecute(deleteFacility, record, clinic);
  }
}

