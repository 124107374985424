import { SetDateContext } from '@/components/Main/provider/MainProvider'
// import { AppointmentList } from '../Appointment/AppointmentList'
import { useNavigate } from '@tanstack/react-location'
import { getUnixTime, startOfDay } from 'date-fns'
import React, { useContext, useEffect } from 'react'

export const DashBoard: React.FC = () => {
  //現在はログインリダイレクト後の中継地点としてのみ機能
  const navigate = useNavigate();
  useEffect(() => {
    navigate({ to: `/main/?calendar-date=${getUnixTime(startOfDay(new Date()))}` })
  }, [])

  return (
    <div className="flex flex-col justify-center gap-6 h-screen px-24">
      {/* <div className="flex justify-end pt-5">
        <Link
          to={'/qr-recept'}
          className="leading-8 text-gray-100 text-sm font-medium h-8 w-28 text-center rounded-2xl bg-gray-700 hover:bg-gray-800"
        >
          QR受付
        </Link>
      </div>
      <div className="overflow-y-scroll h-[46%] w-full border border-black">
        <AppointmentList />
      </div>
      <div className="h-[46%] w-full border border-black">
        <WeeklyCalendar />
      </div> */}
    </div>
  )
}
