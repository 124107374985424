import { EditFacilityRowProps } from '../../entities';
import { Checkbox, Flex, MultiSelect, TextInput } from '@mantine/core';
import { BasicButton, $styledTR, _styledTD } from '@/components/Common/components/Config';
import { MultiSelectModalOpener } from './MultiSelectModalOpener';

// 編集フォーム（施設グループ）
export const EditFacilityRow: React.FC<EditFacilityRowProps> = ({
  editingFacilityName, // 編集中の施設名
  editingStatus, // 編集中のステータス,
  setEditingStatus, // 編集中のステータスをセットする関数
  setEditingFacilityName, // 編集中の施設名をセットする関数
  resetFormState,
  editingGroupList,
  setIsOpenSelectModal,
  updateFacilityMutation
}) => {
  return (
    // テーブルロウ
    <$styledTR border={false}>
      {/* テーブルカラム */}
      <_styledTD>
        {/* チェックボックス */}
        <Checkbox
          disabled
          // checked={isAllowed}
          radius="xs"
          size="xs"
        />
      </_styledTD>

      <_styledTD>
      </_styledTD>

      <_styledTD>
        {/* 施設名 */}
        <TextInput
          placeholder="施設名"
          size="xs"
          required
          value={editingFacilityName}
          onChange={(e) => {
            setEditingFacilityName(e.target.value);
          }}
        />
      </_styledTD>
      <_styledTD>
        <MultiSelectModalOpener
          editingGroupList={editingGroupList}
          setIsOpenSelectModal={() => setIsOpenSelectModal()}
        />
      </_styledTD>

      <_styledTD>
        {/* ステータス */}
        <Checkbox
          checked={editingStatus}
          onChange={setEditingStatus}
          radius="xs"
          size="xs"
        />
      </_styledTD>

      <_styledTD>
        {/* ボタン */}
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          gap={{ base: 'sm', sm: 'sm' }}
          justify={{ sm: 'center' }}
        >
          {/* 更新ボタン */}
          <BasicButton
            label="更新"
            width="small"
            color="gray"
            disabled={editingFacilityName === ''}
            onClickEvent={() => {
              updateFacilityMutation();
              setEditingFacilityName('');
            }}
          />
          {/* キャンセルボタン */}
          <BasicButton
            label="キャンセル"
            width="small"
            color="gray"
            disabled={false}
            onClickEvent={() => {
              resetFormState();
            }}
          />
        </Flex>
      </_styledTD>
    </$styledTR>
  );
};

export default EditFacilityRow;
