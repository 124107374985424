import { ClinicTimeListType } from '@/components/Common/entities';
import { format, addMinutes, differenceInMinutes, parse, isWithinInterval } from 'date-fns';
import { ClinicTimeConfigType, TimeSlot } from '../../entities';

function generateTimeSlots(
    interval: number,
    isStart: boolean,
    limitTime: string
): string[] {
    const startTime = '05:00';
    const endTime = '24:00';

    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const start = new Date();
    start.setHours(startHours, startMinutes, 0, 0);

    const end = new Date();
    end.setHours(endHours, endMinutes, 0, 0);

    const timeSlots: string[] = [];
    const totalMinutes = differenceInMinutes(end, start);
    const limit = new Date();
    const [limitHours, limitMinutes] = limitTime.split(':').map(Number);
    limit.setHours(limitHours, limitMinutes, 0, 0);

    for (let i = 0; i <= totalMinutes; i += interval) {
        const newTimeSlot = addMinutes(start, i);
        const formattedTimeSlot = format(newTimeSlot, 'HH:mm');

        if (isStart) {
            if (newTimeSlot < addMinutes(limit, -15)) {
                timeSlots.push(formattedTimeSlot);
            }
        } else {
            if (newTimeSlot >= addMinutes(limit, 15)) {
                timeSlots.push(formattedTimeSlot);
            }
        }
    }

    return timeSlots;
}

function generateTimeSlotsObj(
    interval: number,
    isStart: boolean,
    limitTime: string
): TimeSlot[] {
    const startTime = '05:00';
    const endTime = '24:00';

    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const start = new Date();
    start.setHours(startHours, startMinutes, 0, 0);

    const end = new Date();
    end.setHours(endHours, endMinutes, 0, 0);

    const timeSlots: TimeSlot[] = [];
    const totalMinutes = differenceInMinutes(end, start);
    const limit = new Date();
    const [limitHours, limitMinutes] = limitTime.split(':').map(Number);
    limit.setHours(limitHours, limitMinutes, 0, 0);

    for (let i = 0; i <= totalMinutes; i += interval) {
        const newTimeSlot = addMinutes(start, i);
        const formattedTimeSlot = format(newTimeSlot, 'HH:mm');

        if (isStart) {
            if (newTimeSlot < addMinutes(limit, -15)) {
                timeSlots.push({
                    label: formattedTimeSlot,
                    value: { hours: newTimeSlot.getHours(), minutes: newTimeSlot.getMinutes() }
                });
            }
        } else {
            if (newTimeSlot >= addMinutes(limit, 15)) {
                timeSlots.push({
                    label: formattedTimeSlot,
                    value: { hours: newTimeSlot.getHours(), minutes: newTimeSlot.getMinutes() }
                });
            }
        }
    }

    return timeSlots;
}


function updateEndTimeIfNeeded(
    startHours: number,
    startMinutes: number,
    endHours: number,
    endMinutes: number,
    setEndHours: (value: number) => void,
    setEndMinutes: (value: number) => void
): void {
    const startDateTime = new Date().setHours(startHours, startMinutes, 0, 0);
    const endDateTime = new Date().setHours(endHours, endMinutes, 0, 0);

    if (startDateTime >= endDateTime) {
        const newEndTime = addMinutes(startDateTime, 15);
        const newEndHours = newEndTime.getHours();
        const newEndMinutes = newEndTime.getMinutes();
        setEndHours(newEndHours);
        setEndMinutes(newEndMinutes);
    }
}

export {
    generateTimeSlots,
    updateEndTimeIfNeeded,
    generateTimeSlotsObj,
    calculateEndTime
}

function calculateEndTime(startTime: string, endTime: string, interval: number, setEndTime: React.Dispatch<React.SetStateAction<string>>) {
    const start = parse(startTime, 'H:mm', new Date());
    const end = parse(endTime, 'H:mm', new Date());

    // start >= endならendTimeの初期スロットをstart+interval時とする
    if (start >= end) {
        const EndTimeSlot = addMinutes(start, interval);
        setEndTime(format(EndTimeSlot, 'H:mm'));
    }

    // start < endならendTimeの初期スロットに変更なし
    return
}

export function isOverlapping(
    startDate: number,
    endDate: number,
    clinicTimeList: ClinicTimeConfigType[],
    config_id?: string
): boolean {
    const inputRange = {
        start: new Date(startDate + 100),
        end: new Date(endDate - 100),
    };

    return clinicTimeList.some((clinicTime) => {
        if (clinicTime.config_id && clinicTime.config_id === config_id) {
            return false;
        }

        const clinicRange = {
            start: new Date(clinicTime.startDate),
            end: new Date(clinicTime.endDate),
        };

        return (
            isWithinInterval(inputRange.start, clinicRange) ||
            isWithinInterval(inputRange.end, clinicRange) ||
            (inputRange.start <= clinicRange.start && inputRange.end >= clinicRange.end)
        );
    });
}

