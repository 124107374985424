import { useGetHolidays } from '@/domain'
import { MonthCalendarPresenter } from '@/components/MonthCalendar/components/presenter/MonthCalendarPresenter'
import { useContext } from 'react'
import { MediaQueryContext, SetDateContext } from '@/components/Main/provider/MainProvider'

export const MonthCalendarViews: React.FC = () => {
    const holidays = useGetHolidays()
    const { nowDate, setNowDate } = useContext(SetDateContext)
    const matches = useContext(MediaQueryContext);

    return (
        matches === false ?
            <MonthCalendarPresenter
                nowDate={nowDate}
                setNowDate={setNowDate}
                holidays={holidays}
            /> : <></>
    )
}
