import { useContext, useState } from 'react';
import { useDeleteAppointmentDialog } from '@/components/Appointment/hooks/useDeleteAppointmentDialog';
import { getFacilityName } from '@/feature/phr-28/components/common/facility';
import { getMenuName } from '@/components/Common/utils';
import { getManegerNameById } from '@/components/Common/utils';

import { AppointmentCardPresenter } from '../presenter/AppointmentCardPresenter';
import { AppointmentCardViewsType } from '../utils/common/type';
import { useGetActiveScheduleQuery } from '@/_graphql/graphql-client';
import { getUnixTime, startOfDay } from 'date-fns';
import { ClinicContext, useGraphqlClient } from '@/App';

export const AppointmentCardViews: React.FC<AppointmentCardViewsType> = ({
  nowDate,
  id,
  startTime,
  endTime,
  facilityId,
  appointId,
  confirmed,
  appointment,
  reservation,
  patientName,
}) => {
  const graphqlClient = useGraphqlClient();
  const clinic = useContext(ClinicContext);

  const [isConfirm, setIsConfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { DeleteAppointmentDialog, setCurrentAppointment } = useDeleteAppointmentDialog();
  const schedule = useGetActiveScheduleQuery(graphqlClient, { clinic_id: clinic.clinic_id, current_date: startTime })
  const isBeforeDay = schedule.data?.getActiveSchedule?.isBeforeDay

  return (
    <AppointmentCardPresenter
      isBeforeDay={isBeforeDay || false}
      id={id}
      confirmed={confirmed}
      isConfirm={isConfirm}
      setIsConfirm={setIsConfirm}
      patientName={patientName}
      facilityName={reservation.facilityName}
      menuName={reservation.menuName || ''}
      manegerName={reservation.staffNames[0].name}
      appointment={appointment}
      nowDate={nowDate}
      startTime={startTime}
      endTime={endTime}
      facilityId={facilityId}
      appointId={appointId}
      setIsOpen={setIsOpen}
      setCurrentAppointment={setCurrentAppointment}
      reservation={reservation}
      isOpen={isOpen}
      DeleteAppointmentDialog={DeleteAppointmentDialog}
    />
  );
};
