import { UseQueryResult } from "react-query"
import { ListMenuQuery, ListMenuNameQuery } from "@/domain/Menu/entities/types"
import { MenuNameType, MenuType } from "@/components/Menu/entities"

export const menuListMapper = (
    menus: UseQueryResult<ListMenuQuery, unknown>
) => {
    const menuList: MenuType[] = []
    const menuListIncludeDelete: MenuType[] = []

    if (menus.isSuccess) {
        const { data } = menus

        data.listMenu.Menus.forEach((menu: any) => {
            if (menu.isDelete !== true && menu.status === '有効') {
                menuList.push(menu)
            }
            menuListIncludeDelete.push(menu)
        })
    }

    return {
        menuList,
        menuListIncludeDelete
    }
}

export const menuNameListMapper = (
    menus: UseQueryResult<ListMenuNameQuery, unknown>
) => {
    const menuNameList: MenuNameType[] = [];

    if (menus.isSuccess) {
        const { data } = menus

        data.listMenu.Menus.forEach((menu: any) => {
            menuNameList.push(menu);
        })
    }

    return menuNameList;
}