import React, { useState } from 'react'
import { MedicalMenuTableViewsType } from '../util/common/type'
import { MedicalMenuTablePresenter } from '../presenter/MedicalMenuTablePresenter'

export const MedicalMenuTableViews: React.FC<MedicalMenuTableViewsType> = ({
  filteredLists,
  categoryList,
  setSortingFactorNumber,
  isSort,
  setIsSort,
  setOrder,
  bulkDeleteArr,
  setBulkDeleteArr,
  lastIndexNum
}) => {
  const [sortingFactorNumberOfTable, setSortingFactorNumberOfTable] = useState(0)
  const [orderOfTable, setOrderOfTable] = useState('DESC')
  const bulkDelete: boolean[] = []
  for (let i = 0; i < filteredLists.length; i++) {
    bulkDelete.push(false)
  }
  return (
      <MedicalMenuTablePresenter
        filteredLists={filteredLists}
        categoryList={categoryList}
        setSortingFactorNumber={setSortingFactorNumber}
        isSort={isSort}
        setIsSort={setIsSort}
        setOrder={setOrder}
        bulkDeleteArr={bulkDeleteArr}
        setBulkDeleteArr={setBulkDeleteArr}
        lastIndexNum={lastIndexNum}
        orderOfTable={orderOfTable}
        setOrderOfTable={setOrderOfTable}
        sortingFactorNumberOfTable={sortingFactorNumberOfTable}
        setSortingFactorNumberOfTable={setSortingFactorNumberOfTable}
      />
  )
}
