import { emptyAppoint } from '@/components/Common/utils/common/emptyData';
import { MethodsContext } from '@/components/Main/provider/FormModeProvider';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AddAppointDefaultValuesType } from '../entities';

//formの初期化+methodsをグローバルステートに代入
export const useInitialFormHook = () => {
    const methodsContext = useContext(MethodsContext);

    const methods = useForm<AddAppointDefaultValuesType>({
        shouldUnregister: false,
        defaultValues: {
            appointData: { ...emptyAppoint },
            ReferenceAppointKey: {
                baseId: '',
                baseStartTime: 1
            }
        }
    });

    useEffect(() => {
        methodsContext.setMethods(methods);
    }, [methods]);

    return methods;
};
