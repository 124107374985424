import { AddCategoryMutation, AddCategoryMutationVariables, Exact, InputMaybe, UpdateCategoryMutation, UpdateCategoryMutationVariables, useAddCategoryMutation, useUpdateCategoryMutation } from '../../../../../../.graphql/types';
import { Dialog, DialogContent } from '@mui/material'
import { emptyCategory } from '../../common/emptyData';
import type { CategoryType } from '../../common/type';
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { UseMutationResult, useQueryClient } from 'react-query';
import { ClinicContext, useGraphqlClient } from '@/App';

interface AddCategoryModalType {
}

function clearState(
    setCurrentCategory: React.Dispatch<React.SetStateAction<CategoryType>>,
    setOpenAddCategory: React.Dispatch<React.SetStateAction<boolean>>,
) {
    setCurrentCategory(emptyCategory);
    setOpenAddCategory(false)
}

function onSubmit(
    data: CategoryType,
    clinic_id: string,
    currentCategory: CategoryType,
    AddCategoryMutation: UseMutationResult<AddCategoryMutation, unknown, Exact<AddCategoryMutationVariables>, unknown>,
    UpdateCategoryMutation: UseMutationResult<UpdateCategoryMutation, unknown, Exact<UpdateCategoryMutationVariables>, unknown>,
) {
    if (currentCategory.id.length === 0) {
        const addCategoryValue = {
            name: data.name,
            mainCategory: data.mainCategory,
            isDelete: false,
            clinic_id
        }
        AddCategoryMutation.mutate(addCategoryValue)
    }

    if (currentCategory.id.length !== 0) {
        const updateCategoryValue = {
            id: data.id,
            name: data.name,
            mainCategory: data.mainCategory
        }
        console.log(updateCategoryValue)
        UpdateCategoryMutation.mutate(updateCategoryValue);
    }
}

const defaultFormValues = {
    defaultValues: {
        id: '',
        name: '',
        mainCategory: '医科',
        isDelete: false
    }
}

export const useAddCategoryModal = () => {
    const graphqlClient = useGraphqlClient()
    const [currentCategory, setCurrentCategory] = useState(emptyCategory);
    const [openAddCategory, setOpenAddCategory] = useState(false);
    const queryClient = useQueryClient();

    //モーダル
    const AddCategoryModal: React.FC<AddCategoryModalType> = ({
    }) => {
        const methods = useForm(defaultFormValues);
        const clinic_id = useContext(ClinicContext).clinic_id;
        const { register, setValue } = methods;

        const mutationOption = {
            onSettled: () => {
                queryClient.invalidateQueries('listCategorys')
            },
            onSuccess: () => {
                clearState(setCurrentCategory, setOpenAddCategory);
            },
            onError: () => {
                clearState(setCurrentCategory, setOpenAddCategory);
            }
        }
        const AddCategoryMutation = useAddCategoryMutation(graphqlClient, mutationOption)
        const UpdateCategoryMutation = useUpdateCategoryMutation(graphqlClient, mutationOption)

        useEffect(() => {
            setValue('id', currentCategory.id);
            setValue('name', currentCategory.name);
            setValue('mainCategory', currentCategory.mainCategory);
            setValue('isDelete', currentCategory.isDelete);
        }, [currentCategory])

        return (
            <>
                {openAddCategory &&
                    <Dialog
                        transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
                        onClose={() => clearState(setCurrentCategory, setOpenAddCategory)}
                        open={true}
                        PaperProps={{
                            style: {
                                maxWidth: 'none',
                                width: '60%',
                                transition: 'all 0',
                                zIndex: 9999
                            }
                        }}
                    >
                        {/* content */}
                        <DialogContent
                            dividers
                            style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', height: '56vh', position: 'relative' }}
                        >
                            <FormProvider {...methods}>
                                <form
                                    onSubmit={methods.handleSubmit((data) => onSubmit(data, clinic_id, currentCategory, AddCategoryMutation, UpdateCategoryMutation))}
                                    className="flex flex-col justify-center items-center h-full gap-y-4"
                                >
                                    <p className="w-full inline-flex justify-center items-center py-3 text-xl tracking-wide">
                                        <span className="text-red-500">診療カテゴリ</span>を登録してください。
                                    </p>
                                    <div className="w-1/2 rounded-md px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                        <label className="block text-xs font-medium text-gray-900">
                                            カテゴリ名
                                            <div className="text-xl border border-gray-300 rounded">
                                                <input
                                                    type="text"
                                                    {...register('name', { required: true })}
                                                    className="px-4 h-10 inline-flex items-center block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                                                    placeholder="診療カテゴリ名"
                                                />
                                            </div>
                                        </label>
                                    </div>
                                    <div className="w-1/2 rounded-md px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                        <label className="block text-xs font-medium text-gray-900">
                                            タイプ
                                            <div className="text-xl border border-gray-300 rounded">
                                                <select
                                                    {...register('mainCategory', { required: true })}
                                                    className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                                                >
                                                    <option value="医科">医科</option>
                                                    <option value="歯科">歯科</option>
                                                </select>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="flex justify-end gap-4">
                                        <input type="submit" value="登録" className="w-36 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 py-2 text-sm font-medium text-white shadow-sm" />
                                        <p onClick={() => { clearState(setCurrentCategory, setOpenAddCategory) }} className="w-36 inline-flex justify-center items-center rounded-md border border-transparent bg-red-400 py-2 text-sm font-medium text-white shadow-sm">キャンセル</p>
                                    </div>
                                </form>
                            </FormProvider>
                        </DialogContent>
                    </Dialog >}
            </>
        )
    }

    return {
        AddCategoryModal,
        setCurrentCategory,
        openAddCategory,
        setOpenAddCategory
    }

}
