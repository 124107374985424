import { Dialog, DialogContent } from "@mui/material"

export interface DeleteConfirmationDialogType {
    label: '予約' | 'ブロック'
    onClose: () => void,
    onDelete: () => void
    isChecked: boolean
    setIsChecked: () => void
}

export const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogType> = ({
    label,
    isChecked,
    setIsChecked,
    onDelete,
    onClose
}) => {

    return (
        <>
            <Dialog
                transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
                onClose={onClose}
                open={true}
                PaperProps={{
                    style: {
                        maxWidth: 'none',
                        zIndex: 9999,
                    }
                }}
            >
                <DialogContent
                    dividers
                    style={{ padding: '0px 0px 0px 0px', minWidth: '40px', minHeight: '20px', position: 'relative' }}
                >
                    <div className="h-full relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6">
                        {
                            <>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5 mb-3">
                                        <div className="text-lg font-medium leading-6 text-gray-900">
                                            {
                                                <>
                                                    <div className="flex gap-3 items-center">
                                                        <input type="checkbox" checked={isChecked} onChange={setIsChecked} />
                                                        <p>{label}情報を<span className="text-red-600">削除</span>しますか？</p>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center mt-5">
                                    {
                                        <p
                                            onClick={onDelete}
                                            className={`${!isChecked ? 'opacity-50 pointer-events-none' : ''} inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                                        >
                                            削除する
                                        </p>
                                    }
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={onClose}
                                    >
                                        キャンセル
                                    </button>
                                </div>
                            </>
                        }
                    </div>

                </DialogContent>
            </Dialog>
        </>
    )
}