import { _styledComponentProps } from "@/components/Common"

export const _styledDivBottom: React.FC<_styledComponentProps> = ({
    children
}) => {
    return (
        <div className="flex justify-between gap-8">
            {children}
        </div>
    )
}
