import { Checkbox, Select, clsx } from "@mantine/core"
import { UpsertRecordPresenterType } from "../../entities"
import DatePicker from 'react-datepicker';
import { add, endOfDay, fromUnixTime, getUnixTime, startOfDay } from "date-fns";
import { ClinicType } from "@/components/Common";
import { AddClinicTimeConfigType, UpdateClinicTimeConfigType } from "@/components/Common/entities/common/types";
import { validateBooking } from "../../utils";
import { useAppointList_service } from "@/domain";
import { ValidateDialog } from "../tmp/ValidateDialog";
import { useState } from "react";
import { AddClinicTimeConfigMutationVariables, UpdateClinicTimeConfigMutationVariables } from "@/_graphql/graphql-client";


function isSomeTime(time1: string, time2: string) {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    const date1 = new Date();
    date1.setHours(hours1, minutes1, 0, 0);

    const date2 = new Date();
    date2.setHours(hours2, minutes2, 0, 0);

    return date1.getTime() === date2.getTime();
}

function addClinicTimeConfigMutation(
    clinic: ClinicType,
    startHours: number,
    startMinutes: number,
    endHours: number,
    endMinutes: number,
    startDate: number,
    endDate: number | null,
    addClinicTimeConfig: AddClinicTimeConfigType
) {

    const mutateValiables = {
        clinic_id: clinic.clinic_id,
        config: {
            clinicTime: {
                startTime: {
                    hours: startHours,
                    minutes: startMinutes
                },
                endTime: {
                    hours: endHours,
                    minutes: endMinutes
                }
            },
        },
        startDate: startDate,
        endDate: endDate
    }

    addClinicTimeConfig.mutate(mutateValiables)
}

function updateClinicTimeConfigMutation(
    clinic: ClinicType,
    config_id: string,
    startHours: number,
    startMinutes: number,
    endHours: number,
    endMinutes: number,
    startDate: number,
    endDate: number | null,
    updateClinicTimeConfig: UpdateClinicTimeConfigType
) {

    const mutateValiables = {
        clinic_id: clinic.clinic_id,
        config_id,
        config: {
            clinicTime: {
                startTime: {
                    hours: startHours,
                    minutes: startMinutes
                },
                endTime: {
                    hours: endHours,
                    minutes: endMinutes
                }
            },
        },
        startDate: startDate,
        endDate: endDate
    }

    updateClinicTimeConfig.mutate(mutateValiables)
}


export const UpsertRecordPresenter: React.FC<UpsertRecordPresenterType> = ({
    isUpdate,

    graphqlClient,
    clinicTimeList,

    addClinicTimeConfig,
    updateClinicTimeConfig,
    clinic,
    config_id,
    startHours,
    startMinutes,
    endHours,
    endMinutes,
    startDate,
    endDate,
    setStartHours,
    setStartMinutes,
    setEndHours,
    setEndMinutes,
    setStartDate,
    setEndDate,
    startTimeSlots,
    endTimeSlots,
    setIsOpenAddRecord,
    setEditing,
    enableBtnCss,
    disableBtnCss,
}) => {

    const clinicTime = {
        startTime: {
            hours: startHours,
            minutes: startMinutes
        },
        endTime: {
            hours: endHours,
            minutes: endMinutes
        }
    }

    const addClinicTimeVariables: AddClinicTimeConfigMutationVariables = {
        clinic_id: clinic.clinic_id,
        config: {
            clinicTime,
        },
        startDate,
        endDate
    }

    const updateClinicTimeVariables: UpdateClinicTimeConfigMutationVariables = {
        clinic_id: clinic.clinic_id,
        config_id: config_id || '',
        config: {
            clinicTime,
        },
        startDate,
        endDate
    }

    const [openValidateRule, setOpenValidateRule] = useState(false)
    const [confirmed, setConfirmed] = useState(false)

    return (
        <>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                {/* １列目・・・チェックボックス */}
                <td className="w-4 p-4 flex">
                    {/* <div className="h-full opacity-40 pointer-events-none">
                    <Checkbox
                        transitionDuration={0}
                    />
                </div> */}
                </td>
                <td>
                    {/* 空白 */}
                </td>
                {/* データ部分 */}
                <td>
                    <div className="h-full w-full">
                        {/* 開始時間 */}
                        {
                            <select
                                className='w-full h-full text-xs'
                                onChange={(e) => {
                                    const [hours, minutes] = e.target.value.split(":").map(Number)
                                    setStartHours(hours)
                                    setStartMinutes(minutes)
                                }}
                            >
                                {startTimeSlots.map((time, index) => (
                                    <option key={index} value={time} selected={isSomeTime(time, `${startHours}:${startMinutes}`)}>{time}</option>
                                ))}
                            </select>
                        }
                    </div>
                </td>
                <td>
                    <div className="w-full h-full">
                        {/* 終了時間 */}
                        {
                            <select
                                className='w-full h-full text-xs'
                                onChange={(e) => {
                                    const [hours, minutes] = e.target.value.split(":").map(Number)
                                    setEndHours(hours)
                                    setEndMinutes(minutes)
                                }}
                            >
                                {endTimeSlots.map((time, index) => (
                                    <option key={index} value={time} selected={isSomeTime(time, `${endHours}:${endMinutes}`)}>{time}</option>
                                ))}
                            </select>
                        }
                    </div>
                </td>
                <td>
                    <div className="h-full">
                        {/* 有効期間(開始) */}
                        <DatePicker
                            popperProps={{ strategy: 'fixed' }}
                            className="text-xs"
                            selected={fromUnixTime(startDate)}
                            onChange={(date) => {
                                setStartDate(getUnixTime(startOfDay(date || 0)))
                                // 終了日が開始日より前の場合、開始日の翌日に自動的に設定する
                                if (endDate && (date != undefined) && date >= fromUnixTime(endDate)) {
                                    setEndDate(getUnixTime(endOfDay(date || 0)));
                                }
                            }}
                            dateFormat="yyyy/MM/dd"
                            minDate={startOfDay(new Date())}
                        />
                    </div>
                </td>
                <td>
                    <div className="h-full">
                        {/* 有効期間(終了) */}
                        <DatePicker
                            popperProps={{ strategy: 'fixed' }}
                            className="text-xs"
                            selected={endDate ? fromUnixTime(endDate) : null}
                            onChange={(date) => setEndDate(getUnixTime(endOfDay(date || 0)))}
                            dateFormat="yyyy/MM/dd"
                            minDate={fromUnixTime(startDate)}
                            required={false}
                        />
                    </div>
                </td>
                <td>
                    {/* 空白欄 */}
                </td>
                <td>
                    {/* mutation */}
                    {/* <p className={`${isOverlap ? 'text-xs text-red-500 font-bold' : 'hidden'}`}>
                    有効期間が重複しています
                </p> */}
                    <div className="flex gap-2 h-full">
                        <p
                            className={enableBtnCss}
                            onClick={() => setOpenValidateRule(true)}
                        >
                            {config_id ? '更新' : '登録'}
                        </p>
                        <p
                            className={enableBtnCss + ' cursor-pointer'}
                            onClick={() => {
                                setEditing('')
                                setIsOpenAddRecord(false)
                            }}
                        >
                            戻る
                        </p>
                    </div>
                </td>
            </tr >
            {
                openValidateRule &&
                <ValidateDialog
                    ruleStartDate={startDate}
                    clinicStartTime={clinicTime.startTime}
                    clinicEndTime={clinicTime.endTime}
                    setEditing={setEditing}
                    setOpenValidateRule={setOpenValidateRule}
                    isUpdate={isUpdate}
                    confirmed={confirmed}
                    setConfirmed={setConfirmed}
                    addScheduleSubmit={() => addClinicTimeConfig.mutate(addClinicTimeVariables)}
                    updateScheduleSubmit={() => updateClinicTimeConfig.mutate(updateClinicTimeVariables)}
                />
            }
        </>
    )
}