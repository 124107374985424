import { RectangleButton } from "../../RectangleButton"

export interface DateChangeButtonProps {
    disable: boolean
    onDateChange: () => void
}

export const DateChangeButton: React.FC<DateChangeButtonProps> = ({
    disable,
    onDateChange
}) => {
    return (
        <RectangleButton
            label='日時変更'
            color='blue'
            size='md'
            disable={disable}
            onClickHandler={onDateChange}
        />
    )
}
