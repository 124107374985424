// 必要なモジュールをインポート
import { useQueryParams, useWatchQueryParam } from "../queryParams"
import { createContext, useContext, ReactNode } from 'react';

// Context の型定義
export interface CommonParamsContextType {
    calendarDate: number | null;
    startTime: number | null;
    endTime: number | null;
    facilityId: string | null;
    setCalendarDate: (calendarDate: number) => void;
    setStartTime: (startTime: number) => void;
    setEndTime: (endTime: number) => void;
    setFacilityId: (facilityId: string) => void;
    setCommonParams: setCommonParamsType;
}

export type setCommonParamsType = (calendarDate: number, startTime: number, endTime: number, facilityId: string) => void

// Contextの初期値と型を設定
const CommonParamsContext = createContext<CommonParamsContextType>({
    calendarDate: null,
    startTime: null,
    endTime: null,
    facilityId: null,
    setCalendarDate: () => { },
    setStartTime: () => { },
    setEndTime: () => { },
    setFacilityId: () => { },
    setCommonParams: () => { }
});

export const useCommonParams = (): CommonParamsContextType => {

    // クエリパラメータを操作するための関数を取得
    const { setQueryParam, resetQueryParams } = useQueryParams();

    // クエリパラメータを監視
    const calendarDate = Number(useWatchQueryParam("calendar-date"));
    const startTime = Number(useWatchQueryParam("start-time"));
    const endTime = Number(useWatchQueryParam("end-time"));
    const facilityId = useWatchQueryParam("facility-id");

    const setCalendarDate = (calendarDate: number) => {
        setQueryParam("calendar-date", calendarDate);
    }

    const setStartTime = (startTime: number) => {
        setQueryParam("start-time", startTime);
    };

    const setEndTime = (endTime: number) => {
        setQueryParam("end-time", endTime);
    };

    const setFacilityId = (facilityId: string) => {
        setQueryParam("facility-id", facilityId);
    }

    const setCommonParams = (calendarDate: number, startTime: number, endTime: number, facilityId: string) => {
        setCalendarDate(calendarDate);
        setStartTime(startTime);
        setEndTime(endTime);
        setFacilityId(facilityId);
    }

    return {
        calendarDate,
        startTime,
        endTime,
        facilityId,
        setCalendarDate,
        setStartTime,
        setEndTime,
        setFacilityId,
        setCommonParams
    };
}

export const CommonParamsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const commonParams = useCommonParams();

    return (
        <CommonParamsContext.Provider value={commonParams}>
            {children}
        </CommonParamsContext.Provider>
    );
}

// 他のコンポーネントでContextの値を取得するためのカスタムフック
export const useCommonParamsContext = (): CommonParamsContextType => {
    return useContext(CommonParamsContext);
};
