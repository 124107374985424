import { $styledDivPropsType } from "../../../../entities"

export const $styledDiv: React.FC<$styledDivPropsType> = ({
    styleType,
    children,
    isMobile
}) => {
    //left
    const leftStyle = `flex items-bottom ${isMobile ? 'flex-wrap w-full gap-4' : 'gap-10'}`
    //right
    const rightStyle = "flex gap-2 items-bottom"
    //wrapper
    const wrapperIsMobileStyle = "flex-wrap flex-row-reverse justify-start gap-y-2"
    const wrapperIsNotMobileStyle = "pl-12"
    const wrapperCommonStyle = "mt-4 w-full flex items-bottom justify-between"
    const wrapperStyle = `${isMobile ? wrapperIsMobileStyle : wrapperIsNotMobileStyle} ${wrapperCommonStyle}`
    //style
    const style = styleType === 'left' ? leftStyle : styleType === 'right' ? rightStyle : wrapperStyle

    return (
        <div className={style}>
            {children}
        </div>
    )
}