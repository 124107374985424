import { MenuType } from '@/feature/phr-28/components/common/type'
import { addMinutes, differenceInMinutes, endOfDay, fromUnixTime, getHours, getMinutes, getUnixTime, setHours, setMinutes, sub } from 'date-fns'

export const medicalRole: string[] = ['ドクター(歯科)', 'ドクター(医科)', '歯科衛生士', '歯科助手', '技工士', 'ナース', 'コンシェルジュ']
export const medicalParts: string[] = ['指定無し', '口', '関節', '背中']
export const medicalSubjects: string[] = ['歯科', '医科']
export const medicalStatus: string[] = ['有効', '無効']
export const treatmentTimes: number[] = [30, 45, 60, 75, 90, 120, 150, 180]

type ClinicTimeType = {
  start: {
    hours: number,
    minutes: number
  },
  end: {
    hours: number,
    minutes: number
  }
}

export function getOptionElements(
  treatmentStartTime: number,
  clinic_time: ClinicTimeType
) {
  const treatmentStartDate = fromUnixTime(treatmentStartTime);
  const endOfShiftDate = getEndOfShiftDate(treatmentStartDate, clinic_time);

  const maxTreatmentMinutes = differenceInMinutes(endOfShiftDate, treatmentStartDate)
  const treatmentMinutes: number[] = getTreatmentMinutes(maxTreatmentMinutes)

  const treatmentTimes = getTreatmentTimes(treatmentStartDate, treatmentMinutes);
  const optionElements = createOptionElements(treatmentTimes);

  return optionElements;
}

function getTreatmentMinutes(maxTreatmentTime: number) {
  const treatmentMinutes: number[] = []
  for (let i = 15; i <= maxTreatmentTime; i += 15) {
    treatmentMinutes.push(i)
  }
  return treatmentMinutes;
}

function getEndOfShiftDate(
  treatmentStartDate: Date,
  clinic_time: ClinicTimeType
) {
  const settedHoursDate = setHours(treatmentStartDate, clinic_time.end.hours);
  const endOfShiftDate = setMinutes(settedHoursDate, clinic_time.end.minutes);

  return endOfShiftDate;
}

function getTreatmentTimes(
  appointTreatmentStartDate: Date,
  treatmentMinutes: number[]
) {
  const treatmentTimes = treatmentMinutes.map(minutes => {
    const _treatmentTime = addMinutes(appointTreatmentStartDate, minutes);
    const treatmentTime = getUnixTime(_treatmentTime);
    return treatmentTime;
  })
  const sortedTreatmentTimes = treatmentTimes.sort((a, b) => a - b);
  return sortedTreatmentTimes;
}

function createOptionElements(
  treatmentTimes: number[]
) {
  return (
    <>
      {treatmentTimes.map((treatmentTime, index) => {
        return (
          <option className="font-normal text-gray-800 text-l" value={treatmentTime} key={index}>
            {`${getHours(fromUnixTime(treatmentTime))}時${getMinutes(fromUnixTime(treatmentTime))}分`}
          </option>
        )
      })}
    </>
  )
}
//--------------------------------------------------------------------

export function getMenuTime(menuList: MenuType[], id: string): number {
  let menuTime: number = 0
  menuList.forEach((menu: MenuType) => {
    if (menu.SDK_ID === id) {
      menuTime = menu.time * 60
    }
  })
  return menuTime
}

export function getMenuId(menuList: MenuType[], menuName: string): string {
  let menuId: string = ''
  menuList.forEach((menu: MenuType) => {
    if (menu.name === menuName) {
      menuId = menu.SDK_ID
    }
  })
  return menuId
}

export function getMenuRole(menuList: MenuType[], id: string): string {
  let menuRole: string = '未登録'
  menuList.forEach((menu: MenuType) => {
    if (menu.SDK_ID === id) {
      menuRole = menu.role
    }
  })
  return menuRole
}
