import { useTransactDeleteCategorysMutation } from "../../../../../../.graphql/types";
import { Dialog, DialogContent } from '@mui/material'
import { useState } from "react";
import { useQueryClient } from "react-query";
import { CategoryType } from "../../common/type";
import { useGraphqlClient } from "@/App";

interface DeleteCategorysDialogType {
    categoryList: CategoryType[]
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function closeDialog(
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>,
    setCurrentCategory: React.Dispatch<React.SetStateAction<string[]>>
) {
    setCurrentCategory(['']);
    setIsChecked(false);
    setIsOpen(false);
}

function getCategoryNames(
    categoryList: CategoryType[],
    currentCategorys: string[]
) {
    const categoryNames: string[] = [];
    categoryList.forEach(category => {
        currentCategorys.forEach(categoryId => {
            if (category.id === categoryId) {
                categoryNames.push(category.name)
            }
        })
    })
    return categoryNames;
}

export const useDeleteCategorysDialog = () => {
    const queryClient = useQueryClient()
    const graphqlClient = useGraphqlClient()
    const [currentCategorys, setCurrentCategorys] = useState<string[]>(['']);
    const [isChecked, setIsChecked] = useState(false);

    const DeleteCategoryDialog: React.FC<DeleteCategorysDialogType> = ({
        categoryList,
        setIsOpen
    }) => {
        const deletableCategoryNames = getCategoryNames(categoryList, currentCategorys)

        const { mutate, isLoading } = useTransactDeleteCategorysMutation(graphqlClient, {
            onSettled: () => {
                queryClient.invalidateQueries('listCategorys')
            },
            onSuccess: () => {
                closeDialog(setIsOpen, setIsChecked, setCurrentCategorys);
            },
            onError: (err) => {
                alert('カテゴリの削除に失敗しました。再度お試しください。')
                closeDialog(setIsOpen, setIsChecked, setCurrentCategorys);
            }
        })

        return (
            <>
                {
                    isLoading &&
                    <div className="flex items-center justify-center fixed top-0 left-0 z-[9999] h-full w-full flex justify-center bg-black bg-opacity-50">
                        <div className="animate-ping h-4 w-4 bg-blue-600 rounded-full"></div>
                    </div>
                }
                <Dialog
                    transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
                    onClose={() => { closeDialog(setIsOpen, setIsChecked, setCurrentCategorys) }}
                    open={true}
                    PaperProps={{
                        style: {
                            maxWidth: 'none',
                            width: '50%',
                            height: '50%',
                        },
                    }}
                >
                    <DialogContent
                        dividers
                        style={{ padding: '0px 0px 0px 0px', height: '100%', width: '100%', maxWidth: 'none', maxHeight: 'none', position: 'relative' }}
                    >
                        <div className="w-full h-full relative flex flex-col justify-center items-center transform overflow-hidden rounded-lg bg-white px-16 pt-5 pb-4 text-left shadow-xl transition-all">
                            <div className="w-full mb-6">
                                <div className="mt-3 text-center sm:mt-5">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900 flex flex-wrap justify-start gap-y-6 mb-8 border-b border-black pb-8">
                                        {deletableCategoryNames.map(categoryName => {
                                            return (
                                                <span className="text-red-500 w-1/3 text-left">{categoryName}</span>
                                            )
                                        })}
                                    </h3>
                                    <div className="flex justify-center">
                                        <label className="flex gap-4 items-center">
                                            <input type="checkbox" checked={isChecked} onChange={() => { setIsChecked(!isChecked) }} />
                                            <p>上記カテゴリを削除しますか？</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="button"
                                    className={`${isChecked ? '' : 'pointer-events-none opacity-50'} inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                                    onClick={() => { mutate({ SDK_IDs: currentCategorys }) }}
                                >
                                    削除する
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                    onClick={() => closeDialog(setIsOpen, setIsChecked, setCurrentCategorys)}
                                >
                                    キャンセル
                                </button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    return {
        DeleteCategoryDialog,
        setCurrentCategorys
    }
}
