import { SpecificDatePickerType } from "@/components/ClinicSettings/entities"
import { endOfDay, fromUnixTime, getUnixTime, startOfDay } from "date-fns"
import ReactDatePicker from "react-datepicker"

// [フォームパーツ]特定日付選択
export function SpecificDatePicker({
    specific_date,
    setSpecificDate,
    setStartDate,
    setEndDate
}: SpecificDatePickerType): JSX.Element {
    return (
        <>
            <td>
                <div className="h-full">
                    {/* 特定日付 */}
                    <ReactDatePicker
                        popperProps={{ strategy: 'fixed' }}
                        className="text-xs"
                        selected={fromUnixTime(specific_date)}
                        onChange={(date) => {
                            setSpecificDate(getUnixTime(startOfDay(date || 0)))
                            setStartDate(getUnixTime(startOfDay(date || 0)))
                            setEndDate(getUnixTime(endOfDay(date || 0)))
                        }}
                        dateFormat="yyyy/MM/dd"
                    />
                </div>
            </td>
        </>
    )
}