import React from 'react'
import { StatusNumbersProps } from '../../../../entities'
import { StatusBadge } from '../../StatusBadge'

type StatusType = {
    status: 'reserved' | 'checkedIn' | 'canceled' | 'completed'
    label: string
    number: number
}

const style = "flex items-bottom gap-4"

export const StatusNumbers: React.FC<StatusNumbersProps> = ({
    numberOfReserved,
    numberOfCheckedIn,
    numberOfCompleted,
    numberOfCanceled,
}) => {

    const appointStatuses: StatusType[] = [
        {
            status: 'reserved',
            label: '予約',
            number: numberOfReserved,
        },
        {
            status: 'checkedIn',
            label: '受付',
            number: numberOfCheckedIn,
        },
        {
            status: 'canceled',
            label: 'キャンセル',
            number: numberOfCanceled,
        },
        {
            status: 'completed',
            label: '完了',
            number: numberOfCompleted,
        },
    ]

    return (
        <div className={style}>
            {
                appointStatuses.map((item, index) => {
                    return (
                        <StatusBadge
                            key={index}
                            status={item.status}
                            label={`${item.label}: ${item.number}件`}
                            onClickHandler={() => { }}
                        />
                    )
                })
            }
        </div>
    )
}
