import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { usePatientsPagination } from '@/components/Patient/components/views/hooks/usePatientsPagination';
import { emptyPatients } from '@/feature/phr-28/components/common/emptyData';

import {
  ListAppointByIdDocument,
  type DeletePatientByIdV2Mutation,
  type Exact,
  type ListAppointByIdQuery,
  type PatientInputV2,
  type UpdatePatientByIdV2Mutation,
  useUpdatePatientByIdV2Mutation,
  useDeletePatientByIdV2Mutation,
  GetPatientByIdV2Query,
  UpdatePatientByIdV2MutationVariables,
} from "@/_graphql/graphql-client";
import { type PatientsType } from "@/components/Patient/components/util/common/type";
import { getMutationPatient } from "@/feature/phr-29/components/Patients/utils/mutation";
import { type GraphQLClient } from 'graphql-request';

import React, { useState } from 'react';
// import { checkPatients } from '@/feature/phr-28/components/common/Api'
import { type UseMutationResult, useQueryClient, UseMutateFunction } from 'react-query';
// import { emptyValues } from 'utils/emptyValues'
// import { getMutationPatient } from './utils/mutation'
// import { setBaseValues, getBirthday } from './utils/updatePatients'
// import { setPostAddress } from './utils/fetchAddress'

import { useGraphqlClient } from '@/App';
import {
  DialogActions,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';


export function IntegrateP5GModal({
  clinic_id,
  currentPatient,
  isOpen,
  setIsOpen,
  setSelected,
}: {
  clinic_id: string;
  currentPatient: GetPatientByIdV2Query['getPatientByIdV2'] | PatientsType;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelected: React.Dispatch<React.SetStateAction<{}>>;
}) {
  const [selectIdx, setSelectIdx] = useState(-1);
  const [patientsInfo, setPatientsInfo] = useState(emptyPatients);

  // clientを取得
  const graphqlClient = useGraphqlClient();

  const queryClient = useQueryClient();
  // const graphqlClient = useGraphqlClient()

  const updatePatient = useUpdatePatientByIdV2Mutation(graphqlClient, {
    onSettled: async () => {
      await queryClient
        .invalidateQueries('getPatientByIdV2')
        .catch((err) => console.log('on Settled', err));
      await queryClient
        .invalidateQueries('listPatientsByIdV2')
        .catch((err) => console.log('on Settled', err));
      await queryClient.invalidateQueries();
    },
    onSuccess: () => {
      alert('患者情報を更新しました');
    },
  });

  const deletePatient = useDeletePatientByIdV2Mutation(graphqlClient, {
    onSettled: async () => {
      await queryClient
        .invalidateQueries('getPatientByIdV2')
        .catch((err) => console.log('on Settled', err));
      await queryClient
        .invalidateQueries('listPatientsV2')
        .catch((err) => console.log('on Settled', err));
      await queryClient.invalidateQueries('listPatientsByIdPaginated');
      await queryClient.invalidateQueries();
    },
    onSuccess: () => {
      alert('患者情報を削除しました');
    },
  });

  // hooksでpatientsListを取得
  const { patients, setResult, orderAndFilterController } = usePatientsPagination(graphqlClient, clinic_id);
  const patientsList = patients;


  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: {
          maxWidth: 'none',
          width: '80%',
          transition: 'all .2s',
        },
      }}
    >
      <DialogContent
        dividers
        style={{
          padding: '0px 0px 0px 0px',
          maxWidth: 'none',
          maxHeight: 'none',
          height: '50vh',
          position: 'relative',
        }}
      >
        <TableContainer style={{ width: '100%', padding: '30px' }}>
          <TableBody>
            {patientsList
              .filter((patient) => {
                return patient.SNumber !== null && patient.from_p5g;
              })
              .map((patient, idx) => {
                return (
                  <TableRow style={{ width: '100%' }} key={patient.id}>
                    <TableCell>
                      <input
                        type="checkbox"
                        onChange={(value) => {
                          setPatientsInfo(patient);
                          setSelectIdx(idx);
                        }}
                        checked={idx === selectIdx}
                      ></input>
                    </TableCell>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>
                      名前: {patient.name.last} {patient.name.first}
                    </TableCell>

                    <TableCell>診察券番号: {patient.SNumber}</TableCell>
                    <TableCell>電話番号: {patient.tel}</TableCell>
                    <TableCell style={{ width: '30%' }}></TableCell>
                    {/* 登録日: {patient.createdAt} */}
                  </TableRow>
                );
              })}
          </TableBody>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          onClick={() => {
            // console.log(patientsInfo);
            useCaseIntegrateP5G(
              clinic_id,
              currentPatient,
              patientsInfo,
              deletePatient,
              updatePatient,
              setIsOpen,
              graphqlClient,
              setSelectIdx
            );
          }}
          className="my-2 rounded-sm border border-transparent bg-indigo-600 h-7 px-12 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          統合する
        </button>

        <button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="my-2 rounded-sm border border-transparent bg-gray-300 h-7 px-12 text-xs font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          閉じる
        </button>
      </DialogActions>
    </Dialog>
  );
}

// sourcePatientInfoの情報をdestPatientInfoに統合する
// sourcePatientInfoは予約を持っていないことが条件
// 統合後にsourcePatientInfoを削除する
const useCaseIntegrateP5G = async function (
  clinic_id: string,
  destPatientInfo: GetPatientByIdV2Query['getPatientByIdV2'] | PatientsType,
  sourcePatientInfo: PatientsType,
  deletePatient: UseMutationResult<DeletePatientByIdV2Mutation, unknown, Exact<{
    clinic_id: string;
    id: string;
  }>, unknown>,
  updatePatient: UseMutationResult<UpdatePatientByIdV2Mutation, unknown, Exact<{
    input: PatientInputV2;
    id: string;
  }>, unknown>,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  graphqlClient: GraphQLClient,
  setSelectIdx: React.Dispatch<React.SetStateAction<number>>,
) {
  const id = destPatientInfo?.id;
  if (!id) { return; }

  const newPatientInfo = { ...sourcePatientInfo, id };
  if (!newPatientInfo.address) {
    newPatientInfo.address = {
      house: '',
      post: '',
      prefectures: ''
    }
  }

  console.log(clinic_id, newPatientInfo, destPatientInfo, sourcePatientInfo);

  // 元患者の予約件数チェック
  // DocumentとGraphQLクライアントを使って非コンポーネント内で実行
  const appoints: ListAppointByIdQuery = await graphqlClient.request(ListAppointByIdDocument, {
    id: sourcePatientInfo.id,
  });

  console.log(appoints);

  if (appoints?.listAppointById?.Appoints?.length === 0) {
    // mutation用のデータを作成
    const mutationPatient = getMutationPatient(newPatientInfo);

    const { SNumber, DNumber, searchFull, searchKana, lineId, token, searchName, status, ..._data } =
      mutationPatient;
    _data.fullName = searchFull;
    _data.kanaName = searchKana;

    if (SNumber) {
      _data.patientCardNumber = SNumber;
    }

    if (DNumber) {
      _data.patientCardNumberOptional = DNumber;
    }

    _data.clinic_id = clinic_id;

    const patientInputV2 = _data as PatientInputV2;

    const input = { id, input: patientInputV2 };
    console.log(input);

    await updatePatient.mutateAsync(input);
    await deletePatient.mutateAsync({ id: sourcePatientInfo.id, clinic_id });

    // // 統合後の情報をフォームに反映
    setIsOpen(false);
    setSelectIdx(-1);
    return;
  }

  alert('すでに予約があるため統合できません');
};
