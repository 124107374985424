import React from 'react';
import { MedicalMenuRecordPresenterType } from '../util/common/type';
import {
  DeleteMedicalMenuButtonType,
  EditMedicalMenuButtonType,
  MedicalMenuCheckboxItemType,
  MedicalMenuInfoType,
  UpdateIndexButtonType,
} from '../util/components/parts';

export const MedicalMenuRecordPresenter: React.FC<MedicalMenuRecordPresenterType> = ({
  editMode,
  setEditMode,
  EditMenuModal,
  categoryList,
  bulkDeleteArr,
  setBulkDeleteArr,
  isCheck,
  setIsCheck,
  updateMenuIndexExecute,
  record,
  index,
  lastIndexNum,
  currentCategory,
  disableBtnCss,
  deleteBtnCss,
  setCurrentMenu,
  DeleteMenuDialog,
  setDeleteMenu,
  openDeleteDialog,
  setOpenDeleteDialog,
  filteredLists,
}) => {
  return (
    <Outline>
      {editMode ? (
        <EditMenuModal categoryList={categoryList} setEditMode={setEditMode} />
      ) : (
        <>
          <ArrangeMedicalMenuItems>
            <MedicalMenuCheckboxItem
              index={index}
              bulkDeleteArr={bulkDeleteArr}
              isCheck={isCheck}
              setIsCheck={setIsCheck}
              setBulkDeleteArr={setBulkDeleteArr}
            />
            <UpdateIndexButtons>
              {record.index > 1 && (
                <IncrementIndexButton updateMenuIndexExecute={updateMenuIndexExecute} />
              )}
              {record.index < lastIndexNum && record.index !== null && (
                <DecrementIndexButton updateMenuIndexExecute={updateMenuIndexExecute} />
              )}
              {/* NOTE 230605 現状使われてない？？？ */}
              {/* {record.index === null && (
                <UnknownIndexUpdateButton updateMenuIndexExecute={updateMenuIndexExecute} />
              )} */}
            </UpdateIndexButtons>
          </ArrangeMedicalMenuItems>
          <MedicalMenuInfo record={record} currentCategory={currentCategory} />
          <MedicalMenuManageButtons>
            <EditMedicalMenuButton
              disableBtnCss={disableBtnCss}
              record={record}
              setCurrentMenu={setCurrentMenu}
              setEditMode={setEditMode}
            />
            <DeleteMedicalMenuButton
              bulkDeleteArr={bulkDeleteArr}
              deleteBtnCss={deleteBtnCss}
              disableBtnCss={disableBtnCss}
              index={index}
              isCheck={isCheck}
              record={record}
              setDeleteMenu={setDeleteMenu}
              setOpenDeleteDialog={setOpenDeleteDialog}
            />
          </MedicalMenuManageButtons>
        </>
      )}
      {openDeleteDialog && (
        <DeleteMenuDialog
          filteredLists={filteredLists}
          setIsOpen={setOpenDeleteDialog}
          setBulkDeleteArr={setBulkDeleteArr}
        />
      )}
    </Outline>
  );
};

// NOTE 230604 Commonからインポートする（まだmainブランチに入ってない）
export interface OutlineType {
  children?: React.ReactNode;
}

const Outline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle = "bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
  return (
    <tr className={outlineStyle}>
      {children}
    </tr>
  );
};

const ArrangeMedicalMenuItems: React.FC<OutlineType> = ({ children }) => {
  return <td className="w-4 p-4 flex">{children}</td>;
};

const MedicalMenuCheckboxItem: React.FC<MedicalMenuCheckboxItemType> = ({
  index,
  bulkDeleteArr,
  isCheck,
  setIsCheck,
  setBulkDeleteArr,
}) => {
  const containerStyle = "flex items-center pr-4"
  const checkboxStyle = "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
  return (
    <div className={containerStyle}>
      <input
        id="checkbox-table-search-1"
        type="checkbox"
        checked={bulkDeleteArr.includes(index)}
        onChange={() => {
          setIsCheck(!isCheck);
          let tmpBulkDeleteArr: number[] = [];
          if (isCheck) {
            if (bulkDeleteArr.includes(index)) {
              tmpBulkDeleteArr = bulkDeleteArr.filter((element: number) => {
                return element !== index;
              });
            }
          } else {
            tmpBulkDeleteArr = bulkDeleteArr.slice();
            tmpBulkDeleteArr.push(index);
          }
          tmpBulkDeleteArr.sort((a, b) => a - b);
          setBulkDeleteArr(tmpBulkDeleteArr);
        }}
        className={checkboxStyle}
      />
    </div>
  );
};

const UpdateIndexButtons: React.FC<OutlineType> = ({ children }) => {
  return <td className="flex flex-col">{children}</td>;
};

const IncrementIndexButton: React.FC<UpdateIndexButtonType> = ({ updateMenuIndexExecute }) => {
  const buttonIconStyle = "text-gray-100 h-8 w-8 text-center rounded-full bg-gray-400 hover:bg-gray-800 flex justify-center items-center my-1 cursor-pointer"
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={buttonIconStyle}
      onClick={() => updateMenuIndexExecute(true)}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

const DecrementIndexButton: React.FC<UpdateIndexButtonType> = ({ updateMenuIndexExecute }) => {
  const buttonIconStyle = "text-gray-100 h-8 w-8 text-center rounded-full bg-gray-400 hover:bg-gray-800 flex justify-center items-center my-1 cursor-pointer"
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={buttonIconStyle}
      onClick={() => updateMenuIndexExecute(false)}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

// NOTE 230605 現状使われてない？？？
// const UnknownIndexUpdateButton: React.FC<UpdateIndexButtonType> = ({ updateMenuIndexExecute }) => {
//   return (
//     <button
//       onClick={() => updateMenuIndexExecute()}
//       className="text-gray-100 h-8 w-8 text-center rounded-full bg-gray-400 hover:bg-gray-800 flex justify-center items-center my-1 cursor-pointer"
//     >
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         fill="none"
//         viewBox="0 0 24 24"
//         strokeWidth={1.5}
//         stroke="currentColor"
//         className="w-6 h-6"
//       >
//         <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
//       </svg>
//     </button>
//   );
// };

const MedicalMenuInfo: React.FC<MedicalMenuInfoType> = ({ record, currentCategory }) => {
  const menuNameTdStyle = "text-center px-12 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
  return (
    <>
      <td
        scope="row"
        className={menuNameTdStyle}
      >
        {record.name}
      </td>
      <td className="px-6 py-4 text-center">{record.abbreviation}</td>
      <td className="px-6 py-4 text-right">{record.time}分</td>
      <td className="px-6 py-4">{record?.type}</td>
      <td className="px-6 py-4">{currentCategory?.[0]?.name || '削除済みカテゴリ'}</td>
      <td className="px-6 py-4">{record?.role}</td>
      <td className="px-6 py-4">{record?.status}</td>
    </>
  );
};

const MedicalMenuManageButtons: React.FC<OutlineType> = ({ children }) => {
  return <td className="px-2 py-4 text-right">{children}</td>;
};

const EditMedicalMenuButton: React.FC<EditMedicalMenuButtonType> = ({
  disableBtnCss,
  record,
  setCurrentMenu,
  setEditMode,
}) => {
  return (
    <button
      type="button"
      className={disableBtnCss}
      onClick={() => {
        setCurrentMenu(record);
        setEditMode(true);
      }}
    >
      編集
    </button>
  );
};

const DeleteMedicalMenuButton: React.FC<DeleteMedicalMenuButtonType> = ({
  bulkDeleteArr,
  deleteBtnCss,
  disableBtnCss,
  index,
  isCheck,
  record,
  setDeleteMenu,
  setOpenDeleteDialog,
}) => {
  return (
    <button
      type="submit"
      className={bulkDeleteArr.includes(index) ? deleteBtnCss : disableBtnCss}
      onClick={() => {
        if (isCheck) {
          setDeleteMenu(record);
          setOpenDeleteDialog(true);
        }
      }}
    >
      削除
    </button>
  );
};
