import { GraphQLClient } from 'graphql-request'
import { useListFacilitiesQuery } from "@/_graphql/graphql-client"
import { facilityListMapper } from '@/domain/Facility/services/mapper/facilityListMapper';
import { useFacilityListVariablesType } from '@/domain/Facility/entities/types';

export const useFacilityList_service = (
    graphqlClient: GraphQLClient,
    variables: useFacilityListVariablesType
) => {
    const facilities = useListFacilitiesQuery(graphqlClient, variables);
    const allFacilityList = facilityListMapper(facilities);
    return allFacilityList;
}
