import React from 'react';
import { MedicalMenuTablePresenterType } from '../util/common/type';
import { MenuType } from '@/feature/phr-28/components/common/type';
import { MedicalMenuRecordViews } from '../views/MedicalMenuRecordViews';
import { MedicalMenuTableHeaderType } from '../util/components/parts';

export const MedicalMenuTablePresenter: React.FC<MedicalMenuTablePresenterType> = ({
  filteredLists,
  categoryList,
  setSortingFactorNumber,
  isSort,
  setIsSort,
  setOrder,
  bulkDeleteArr,
  setBulkDeleteArr,
  lastIndexNum,
  orderOfTable,
  setOrderOfTable,
  sortingFactorNumberOfTable,
  setSortingFactorNumberOfTable,
}) => {
  return (
    <Outline>
      <MedicalMenuTable>
        <MedicalMenuTableHeader
          isSort={isSort}
          setIsSort={setIsSort}
          sortingFactorNumberOfTable={sortingFactorNumberOfTable}
          setSortingFactorNumber={setSortingFactorNumber}
          setSortingFactorNumberOfTable={setSortingFactorNumberOfTable}
          orderOfTable={orderOfTable}
          setOrder={setOrder}
          setOrderOfTable={setOrderOfTable}
        />
        <MedicalMenuTableBody>
          {filteredLists.length > 0 ? (
            filteredLists.map((list: MenuType, index: number) => (
              <MedicalMenuRecordViews
                record={list}
                bulkDeleteArr={bulkDeleteArr}
                setBulkDeleteArr={setBulkDeleteArr}
                index={index}
                filteredLists={filteredLists}
                categoryList={categoryList}
                lastIndexNum={lastIndexNum}
              />
            ))
          ) : (
            <NoMedicalMenuResult />
          )}
        </MedicalMenuTableBody>
      </MedicalMenuTable>
    </Outline>
  );
};

// NOTE 230604 Commonからインポートする（まだmainブランチに入ってない）
export interface OutlineType {
  children?: React.ReactNode;
}

const Outline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle = "relative overflow-x-auto shadow-md sm:rounded-lg"
  return <div className={outlineStyle}>{children}</div>;
};

const MedicalMenuTable: React.FC<OutlineType> = ({ children }) => {
  const tableStyle = "w-full text-sm text-left text-gray-500 dark:text-gray-400"
  return (
    <table className={tableStyle}>{children}</table>
  );
};

// NOTE 230605 ソート諸々の機能は現状だと動いていない？？？
const MedicalMenuTableHeader: React.FC<MedicalMenuTableHeaderType> = ({
  isSort,
  setIsSort,
  sortingFactorNumberOfTable,
  setSortingFactorNumber,
  setSortingFactorNumberOfTable,
  orderOfTable,
  setOrder,
  setOrderOfTable,
}) => {
  const theadStyle = "text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
  const theadCheckboxStyle = "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
  return (
    <thead className={theadStyle}>
      <tr>
        <th scope="col" className="p-4">
          <div className="flex items-center">
            <input
              id="checkbox-all-search"
              type="checkbox"
              disabled
              className={theadCheckboxStyle}
            />
          </div>
        </th>
        <th
          scope="col"
          className="text-center"
          onClick={() => {
            if (isSort) {
              if (sortingFactorNumberOfTable !== 0) {
                setOrderOfTable('ASC');
              } else {
                setOrderOfTable(orderOfTable === 'ASC' ? 'DESC' : 'ASC');
              }
              setOrder(orderOfTable);
              setSortingFactorNumberOfTable(0);
              setSortingFactorNumber(0);
            } else {
              setIsSort(true);
              setOrder('ASC');
              setSortingFactorNumberOfTable(0);
              setSortingFactorNumber(0);
            }
          }}
        >
          診療名称
        </th>
        <th
          scope="col"
          className="text-center"
          onClick={() => {
            if (isSort) {
              if (sortingFactorNumberOfTable !== 0) {
                setOrderOfTable('ASC');
              } else {
                setOrderOfTable(orderOfTable === 'ASC' ? 'DESC' : 'ASC');
              }
              setOrder(orderOfTable);
              setSortingFactorNumberOfTable(0);
              setSortingFactorNumber(0);
            } else {
              setIsSort(true);
              setOrder('ASC');
              setSortingFactorNumberOfTable(0);
              setSortingFactorNumber(0);
            }
          }}
        >
          略称
        </th>
        <th
          scope="col"
          className="text-center"
          onClick={() => {
            if (isSort) {
              if (sortingFactorNumberOfTable !== 3) {
                setOrderOfTable('ASC');
              } else {
                setOrderOfTable(orderOfTable === 'ASC' ? 'DESC' : 'ASC');
              }
              setOrder(orderOfTable);
              setSortingFactorNumberOfTable(3);
              setSortingFactorNumber(3);
            } else {
              setIsSort(true);
              setOrder('ASC');
              setSortingFactorNumberOfTable(3);
              setSortingFactorNumber(3);
            }
          }}
        >
          治療時間
        </th>
        <th
          scope="col"
          className="text-center"
          onClick={() => {
            if (isSort) {
              if (sortingFactorNumberOfTable !== 4) {
                setOrderOfTable('ASC');
              } else {
                setOrderOfTable(orderOfTable === 'ASC' ? 'DESC' : 'ASC');
              }
              setOrder(orderOfTable);
              setSortingFactorNumberOfTable(4);
              setSortingFactorNumber(4);
            } else {
              setIsSort(true);
              setOrder('ASC');
              setSortingFactorNumberOfTable(4);
              setSortingFactorNumber(4);
            }
          }}
        >
          タイプ
        </th>
        <th scope="col" className="text-center">
          サブカテゴリ
        </th>
        <th
          scope="col"
          className="text-center"
          onClick={() => {
            if (isSort) {
              if (sortingFactorNumberOfTable !== 5) {
                setOrderOfTable('ASC');
              } else {
                setOrderOfTable(orderOfTable === 'ASC' ? 'DESC' : 'ASC');
              }
              setOrder(orderOfTable);
              setSortingFactorNumberOfTable(5);
              setSortingFactorNumber(5);
            } else {
              setIsSort(true);
              setOrder('ASC');
              setSortingFactorNumberOfTable(5);
              setSortingFactorNumber(5);
            }
          }}
        >
          担当
        </th>
        <th
          scope="col"
          className="px-6 py-3"
          onClick={() => {
            if (isSort) {
              if (sortingFactorNumberOfTable !== 6) {
                setOrderOfTable('ASC');
              } else {
                setOrderOfTable(orderOfTable === 'ASC' ? 'DESC' : 'ASC');
              }
              setOrder(orderOfTable);
              setSortingFactorNumberOfTable(6);
              setSortingFactorNumber(6);
            } else {
              setIsSort(true);
              setOrder('ASC');
              setSortingFactorNumberOfTable(6);
              setSortingFactorNumber(6);
            }
          }}
        >
          状態
        </th>
        {/* NOTE 230605 現状だと表示されてない？？？ */}
        <th scope="col" className="px-2 py-3">
          <span className="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
  );
};

const MedicalMenuTableBody: React.FC<OutlineType> = ({ children }) => {
  return <tbody>{children}</tbody>;
};

const NoMedicalMenuResult: React.FC = () => {
  return (
    <tr className="h-20">
      <th className="p-5" colSpan={8}>
        該当する診療メニュー情報は見つかりませんでした。
      </th>
    </tr>
  );
};
