import { emptyClinicTimeConfig } from "@/components/Common/utils/common/emptyData";
import { useEffect, useState } from "react";

export function useShiftTimeObserver(
    LatestClinicTime: { isSuccess: boolean, isError: boolean, data: any, isFetchedAfterMount: boolean },
    nowDate: Date,
    isAllTime: boolean
) {
    const [shiftTime, setShiftTime] = useState({ startTime: { hours: 8, minutes: 15 }, endTime: { hours: 23, minutes: 30 } });
    const [shiftFrameCount, setShiftFrameCount] = useState({
        start: isAllTime ? 8 : shiftTime.startTime.hours,
        end: isAllTime ? 23 : shiftTime.endTime.hours
    });

    useEffect(() => {
        if (LatestClinicTime.isSuccess || LatestClinicTime.isError) {
            const activeClinicTime = LatestClinicTime.data?.getLatestClinicConfig?.config.clinicTime || emptyClinicTimeConfig.config.clinicTime;
            const shiftStartHours = activeClinicTime.startTime.hours;
            const shiftEndHours = activeClinicTime.endTime.hours;
            const shiftStartMinutes = activeClinicTime.startTime.minutes;
            const shiftEndMinutes = activeClinicTime.endTime.minutes;

            setShiftTime({
                startTime: { hours: shiftStartHours, minutes: shiftStartMinutes },
                endTime: { hours: shiftEndHours, minutes: shiftEndMinutes }
            });
        }
    }, [nowDate, LatestClinicTime.isFetchedAfterMount]);

    useEffect(() => {
        setShiftFrameCount({
            start: isAllTime ? 8 : shiftTime.startTime.hours,
            end: isAllTime ? 23 : shiftTime.endTime.hours
        });
    }, [isAllTime, shiftTime]);

    return { shiftTime, shiftFrameCount };
}
