import { UseQueryResult } from "react-query";
import { ListFloorsQuery } from "@/domain/Floor/entities/types";
import { FloorType } from "@/components/Floor/entities";

export const floorListMapper = (
    floors: UseQueryResult<ListFloorsQuery>
) => {
    const floorList: FloorType[] = []
    const floorListIncludeDelete: FloorType[] = []

    if (floors.isSuccess) {
        const { data } = floors;

        data.listFloors.forEach((floor: any) => {
            floorListIncludeDelete.push(floor)
            if ((floor.isDelete !== true)) {
                floorList.push(floor)
            }
        })
    }

    return {
        floorList,
        floorListIncludeDelete
    }
}