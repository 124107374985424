import { useForm, FormProvider } from 'react-hook-form'
import classnames from 'classnames'
import CheckIcon from '@heroicons/react/solid/CheckIcon'
import { useEffect } from 'react'
import { onSubmit, getStyle, getButtonStyle, getCommentStyle } from '@/components/Comment/util'
import type { CommentPresenterPropsType } from "@/components/Comment/entities";


export const CommentPresenter: React.FC<CommentPresenterPropsType> = ({
    matches,
    nowDate,
    clinic_id,
    comment,
    addComments
}) => {
    const methods = useForm()
    const { register, setValue, watch } = methods

    // 登録失敗アラート
    useEffect(() => {
        if (addComments.isError) {
            alert('コメントの登録に失敗しました')
        }
    }, [addComments.isError])

    // 日付切り替えでコメント欄更新
    useEffect(() => {
        setValue('comments', comment || '')
    }, [comment])

    return (
        <FormProvider {...methods}>
            <div className={classnames(getCommentStyle(matches))}>
                <form className="h-full" onSubmit={methods.handleSubmit(() => onSubmit(clinic_id, nowDate, watch('comments'), addComments.mutate))}>
                    <label className="cursor-pointer absolute bottom-2 right-2 p-2 bg-indigo-600 rounded">
                        <input
                            className='hidden'
                            type="submit"
                            value="登録"
                        />
                        <CheckIcon className="h-3 w-3 text-white" />
                    </label>
                    <textarea
                        {...register('comments', { required: true })}
                        className={classnames(getStyle())}
                        placeholder="コメントを入力してください"
                        {...comment.length > 0 && { defaultValue: comment }}
                    />
                </form>
            </div>
        </FormProvider>
    )

}
