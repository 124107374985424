import { ClinicContext } from "@/App"
import { getEndTimeOfToDayTimestamp, getStartTimeOfToDayTimestamp } from "@/components/Common";
import { useCategoryList_service, useFacilityList_service, useMenuList_service, usePatientList_service, useStaffList_service } from "@/domain";
import { useAppointList_service } from "@/domain/Appointment";
import { useContext, useEffect, useState } from "react";
import { AddAppointViewsType } from "@/components/Reservation/entities";
import { AddAppointPresenter } from "./AddAppointPresenter";
import { emptyPatients } from "@/components/Common/utils/common/emptyData";
import { getSortedManegerList } from "@/components/Staff/components/utils/common/util";
import { getUnixTime, startOfDay } from "date-fns";
import { useAppoint_service } from "@/domain/Appointment/services/hooks";

export const AddAppointViews: React.FC<AddAppointViewsType> = ({
    graphqlClient,

    nowDate,

    appoint,
    req,
    operation,

    search, //暫定
    patientId,
    startTime,
    endTime,
    facilityId,
    appointId
}) => {
    //ブロック/予約設定/患者追加モーダル関連ステート
    const [openSearchPatientModal, setOpenSearchPatientModal] = useState(false);
    const [openAddPatientModal, setOpenAddPatientModal] = useState(false);
    //新規追加患者
    const [newPatient, setNewPatient] = useState(emptyPatients);

    const clinic = useContext(ClinicContext)

    const facilityList = useFacilityList_service(graphqlClient, { clinic_id: clinic.clinic_id }).facilityList
    const allMenuList = useMenuList_service(graphqlClient, { clinic_id: clinic.clinic_id })
    const menuList = allMenuList.menuList
    const menuListIncludeDelete = allMenuList.menuListIncludeDelete
    const categoryList = useCategoryList_service(graphqlClient, { clinic_id: clinic.clinic_id }).categoryList
    const allStaffLists = useStaffList_service(graphqlClient, { clinic_id: clinic.clinic_id })
    const manegerLists = getSortedManegerList(allStaffLists.staffList)
    const manegerListsIncludeDelete = getSortedManegerList(allStaffLists.staffListIncludeDelete)

    // 過去日付判定
    const isBeforeDay = (getUnixTime(startOfDay(new Date())) > appoint.startTime) && appoint.startTime !== 0

    return (
        <>
            {
                <AddAppointPresenter
                    // 患者新規追加
                    newPatient={newPatient}
                    setNewPatient={setNewPatient}

                    // 選択日付
                    nowDate={nowDate}
                    // 過去日付判定
                    isBeforeDay={isBeforeDay}

                    // URLパラメータ
                    search={search}
                    patientId={patientId}
                    startTime={startTime}
                    endTime={endTime}
                    facilityId={facilityId}
                    appointId={appointId}

                    // データ
                    appoint={appoint}
                    req={req}
                    operation={operation}

                    menuList={menuList}
                    menuListIncludeDelete={menuListIncludeDelete}
                    categoryList={categoryList}
                    manegerLists={manegerLists}
                    manegerListsIncludeDelete={manegerListsIncludeDelete}
                    facilityList={facilityList}

                    // 患者検索モーダル+患者新規追加モーダル
                    openSearchPatientModal={openSearchPatientModal}
                    setOpenSearchPatientModal={setOpenSearchPatientModal}
                    openAddPatientModal={openAddPatientModal}
                    setOpenAddPatientModal={setOpenAddPatientModal}
                />
            }
        </>
    )
}
