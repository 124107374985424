import React, { useContext, useState } from 'react'
import { getCategoryList, getMenuList } from '@/feature/phr-28/components/common/Api'
import { MedicalMenuViewsType, MenuType } from '../util/common/type'
import { useDeleteMenusDialog } from '@/feature/phr-28/components/template/medicalCareRegistration/useDeleteMenusDialog'
import { ClinicContext, useGraphqlClient } from '@/App'
import { MedicalMenuPresenter } from '../presenter/MedicalMenuPresenter'
import { getLastIndexNumFromMenuList, getSortedMenuList } from '../util/common/util'

export const MedicalMenuViews: React.FC<MedicalMenuViewsType> = (): JSX.Element => {
  const graphqlClient = useGraphqlClient();
  const clinic_id = useContext(ClinicContext).clinic_id;

  const { DeleteMenusDialog } = useDeleteMenusDialog()
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const [filterKeyword, setFilterKeyword] = useState('')
  const [sortingFactorNumber, setSortingFactorNumber] = useState(0)
  const [isSort, setIsSort] = useState(false)
  const [order, setOrder] = useState('DESC')
  const [show, setShow] = useState(false)
  // NOTE 0318 *** CSSスタイルを文字列で渡してるのが怪しみ
  const deleteBtnCss = 'leading-8 text-gray-100 text-sm font-medium h-8 w-32 text-center rounded-2xl bg-red-700 hover:bg-red-900'
  const disableBtnCss = 'leading-8 text-gray-100 text-sm font-medium h-8 w-32 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'
  // *** 
  const [bulkDeleteArr, setBulkDeleteArr] = useState<number[]>([])
  const menuLists: MenuType[] = getSortedMenuList(getMenuList(graphqlClient, clinic_id))
  const lastIndexNum = getLastIndexNumFromMenuList(menuLists)
  const categoryList = getCategoryList(graphqlClient, clinic_id)

  const tabTitles: string[] = ['診療メニュー登録', '診療カテゴリ登録']
  const isCenter: boolean = false
  const [tabName, setTabName] = useState('診療メニュー登録')

  // 検索
  const filteredLists = menuLists.filter((card) => {
    const tmpFilterTitle = filterKeyword.toLowerCase()
    // 小文字で比較して部分一致するものだけを残す
    return new RegExp(tmpFilterTitle).test(card.name.toLowerCase())
  })




  return (
    <MedicalMenuPresenter
      tabTitles={tabTitles}
      isCenter={isCenter}
      tabName={tabName}
      setTabName={setTabName}
      // NOTE 230318 *** ひとまず、オリジナル通りにnullを渡す 
      nowDate={null}
      setNowDate={null}
      matches={null}
      // ***
      setFilterKeyword={setFilterKeyword}
      bulkDeleteArr={bulkDeleteArr}
      setBulkDeleteArr={setBulkDeleteArr}
      deleteBtnCss={deleteBtnCss}
      disableBtnCss={disableBtnCss}
      show={show}
      setShow={setShow}
      lastIndexNum={lastIndexNum}
      categoryList={categoryList}
      isOpenDeleteDialog={isOpenDeleteDialog}
      setIsOpenDeleteDialog={setIsOpenDeleteDialog}
      DeleteMenusDialog={DeleteMenusDialog}
      filteredLists={filteredLists}
      sortingFactorNumber={sortingFactorNumber}
      setSortingFactorNumber={setSortingFactorNumber}
      isSort={isSort}
      setIsSort={setIsSort}
      setOrder={setOrder}
    />
  )
}
