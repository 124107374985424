import React from 'react';
import type { FacilityType } from '@/components/Facility/entities';
import { $FacilityLabel } from './$FacilityLabel';

export type FacilityFramesProps = {
    facilityList: FacilityType[];
    facilityFrameWidth: number;
};

const style = 'flex justify-around sticky top-0 z-[999] bg-white ml-[5%]'

export const $FacilitiesLabel: React.FC<FacilityFramesProps> = ({
    facilityList,
    facilityFrameWidth
}) => {
    const shiftFrameWidth = 100 / facilityList.length;

    return (
        <div className={style}>
            {facilityList.map((facility: FacilityType) => (
                <$FacilityLabel
                    key={facility.id}
                    facility={facility}
                    facilityFrameWidth={facilityFrameWidth}
                />
            ))}
        </div>
    )
};

