import { ReserveType } from "@/components/Reservation/entities"
import { PatientsType } from "@/components/Patient/components/util/common/type";
import { StaffType } from "@/components/Staff/entities"
import { emptyPatients } from "@/components/Common/utils/common/emptyData"
import { MenuType } from "@/components/Menu/entities"
import { FacilityNameType, FacilityType } from "@/components/Facility/entities"
import { FloorType } from "@/components/Floor/entities"
import { listManegerType } from "@/components/Staff/components/utils/common/type";

export function getPatientName(
  patient: PatientsType
) {
  let patientName = ''

  if (patient?.status !== "true") {
    patientName = `${patient?.name?.last} ${patient?.name?.first}`
  } else {
    patientName = '削除済み患者'
  }

  return patientName
}

export function getPatientInfo(list: ReserveType, patientsList: PatientsType[]): PatientsType {
  let currentPatient: PatientsType = emptyPatients
  patientsList.forEach((patient: PatientsType) => {
    if (list.id === patient.id) {
      currentPatient = patient
    }
  })

  return currentPatient
}

export function getPatientMedicalNumber(
  patient: PatientsType,
  clinic_label: string
) {
  let medicalNumber = ''
  //エルディアの場合DNumberも返却
  if (clinic_label === 'eldear') {
    medicalNumber = `D-${patient?.DNumber || '未登録'} / S-${patient?.SNumber || '未登録'}`
  } else {
    medicalNumber = `${patient?.SNumber || '未登録'}`
  }
  return medicalNumber
}

export function getPatientsNameWithKanaById(
  patientList: PatientsType[],
  id: string
) {
  let nameWithKana
  patientList.forEach(patient => {
    if (patient.id === id) nameWithKana = `${patient.name.last} ${patient.name.first}(${patient.kana.last} ${patient.kana.first})`
  })
  return nameWithKana
}

export function getStaffNameById(staffLists: StaffType[], staffId: string): string {
  let staffName: string = ''
  staffLists.forEach((staff: StaffType) => {
    if (staff.id === staffId) {
      if (staff.isDelete) {
        staffName = `${staff.name}(削除済み)`
        return
      }
      staffName = staff.name
    }
  })
  return staffName
}

export function getStaffColorById(staffLists: StaffType[], staffId: string): string {
  //bg-slate-500
  let staffColor: string = 'rgb(100,116,139)'
  staffLists.forEach((staff: StaffType) => {
    if (staff.id === staffId && staff.color) {
      staffColor = staff.color
    }
  })
  return staffColor
}

export function getMenuName(menuList: MenuType[], id: string): string {
  let menuName: string = ''
  menuList.forEach((menu: MenuType) => {
    if (menu.SDK_ID === id) {
      if (menu.isDelete) {
        menuName = `${menu.name}(削除済み)`
        return
      }
      menuName = menu.name
    }
  })
  return menuName
}

export function getMenuAbbreviation(menuList: MenuType[], id: string): string {
  let getMenuAbbreviation: string = ''
  menuList.forEach((menu: MenuType) => {
    if (menu.SDK_ID === id) {
      if (menu.isDelete === true) {
        getMenuAbbreviation = `${menu.abbreviation}(削除済み)`
        return
      }
      getMenuAbbreviation = menu.abbreviation
    }
  })
  return getMenuAbbreviation
}

export function hasStaff(staffList: StaffType[], staffId: string): boolean {
  const staff = staffList.find(staff => staff.id === staffId);
  const isStaff = Boolean(staff);
  return isStaff;
}


// FIXME: Array.findに変更する
export function getFacilityName(facilityList: FacilityType[] | FacilityNameType[], id: string): string {
  let facilityName: string = ''
  facilityList.forEach((facility: FacilityType | FacilityNameType) => {
    if (facility.id === id) {
      if (facility.isDelete) {
        facilityName = `${facility.name}(削除済み)`
        return
      }
      facilityName = facility.name
    }
  })
  return facilityName
}

export function getFloorName(floorList: FloorType[], floor_id: string): string {
  let floorName: string = ''
  floorList?.forEach((floor: FloorType) => {
    if (floor.floor_id === floor_id) {
      if (floor.isDelete) {
        floorName = `${floor.floorName}(削除済み)`
        return
      }
      floorName = floor.floorName
    }
  })
  return floorName
}

export function getManegerNameById(manegerLists: listManegerType[], manegerId: string): string {
  let manegerName: string = ''
  manegerLists.forEach((maneger: listManegerType) => {
    if (maneger.id === manegerId) {
      if (maneger.isDelete) {
        manegerName = `${maneger.name}(削除済み)`
        return
      }
      manegerName = maneger.name
    }
  })
  return manegerName
}