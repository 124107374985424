// 必要なモジュールをインポート
import { useLocalStorage } from "@mantine/hooks";
import { useQueryParams, useWatchQueryParam } from "../queryParams"
import { createContext, useContext, ReactNode } from 'react';
import { ClinicContext } from "@/App";

// FacilityGroupContext の型定義
interface FacilityGroupContextType {
    group_id: string | null;
    setFacilityGroupId: (newGroupId: string) => void;
}

interface StorageValueType {
    key: string;
    dafaultValue: string;
}

// FacilityGroupContextの初期値と型を設定
const FacilityGroupContext = createContext<FacilityGroupContextType>({
    group_id: null,
    setFacilityGroupId: () => { }
});

export const useFacilityGroup = (): FacilityGroupContextType => {
    const { clinic_id } = useContext(ClinicContext)

    const strageValue: StorageValueType = {
        key: clinic_id,
        dafaultValue: ''
    }

    // クエリパラメータを操作するための関数を取得
    const { setQueryParam } = useQueryParams();

    // ローカルストレージからgroup_idを取得、または設定するための関数を取得
    const [storedGroupId, setStoredGroupId] = useLocalStorage(strageValue);

    // クエリパラメータのgroup_idを監視
    const group_id = useWatchQueryParam("group_id") || storedGroupId;

    // group_idをクエリパラメータとローカルストレージに設定する関数
    const setFacilityGroupId = (newGroupId: string) => {
        setQueryParam("group_id", newGroupId);
        setStoredGroupId(newGroupId);
    };

    return {
        group_id,
        setFacilityGroupId
    };
}

export const FacilityGroupContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const facilityGroup = useFacilityGroup();

    return (
        <FacilityGroupContext.Provider value={facilityGroup}>
            {children}
        </FacilityGroupContext.Provider>
    );
}

// 他のコンポーネントでFacilityGroupContextの値を取得するためのカスタムフック
export const useFacilityGroupContext = (): FacilityGroupContextType => {
    return useContext(FacilityGroupContext);
};
