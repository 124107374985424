export interface QuarterSlotProps {
    isHourTime: boolean;
    isHalfTime: boolean;
    isHoliday: boolean;
    isDisabled: boolean;
    isBeforeDay: boolean;
    onClickHandler: () => void;
}

//名称
//QuarterSlot
//HourSlot
//shiftSlots

const baseClass = "relative cursor-pointer box-border border-black bg-blue-200 hover:bg-blue-400";
export const QuarterSlot: React.FC<QuarterSlotProps> = ({ isHourTime, isHalfTime, isHoliday, isDisabled, isBeforeDay, onClickHandler }) => {

    // 枠線スタイル
    // 時間枠の場合
    const hourTimeBorderStyle = isHourTime ? 'border-t-2' : '';
    // 30分枠の場合
    const halfTimeBorderStyle = isHalfTime ? 'border-t-2 border-dotted' : '';
    const borderStyle = `${hourTimeBorderStyle} ${halfTimeBorderStyle}`;

    // 最終的なクラス
    const finalClass = `${baseClass} ${borderStyle}`;

    // 高さ+マージン
    // 現状は固定値
    const sizeStyle = {
        height: '190px',
        marginBottom: '10px'
    };

    //背景色スタイル
    // 祝日か無効の場合
    const holidayOrDisabledStyle: React.CSSProperties = isBeforeDay ? { backgroundColor: '#aaa', pointerEvents: 'none' } : {};
    // 過去日付の場合
    const beforeDayStyle: React.CSSProperties = (isHoliday || isDisabled) ? { backgroundColor: '#FFC0CB', pointerEvents: 'none' } : {};
    const backgroudStyle = {
        ...holidayOrDisabledStyle,
        ...beforeDayStyle
    }

    // 最終的なスタイル
    const finalStyle = {
        ...sizeStyle,
        ...backgroudStyle
    };

    return (
        <div
            onClick={onClickHandler}
            className={finalClass}
            style={finalStyle}
        >
        </div>
    );
}