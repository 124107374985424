import React, { useContext, useState } from 'react'
import { MonthCalendar } from './MonthlyCalendar'
import { ShiftDaily } from './ShiftDaily'
import { ShiftWeekly } from './ShiftWeekly'
import { listManegerType } from '@/feature/phr-28/components/common/type'
import { getManegerList } from '@/feature/phr-28/components/common/Api'
import { ClinicContext, useGraphqlClient } from '@/App'

interface ShiftRegistrationType {
  setShiftFix: (isShow: boolean) => void
  setShiftIdentificationNumber: (identification: string) => void
  nowDate: Date
  setNowDate: (date: Date) => void
}

export const DailyCalendar: React.FC<ShiftRegistrationType> = ({
  setShiftFix,
  setShiftIdentificationNumber,
  nowDate,
  setNowDate
}) => {
  const graphqlClient = useGraphqlClient();
  const clinic_id = useContext(ClinicContext).clinic_id;
  const [shiftDisplayRange, setShiftDisplayRange] = useState('daily')
  const manegerLists: listManegerType[] = getManegerList(graphqlClient, clinic_id)

  return (
    <>
      <div className="flex h-full flex-col">
        <ul className="flex flex-wrap text-sm pb-1 font-medium text-center text-gray-500 dark:text-gray-400">
          <li className="">
            <button
              type="button"
              onClick={() => {
                setShiftDisplayRange('daily')
              }}
              className={
                shiftDisplayRange === 'daily'
                  ? 'inline-block py-3 px-4 text-white bg-blue-600 rounded-lg active'
                  : 'inline-block py-3 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-100'
              }
              aria-current="page"
            >
              日間
            </button>
          </li>
          <li className="">
            <button
              type="button"
              onClick={() => {
                setShiftDisplayRange('weekly')
              }}
              className={
                shiftDisplayRange === 'weekly'
                  ? 'inline-block py-3 px-4 text-white bg-blue-600 rounded-lg active'
                  : 'inline-block py-3 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-100'
              }
              aria-current="page"
            >
              週間
            </button>
          </li>
        </ul>

        {/* シフト */}
        <div className="flex flex-auto box-border">
          <div className="w-8/12 flex flex-col">
            {shiftDisplayRange === 'daily'
              ? (
                <ShiftDaily
                  nowDate={nowDate}
                  setShiftFix={setShiftFix}
                  setShiftIdentificationNumber={setShiftIdentificationNumber}
                  manegerLists={manegerLists}
                />
              )
              : (
                <ShiftWeekly
                  nowDate={nowDate}
                  setShiftFix={setShiftFix}
                  setShiftIdentificationNumber={setShiftIdentificationNumber}
                  manegerLists={manegerLists}
                />
              )}
          </div>
          <div className="w-4/12 px-4 box-border">
            <MonthCalendar nowDate={nowDate} setNowDate={setNowDate} />
          </div>
        </div>
      </div>
    </>
  )
}
