import { formatDateWithTime } from '@/components/Common/utils';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { Link } from '@tanstack/react-location';
import { AppointmentCardPresenterType } from '../utils/common/type';
import {
  AppointmentCardAppointmentInfoButtonType,
  AppointmentCardAppointmentStatusButtonType,
  AppointmentCardDateStartToEndType,
  AppointmentCardDeleteButtonType,
  AppointmentCardFacilityNameItemType,
  AppointmentCardManegerNameItemType,
  AppointmentCardMenuNameItemType,
  AppointmentCardNameItemType,
  AppointmentCardOutlineType,
} from '../utils/components/parts';
import { OutlineType } from '@/components/Common/entities/parts';
import { AppointmentStatusConfirm } from '../../AppointmentStatusConfirm';

export const AppointmentCardPresenter: React.FC<AppointmentCardPresenterType> = ({
  isBeforeDay,

  id,
  confirmed,
  isConfirm,
  setIsConfirm,
  patientName,
  facilityName,
  menuName,
  manegerName,
  appointment,
  nowDate,
  startTime,
  endTime,
  facilityId,
  appointId,
  setIsOpen,
  setCurrentAppointment,
  reservation,
  isOpen,
  DeleteAppointmentDialog,
}) => {
  return (
    <>
      {/* 予約ステータス変更の確認 */}
      <AppointmentStatusConfirm
        id={id}
        confirmed={confirmed}
        isConfirm={isConfirm}
        setIsConfirm={setIsConfirm}
      />

      <AppointmentCardOutline>
        {/* NOTE 230523 機能的な意味のないチェックボックス？？？ */}
        {/* <AppointmentCardCheckboxItem /> */}

        <AppointmentCardDataItemOutline>
          <AppointmentCardDataUpperSectionOutline>
            <AppointmentCardNameItem patientName={patientName} />
            <AppointmentCardFacilityNameItem facilityName={facilityName} />
            <AppointmentCardMenuNameItem menuName={menuName} />
            <AppointmentCardManegerNameItem manegerName={manegerName} />
          </AppointmentCardDataUpperSectionOutline>

          <AppointmentCardDataLowerSectionOutline>
            <AppointmentCardCalendarIcon />
            <AppointmentCardDateStartToEnd appointment={appointment} confirmed={confirmed} />
          </AppointmentCardDataLowerSectionOutline>
        </AppointmentCardDataItemOutline>

        <AppointmentCardButtonsOutline>
          <AppointmentCardButtonsLeftSectionOutline>
            <AppointmentCardAppointmentStatusButton
              setIsConfirm={setIsConfirm}
              appointment={appointment}
            />
          </AppointmentCardButtonsLeftSectionOutline>

          <AppointmentCardButtonsRightSectionOutline>
            <AppointmentCardAppointmentInfoButton
              nowDate={nowDate}
              id={id}
              startTime={startTime}
              endTime={endTime}
              facilityId={facilityId}
              appointId={appointId}
            />

            {/* <AppointmentCardTogglePhrScreenButton /> */}

            {/* 過去予約の場合は削除不可 */}
            {!isBeforeDay &&
              <AppointmentCardDeleteButton
                setIsOpen={setIsOpen}
                setCurrentAppointment={setCurrentAppointment}
                reservation={reservation}
              />
            }
          </AppointmentCardButtonsRightSectionOutline>
        </AppointmentCardButtonsOutline>
      </AppointmentCardOutline>

      {/* 削除ダイアログ */}
      {isOpen && <DeleteAppointmentDialog patientName={patientName} setIsOpen={setIsOpen} />}
    </>
  );
};

const AppointmentCardOutline: React.FC<AppointmentCardOutlineType> = ({ children }) => {
  const outlineContainerStyle = 'py-[2px] list-none';
  const innterOutlineContainerStyle = 'flex items-center justify-between';
  return (
    <li className={outlineContainerStyle}>
      <div className={innterOutlineContainerStyle}>{children}</div>
    </li>
  );
};

const AppointmentCardCheckboxItem: React.FC = () => {
  const checkboxStyle =
    'mr-4 w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600';
  return (
    <div>
      <input type="checkbox" value="" className={checkboxStyle} />
    </div>
  );
};

const AppointmentCardDataItemOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'flex-1 min-w-0';
  return <div className={outlineContainerStyle}>{children}</div>;
};

const AppointmentCardDataUpperSectionOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'flex gap-4';
  return <div className={outlineContainerStyle}>{children}</div>;
};

const AppointmentCardDataLowerSectionOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'flex gap-4';
  return <div className={outlineContainerStyle}>{children}</div>;
};

const AppointmentCardNameItem: React.FC<AppointmentCardNameItemType> = ({ patientName }) => {
  const textStyle = 'text-xs font-medium text-gray-900 truncate dark:text-white';
  return (
    <p className={textStyle}>{`名前 : ${patientName}`}</p>
  );
};

const AppointmentCardFacilityNameItem: React.FC<AppointmentCardFacilityNameItemType> = ({
  facilityName,
}) => {
  const textStyle = 'text-xs font-medium text-gray-900 truncate dark:text-white';
  return <p className={textStyle}>施設名：{facilityName}</p>;
};

const AppointmentCardMenuNameItem: React.FC<AppointmentCardMenuNameItemType> = ({ menuName }) => {
  const textStyle = 'text-xs font-medium text-gray-900 truncate dark:text-white';
  return <p className={textStyle}>メニュー：{menuName}</p>;
};

const AppointmentCardManegerNameItem: React.FC<AppointmentCardManegerNameItemType> = ({
  manegerName,
}) => {
  const textStyle = 'text-xs font-medium text-gray-900 truncate dark:text-white';
  return <p className={textStyle}>担当：{manegerName}</p>;
};

const AppointmentCardCalendarIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  );
};

const AppointmentCardDateStartToEnd: React.FC<AppointmentCardDateStartToEndType> = ({
  appointment,
  confirmed,
}) => {
  const containerStyle = 'text-xs';
  return (
    <div className={containerStyle}>
      <p>
        {appointment === '予約未確定'
          ? '-- -- --'
          : `${formatDateWithTime(fromUnixTime(confirmed.start), '/')} 〜
          ${appointment === '予約未確定'
            ? '-- -- --'
            : formatDateWithTime(fromUnixTime(confirmed.end), '/')
          }`}
      </p>
    </div>
  );
};

const AppointmentCardButtonsOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'flex justify-end h-full w-1/4 gap-x-2';
  return <div className={outlineContainerStyle}>{children}</div>;
};

const AppointmentCardButtonsLeftSectionOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'flex w-1/2 flex-col justify-between flex-wrap gap-2';
  return <div className={outlineContainerStyle}>{children}</div>;
};

const AppointmentCardAppointmentStatusButton: React.FC<
  AppointmentCardAppointmentStatusButtonType
> = ({ setIsConfirm, appointment }) => {
  const buttonStyle = `inline-flex items-center justify-center h-5 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white 
  ${appointment === '予約済み' ? 'bg-[#565656]' : ''}
  ${appointment === '受付済み' ? 'bg-indigo-600' : ''}
  ${appointment === '診療完了' ? 'bg-[#5EB5A5]' : ''}
  ${appointment === 'キャンセル' ? 'bg-red-500' : ''}
   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
   `;
  return (
    <button
      onClick={() => {
        setIsConfirm(true);
      }}
      type="button"
      className={buttonStyle}
    >
      {appointment}
    </button>
  );
};

const AppointmentCardButtonsRightSectionOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'h-full w-1/2 flex flex-wrap gap-2 justify-end'
  return <div className={outlineContainerStyle}>{children}</div>;
};

const AppointmentCardAppointmentInfoButton: React.FC<AppointmentCardAppointmentInfoButtonType> = ({
  nowDate,
  id,
  startTime,
  endTime,
  facilityId,
  appointId,
}) => {
  const buttonStyle = 'inline-flex items-center justify-center h-5 w-full border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
  return (
    <Link
      className={buttonStyle}
      to={`appoints-list/appoint-info?calendar-date=${getUnixTime(
        nowDate
      )}&patient-id=${id}&start-time=${startTime}&end-time=${endTime}&facility-id=${facilityId}&appoint-id=${appointId}`}
    >
      <button
        type="button"
      // onClick={() => setShow(true)}
      >
        予約詳細
      </button>
    </Link>
  );
};

const AppointmentCardDeleteButton: React.FC<AppointmentCardDeleteButtonType> = ({
  setIsOpen,
  setCurrentAppointment,
  reservation,
}) => {
  const buttonStyle = 'inline-flex items-center justify-center h-5 w-full border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
  return (
    <button
      type="button"
      onClick={() => {
        setIsOpen(true);
        setCurrentAppointment({ ...reservation });
      }}
      className={buttonStyle}
    >
      削除
    </button>
  );
};
