import { GraphQLClient } from 'graphql-request'
import { useListFloorsQuery } from "@/_graphql/graphql-client"
import { useFloorListVariablesType } from '@/domain/Floor/entities/types';
import { floorListMapper } from '../../mapper/floorListMapper';

export const useFloorList_service = (
    graphqlClient: GraphQLClient,
    variables: useFloorListVariablesType
) => {
    const floorList = useListFloorsQuery(graphqlClient, variables);
    const allFloorList = floorListMapper(floorList);
    return allFloorList;
}
