import { useContext, useState } from 'react'
import { getFacilityList } from '../common/Api'
import { FacilityType } from '@/feature/phr-28/components/common/type'
import { ClinicContext, useGraphqlClient } from '@/App'

export const FacilityView = (): JSX.Element => {
  const graphqlClient = useGraphqlClient();
  const clinic_id = useContext(ClinicContext).clinic_id;
  const [show, setShow] = useState(false)
  const tmpfacilityList: FacilityType[] = getFacilityList(graphqlClient, clinic_id)
  const facilityListIndex: FacilityType[] = []
  const facilityListIndexNull: FacilityType[] = []
  tmpfacilityList.forEach((facility: FacilityType) => {
    if (facility.index === null) {
      facilityListIndexNull.push(facility)
    } else {
      facilityListIndex.push(facility)
    }
  })
  facilityListIndex.sort((a, b) => a.index - b.index)
  facilityListIndexNull.sort((a, b) => a.id.localeCompare(b.id, 'ja'))
  const facilityList: FacilityType[] = facilityListIndex.concat(facilityListIndexNull)
  const lastIndexNum: number = getLastIndexNumFromFacilityList(facilityList)

  return (
    <div className="h-full w-full overflow-scroll">
      <div className="w-full relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    disabled
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
              </th>
              <th
                scope="col"
                className="px-12 py-3"
              >
                施設名
              </th>
              <th
                scope="col"
                className="px-6 py-3"
              >
                状態
              </th>
              <th scope="col" className="px-2 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {facilityList.length > 0
              ? (
                facilityList.map((facility: FacilityType, index: number) => (
                  <FacilityRecord record={facility} key={index} lastIndexNum={lastIndexNum} facilityList={facilityList} />
                ))
              )
              : (
                <tr className="h-20">
                  <th className="p-5" colSpan={8}>
                    該当する患者情報は見つかりませんでした。
                  </th>
                </tr>
              )
            } */}
          </tbody>
        </table>
      </div>
      {/* {show && (<FacilityAddRecord setShow={setShow} indexNum={lastIndexNum + 1} />)} */}
      <div className="pt-2 flex justify-end">
        <button
          type="button"
          onClick={() => setShow(!show)}
          className="leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-32 text-center rounded-2xl bg-red-400 hover:bg-red-500"
        >
          {show ? 'キャンセル' : '施設の追加'}
        </button>
      </div>
    </div>
  )
}

function getLastIndexNumFromFacilityList(facilityList: FacilityType[]): number {
  let lastIndex: number = 0
  facilityList.forEach((facility: FacilityType) => {
    if (facility.index !== null && facility.index !== undefined) {
      if (lastIndex < facility.index) {
        lastIndex = facility.index
      }
    }
  })
  return lastIndex
}
