import React, { useState, useEffect } from 'react'
import 'rc-time-picker/assets/index.css'
import { useAddShiftMutation } from '../../../../../../../../.graphql/types'
import { useQueryClient } from 'react-query'
import {
  dateFromUnixTime,
  formatDate,
  amShifts as tmpAmShifts,
  pmShifts as tmpPmShifts
} from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon'
import { shiftType } from '@/feature/phr-28/components/common/type'
import {
  workingTimeAm,
  workingTimePm
} from '@/feature/phr-28/components/common/emptyData'
import { useGraphqlClient } from '@/App';

interface ShiftAddModalType {
  isOpen: boolean
  setShow: (isOpen: boolean) => void
  shift: shiftType
}

export const ShiftAddModal: React.FC<ShiftAddModalType> = ({
  isOpen,
  setShow,
  shift
}) => {
  const [amShifts, setAmShifts] = useState(tmpAmShifts)
  const [pmShifts, setPmShifts] = useState(tmpPmShifts)
  // シフト追加登録
  const queryClient = useQueryClient()
  const graphqlClient = useGraphqlClient()
  const { mutate } = useAddShiftMutation(graphqlClient, {
    onSettled: () => {
      queryClient.invalidateQueries('listShift').catch(() => console.log('on Settled'))
    },
    onSuccess: (res) => {
      console.log(res)
    },
    onError: (error) => {
      throw error
    },
    retry: 3
  })
  const addShiftData: shiftType = {
    date: shift.date,
    id: shift.id,
    name: shift.name,
    shifts: amShifts.concat(pmShifts),
    workingTimes: { am: workingTimeAm, pm: workingTimePm }
  }
  const addShift = (): void => {
    mutate(addShiftData)
  }

  const updateShifts = (i: number, isAm: boolean): void => {
    if (isAm) {
      setAmShifts(
        amShifts.map((amShift, index) =>
          index !== i ? amShift : !amShift
        )
      )
    } else {
      setPmShifts(
        pmShifts.map((pmShift, index) =>
          index !== i ? pmShift : !pmShift
        )
      )
    }
  }

  const shiftTime = (): JSX.Element => {
    const amTimes = []
    let tmpAmTimes = []
    const pmTimes = []
    let tmpPmTimes = []

    for (let i = 0; i < amShifts.length; i++) {
      tmpAmTimes.push(
        <div className="flex items-center mb-4 w-20" key={i}>
          <input
            id="default-checkbox"
            type="checkbox"
            value={i}
            onChange={() => {
              updateShifts(i, true)
            }}
            checked={amShifts[i]}
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="default-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {workingTimeAm[i]}
          </label>
        </div>
      )
      if (i % 4 === 3) {
        amTimes.push(
          <div className="flex flex-row" key={i + 100}>
            {tmpAmTimes}
          </div>
        )
        tmpAmTimes = []
      }
    }
    for (let i = 0; i < pmShifts.length; i++) {
      tmpPmTimes.push(
        <div className="flex items-center mb-4 w-20" key={i}>
          <input
            id="default-checkbox"
            type="checkbox"
            value={i}
            onChange={() => {
              updateShifts(i, false)
            }}
            checked={pmShifts[i]}
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="default-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {workingTimePm[i]}
          </label>
        </div>
      )
      if (i % 4 === 3) {
        pmTimes.push(
          <div className="flex flex-row" key={i + 200}>
            {tmpPmTimes}
          </div>
        )
        tmpPmTimes = []
      }
    }
    useEffect(() => {
      setAmShifts(tmpAmShifts)
      setPmShifts(tmpPmShifts)
    }, [shift])

    return (
      <>
        <div className="p-2 h-96 overflow-scroll">
          <div>
            <label
              htmlFor="am"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              勤務時間:AM
            </label>
            <div className="flex flex-col mb-4">{amTimes}</div>
          </div>
          <div>
            <label
              htmlFor="pm"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              勤務時間:PM
            </label>
            <div className="flex flex-col mb-4">{pmTimes}</div>
          </div>
        </div>
      </>
    )
  }

  const modalElement = (
    <>
      <div>
        <div
          id="overlay"
          className="fixed top-0 z-[99] left-0 w-full h-full bg-gray-500 flex align-middle justify-center bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                onClick={() => setShow(false)}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="authentication-modal"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>

              <div className="py-6 px-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  シフト登録
                </h3>
                <form className="space-y-3" action="/masta">
                  <div>
                    <label
                      htmlFor="roles"
                      className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-400"
                    >
                      登録日付
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      value={formatDate(dateFromUnixTime(shift.date))}
                      disabled
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      名前
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      value={shift.name}
                      disabled
                    />
                  </div>

                  {shiftTime()}

                  <button
                    type="submit"
                    className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={() => addShift()}
                  >
                    保存
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  return <>{isOpen && modalElement}</>
}
