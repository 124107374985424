import React, { useContext, useState } from 'react'
import { ShiftRegistration } from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/ShiftRegistration'
import { ResponsibleList } from '@/feature/phr-28/components/template/mastaRegistration/responsibleList/ResponsibleList'
// import { ResponsibleAddModal } from '@/feature/phr-28/components/template/mastaRegistration/responsibleList/ResponsibleAddModal'
import { getManegerList } from '@/feature/phr-28/components/common/Api'
import { listManegerType } from '@/feature/phr-28/components/common/type'
import { BasicTabBar } from './BasicTabBar'
import { ClinicContext, useGraphqlClient } from '@/App'
import { ResponsibleAddModalViews } from '@/components/Staff/components/views/ResponsibleAddModalViews'

export const MastaRegistrationView = (): JSX.Element => {
  const graphqlClient = useGraphqlClient()
  const clinic_id = useContext(ClinicContext).clinic_id;
  const manegerLists: listManegerType[] = getSortedManegerList(getManegerList(graphqlClient, clinic_id))
  const lastIndexNum: number = getLastIndexNumFromManegerList(manegerLists)

  const tabTitles: string[] = ['担当者一覧']
  const isCenter: boolean = true
  const [show, setShow] = useState(false)
  const [tabName, setTabName] = useState('担当者一覧')
  let selectedTabComponent = null
  switch (tabName) {
    case 'シフト登録':
      selectedTabComponent = (
        <>
          <ShiftRegistration manegerLists={manegerLists} />
        </>
      )
      break
    case '担当者一覧':
      selectedTabComponent = (
        <ResponsibleList manegerLists={manegerLists} lastIndexNum={lastIndexNum} />
      )
      break
  }
  return (
    <>
      <BasicTabBar
        tabTitles={tabTitles}
        arrangement={isCenter}
        setTabName={setTabName}
        nowDate={null}
        setNowDate={null}
        matches={null}
      />
      <div className="flex justify-end p-2">
        <button
          type="button"
          onClick={() => setShow(true)}
          className="leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-28 text-center rounded-2xl bg-gray-700 hover:bg-gray-800"
        >
          担当者の追加
        </button>
        <ResponsibleAddModalViews isOpen={show} setShow={setShow} indexNum={lastIndexNum + 1} />
      </div>
      {selectedTabComponent}
    </>
  )
}

function getLastIndexNumFromManegerList(manegerList: listManegerType[]): number {
  let lastIndex: number = 0
  manegerList.forEach((maneger: listManegerType) => {
    if (maneger.index !== null && maneger.index !== undefined) {
      if (lastIndex < maneger.index) {
        lastIndex = maneger.index
      }
    }
  })
  return lastIndex
}

function getSortedManegerList(manegerLists: listManegerType[]): listManegerType[] {
  const manegerListIndex: listManegerType[] = []
  const manegerListIndexNull: listManegerType[] = []
  manegerLists.forEach((maneger: listManegerType) => {
    if (maneger.index !== null && maneger.index !== undefined) {
      manegerListIndex.push(maneger)
    } else {
      manegerListIndexNull.push(maneger)
    }
  })
  manegerListIndex.sort((a, b) => a.index - b.index)
  manegerListIndexNull.sort((a, b) => a.id.localeCompare(b.id, 'ja'))
  const returnLists: listManegerType[] = manegerListIndex.concat(manegerListIndexNull)
  return returnLists
}
