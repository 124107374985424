//dateを文字列に変換
//yyyy年MM月DD日hours時minutes分
export function japanTimeFormatDate(date: any): string {
    return (
        String(date.getFullYear()) + '年' +
        (String(Number(date.getMonth()) + 1)).padStart(2, '0') + '月' +
        String(date.getDate()) + '日' +
        String(date.getHours()).padStart(2, '0') + '時' +
        String(date.getMinutes()).padStart(2, '0') + '分'
    )
}

// 診察券番号+患者グループのフォーマット
export function stringFormatMedicalNumbers(clinic_label: string, DNumber: string, SNumber: string, group: string) {
    let formatedMedicalNumbers = '';
    // エルディアクリニックのみ診察券番号を二種表示
    if (clinic_label === 'eldear') {
        // S-xxxx : D-xxxx : 種別-z
        formatedMedicalNumbers =
            `${SNumber ? 'S-' + SNumber.toString().padStart(4, '0') : ''}` +
            `${DNumber !== '0' ? ' D-' + DNumber.toString().padStart(4, '0') : ' D-'}` +
            `${group ? ' 種別-' + group : ''}`;
    } else {
        // S-xxxx : 種別-z
        formatedMedicalNumbers =
            `${SNumber ? SNumber.toString().padStart(4, '0') : ''}` +
            `${group ? '-種別-' + group : ''}`;
    }
    return formatedMedicalNumbers;
}
