import { useSearch } from "@tanstack/react-location";
import { fromUnixTime } from "date-fns";
import { useEffect, useRef } from "react";
import { useQueryClient } from "react-query";

//予約一覧の定期更新----
export const useRefreshTimer = (
    setNowDate: React.Dispatch<React.SetStateAction<Date>>
) => {
    const queryClient = useQueryClient();
    const refClient = useRef(queryClient);

    useEffect(() => {
        refClient.current = queryClient;
    }, [queryClient]);

    useEffect(() => {
        const timer = setInterval(() => {
            refClient.current.invalidateQueries()
            // refClient.current.invalidateQueries('listPatientsV2')
            // refClient.current.invalidateQueries('listManeger')
            // refClient.current.invalidateQueries('listMenu')
            // refClient.current.invalidateQueries('listCategorys')
            // refClient.current.invalidateQueries('listFacilities')
            // refClient.current.invalidateQueries('scanPeriodAppoint')
            // refClient.current.invalidateQueries('scanPeriodBlock')
        }, 1000000);
        return () => clearInterval(timer);
    }, []);
    //ここまで-------------

    // リロードしても選択した日付を維持
    const params = useSearch();
    useEffect(() => {
        if (params.timestamp) {
            setNowDate(fromUnixTime(params.timestamp as number))
        }
    }, [params.timestamp])
}
