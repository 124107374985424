import { CloseButton } from '@/components/Common'
import { LeftPaneHeaderPresenterPropsType } from '@/components/Main/entities'
import { useState } from 'react'

export const HeaderPresenter: React.FC<LeftPaneHeaderPresenterPropsType> = ({
    panesRef
}) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div className="flex justify-start mb-2">
            <CloseButton panesRef={panesRef} closeTarget='left' />
        </div>
    )
}
