import { ClinicSettingsPresenterType } from "@/components/ClinicSettings/entities"
import { ClinicTimeViews } from "@/components/ClinicSettings/components/views/ClinicTimeViews"
import { ClinicScheduleViews } from "../views/ClinicScheduleViews"
import { Card } from "@mantine/core"

export const ClinicSettingsPresenter: React.FC<ClinicSettingsPresenterType> = () => {
    return (
        <div className="h-full">
            <Card>
                <p className="text-lg font-bold text-gray-500">診療時間設定</p>
                <ClinicTimeViews />
            </Card>
        
            <Card>
                <p className="text-lg font-bold text-gray-500">休診日/診療日ルール設定</p>
                <ClinicScheduleViews />
            </Card>
        </div>
    )
}
