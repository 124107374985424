import { useContext } from 'react'
import { ClinicContext, useGraphqlClient } from '@/App'
import { useComment_service } from '@/domain'
import { MutateContext } from '@/provider/common/MutateProvider'
import { CommentPresenter } from '@/components/Comment/components/presenter/CommentPresenter'
import { getStartTimeOfToDayTimestamp } from '@/components/Common'
import { MediaQueryContext, SetDateContext } from '@/components/Main/provider/MainProvider'

export const CommentViews: React.FC = () => {
    const graphqlClient = useGraphqlClient()
    const { nowDate } = useContext(SetDateContext)
    const matches = useContext(MediaQueryContext);

    //医院メタデータ
    const clinic = useContext(ClinicContext);
    // コメント取得
    const getComments = useComment_service(graphqlClient, { clinic_id: clinic.clinic_id, date: getStartTimeOfToDayTimestamp(nowDate) })
    const comment = getComments?.comment || '';
    // コメント追加
    const { addComments } = useContext(MutateContext)

    return (
        <CommentPresenter
            matches={matches}
            nowDate={nowDate}
            clinic_id={clinic.clinic_id}
            comment={comment}
            addComments={addComments}
        />
    )

}
