import { MastaRegistrationView } from '@/feature/phr-28/components/views/MastaRegistrationView'

export const Staff: React.FC = () => {
  return (
    <>
      <div className="p-5">
        <MastaRegistrationView />
      </div>
    </>
  )
}
