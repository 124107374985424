import { GraphQLClient } from 'graphql-request'
import { useListManegerQuery } from '@/_graphql/graphql-client'
import { staffListMapper } from '@/domain/Staff/services/mapper/staffListMapper';
import { useStaffListVariablesType } from '@/domain/Staff/entities/types';

export const useStaffList_service = (
    graphqlClient: GraphQLClient,
    variables: useStaffListVariablesType
) => {
    const staffs = useListManegerQuery(graphqlClient, variables)
    const allStaffList = staffListMapper(staffs)
    return allStaffList
}