import { useContext } from "react";
import { ClinicSchedulePresenter } from "../presenter/ClinicSchedulePresenter"
import { MutateContext } from "@/provider/common/MutateProvider";
import { ClinicContext, useGraphqlClient } from "@/App";
import { useListClinicSchedule_service } from "@/domain/ClinicSchedule/services/hooks/Query/useClinicSchedule";

export const ClinicScheduleViews: React.FC = () => {

    const clinic = useContext(ClinicContext)
    const graphqlClient = useGraphqlClient();

    const clinicScheduleList = useListClinicSchedule_service(graphqlClient, { clinic_id: clinic.clinic_id })
    const { deleteClinicSchedule } = useContext(MutateContext)

    return (
        <ClinicSchedulePresenter
            clinic={clinic}
            clinicScheduleList={clinicScheduleList.clinicSchedules?.clinic_schedules ?? []}
            deleteClinicSchedule={deleteClinicSchedule}
        // addClinicSchedule={addClinicSchedule}
        />
    )
}
