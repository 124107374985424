import { Badge } from "@mantine/core"
import { StatusBadgeProps } from "../../entities"

const styles = {
  'reserved': 'bg-green-200 text-green-900',
  'checkedIn': 'bg-blue-300 text-blue-900',
  'canceled': 'bg-red-300 text-red-900',
  'completed': 'bg-yellow-200 text-yellow-900',
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  label,
  onClickHandler,
}) => {

  return (
    <Badge
      className={`${styles[status]} font-bold`}
      variant="filled"
      onClick={onClickHandler}
    >
      {label}
    </Badge>
  )
}
