import { MenuType } from '@/components/Menu/entities'
import { getMenuName } from '@/components/Common/utils';
import { IconLabel } from '../../IconLabel';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined'

export interface SelectMenuLabelPropsType {
    allMenuList: MenuType[]
    menuId: string
    openSearchMenuModal: () => void
}

export const SelectMenuLabel: React.FC<SelectMenuLabelPropsType> = ({
    allMenuList,
    menuId,
    openSearchMenuModal,
}) => {
    const label = getMenuName(allMenuList, menuId) || '診療メニュー'

    return (
        <IconLabel
            icon={PlaylistAddOutlinedIcon}
            label={label}
            disable={false}
            isHighlighted={false}
            onClickHandler={openSearchMenuModal}
        />
    )
}
