import React, { useContext, useState } from 'react';
import { ClinicContext, useGraphqlClient } from '@/App';
import { useQueryClient } from 'react-query';
import { Maneger, useUpDateManegerMutation } from '.graphql/types';
import { closeDialog } from '../utils/common/util';
import { Dialog, DialogContent } from '@mui/material';
import { ResponsibleDeleteModalPresenter } from '../presenter/ResponsibleDeleteModalPresenter';
import { ResponsibleDeleteModalViewsType } from '../utils/common/type';
import { MutateContext } from '@/provider/common/MutateProvider';

export const ResponsibleDeleteModalViews: React.FC<ResponsibleDeleteModalViewsType> = ({
  setIsOpen,
  currentManeger,
}) => {
  const emptyManeger: Maneger = {
    id: '',
    name: '',
    email: '',
    rights: '',
    color: '',
    role: '',
    isDelete: false,
  };
  const [deleteCurrentManeger, setDeleteCurrentManeger] = useState<Maneger>(currentManeger);
  const queryClient = useQueryClient();
  const graphqlClient = useGraphqlClient();

  const { deleteStaff } = useContext(MutateContext);
  const clinic = useContext(ClinicContext);

  const deleteManeger = (): void => {
    deleteStaff.mutate({ id: currentManeger.id, clinic_id: clinic.clinic_id });
  };

  return (
    <ResponsibleDeleteModalPresenter
      setIsOpen={setIsOpen}
      Dialog={Dialog}
      closeDialog={closeDialog}
      emptyManeger={emptyManeger}
      setDeleteCurrentManeger={setDeleteCurrentManeger}
      DialogContent={DialogContent}
      currentManeger={currentManeger}
      deleteManeger={deleteManeger}
    />
  );
};
