import { getStartTimeOfToDayTimestamp } from "@/components/Common"

export function onSubmit(clinic_id: string, nowDate: Date, comment: string, commentsMutate: any) {
    const mutateObject = {
        clinic_id,
        date: getStartTimeOfToDayTimestamp(nowDate),
        comment
    }

    commentsMutate(mutateObject)
}