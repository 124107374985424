export function toggleRowState(
    setIsOpenAddRow: React.Dispatch<React.SetStateAction<boolean>>,
    setIsOpenEditRow: React.Dispatch<React.SetStateAction<string>>,
    setIsOpenFilterRow: React.Dispatch<React.SetStateAction<boolean>>,
    isAdd: boolean,
) {
    setIsOpenAddRow(isAdd ? (prev) => !prev : false);
    setIsOpenFilterRow(isAdd ? false : (prev) => !prev);
    setIsOpenEditRow('');
    return
}