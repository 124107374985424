import { FacilityType, FacilityUpdateIndexButtonsType, ReferenceFacilityRowProps } from '../../entities';
import { Checkbox, Flex } from '@mantine/core';
import {
  $styledTR,
  _styledTD,
  BasicButton,
  PermissionActionButton,
} from '@/components/Common/components/Config';

// 参照用施設行
export const ReferenceFacilityRow: React.FC<ReferenceFacilityRowProps> = ({
  facilityId, // 施設id
  facilityName, // 施設名
  facilityStatus, // 施設ステータス
  editingFacilityId, // 編集中の行のid
  openEditForm, // 編集フォーム表示
  deleteFacility, // 削除mutation
  setAllowedDeletes, // 削除許可フラグセット
  groups, // 施設に紐づくグループ
  isAllowed,
  updateFacilityIdxMutation,

  //FIXME
  facility,
  updateFacilityMutation,
  lastIndexNum,
  facilityList
}) => {

  return (
    // 施設グループ行
    <$styledTR border={false}>
      <_styledTD>
        {/* チェックボックス */}
        <Checkbox checked={isAllowed} onChange={() => setAllowedDeletes()} radius="xs" size="xs" />
      </_styledTD>

      <_styledTD>
        {/* インデックス更新ボタン */}
        < FacilityUpdateIndexButtons
          record={facility}
          updateFacilityIdxMutation={updateFacilityIdxMutation}
          lastIndexNum={lastIndexNum}
        />
      </_styledTD>

      {/* 施設名 */}
      <_styledTD>{facilityName}</_styledTD>

      {/* 所属グループ */}
      <_styledTD>
        <Flex
          miw={300}
          maw={560}
          gap="xs"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          {
            groups.map((group: any) => <div>{group.name}</div>)
          }
        </Flex>
      </_styledTD>

      <_styledTD>
        {/* ステータス */}
        {facilityStatus ? '有効' : '無効'}
      </_styledTD>

      <_styledTD>
        <Flex direction={{ base: 'column', sm: 'row' }} gap="sm" align="center">
          {/* 編集ボタン */}
          {/* FIXME
                    tailwindとmantineのボタンスタイルが競合しているため一旦自前で用意
                    対処法あり: 後日要相談 */}
          <BasicButton
            label={editingFacilityId === facilityId ? 'キャンセル' : '編集'}
            width="small"
            color="gray"
            disabled={false}
            onClickEvent={() => {
              openEditForm();
            }}
          />

          {/* 削除ボタン */}
          {/* FIXME */}
          <PermissionActionButton
            isAllowed={isAllowed}
            action={() => deleteFacility()}
            label="削除"
          />
        </Flex>
      </_styledTD>
    </$styledTR>
  );
};

export default ReferenceFacilityRow;


const FacilityUpdateIndexButtons: React.FC<FacilityUpdateIndexButtonsType> = ({
  record,
  updateFacilityIdxMutation,
  lastIndexNum,
}) => {

  return (
    <>
      {record.index > 1 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="text-gray-100 h-8 w-8 text-center rounded-full bg-gray-400 hover:bg-gray-800 flex justify-center items-center my-1 cursor-pointer"
          onClick={() => updateFacilityIdxMutation({ inputs: { clinic_id: record.clinic_id, facility_id: record.id, direction: -1 } })}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )}
      {record.index < lastIndexNum && record.index !== null && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="text-gray-100 h-8 w-8 text-center rounded-full bg-gray-400 hover:bg-gray-800 flex justify-center items-center my-1 cursor-pointer"
          onClick={() => updateFacilityIdxMutation({ inputs: { clinic_id: record.clinic_id, facility_id: record.id, direction: 1 } })}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )}

    </>
  );
};