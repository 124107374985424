import { GraphQLClient } from 'graphql-request'
import { MutationsType } from '@/components/Common/entities/common/types'
import { createContext } from 'react'
import { useInitMutate } from '@/components/Common/hooks'

export const MutateContext = createContext<MutationsType>({} as MutationsType)
interface AppProviderProps {
  children: React.ReactNode
  graphqlClient: GraphQLClient
}

export const MutateProvider = ({ children, graphqlClient }: AppProviderProps): JSX.Element => {

  const mutates = useInitMutate(graphqlClient)

  return (
    <MutateContext.Provider value={mutates} >
      {children}
    </MutateContext.Provider>
  )
}
