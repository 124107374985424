import React, { useContext, useState } from 'react';
import { useSearch } from '@tanstack/react-location';
import { useDeletePatientsDialog } from '@/feature/phr-29/components/Patients/useDeletePatientsDialog';
import { usePatientsView } from '@/feature/phr-28/components/views/usePatientsView';
import { stringFormatMedicalNumbers } from '@/feature/phr-28/components/common/appointment';
import { ClinicContext } from '@/App';
import { PatientsCardPresenter } from '../presenter/PatientsCardPresenter';
import { PatientsCardViewsType, PatientsData, PatientsNavigationsType } from '../util/common/type';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import { getUnixTime } from 'date-fns';
// import { addOptionalParamToURL, addParamToURL } from '@/components/Common/utils';

export const PatientsCardViews: React.FC<PatientsCardViewsType> = ({
  seq,
  id,
  name,
  numberS,
  numberD,
  group,
  status,
  tel,
  orderAndFilterController,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { DeletePatientDialog, setCurrentPatient } = useDeletePatientsDialog(orderAndFilterController);

  const clinic = useContext(ClinicContext);
  const { nowDate } = useContext(SetDateContext)

  const navigations: PatientsNavigationsType[] = [
    {
      name: '詳細',
      path: `/main/patients-list/patient-info?calendar-date=${getUnixTime(nowDate)}&patient-id=${id}`,
    },
    {
      name: '削除',
      path: `${window.location.pathname}?calendar-date=${getUnixTime(nowDate)}`,
      func: () => {
        setCurrentPatient({ id, name, clinic_id: clinic.clinic_id });
        setIsOpen(true);
      },
    },
  ];
  const nameData: PatientsData[] = [
    {
      name: '名前:',
      property: name,
    },
  ];
  const data: PatientsData[] = [
    {
      name: '診察券番号:',
      property: stringFormatMedicalNumbers(clinic.clinic_label, numberD, numberS, group),
    },
    {
      name: '電話番号:',
      property: tel,
    },
  ];

  return (
    <>
      <PatientsCardPresenter
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        DeletePatientDialog={DeletePatientDialog}
        navigations={navigations}
        seq={seq}
        nameData={nameData}
        data={data}
        status={status}
      />
    </>
  );
};
