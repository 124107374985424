import React from 'react';
import { FieldError } from 'react-hook-form';

interface CardNumberErrorMessageProps {
    error: FieldError | null;
}

/**
 * カード番号のエラーメッセージを表示するコンポーネント
 * @param error - react-hook-formの特定のエラーオブジェクト
 * @returns エラーメッセージのReact要素
 */
export const CardNumberErrorMessage: React.FC<CardNumberErrorMessageProps> = ({ error }) => {
    if (!error || typeof error.message !== 'string') return null;

    return <span className="text-red-600 text-xs pl-1">{error.message}</span>;
};

// 使用例
// <CardNumberErrorMessage error={errors.addValues?.SNumber} />
