import {
  DentalFormulaArrayInput,
  type InputMaybe,
  type DentalFormulaInput,
} from '@/_graphql/graphql-client';
import { RectangleButton } from '../../RectangleButton';
import { babyLabels } from '@/feature/phr-28/components/template/Appointment/ModalContents/DentalFormulaModal';

export interface SelectDentalFormulaLabelPropsType {
  openSearchMenuModal: () => void;
  dentalFormula: DentalFormulaInput;
}

export function ToothFormula (
  labels: string[],
  position: 'rightTop' | 'leftTop' | 'rightBottom' | 'leftBottom',
  fontColor: string = '#1976d2',
  borderColor: string = '#1976d2'
): JSX.Element[] {
  const getStyle = (position: 'rightTop' | 'leftTop' | 'rightBottom' | 'leftBottom') => {
    const baseStyle = {
      display: 'inline-flex',
      alignItems: 'center',
      height: '18px',
      color: fontColor,
      backgroundColor: 'transparent',
      fontSize: '0.7125rem',
      margin: '2px',
      padding: '0 8px',
    };

    switch (position) {
      case 'rightTop':
        return {
          ...baseStyle,
          borderBottom: `3px solid ${borderColor}`,
          borderRight: `3px solid ${borderColor}`,
        };
      case 'leftTop':
        return {
          ...baseStyle,
          borderBottom: `3px solid ${borderColor}`,
          borderLeft: `3px solid ${borderColor}`,
        };
      case 'rightBottom':
        return {
          ...baseStyle,
          borderTop: `3px solid ${borderColor}`,
          borderRight: `3px solid ${borderColor}`,
        };
      case 'leftBottom':
        return {
          ...baseStyle,
          borderTop: `3px solid ${borderColor}`,
          borderLeft: `3px solid ${borderColor}`,
        };
    }
  };

  return labels.map((tooth, idx) => (
    <span key={idx} style={getStyle(position)}>
      {tooth}
    </span>
  ));
}


export const SelectDentalFormulaLabel: React.FC<SelectDentalFormulaLabelPropsType> = ({
  openSearchMenuModal,
  dentalFormula,
}) => {
  const label = '歯式入力';

  return (
    <>
      <RectangleButton
        label={label}
        color={'blue'}
        size={'sm'}
        disable={false}
        onClickHandler={openSearchMenuModal}
      />{' '}
      :
      {ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.rightTop ?? [])), "rightTop")}
      {ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.leftTop ?? [])), "leftTop")}
      {ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.rightBottom ?? [])), "rightBottom")}
      {ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.leftBottom ?? [])), "leftBottom")}
      {ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.rightTop ?? [])), "rightTop")}
      {ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.leftTop ?? [])), "leftTop")}
      {ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.rightBottom ?? [])), "rightBottom")}
      {ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.leftBottom ?? [])), "leftBottom")}
    </>
  );
};

export function cleanTeethData(teeth: Array<InputMaybe<boolean>>): boolean[] {
  return teeth.map((tooth) => Boolean(tooth));
}

// 歯の連番を〜で結合して返却する
export function formatDentalSequence (teeth: boolean[]): string[] {
  const ranges = [];
  let start: null | number = null;

  teeth.forEach((tooth: any, index: any) => {
    if (Boolean(tooth) && start === null) {
      start = index + 1; // 歯の番号は1から始まるため、index + 1
    } else if (!tooth && start !== null) {
      ranges.push(start === index ? `${start}` : `${start}〜${index}`);
      start = null;
    }
  });

  // 最後の範囲を閉じる
  if (start !== null) {
    ranges.push(start === teeth.length ? `${start}` : `${start}〜${teeth.length}`);
  }

  return ranges;
}

export function formatBabyDentalSequence(teeth: boolean[]): string[] {
  const ranges = [];
  let start: number | null = null;

  teeth.forEach((tooth, index) => {
    if (tooth && start === null) {
      start = index;
    } else if (!tooth && start !== null) {
      ranges.push(start === index - 1 ? `${babyLabels[start]}` : `${babyLabels[start]}〜${babyLabels[index - 1]}`);
      start = null;
    }
  });

  // 最後の範囲を閉じる
  if (start !== null) {
    ranges.push(start === teeth.length - 1 ? `${babyLabels[start]}` : `${babyLabels[start]}〜${babyLabels[teeth.length - 1]}`);
  }

  return ranges;
}