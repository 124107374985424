import { toggleRowState } from "@/components/ClinicSettings/utils"

interface HeadColAddButtonType {
    isOpenAddRow: boolean,
    setIsOpenAddRow: React.Dispatch<React.SetStateAction<boolean>>,
    setIsOpenEditRow: React.Dispatch<React.SetStateAction<string>>,
    isOpenFilterRow: boolean,
    setIsOpenFilterRow: React.Dispatch<React.SetStateAction<boolean>>,
}

/* 新規追加ボタン */
export function HeadColAddButton({
    isOpenAddRow,
    setIsOpenAddRow,
    setIsOpenEditRow,
    isOpenFilterRow,
    setIsOpenFilterRow,
}: HeadColAddButtonType): JSX.Element {
    // スタイル
    const deleteBtnCss: string = 'cursor-pointer leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-red-700 hover:bg-red-900'

    const isActiveStyle = "bg-red-600"
    const isInActiveStyle = "bg-gray-600"

    const dynamicStyleAddRow = isOpenAddRow ? isActiveStyle : isInActiveStyle
    const dynamicStyleFilterRow = isOpenFilterRow ? isActiveStyle : isInActiveStyle
    const staticStyle = "text-xs p-0"

    return <th scope="col">
        <div className="flex">
            <p
                className={deleteBtnCss + ' ' + staticStyle + ' ' + dynamicStyleAddRow}
                onClick={() => toggleRowState(setIsOpenAddRow, setIsOpenEditRow, setIsOpenFilterRow, true)}
            >
                新規追加
            </p>
            <p
                className={deleteBtnCss + ' ' + staticStyle + ' ' + dynamicStyleFilterRow}
                onClick={() => toggleRowState(setIsOpenAddRow, setIsOpenEditRow, setIsOpenFilterRow, false)}
            >
                フィルター
            </p>
        </div>
    </th>
}