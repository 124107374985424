import { $PatientRemarksLabelPropsType } from "@/components/Reservation/entities";

const divStyle = "w-4/5 px-4 h-6 inline-flex items-center gap-4 rounded-sm border-gray-50 text-xs bg-gray-200";
const paragraphStyle = "text-xs w-full truncate";

export const $PatientRemarksLabel: React.FC<$PatientRemarksLabelPropsType> = ({
    label
}) => {
    return (
        <div className={divStyle}>
            <p className={paragraphStyle}>
                {label}
            </p>
        </div>
    )
}
