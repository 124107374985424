import { listManegerType } from "./type"
import { Maneger } from '.graphql/types'

export function getLastIndexNumFromManegerList(manegerList: listManegerType[]): number {
    let lastIndex: number = 0
    manegerList.forEach((maneger: listManegerType) => {
      if (maneger.index !== null && maneger.index !== undefined) {
        if (lastIndex < maneger.index) {
          lastIndex = maneger.index
        }
      }
    })
    return lastIndex
  }
  
export function getSortedManegerList(manegerLists: listManegerType[]): listManegerType[] {
    const manegerListIndex: listManegerType[] = []
    const manegerListIndexNull: listManegerType[] = []
    manegerLists.forEach((maneger: listManegerType) => {
      if (maneger.index !== null && maneger.index !== undefined) {
        manegerListIndex.push(maneger)
      } else {
        manegerListIndexNull.push(maneger)
      }
    })
    manegerListIndex.sort((a, b) => a.index - b.index)
    manegerListIndexNull.sort((a, b) => a.id.localeCompare(b.id, 'ja'))
    const returnLists: listManegerType[] = manegerListIndex.concat(manegerListIndexNull)
    return returnLists
  }

export function openDialog(
  currentManeger: Maneger,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentManeger: React.Dispatch<React.SetStateAction<Maneger>>
): void {
  setCurrentManeger(currentManeger)
  setIsOpen(true)
}

export function clearState(
  setSelectRole: React.Dispatch<React.SetStateAction<string>>,
  setSelectRights: React.Dispatch<React.SetStateAction<string>>,
  setInputName: React.Dispatch<React.SetStateAction<string>>,
  setInputEmail: React.Dispatch<React.SetStateAction<string>>,
  setInputPass: React.Dispatch<React.SetStateAction<string>>
): void {
  setSelectRole('')
  setSelectRights('')
  setInputName('')
  setInputEmail('')
  setInputPass('')
}

export function closeDialog(
  emptyManeger: Maneger,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentManeger: React.Dispatch<React.SetStateAction<Maneger>>
) {
  setCurrentManeger(emptyManeger);
  setIsOpen(false);
}