import Dialog from '@mui/material/Dialog'
import axios from 'axios'
import DialogContent from '@mui/material/DialogContent'
import { emptyPatients } from '@/feature/phr-28/components/common/emptyData'
import { AddPatientByIdV2Mutation, AddPatientByIdV2MutationVariables, Exact, useAddPatientByIdV2Mutation, useCheckForDuplicateCardNumbersQuery } from '@/_graphql/graphql-client'
import { useForm, FormProvider, FieldValues, UseFormReset, UseFormSetValue } from 'react-hook-form'
import { getUnixTime } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { type UseMutateFunction, useQueryClient } from 'react-query'
import type { PatientsType } from '@/components/Patient/components/util/common/type';
import { ClinicContext, useGraphqlClient } from '@/App'
import { checkForDuplicateCardNumbers } from '@/components/PatientInfo/components/views/PatientsEditViews'
import { type OrderAndFilter } from '@/components/Patient/components/views/OrderAndFilterController'
import { getMaxDaysInMonth, invalidBirthday, setPostAddress, validateNumRange } from '@/components/PatientInfo/components/views/utils'
import { CardNumberErrorMessage } from '@/components/PatientInfo/components/views/CardNumberErrorMessage'

interface AddPatientsModalType {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  modalOptions?: ModalOptionsType,
  orderAndFilterController?: OrderAndFilter,
}

type ModalOptionsType = {
  setOpenSelectPatientModal: React.Dispatch<React.SetStateAction<boolean>>,
  setNewPatient: React.Dispatch<React.SetStateAction<PatientsType>>,
}


const emptyValues = {
  addValues:
  {
    name: {
      last: '',
      first: ''
    },
    kana: {
      last: '',
      first: ''
    },
    age: 0,
    searchFull: '',
    searchKana: '',
    gender: '',
    tel: '',
    email: '',
    birthday: 0,
    address: {
      post: '',
      prefectures: '',
      house: ''
    },
    DNumber: 0,
    SNumber: 0,
    remarks: '',
    comment: '',
    group: '',
    invitationNumber: '',
    clinic_id: ''
  },
  baseValues: {
    year: '',
    month: '',
    day: '',
    DNumber: '',
    SNumber: ''
  }
}

// Updateの方の関数を共用予定 こちらはそのまま削除-------
function onSubmit(
  data: FieldValues,
  clinic_id: string,
  mutate: UseMutateFunction<AddPatientByIdV2Mutation, unknown, Exact<AddPatientByIdV2MutationVariables>, unknown>
) {

  console.log('data', data)
  for (const key in data.addValues) {
    if ((data.addValues[key].length === 0) || (data.addValues[key] === 0)) {
      delete data.addValues[key]
    }
    if (key === 'address') {
      if ((data.addValues.address.post.length === 0)) {
        delete data.addValues.address.post
      }
      if ((data.addValues.address.prefectures.length === 0)) {
        delete data.addValues.address.prefectures
      }
      if ((data.addValues.address.house.length === 0)) {
        delete data.addValues.address.house
      }
      if ((Object.keys(data.addValues.address).length === 0)) {
        delete data.addValues.address
      }
    }
    // if (key === 'DNumber' || key === 'SNumber') {
    //   if (data.addValues[key] === undefined) {
    //     data.addValues[key] = 0
    //   }
    // }

  }


  const { SNumber, DNumber, ..._data } = data.addValues
  _data.fullName = `${_data.name.last}${_data.name.first}`
  _data.kanaName = `${_data.kana.last}${_data.kana.first}`

  if (SNumber) {
    _data.patientCardNumber = SNumber
  }

  if (DNumber) {
    _data.patientCardNumberOptional = DNumber
  }

  _data.clinic_id = clinic_id


  const input = { input: _data }
  mutate(input)
}
// ------------------------------------------

function closeModal(
  emptyValues: FieldValues,
  reset: UseFormReset<any>,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  reset(emptyValues)
  setIsOpen(false)
}

function closeSelectPatientsModal(
  modalOptions: ModalOptionsType
) {
  const { setOpenSelectPatientModal } = modalOptions;
  setOpenSelectPatientModal(false);
}

function getBirthday(
  year: string, month: string, day: string
): number {
  const birthdayString = `${year}/${month}/${day}`
  const birthday = getUnixTime(new Date(birthdayString))
  return birthday
}

function getOptionsList(placeholder: '性別' | '患者グループ'): JSX.Element {
  let options: JSX.Element = <></>
  switch (placeholder) {
    case '性別':
      options = <>
        <option value="男">男</option>
        <option value="女">女</option>
      </>
      break
    case '患者グループ':
      options = <>
        <option value="">設定しない</option>
        <option value="A">A</option>
        <option value="B">B</option>
        <option value="C">C</option>
        <option value="D">D</option>
      </>
      break
  }
  return options
}

export const AddPatientModal: React.FC<AddPatientsModalType> = ({
  setIsOpen,
  modalOptions,
  orderAndFilterController,
}) => {
  const [DNumber, setDNumber] = useState(emptyPatients.DNumber)
  const [SNumber, setSNumber] = useState(emptyPatients.SNumber)

  const methods = useForm({
    mode: 'onChange',
    defaultValues: Object.assign({}, emptyValues)
  }
  )

  const { register, setValue, getValues, watch, reset, formState: { dirtyFields, isValid, errors } } = methods
  const isInvalidBirthday = invalidBirthday(watch('baseValues.year'), watch('baseValues.month'), watch('baseValues.day'));
  const maxDaysInMonth = getMaxDaysInMonth(watch('baseValues.year'), watch('baseValues.month'));


  // 診察券番号のバリデーション
  register('addValues.SNumber', { validate: value => validateNumRange(value) });
  register('addValues.DNumber', { validate: value => validateNumRange(value) });

  useEffect(() => {
    const { year, month, day } = getValues('baseValues')
    if ((year.length === 0) || (month.length === 0) || (day.length === 0)) return
    const birthday = getBirthday(year, month, day)
    setValue('addValues.birthday', birthday)
  }, [watch('baseValues.year'), watch('baseValues.month'), watch('baseValues.day')])

  const queryClient = useQueryClient()
  const graphqlClient = useGraphqlClient()
  const clinic = useContext(ClinicContext);

  // const isDupNumbers: boolean = checkForDuplicateCardNumbers(DNumber, SNumber, "", patientsList, false)
  const SNumberDuplicateData = useCheckForDuplicateCardNumbersQuery(graphqlClient, { inputs: { clinic_id: clinic.clinic_id, patient_id: 'newPatient', cardNumber: SNumber || 0, isOptional: false } }, { enabled: SNumber != null })
  const DNumberDuplicateData = useCheckForDuplicateCardNumbersQuery(graphqlClient, { inputs: { clinic_id: clinic.clinic_id, patient_id: 'newPatient', cardNumber: DNumber || 0, isOptional: true } }, { enabled: DNumber != null })

  const isDupSNumber = SNumberDuplicateData.data?.checkForDuplicateCardNumbers.isDuplicate || false
  const isDupDNumber = DNumberDuplicateData.data?.checkForDuplicateCardNumbers.isDuplicate || false
  const isDupNumbers = isDupSNumber || isDupDNumber


  useEffect(() => {
    if (clinic.clinic_id) {
      setValue('addValues.clinic_id', clinic.clinic_id)
    }
  }, [clinic.clinic_id])

  const { mutate, isLoading } = useAddPatientByIdV2Mutation(graphqlClient, {
    onSettled: async () => {
      await queryClient.invalidateQueries('listPatientsV2').catch(() => console.log('on Settled'))
      await queryClient.invalidateQueries('listPatientsByClinicIdV2')
      await queryClient.invalidateQueries('listPatientsByIdPaginated');

      orderAndFilterController?.setFilterOption({ ...{ filterType: undefined } });

    },
    onSuccess: async (data) => {
      console.log('患者の新規追加:成功', data.addPatientByIdV2)
      if (modalOptions !== undefined) {
        //新規追加患者をモーダルコンポーネントに渡す
        modalOptions.setNewPatient(Object.assign({ ...emptyPatients }, { ...data.addPatientByIdV2 }))
        closeSelectPatientsModal(modalOptions);
      }
      closeModal(emptyValues, reset, setIsOpen)
    },
    onError: (err) => {
      console.log('患者の新規追加:失敗', err)
      closeModal(emptyValues, reset, setIsOpen)
    }
  })

  return (
    <Dialog
      onClose={() => { closeModal(emptyValues, reset, setIsOpen) }}
      open={true}
      transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
      PaperProps={{
        style: {
          maxWidth: 'none',
          width: '62%'
        }
      }}
    >
      <DialogContent
        dividers
        style={{
          padding: '0',
          maxWidth: 'none',
          maxHeight: 'none',
          height: '90vh',
          position: 'relative'
        }}
      >
        {/* ローディングアイコン＆ダイアログ */}
        {isLoading && (
          <div className="flex items-center justify-center fixed top-0 left-0 z-[9999] h-full w-full bg-black bg-opacity-50">
            <div className="animate-ping h-4 w-4 bg-blue-600 rounded-full"></div>
          </div>
        )}

        <FormProvider {...methods}>
          <div>
            <form onSubmit={methods.handleSubmit((data, e) => { onSubmit(getValues(), clinic.clinic_id, mutate) })}>
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <p className="w-full text-center mb-12"><span>新規登録</span><br /><div className="w-full h-2"></div><span className="text-sm">患者情報を入力してください</span></p>
                  <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-3 sm:col-span-3">
                      <p className="block text-sm font-medium text-gray-700">
                        <span className={`${dirtyFields.addValues?.name?.last ? '' : 'text-red-500'}`}>姓</span>
                      </p>
                      <input
                        className="rounded w-full"
                        type="text"
                        {...register('addValues.name.last', { required: true })}
                      />
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <p className="block text-sm font-medium text-gray-700">
                        <span className={`${dirtyFields.addValues?.name?.first ? '' : 'text-red-500'}`}>名</span>
                      </p>
                      <input
                        className="rounded w-full"
                        type="text"
                        {...register('addValues.name.first', { required: true })}
                      />
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <p className="block text-sm font-medium text-gray-700">
                        <span className={`${dirtyFields.addValues?.kana?.last ? '' : 'text-red-500'}`}>セイ</span>
                      </p>
                      <input
                        className="rounded w-full"
                        type="text"
                        pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
                        {...register('addValues.kana.last', { required: true })}
                      />
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <p className="block text-sm font-medium text-gray-700">
                        <span className={`${dirtyFields.addValues?.kana?.first ? '' : 'text-red-500'}`}>メイ</span>
                      </p>
                      <input
                        type="text"
                        pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
                        className="rounded w-full"
                        {...register('addValues.kana.first', { required: true })}
                      />
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <p className="block text-sm font-medium text-gray-700">
                        電話番号
                      </p>
                      <input
                        type="tel"
                        pattern="\d{2,4}\d{2,4}\d{3,4}"
                        placeholder='例: 09012345678'
                        className="rounded w-full"
                        {...register('addValues.tel')}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <p className="block text-sm font-medium text-gray-700">
                        性別
                      </p>
                      <select
                        {...register('addValues.gender')}
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      >
                        {getOptionsList('性別')}
                      </select>
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                      <p className="block text-sm font-medium text-gray-700">
                        email
                      </p>
                      <input
                        className="rounded w-full"
                        type="email"
                        {...register('addValues.email')}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                      <p className="block text-sm font-medium text-gray-700">
                        生年月日
                      </p>
                      <div className="flex justify-between items-center gap-x-6">
                        <input
                          type="number"
                          placeholder='年（例: 2000）'
                          className="rounded w-1/3"
                          max={`${new Date().getFullYear()}`}
                          {...register('baseValues.year')}
                        />
                        <input
                          type="number"
                          placeholder='月（例: 5）'
                          className="rounded w-1/3"
                          min="1"
                          max="12"
                          {...register('baseValues.month')}
                        />
                        <input
                          type="number"
                          min="1"
                          max={maxDaysInMonth}
                          className="rounded w-1/3"
                          placeholder='日（例: 1）'
                          {...register('baseValues.day')}
                        />
                      </div>
                    </div>

                    <div className="col-span-6">
                      <p className="block text-sm font-medium text-gray-700">
                        住所
                      </p>
                      <div className="flex flex-col gap-y-2">
                        <div className="flex gap-x-4 items-stretch">
                          <input
                            type="text"
                            className="rounded"
                            placeholder='郵便番号(例: 0001234)'
                            {...register('addValues.address.post')}
                          />
                          <p
                            onClick={() => {
                              setPostAddress(getValues('addValues.address.post'), setValue)
                            }}
                            className="inline-flex items-center justify-center rounded border border-transparent bg-indigo-600 px-12 text-sm font-medium text-white"
                          >
                            検索
                          </p>
                        </div>

                        <input
                          type="text"
                          className="rounded w-full"
                          placeholder='都道府県'
                          {...register('addValues.address.prefectures')}
                        />

                        <input
                          type="text"
                          className="rounded w-full"
                          placeholder='市区町村'
                          {...register('addValues.address.house')}
                        />
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                      <p className={`${isDupNumbers ? 'text-red-500' : ''} block text-sm font-medium text-gray-700`}>
                        診察券番号
                      </p>
                      <div className="flex flex-col gap-y-2">
                        <input
                          type="text"
                          className="rounded w-full"
                          {...register('baseValues.SNumber')}
                          placeholder={clinic.clinic_label === 'eldear' ? 'S番号' : '患者番号'}
                          onChange={(e) => {
                            setSNumber(Number(e.currentTarget.value))
                            setValue('addValues.SNumber', Number(e.currentTarget.value), { shouldValidate: true })
                          }}
                        />
                      </div>
                      {errors.addValues?.SNumber && <CardNumberErrorMessage error={errors.addValues.SNumber} />}
                      {/* エルディアクリニックのみ診察券番号Dに対応 */}
                      {clinic.clinic_label === 'eldear' &&
                        <div className="flex flex-col gap-y-2 mt-4">
                          <input
                            type="text"
                            className="rounded w-full"
                            {...register('baseValues.DNumber')}
                            placeholder='D番号'
                            onChange={(e) => {
                              setDNumber(Number(e.currentTarget.value))
                              setValue('addValues.DNumber', Number(e.currentTarget.value), { shouldValidate: true })
                            }}
                          />
                        </div>
                      }
                      {errors.addValues?.DNumber && <CardNumberErrorMessage error={errors.addValues.DNumber} />}
                    </div>


                    {/* <div className="col-span-6 sm:col-span-3">
                      <p className="block text-sm font-medium text-gray-700">
                        患者グループ
                      </p>
                      <select
                        {...register('addValues.group')}
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      >
                        {getOptionsList('患者グループ')}
                      </select>
                    </div> */}

                    <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                      <p className="block text-sm font-medium text-gray-700">
                        備考
                      </p>
                      <input
                        type="text"
                        className="rounded w-full"
                        {...register('addValues.remarks')}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                      <p className="block text-sm font-medium text-gray-700">
                        コメント
                      </p>
                      <textarea
                        className="w-full border-[#bcbcbc] rounded"
                        {...register('addValues.comment')}
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end gap-4 items-end">
                  {isDupNumbers && <span className='text-red-600 text-sm'>※既に登録されている患者情報です。</span>}
                  <div className={`${isDupNumbers || !isValid ? 'pointer-events-none opacity-50' : ''}`}>
                    <button
                      type="submit"
                      disabled={isInvalidBirthday}
                      className={`${isInvalidBirthday ? 'opacity-50' : ''} inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-12 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                    >
                      登録
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog >
  )
}
