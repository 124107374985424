// 必要なモジュールをインポート
import { CommonParamsContextType, useCommonParams } from "../Common/useCommonParams";
import { useQueryParams, useWatchQueryParam } from "../queryParams"
import { createContext, useContext, ReactNode } from 'react';

// Context の型定義
interface BlockParamsContextType extends Omit<CommonParamsContextType, 'calendarDate' | 'setCalendarDate'> {
    block_id: string | null;
    setBlockId: (blockId: string) => void;
    setBlockParams: setBlockParamsType;
    resetBlockId: () => void;
}

export type setBlockParamsType = (calendarDate: number, blockId: string, startTime: number, endTime: number, facilityId: string) => void

// Contextの初期値と型を設定
const BlockParamsContext = createContext<BlockParamsContextType>({
    block_id: null,
    startTime: null,
    endTime: null,
    facilityId: null,
    setStartTime: () => { },
    setEndTime: () => { },
    setFacilityId: () => { },
    setBlockId: () => { },
    setBlockParams: () => { },
    setCommonParams: () => { },
    resetBlockId: () => { }
});

export const useBlockParams = (): BlockParamsContextType => {
    const { setQueryParam, resetQueryParams } = useQueryParams();
    const commonParams = useCommonParams();
    const block_id = useWatchQueryParam("block-id");

    const setBlockId = (blockId: string) => {
        setQueryParam("block-id", blockId);
    };

    const setBlockParams = (calendarDate: number, blockId: string, startTime: number, endTime: number, facilityId: string) => {
        resetQueryParams();
        commonParams.setCalendarDate(calendarDate)
        setBlockId(blockId);
        commonParams.setStartTime(startTime);
        commonParams.setEndTime(endTime);
        commonParams.setFacilityId(facilityId);
    }

    const resetBlockId = () => {
        resetQueryParams("block-id");
    }

    return {
        ...commonParams,
        block_id,
        setBlockId,
        setBlockParams,
        resetBlockId
    };
}

export const BlockParamsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const blockParams = useBlockParams();

    return (
        <BlockParamsContext.Provider value={blockParams}>
            {children}
        </BlockParamsContext.Provider>
    );
}

// 他のコンポーネントでContextの値を取得するためのカスタムフック
export const useBlockParamsContext = (): BlockParamsContextType => {
    return useContext(BlockParamsContext);
};
