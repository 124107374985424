
import { GraphQLClient } from 'graphql-request'
import { useGetCommentsQuery } from '@/_graphql/graphql-client'
import { commentMapper } from '@/domain/Comment/services/mapper/commentMapper';
import { useCommentVariablesType } from '@/domain/Comment/entities/types';

export const useComment_service = (
    graphqlClient: GraphQLClient,
    variables: useCommentVariablesType
) => {
    const _comment = useGetCommentsQuery(graphqlClient, variables)
    const comment = commentMapper(_comment)
    return comment;
}
