import { useContext, useState } from 'react';
import { ClinicTimeConfigType, ClinicTimePresenterType } from '@/components/ClinicSettings/entities';
import { UpsertRecordViews } from '../views/UpsertRecordViews';
import { format, fromUnixTime, getUnixTime, startOfDay } from 'date-fns';
import { emptyClinicTimeConfig } from '@/components/Common/utils/common/emptyData';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import { ValidateDeleteRule } from '../../utils/libs/validateDialog';
import { DeleteValidateDialog } from '../tmp/DeleteValidateDialog';
import { generateSortedConfigList } from '../../utils/libs/common';
import { DateRangePicker } from '../parts';
import { HeadColAddButton } from '../parts/formParts/common/HeadColAddButton';

const tableHeads = [
    '#',//インデックス行
    '受付開始時間',
    '受付終了時間',
    '有効期間(開始日)',
    '有効期間(終了日)',
    '作成日',//空白テーブル行
]

function isSelection(selection: string[], config_id: string) {
    return selection.find((selectedConfig_id) => selectedConfig_id === config_id)
}

export const ClinicTimePresenter: React.FC<ClinicTimePresenterType> = ({
    currentStartTimestamp,
    currentEndTimestamp,
    clinic,
    clinicTimeList,
    deleteClinicConfig
}) => {
    const { nowDate } = useContext(SetDateContext)
    const [confirmed, setConfirmed] = useState(false)

    // チェックボックス
    const [selection, setSelection] = useState(['']);
    // 編集モード
    const [editing, setEditing] = useState('');
    // 新規追加
    const [isOpenAddRecord, setIsOpenAddRecord] = useState(false);
    //スタイル
    const deleteBtnCss: string = 'cursor-pointer leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-red-700 hover:bg-red-900'
    const disableBtnCss: string = 'leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'

    const [openValidateRule, setOpenValidateRule] = useState(false);
    const [validateRule, setValidateRule] = useState<ClinicTimeConfigType>(emptyClinicTimeConfig)

    //ソート
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

    //フィルター
    const [isOpenFilterRow, setIsOpenFilterRow] = useState(false)
    const [filterStartDate, setFilterStartDate] = useState<number | null>(null)
    const [filterEndDate, setFilterEndDate] = useState<number | null>(null)

    const sortedClinicTimeList = generateSortedConfigList(clinicTimeList, sortOrder, 'created_at')
    const filteredClinicTimeList = sortedClinicTimeList.filter((clinicTime) => {
        if (filterStartDate && filterEndDate) {
            return clinicTime.startDate >= filterStartDate && clinicTime.endDate <= filterEndDate
        } else if (filterStartDate) {
            return clinicTime.startDate >= filterStartDate
        } else {
            return true
        }
    })

    function toggleRow(id: string) {
        setSelection((current) =>
            current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
        )
    }

    function toggleAll() {
        setSelection((current) => (current.length === clinicTimeList.length ? [] : clinicTimeList.map((clinicTime) => clinicTime.config_id)));
    }

    //テーブルヘッド
    const headRow = (() => {
        return (
            <tr>
                {/* １列目:チェックボックス */}
                {/* <th style={{ width: rem(40) }}> */}
                <th scope="col" className="w-4 p-4">
                    <input
                        className="cursor-pointer"
                        type="checkbox"
                        onChange={toggleAll}
                        checked={selection.length === clinicTimeList.length}
                    />
                </th>
                {/* 2列目以降 */}
                {
                    tableHeads.map(item => <th scope="col"
                        onClick={() => {
                            if (item === '作成日') {
                                setSortOrder((current) => current === 'asc' ? 'desc' : 'asc')
                            }
                        }
                        }>
                        {item}
                        {item === '作成日' && sortOrder === 'asc' && <span className="ml-2">▲</span>}
                        {item === '作成日' && sortOrder === 'desc' && <span className="ml-2">▼</span>}
                    </th>)
                }

                {/* <th scope="col">
                    <div className="flex">
                        <p
                            className={deleteBtnCss}
                            onClick={() => setIsOpenAddRecord(bool => !bool)}
                        >
                            新規追加
                        </p>
                    </div>
                </th> */}

                <HeadColAddButton
                    isOpenAddRow={isOpenAddRecord}
                    setIsOpenAddRow={setIsOpenAddRecord}
                    setIsOpenEditRow={setEditing}
                    isOpenFilterRow={isOpenFilterRow}
                    setIsOpenFilterRow={setIsOpenFilterRow}
                />

            </tr>
        )
    })();

    //テーブルボディ
    const bodyRows = filteredClinicTimeList.map((clinicTime, index) => {
        const isSelected = selection.includes(clinicTime.config_id);

        return (
            <>
                {
                    //FilterRow
                }
                {
                    editing === clinicTime.config_id ?
                        <UpsertRecordViews
                            key={index}
                            setIsOpenAddRecord={setIsOpenAddRecord}
                            setEditing={setEditing}
                            clinicTime={clinicTime}
                            clinicTimeList={clinicTimeList}
                        />
                        :
                        <tr key={clinicTime.config_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            {/* １列目・・・チェックボックス */}
                            <td className="w-4 p-4 flex">
                                <input
                                    className="cursor-pointer"
                                    type="checkbox"
                                    checked={selection.includes(clinicTime.config_id)}
                                    onChange={() => toggleRow(clinicTime.config_id)}
                                />
                            </td>
                            <td>
                                {index}
                            </td>
                            {/* データ部分 */}
                            <td className="p-4">{clinicTime.config.clinicTime.startTime.hours.toString().padStart(2, '0')}:{clinicTime.config.clinicTime.startTime.minutes.toString().padStart(2, '0')}</td>
                            <td className="p-4">{clinicTime.config.clinicTime.endTime.hours.toString().padStart(2, '0')}:{clinicTime.config.clinicTime.endTime.minutes.toString().padStart(2, '0')}</td>
                            <td className="p-4">{format(fromUnixTime(clinicTime.startDate), 'yyyy/MM/dd')}</td>
                            <td className="p-4">{clinicTime?.endDate ? format(fromUnixTime(clinicTime?.endDate), 'yyyy/MM/dd') : ''}</td>
                            <td className="p-4">
                                {clinicTime?.created_at ? format(fromUnixTime(clinicTime?.created_at), 'yyyy/MM/dd HH:mm:ss') : ''}
                            </td>
                            <td>
                                <div className="flex gap-2 h-full">
                                    {/* 編集&削除ボタン */}
                                    {/* NOTE: 変更ボタンは現在廃止中(2023_0523) */}

                                    {/* <p
                                className={disableBtnCss + ' cursor-pointer'}
                                onClick={() => setEditing(clinicTime.config_id)}
                            >
                                編集
                            </p> */}
                                    <p
                                        className={`${isSelection(selection, clinicTime.config_id) && (clinicTime.endDate > currentEndTimestamp) ? deleteBtnCss + ' cursor-pointer' : disableBtnCss})}`}
                                        onClick={() => {
                                            if (clinicTime.startDate >= getUnixTime(startOfDay(new Date()))) {
                                                if (isSelection(selection, clinicTime.config_id)) {
                                                    //削除
                                                    ValidateDeleteRule(nowDate, clinicTimeList, clinicTime.config_id, setValidateRule, setOpenValidateRule)
                                                }
                                            } else {
                                                alert('有効期間(開始)が過ぎているため削除できません。変更の必要がある場合は新規ルールを作成して上書きして下さい。')
                                            }
                                        }
                                        }
                                    >
                                        削除
                                    </p>
                                </div>
                            </td>
                        </tr>
                }

                {
                    //削除確認ダイアログ
                    openValidateRule &&
                    isSelected &&
                    <DeleteValidateDialog
                        ruleStartDate={validateRule.startDate}
                        clinicStartTime={validateRule.config.clinicTime.startTime}
                        clinicEndTime={validateRule.config.clinicTime.endTime}
                        setOpenValidateRule={setOpenValidateRule}
                        isUpdate={undefined}
                        confirmed={confirmed}
                        setConfirmed={setConfirmed}
                        setEditing={setEditing}
                        deleteClinicTime={() => deleteClinicConfig.mutate({ clinic_id: clinic.clinic_id, config_id: clinicTime.config_id, config_type: 'clinic_time' })}
                    />
                }

            </>
        );
    });

    return (
        <>
            {/* テーブル */}
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    {headRow}
                </thead>
                <tbody className="text-xs">
                    <td>
                    </td>
                    <td className="p-4"></td>
                    {/* データ部分 */}
                    <td className="p-4"></td>
                    <td className="p-4"></td>
                    {
                        isOpenFilterRow &&
                        <DateRangePicker isNullable={true} startDate={filterStartDate} setStartDate={setFilterStartDate} endDate={filterEndDate} setEndDate={setFilterEndDate} />
                    }
                    <td className="p-4">
                        {/* 空白欄 */}
                    </td>
                    <td className="p-4"></td>
                    {
                        isOpenAddRecord &&
                        <UpsertRecordViews
                            setIsOpenAddRecord={setIsOpenAddRecord}
                            setEditing={setEditing}
                            clinicTimeList={clinicTimeList}
                        />
                    }
                    {bodyRows}
                </tbody>
            </table>
        </>
    );
}