import { Exact, ModifyIndexMenus, TransactDeleteMenusMutation, TransactIndexMenusMutation, UpDateMenuMutation, UpDateMenuMutationVariables, useTransactDeleteMenusMutation, useTransactIndexMenusMutation, useUpDateMenuMutation } from "../../../../../../.graphql/types";
import { Dialog, DialogContent } from '@mui/material'
import { useState } from "react";
import { UseMutationResult, useQueryClient } from "react-query";
import { MenuType } from "../../common/type";
import { useGraphqlClient } from "@/App";

interface DeleteMenusDialogType {
    filteredLists: MenuType[]
    bulkDeleteArr: number[]
    setBulkDeleteArr: React.Dispatch<React.SetStateAction<number[]>>
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function closeDialog(
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>
) {
    setIsChecked(false);
    setIsOpen(false);
}

/**
 * メニューを削除する関数
 * @param {MenuType[]} filteredLists - フィルタリングされたメニューリスト
 * @param {number[]} bulkDeleteArr - 削除するメニューのインデックスの配列
 * @param {(value: React.SetStateAction<number[]>) => void} setBulkDeleteArr - bulkDeleteArrの状態を設定する関数
 * @param {UseMutationResult} transactDeleteMenus - メニューを削除するためのミューテーション
 * @param {UseMutationResult} transactModifyIndexMenus - メニューのインデックスを変更するためのミューテーション
 */

function deleteMenu(
    filteredLists: MenuType[],
    bulkDeleteArr: number[],
    setBulkDeleteArr: (value: React.SetStateAction<number[]>) => void,
    transactDeleteMenus: UseMutationResult<TransactDeleteMenusMutation, unknown, Exact<{ SDK_IDs: string | string[]; }>, unknown>,
    transactModifyIndexMenus: UseMutationResult<TransactIndexMenusMutation, unknown, Exact<{ ModifyIndexMenus: ModifyIndexMenus | ModifyIndexMenus[]; }>, unknown>
) {
    // 削除するメニューがある場合
    if (bulkDeleteArr.length > 0) {
        const SDK_IDs: string[] = [];
        const deleteRecordArray: { SDK_ID: string, index: number }[] = []

        // 削除するメニューのSDK_IDを取得
        bulkDeleteArr.forEach((recordNum) => {
            const _deleteRecord: MenuType = Object.assign({}, filteredLists[recordNum])
            SDK_IDs.push(_deleteRecord.SDK_ID);
            deleteRecordArray.push(_deleteRecord)
        });

        // メニューを削除
        transactDeleteMenus.mutate({ SDK_IDs });

        // 削除後のメニューリストを作成
        const tmpMenuList: MenuType[] = filteredLists.filter((menu: MenuType) => {
            return deleteRecordArray.every(deleteRecord => {
                return menu.SDK_ID !== deleteRecord.SDK_ID
            })
        });

        // 削除後のメニューリストのインデックスを再設定
        modifyIndexWhenDelete(tmpMenuList, transactModifyIndexMenus);

        // 削除するメニューのインデックスの配列をリセット
        setBulkDeleteArr([])
    }
}

/**
 * 削除した後にメニューのインデックスを再設定する関数
 * @param {MenuType[]} tmpMenuList - 削除後のメニューリスト
 * @param {UseMutationResult} transactModifyIndexMenus - メニューのインデックスを変更するためのミューテーション
 */
const modifyIndexWhenDelete = (
    tmpMenuList: MenuType[],
    transactModifyIndexMenus: UseMutationResult<TransactIndexMenusMutation, unknown, Exact<{ ModifyIndexMenus: ModifyIndexMenus | ModifyIndexMenus[]; }>, unknown>
): void => {
    let tmpIndex: number = 0
    const modifyIndexMenus: { SDK_ID: string; index: number; }[] = [];

    // 新しいインデックスを設定
    tmpMenuList.forEach((menu: MenuType) => {
        const menuIndex = ++tmpIndex;
        const transactModifyItem = { SDK_ID: menu.SDK_ID, index: menuIndex }
        modifyIndexMenus.push(transactModifyItem)
    });

    // インデックスを変更するミューテーションを実行
    runningTransactMutate(modifyIndexMenus, transactModifyIndexMenus)
}

/**
 * インデックスを変更するミューテーションを実行する関数
 * @param {{ SDK_ID: string; index: number; }[]} modifyIndexMenus - インデックスを変更するメニューの情報の配列
 * @param {UseMutationResult} transactModifyIndexMenus - メニューのインデックスを変更するためのミューテーション
 */
function runningTransactMutate(
    modifyIndexMenus: {
        SDK_ID: string;
        index: number;
    }[],
    transactModifyIndexMenus: UseMutationResult<TransactIndexMenusMutation, unknown, Exact<{ ModifyIndexMenus: ModifyIndexMenus | ModifyIndexMenus[]; }>, unknown>
) {
    transactModifyIndexMenus.mutate({ ModifyIndexMenus: modifyIndexMenus })
}

/**
 * 削除するメニューの名前を取得する関数
 * @param {MenuType[]} filteredLists - フィルタリングされたメニューリスト
 * @param {number[]} bulkDeleteArr - 削除するメニューのインデックスの配列
 * @returns {string[]} - 削除するメニューの名前の配列
 */
function getMenuNames(
    filteredLists: MenuType[],
    bulkDeleteArr: number[],
) {
    const menuNames: string[] = [];
    if (bulkDeleteArr.length > 0) {
        bulkDeleteArr.forEach((recordNum) => {
            const deleteRecord: MenuType = Object.assign({}, filteredLists[recordNum])
            menuNames.push(deleteRecord.name)
        })
    }
    return menuNames;
}

export const useDeleteMenusDialog = () => {
    const queryClient = useQueryClient()

    const DeleteMenusDialog: React.FC<DeleteMenusDialogType> = ({
        filteredLists,
        bulkDeleteArr,
        setBulkDeleteArr,
        setIsOpen
    }) => {
        const graphqlClient = useGraphqlClient()
        const [isChecked, setIsChecked] = useState(false);
        const deletableMenuNames = getMenuNames(filteredLists, bulkDeleteArr)

        const transactDeleteMenus = useTransactDeleteMenusMutation(graphqlClient, {
            onSettled: () => {
                queryClient.invalidateQueries('listMenu').catch(() => console.log('on Settled'))
            },
            onSuccess: res => {
                console.log(res)
            },
            onError: error => {
                throw error
            },
            retry: 3
        })

        const transactModifyIndexMenus = useTransactIndexMenusMutation(graphqlClient, {
            onSettled: () => {
                queryClient.invalidateQueries('listMenu').catch(() => console.log('on Settled'))
            },
            onSuccess: res => {
                setIsOpen(false)
                console.log(res)
            },
            onError: error => {
                setIsOpen(false)
                throw error
            },
            retry: 3
        })

        return (
            <>
                {
                    transactDeleteMenus.isLoading &&
                    <div className="flex items-center justify-center fixed top-0 left-0 z-[9999] h-full w-full flex justify-center bg-black bg-opacity-50">
                        <div className="animate-ping h-4 w-4 bg-blue-600 rounded-full"></div>
                    </div>
                }
                <Dialog
                    transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
                    onClose={() => { closeDialog(setIsOpen, setIsChecked) }}
                    open={true}
                    PaperProps={{
                        style: {
                            maxWidth: 'none',
                            width: '50%',
                            height: '50%',
                        },
                    }}
                >
                    <DialogContent
                        dividers
                        style={{ padding: '0px 0px 0px 0px', height: '100%', width: '100%', maxWidth: 'none', maxHeight: 'none', position: 'relative' }}
                    >
                        <div className="w-full h-full relative flex flex-col justify-center items-center transform overflow-hidden rounded-lg bg-white px-16 pt-5 pb-4 text-left shadow-xl transition-all">
                            <div className="w-full mb-6">
                                <div className="mt-3 text-center sm:mt-5">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900 flex flex-wrap justify-start gap-y-6 mb-8 border-b border-black pb-8">
                                        {deletableMenuNames.map(menuName => {
                                            return (
                                                <span className="text-red-500 w-1/3 text-left">{menuName}</span>
                                            )
                                        })}
                                    </h3>
                                    <div className="flex justify-center">
                                        <label className="flex gap-4 items-center">
                                            <input type="checkbox" checked={isChecked} onChange={() => { setIsChecked(!isChecked) }} />
                                            <p>上記{deletableMenuNames.length}件を削除しますか？</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="button"
                                    className={`${isChecked ? '' : 'pointer-events-none opacity-50'} inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                                    onClick={() => deleteMenu(filteredLists, bulkDeleteArr, setBulkDeleteArr, transactDeleteMenus, transactModifyIndexMenus)}
                                >
                                    削除する
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                    onClick={() => closeDialog(setIsOpen, setIsChecked)}
                                >
                                    キャンセル
                                </button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    return {
        DeleteMenusDialog
    }
}
