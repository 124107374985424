import { createContext, useState } from "react"
import { AppointModeContextType, BlockModeContextType, FormProviderType } from "@/components/Main/entities"
import { UseFormReturn } from "react-hook-form"
import { AddAppointDefaultValuesType } from "@/components/Reservation/entities"

interface MethodsContextType {
    methods: UseFormReturn<AddAppointDefaultValuesType, any>
    setMethods: React.Dispatch<React.SetStateAction<UseFormReturn<AddAppointDefaultValuesType, any>>>
}

export const AppointModeContext = createContext({} as AppointModeContextType)
export const BlockModeContext = createContext({} as BlockModeContextType)
export const MethodsContext = createContext({} as MethodsContextType)

const FormModeProvider: React.FC<FormProviderType> = ({
    children
}) => {

    //編集モード中の差分検出・・・差分があればSubmit時にUpdateAppoint実行
    const [isAppointUpdated, setIsAppointUpdated] = useState(false)
    const [isAppointEdit, setIsAppointEdit] = useState(false)

    const [isBlockUpdated, setIsBlockUpdated] = useState(false)
    const [isBlockEdit, setIsBlockEdit] = useState(false)

    // methodsをContextで渡す
    const [methods, setMethods] = useState({} as UseFormReturn<AddAppointDefaultValuesType, any>)

    return (
        <MethodsContext.Provider value={{ methods, setMethods }}>
            <AppointModeContext.Provider value={{ isAppointEdit, setIsAppointEdit, isAppointUpdated, setIsAppointUpdated }}>
                <BlockModeContext.Provider value={{ isBlockEdit, setIsBlockEdit, isBlockUpdated, setIsBlockUpdated }}>
                    {children}
                </BlockModeContext.Provider>
            </AppointModeContext.Provider>
        </MethodsContext.Provider>
    )
}

export default FormModeProvider