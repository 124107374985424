import React, { useContext, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useAddManegerMutation } from '.graphql/types'
import { ClinicContext, useGraphqlClient } from '@/App'
import { clearState } from '../utils/common/util'
import { ResponsibleAddModalViewsType, addManegerDataType } from '../utils/common/type'
import { ResponsibleAddModalPresenter } from '../presenter/ResponsibleAddModalPresenter'
import { medicalRole } from '@/feature/phr-28/components/common/medicalMenu'

export const ResponsibleAddModalViews: React.FC<ResponsibleAddModalViewsType> = ({
  isOpen,
  setShow,
  indexNum
}) => {
  const graphqlClient = useGraphqlClient()
  const clinic_id = useContext(ClinicContext).clinic_id;
  const [submitCount, setSubmitCount] = useState(0)
  const firstElement: number = 0
  const rights: string[] = ['管理者', '受付']
  const [selectRole, setSelectRole] = useState('')
  const [selectRights, setSelectRights] = useState('')
  const [inputName, setInputName] = useState('')
  const [inputEmail, setInputEmail] = useState('')
  const [inputPass, setInputPass] = useState('')
  const queryClient = useQueryClient()
  const { mutate } = useAddManegerMutation(graphqlClient, {
    onSettled: () => {
      queryClient.invalidateQueries('listManeger').catch(() => console.log('on Settled'))
    },
    onSuccess: (res) => {
      console.log(res)
    },
    onError: (error) => {
      throw error
    },
    retry: 3
  })
  const addManegerData: addManegerDataType = {
    role: selectRole,
    name: inputName,
    rights: selectRights,
    email: inputEmail,
    color: inputPass,
    passward: '123',
    isDelete: false,
    index: indexNum,
    clinic_id
  }
  const addManeger = (): void => {
    mutate(addManegerData)
  }
  const determineRegisterable = (): boolean => {
    let result: boolean = true
    if (addManegerData.role !== '' && addManegerData.name !== '' && addManegerData.rights !== '' && addManegerData.email !== '' && addManegerData.color !== '') {
      result = false
    }
    return result
  }
  const registerCss: string = 'w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'
  const disableCss: string = 'w-full text-white bg-blue-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'

  return (
    <>
    {/* NOTE isOpenでの判定はもう1階層上ですべき？？？ */}
    {isOpen && 
      <ResponsibleAddModalPresenter 
      setShow={setShow}
      addManegerData={addManegerData}
      medicalRole={medicalRole}
      firstElement={firstElement}
      rights={rights}
      clearState={clearState}
      setSelectRole={setSelectRole}
      setSelectRights={setSelectRights}
      setInputName={setInputName}
      setInputEmail={setInputEmail}
      setInputPass={setInputPass}
      inputEmail={inputEmail}
      inputPass={inputPass}
      determineRegisterable={determineRegisterable}
      disableCss={disableCss}
      registerCss={registerCss}
      submitCount={submitCount}
      setSubmitCount={setSubmitCount}
      addManeger={addManeger}
      />
    }
    </>
  )
}
