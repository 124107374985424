import React, { useState } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import { getPatientNumberById, getPatientsNameById } from '../../../common/patients'
import { getMenuName } from '@/components/Common/utils'
import { getManegerNameById } from '@/components/Common/utils';

import { japanTimeFormatDate } from '../../mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon'
import { fromUnixTime } from 'date-fns'
import { getFacilityName } from '@/components/Common/utils'
import { AppointInfoModalType, AppointInfoType } from '@/components/Reservation/entities/components'
import { clearAppointInfoState } from '@/components/Reservation/utils'
import { emptyAppoint } from '@/components/Common/utils/common/emptyData'

export const useSPAppoint = () => {

  const SPAppointModal: React.FC<AppointInfoModalType> = ({
    appointDetail,
    closeModal
  }) => {

    const staffNames = appointDetail.staffNames.map(staff => staff.name).join(' ')
    const appointInfo: AppointInfoType[] = [
      { label: "施設名", contents: appointDetail.facilityName },
      { label: "診察券番号", contents: appointDetail.patientCardNumber || '未登録' },
      { label: "患者氏名", contents: appointDetail.patientName || '未登録' },
      { label: "診療メニュー", contents: appointDetail.menuName || '未登録' },
      { label: "担当者", contents: staffNames },
    ];

    return (
      <>
        <Dialog
          transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
          onClose={closeModal}
          open={true}
          PaperProps={{
            style: {
              maxWidth: 'none',
              width: '90%',
              transition: 'all 0',
              zIndex: 9999
            }
          }}
        >
          {/* content */}
          <DialogContent
            dividers
            onClick={closeModal}
            style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', height: '90vh', position: 'relative' }}
          >
            <div className="h-full">
              <div className="h-[10%] text-sm text-gray-600 flex items-center pl-4">
                {`${japanTimeFormatDate(fromUnixTime(appointDetail.startTime))} - ${japanTimeFormatDate(fromUnixTime(appointDetail.endTime))}`}
              </div>

              <dl className="h-[90%]">
                {appointInfo.map((info, index) => (
                  <div
                    className={`h-[10%] px-4 sm:grid items-center sm:grid-cols-4 sm:gap-4 sm:px-6 ${index % 2 === 0 ? 'bg-gray-100' : ''}`}
                    key={index}
                  >
                    <dt className="text-sm font-medium text-gray-600">{info.label}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {info.contents}
                    </dd>
                  </div>
                ))}
                <div className="h-[50%] overflow-y-scroll py-5 px-4 text-sm text-gray-900">
                  {appointDetail.remarks}
                </div>
              </dl>

            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  }

  return {
    SPAppointModal
  }
}