import { CloseButtonPropsType } from "@/components/Common/entities"
import { closePane, openPane } from "@/components/Main/util"
import { ChevronDoubleDownIcon } from "@heroicons/react/outline"
import { ChevronDoubleLeftIcon } from "@heroicons/react/outline"
import { useViewportSize } from '@mantine/hooks';
import { AllPanesContext } from "@/components/Main/provider/PanesProvider"
import { useContext } from "react"
import { PaneVisibilityContext } from "@/components/Main/provider/MainProvider";

// MEMO: 汎用の閉じるボタン
// panesRef: 閉じる対象のpaneのref
// closeTarget: 閉じる対象のpaneのどちらを閉じるか
export const CloseButton: React.FC<CloseButtonPropsType> = ({
    panesRef,
    closeTarget
}) => {

    const viewSizes = useViewportSize();
    // ペイン開閉状況ステート
    const { isPaneOpen, setIsPaneOpen } = useContext(PaneVisibilityContext)
    const { paneSize, setPaneSize, paneDefaultSize } = useContext(AllPanesContext);

    const _paneSize = checkPaneSize(closeTarget, paneSize, paneDefaultSize);

    return (
        _paneSize ?
            <div
                onClick={
                    () => {
                        // leftはここで開閉をトグル
                        if (closeTarget === "left") {
                            setPaneSize(_paneSize)
                            return;
                        }

                        if (isPaneOpen) {
                            closePane(panesRef, closeTarget);
                            setIsPaneOpen(false);
                        } else {
                            console.log(viewSizes.height, viewSizes.width);
                            openPane(panesRef, closeTarget, viewSizes.height, viewSizes.width);
                            setIsPaneOpen(true);
                        }

                    }
                }
            >
                {closeTarget === 'left' ? <ChevronDoubleLeftIcon className="h-6 w-6" /> : <ChevronDoubleDownIcon className="h-6 w-6" />}
            </div> : <></>
    )
}

// FIXME: type定義
function checkPaneSize(closeTarget: "top" | "bottom" | "left", paneSize: any, paneDefaultSize: any) {
    if (paneSize && paneSize[closeTarget]) {
        const flg = paneSize[closeTarget].isOpen ? "close" : "open";
        const _paneSize = { ...paneSize }
        _paneSize[closeTarget] = paneDefaultSize[closeTarget][flg]
        return _paneSize
    }
    return false
}