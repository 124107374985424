import { $FacilityFrameProps } from "@/components/Reservation/entities";

export const $FacilityFrame: React.FC<$FacilityFrameProps> = ({
    facilityFrameWidth,
    children,
}) => {
    const frameClassName = 'relative overflow-hidden border-r border-white';
    const frameStyle = {
        width: `${facilityFrameWidth}%`
    };

    return (
        <div style={frameStyle} className={frameClassName}>
            {children}
        </div>
    )
}