import { FacilityGroupTableElementProps } from "../../../../FacilityGroup/entities";


const style = "px-4 py-4 font-medium text-gray-900 dark:text-white";

export const _styledTD: React.FC<FacilityGroupTableElementProps> = ({ children }) => {
    return (
        <td
            scope="row"
            className={style}
        >
            {children}
        </td>
    )
};