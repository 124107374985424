import { BasicButtonProp } from "@/components/Common/entities/config"


export const BasicButton: React.FC<BasicButtonProp> = ({
    label,//ボタンラベル
    width,//ボタン幅
    color,//ボタン色
    onClickEvent,//ボタンクリック時のイベント
    disabled
}) => {
    const disabledStyle = disabled ? 'opacity-50 cursor-not-allowed' : ''
    //ボタン色
    const buttonColor = color === 'red' ? 'bg-red-400 hover:bg-red-500' : 'bg-gray-700 hover:bg-gray-700'
    //ボタン幅
    const buttonWidth = width === 'small' ? 'w-24' : 'w-32'
    //共通スタイル
    const commonStyle = "leading-8 mr-2 text-gray-100 text-sm font-medium h-8 text-center rounded-2xl"
    //スタイル
    const style = `${commonStyle} ${buttonWidth} ${buttonColor} ${disabledStyle}`

    return (
        <button type="button"
            className={style}
            disabled={disabled}
            onClick={() => onClickEvent()}>
            {label}
        </button>
    );
}