
export function resetFormState(
    setEditingGroupId: React.Dispatch<React.SetStateAction<string>>,
    setEditingGroupName: React.Dispatch<React.SetStateAction<string>>
) {
    setEditingGroupId('')
    setEditingGroupName('')
}

export function openEditForm(
    groupId: string,
    groupName: string,
    setEditingGroupName: React.Dispatch<React.SetStateAction<string>>,
    setEditingGroupId: React.Dispatch<React.SetStateAction<string>>,
) {
    setEditingGroupName(groupName)
    setEditingGroupId(groupId)
}
