
import { Dialog, DialogContent } from '@mui/material';

export interface CancelReasonSelectModalPropsType {
    onClose: () => void
    changeAppointStatusMutate: () => void
    selectedCancelReason: number
    setSelectedCancelReason: React.Dispatch<React.SetStateAction<number>>
}

export const CancelReasonSelectModal: React.FC<CancelReasonSelectModalPropsType> = ({
    onClose,
    changeAppointStatusMutate,
    selectedCancelReason,
    setSelectedCancelReason,
}) => {

    // キャンセル理由
    const radioCancelReason = [
        { reasonNum: 0, label: '患者都合', },
        { reasonNum: 1, label: '医院都合' },
        { reasonNum: 2, label: '無断キャンセル' },
    ];

    return (<>
        {/* ダイアログ */}
        <Dialog
            onClose={onClose}
            open={true}
            PaperProps={{
                style: {
                    maxWidth: 'none',
                    width: '50%',
                    transition: 'all .2s',
                },
            }}
        >
            <DialogContent
                dividers
                style={{
                    padding: '0px 0px 0px 0px',
                    maxWidth: 'none',
                    maxHeight: 'none',
                    height: '25vh',
                    position: 'relative',
                }}
            >
                <div className="flex flex-col w-full h-full items-flex-row justify-center items-center gap-y-6">
                    <p className="text-xs">キャンセル理由を選択してください</p>
                    <div className="flex gap-3">
                        {
                            radioCancelReason.map(reason => (
                                <RadioButton
                                    label={reason.label}
                                    checked={reason.reasonNum === selectedCancelReason}
                                    onClick={() => { setSelectedCancelReason(reason.reasonNum) }}
                                />
                            ))
                        }
                    </div>
                    <div className="flex justify-center gap-2">
                        <button
                            onClick={onClose}
                            type="button"
                            className="h-9 w-28 inline-flex items-center justify-center rounded-sm border border-transparent bg-red-600 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            やめる
                        </button>
                        <button
                            onClick={() => {
                                changeAppointStatusMutate()
                                onClose()
                            }}
                            type="button"
                            className="h-9 w-28 inline-flex items-center justify-center rounded-sm border border-transparent bg-indigo-600 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            更新する
                        </button>
                    </div>
                </div>

            </DialogContent>
        </Dialog>
    </>
    )
}

interface RadioButtonPropsType {
    label: string
    checked: boolean
    onClick: () => void
}

const RadioButton: React.FC<RadioButtonPropsType> = ({
    label,
    checked,
    onClick
}) => {
    return (
        <div className="flex items-center">
            <input
                id={label}
                onClick={onClick}
                type="radio"
                checked={checked}
                name="default-radio"
                className="w-4 h-4 text-xs text-blue-600 bg-gray-100 border-gray-300"
            />
            <label htmlFor={label} className="text-xs font-medium text-gray-900">
                {label}
            </label>
        </div>
    )
}