import React, { useState } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import { type AddPatientButtonType, type DisplayPatientsCountType } from '../util/components/parts';
import classNames from 'classnames';
import {
  FilterType,
  InputFilterOption,
  InputOrderOption,
  OrderBy,
} from '@/_graphql/graphql-client';

import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { clearState } from '@/components/MedicalCategory';
import { PatientsType } from '../util/common/type';
import { getSearch } from '../util/common/util';

interface SearchCondition {
  focused?: FilterType;
  [FilterType.Name]?: string;
  [FilterType.CardNumber]?: string;
  [FilterType.PhoneNumber]?: string;
}

// SearchInputsComponent
const SearchInputsComponent: React.FC<{
  orderAndFilterController: OrderAndFilter;
  condition: Condition;
  setIsSearched: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ orderAndFilterController, condition, setIsSearched }) => {
  return (
    <>
      {SearchInputOptions.map((searchOption, index) => (
        <SearchInput
          key={index}
          searchOption={searchOption}
          orderAndFilterController={orderAndFilterController}
          condition={condition}
          setIsSearched={setIsSearched}
        />
      ))}
      <SearchButton
        orderAndFilterController={orderAndFilterController}
        condition={condition}
        setIsSearched={setIsSearched}
      />
    </>
  );
};

// SortButtonsComponent
const SortButtonsComponent: React.FC<{
  orderAndFilterController: OrderAndFilter;
}> = ({ orderAndFilterController }) => {
  return (
    <>
      {sortButtonOptions.map((sortButtonOption, index) => (
        <SortButton
          key={index}
          sortButtonOption={sortButtonOption}
          orderAndFilterController={orderAndFilterController}
          index={index}
          lastIndex={sortButtonOptions.length - 1}
        />
      ))}
    </>
  );
};

// ActionButtonsComponent
const ActionButtonsComponent: React.FC<{
  setIsOpen: any;
  setIsOpen2: any;
  isOpen2: boolean;
  result: any;
}> = ({ setIsOpen, setIsOpen2, isOpen2, result }) => {
  return (
    <div className="ml-auto">
      <div className="flex justify-end gap-2 mb-8">
        <DisplayPatientsCount result={result} />
        <AddPatientButton setIsOpen={setIsOpen} />
        <AddPatientsByCSVButton setIsOpen={() => setIsOpen2(!isOpen2)} />
      </div>
    </div>
  );
};

// このへんからAllSerachWrapperの移植
export function OrderAndFilterController({
  result,
  setIsOpen,
  setIsOpen2,
  isOpen2,
  orderAndFilterController,
}: {
  result: any;
  setIsOpen: any;
  setIsOpen2: any;
  isOpen2: boolean;
  orderAndFilterController: OrderAndFilter;
}): React.ComponentElement<any, any> {
  const [_condition, _setCondition] = useState<SearchCondition>({});
  const condition: Condition = { condition: _condition, setCondition: _setCondition };

  return (
    <div className="">
      <div className="flex mb-2 mt-1 h-6">
        <SearchInputsComponent
          orderAndFilterController={orderAndFilterController}
          condition={condition}
          setIsSearched={() => { }}
        />
      </div>

      <div className="flex gap-8 items-stretch h-6 mb-2">
        <SortButtonsComponent orderAndFilterController={orderAndFilterController} />
        <ActionButtonsComponent
          setIsOpen={setIsOpen}
          setIsOpen2={setIsOpen2}
          isOpen2={isOpen2}
          result={result}
        />
      </div>
    </div>
  );
}

// ここから下は検索機能の移植
interface SortButtonType {
  sortButtonOption: SortButtonOption;
  orderAndFilterController: OrderAndFilter;
  index: number;
  lastIndex: number;
}

const SortButton: React.FC<SortButtonType> = ({
  sortButtonOption,
  index,
  lastIndex,
  orderAndFilterController,
  //   orderType,
  //   buttonName,
  //   patients,
  //   setResult,
}) => {
  const isActive = sortButtonOption.orderType === orderAndFilterController.orderOption.orderBy;

  const activeColor =
    'bg-indigo-600 hover:bg-indigo-700 text-white inline-flex justify-center items-center h-6 w-28 rounded text-xs';
  const inActiveColor =
    'bg-[#ccc]  text-white inline-flex justify-center items-center h-6 w-28 rounded text-xs';
  const colorStyle = {
    [activeColor]: isActive,
    [inActiveColor]: !isActive,
  };
  const commonStyle = 'rounded px-8 py-6';
  return (
    <button
      className={classNames([buttonStyle(commonStyle, index, lastIndex), colorStyle])}
      onClick={() => {
        orderAndFilterController.setOrderOption({
          orderAsc: !orderAndFilterController?.orderOption?.orderAsc,
          orderBy: sortButtonOption.orderType,
        });
      }}
    >
      {sortButtonOption.buttonName}
      {isActive &&
        (orderAndFilterController?.orderOption?.orderAsc ? (
          <NorthIcon fontSize={'small'} />
        ) : (
          <SouthIcon fontSize={'small'} />
        ))}
    </button>
  );
};

interface Condition {
  setCondition: React.Dispatch<React.SetStateAction<SearchCondition>>;
  condition: SearchCondition;
}

const SearchInput: React.FC<{
  searchOption: SearchOption;
  orderAndFilterController: OrderAndFilter;
  condition: Condition;
  setIsSearched: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ searchOption, orderAndFilterController, condition, setIsSearched }) => {
  const uniqueStyle = getUniqueStyle(searchOption.searchType);
  const commonStyle = 'flex border border-[#ccc] w-48 p-0 overflow-hidden';
  const style = `${uniqueStyle} ${commonStyle}`;
  return (
    <>
      <div className={style}>
        <input
          autoFocus={searchOption.autoFocus}
          onChange={(e) => {
            condition.setCondition({
              ...condition.condition,
              focused: searchOption.searchType,
              [searchOption.searchType]: e.currentTarget.value,
            });
          }}
          onFocus={() => {
            condition.setCondition({
              ...condition.condition,
              focused: searchOption.searchType,
            });
          }}
          onKeyDown={(e) => {
            if (e.nativeEvent.isComposing || e.key !== 'Enter') return;
            submit(condition, orderAndFilterController, setIsSearched);
          }}
          className="h-full w-full border-none p-0"
          value={condition.condition[searchOption.searchType]}
          placeholder={searchOption.placeholder}
          type={searchOption.inputType}
        />
      </div>
    </>
  );
};

type SearchInputsOptionType = {
  searchType: FilterType;
  placeholder: string;
  inputType: 'string' | 'number';
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
};

function getUniqueStyle(searchType: FilterType): string {
  let style = '';
  switch (searchType) {
    case FilterType.Name:
      style = 'rounded-l';
      break;
    case FilterType.CardNumber:
      style = 'border-l-none border-r-none p-0';
      break;
    case FilterType.PhoneNumber:
      break;
  }
  return style;
}

function buttonStyle(commonStyle: string, index: number, lastIndex: number) {
  let _bool = true;
  _bool = index === lastIndex;
  _bool = index !== 0 && index !== lastIndex;

  const buttonStyle = {
    [commonStyle]: _bool,
  };
  return buttonStyle;
}

const DisplayPatientsCount: React.FC<DisplayPatientsCountType> = ({ result }) => {
  return (
    <div className="pt-1 text-xs">
      {/* NOTE 230310 表示件数はstatusの型 要確認 */}
      件数: {result.filter((obj) => obj.status !== 'true').length}件
    </div>
  );
};

const AddPatientsByCSVButton: React.FC<AddPatientButtonType> = ({ setIsOpen }) => {
  const buttonStyle =
    'bg-yellow-300 inline-flex items-center justify-center rounded-md border border-transparent  text-xs font-medium leading-4 text-white shadow-sm hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 h-6 w-28';
  return (
    <button
      onClick={() => {
        setIsOpen(true);
      }}
      type="button"
      className={buttonStyle}
    >
      CSV一括登録
    </button>
  );
};

const AddPatientButton: React.FC<AddPatientButtonType> = ({ setIsOpen }) => {
  const buttonStyle =
    'bg-green-500 inline-flex items-center justify-center rounded-md border border-transparent  text-xs font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 h-6 w-28';
  return (
    <button
      onClick={() => {
        setIsOpen(true);
      }}
      type="button"
      className={buttonStyle}
    >
      新規追加
    </button>
  );
};

interface SearchOption {
  searchType: FilterType;
  placeholder: string;
  inputType: 'string';
  autoFocus?: boolean;
}

const SearchInputOptions: SearchOption[] = [
  {
    searchType: FilterType.Name,
    placeholder: '名前',
    inputType: 'string',
  },
  {
    searchType: FilterType.CardNumber,
    placeholder: '診察券番号',
    inputType: 'string',
    autoFocus: true,
  },
  {
    searchType: FilterType.PhoneNumber,
    placeholder: '電話番号',
    inputType: 'string',
  },
];

interface SortButtonOption {
  orderType: OrderBy;
  buttonName: string;
}

const sortButtonOptions: SortButtonOption[] = [
  {
    orderType: OrderBy.KanaName,
    buttonName: '名前',
  },
  {
    orderType: OrderBy.PatientCardNumber,
    buttonName: '診察券番号',
  },
];

const SearchButton: React.FC<{
  orderAndFilterController: OrderAndFilter;
  condition: Condition;
  setIsSearched: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ orderAndFilterController, condition, setIsSearched }) => {
  return (
    <button
      className="inline-flex justify-center items-center w-16 rounded border text-white h-6"
      onClick={() => {
        submit(condition, orderAndFilterController, setIsSearched);
      }}
    >
      <SearchIcon className="fill-gray-500 h-6" fontSize={'small'} />
    </button>
  );
};

function submit(
  condition: Condition,
  orderAndFilterController: OrderAndFilter,
  setIsSearched: React.Dispatch<React.SetStateAction<boolean>>
) {
  setIsSearched(true);

  const focusedType = condition.condition.focused;

  // 値がない場合は検索条件をクリアして再検索させる
  if (!focusedType) {
    orderAndFilterController.setFilterOption({});
    return
  };

  // 入力値を検索条件に反映
  orderAndFilterController.setFilterOption({
    ...{
      filterType: condition.condition.focused,
      filterValue: condition.condition[focusedType]?.toString(),
    },
  });

  // 検索値のクリア
  condition.setCondition({
    focused: undefined,
    [FilterType.Name]: '',
    [FilterType.CardNumber]: '',
    [FilterType.PhoneNumber]: '',
  });
}
export interface OrderAndFilter {
  orderOption: InputOrderOption;
  setOrderOption: React.Dispatch<React.SetStateAction<InputOrderOption>>;
  filterOption: InputFilterOption;
  setFilterOption: React.Dispatch<React.SetStateAction<InputFilterOption>>;
}

export function OrderAndFilterControllerForModal({
  result,
  setOpenAddPatientModal,
  orderAndFilterController,
  setOpenSearchPanel,
  setIsSearched,
}: {
  result: any;
  setOpenAddPatientModal: any; // 新規患者登録モーダル開閉用
  orderAndFilterController: OrderAndFilter;
  setOpenSearchPanel: React.Dispatch<React.SetStateAction<boolean>>; // 検索モーダルの開閉用
  setIsSearched: React.Dispatch<React.SetStateAction<boolean>>; // 検索ボタン押したらtrueにする
}): React.ComponentElement<any, any> {
  const [_condition, _setCondition] = useState<SearchCondition>({});
  const condition: Condition = { condition: _condition, setCondition: _setCondition };

  return (
    <div className="">
      <>
        <div>
          <p
            onClick={() => {
              setOpenAddPatientModal(true);
            }}
            className="bg-red-700 text-white inline-flex justify-center items-center h-6 w-28 rounded text-xs"
          >
            新規追加
          </p>
          <div className="flex mb-2 mt-1 h-6">
            <SearchInputsComponent
              orderAndFilterController={orderAndFilterController}
              condition={condition}
              setIsSearched={setIsSearched}
            />
          </div>

          <div className="flex gap-8 istems-stretch h-6 mb-2">
            <SortButtonsComponent orderAndFilterController={orderAndFilterController} />
            <CloseButton setOpenSearchPanel={setOpenSearchPanel} />
            <DisplayPatientsCount result={result} />
          </div>
        </div>
      </>
    </div>
  );
}

// モーダル用
const CloseButton: React.FC<{
  setOpenSearchPanel: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setOpenSearchPanel }) => {
  return (
    <button
      type="button"
      className="bg-red-700 text-white inline-flex justify-center items-center h-6 w-28 rounded text-xs"
      onClick={() => {
        setOpenSearchPanel(false);
        // clearState(setOpenSearchPanel);
      }}
    >
      閉じる
    </button>
  );
};
