import React, { useState, useEffect } from 'react';

import { AddPatientModal } from '@/feature/phr-29/components/Patients/AddPatientModal';
import { PatientsType, type PatientsListPresenterType } from '../util/common/type';
import { PatientsCardViews } from '../views/PatientsCardViews';
import { UploadDialog } from '../views/CsvDropzone';
import { OrderAndFilter, OrderAndFilterController } from '../views/OrderAndFilterController';
import { Pagination } from '../views/Pagination';

const ITEMS_PER_PAGE = 50;

export const PatientsListPresenter: React.FC<PatientsListPresenterType> = ({
  isOpen,
  setIsOpen,
  result,
  orderAndFilterController,
}) => {
  const [isOpen2, setIsOpen2] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(1);


  const { orderOption, filterOption } = orderAndFilterController;

  useEffect(() => {
    // フィルター/ソートオプションが変更されたらページをリセット
    setCurrentPage(1);
  }, [orderOption, filterOption])

  // 削除済み患者をフィルタリング
  const _result = result.filter((obj) => obj.status !== 'true');

  // 現在のページの患者リスト
  const filteredResults = _result
    .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  return (
    <Outline>
      <OrderAndFilterController
        result={result}
        setIsOpen={setIsOpen}
        setIsOpen2={setIsOpen2}
        isOpen2={isOpen2}
        orderAndFilterController={orderAndFilterController}
      />

      <Pagination itemsPerPage={ITEMS_PER_PAGE} currentPage={currentPage} totalItems={_result.length} setCurrentPage={setCurrentPage} />
      <PatientResultsOutline>
        {filteredResults.length > 0 ? (
          filteredResults.map(({ id, name, SNumber, DNumber, group, status, tel }, idx) => (
            <PatientsCardViews
              key={id}
              seq={idx}
              id={id}
              name={`${name.last}${name.first}`}
              numberS={SNumber ? SNumber.toString() : ''}
              numberD={DNumber ? DNumber.toString() : ''}
              group={group}
              status={status}
              tel={tel}
              orderAndFilterController={orderAndFilterController}
            />
          ))
        ) : (
          <NoPatientResult />
        )}
      </PatientResultsOutline>
      <UploadDialog isOpen2={isOpen2} setIsOpen2={setIsOpen2} orderAndFilterController={orderAndFilterController} />
      {/* 患者新規追加モーダル */}
      {isOpen && <AddPatientModal setIsOpen={setIsOpen} orderAndFilterController={orderAndFilterController} />}
    </Outline>
  );
};

// NOTE 230602 Commonからインポートする（まだmainブランチに入ってない）
export interface OutlineType {
  children?: React.ReactNode;
}

const Outline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle =
    'w-full h-full px-8 max-w-screen-lg max-h-screen-lg w-full h-full overflow-auto';
  return <div className={outlineStyle}>{children}</div>;
};

const PatientResultsOutline: React.FC<OutlineType> = ({ children }) => {
  const listStyle = 'divide-y divide-gray-200 dark:divide-gray-700 bg-white flow-root';
  return (
    <ul role="list" className={listStyle}>
      {children}
    </ul>
  );
};

const NoPatientResult: React.FC = () => {
  const containerStyle = 'h-[50vh] flex items-center justify-center';
  return <div className={containerStyle}>該当する患者情報は見つかりませんでした。</div>;
};

export interface UploadDialogType {
  isOpen2: boolean;
  setIsOpen2: React.Dispatch<React.SetStateAction<boolean>>;
  orderAndFilterController: OrderAndFilter;
}

