import React from 'react'
import { listManegerType } from '@/feature/phr-28/components/common/type'
import { ResponsibleCardViews } from '@/components/Staff/components/views/ResponsibleCardViews'

interface ResponsibleCardType {
  manegerLists: listManegerType[]
  lastIndexNum: number
}

export const ResponsibleList: React.FC<ResponsibleCardType> = ({ manegerLists, lastIndexNum }) => {
  return (
    <>
      <div className="flex flex-col w-4/5 mx-auto pt-2">
        {manegerLists.map((maneger: listManegerType, index: number) => (
          <ResponsibleCardViews
            key={index}
            currentManeger={maneger}
            lastIndexNum={lastIndexNum}
            manegerLists={manegerLists}
          />
        ))}
      </div>
    </>
  )
}
