import { useDeletePatientByIdV2Mutation } from '@/_graphql/graphql-client';
import { Dialog, DialogContent } from '@mui/material';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGraphqlClient } from '@/App';
import { OrderAndFilter } from '@/components/Patient/components/views/OrderAndFilterController';

interface DeletePatientDialogType {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type currentPatientType = {
  id: string;
  name: string;
  clinic_id: string;
};

function closeDialog(
  emptyPatient: currentPatientType,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentPatient: React.Dispatch<React.SetStateAction<currentPatientType>>
) {
  setCurrentPatient(emptyPatient);
  setIsChecked(false);
  setIsOpen(false);
}

const emptyPatient: currentPatientType = {
  id: '',
  name: '',
  clinic_id: '',
};

export const useDeletePatientsDialog = (orderAndFilterController: OrderAndFilter) => {
  const queryClient = useQueryClient();
  const [currentPatient, setCurrentPatient] = useState<currentPatientType>(emptyPatient);
  const [isChecked, setIsChecked] = useState(false);

  const DeletePatientDialog: React.FC<DeletePatientDialogType> = ({ setIsOpen }) => {
    const graphqlClient = useGraphqlClient();
    const { mutate, isLoading } = useDeletePatientByIdV2Mutation(graphqlClient, {
      onSettled: async () => {
        await queryClient.invalidateQueries('listPatientsV2');
        await queryClient.invalidateQueries('listPatientsByIdPaginated');
        queryClient.invalidateQueries('listAppointForAppointShift')
        queryClient.invalidateQueries('listAppointForAppointList')

        // ページネーションの初期化
        orderAndFilterController.setFilterOption({});
      },
      onSuccess: () => {
        closeDialog(currentPatient, setIsOpen, setIsChecked, setCurrentPatient);
      },
      onError: (err) => {
        alert('患者情報の削除に失敗しました。再度お試しください。');
        closeDialog(currentPatient, setIsOpen, setIsChecked, setCurrentPatient);
      },
    });

    return (
      <>
        {isLoading && (
          <div className="flex items-center justify-center fixed top-0 left-0 z-[9999] h-full w-full flex justify-center bg-black bg-opacity-50">
            <div className="animate-ping h-4 w-4 bg-blue-600 rounded-full"></div>
          </div>
        )}
        <Dialog
          transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
          onClose={() => {
            closeDialog(currentPatient, setIsOpen, setIsChecked, setCurrentPatient);
          }}
          open={true}
          PaperProps={{
            style: {
              maxWidth: 'none',
            },
          }}
        >
          <DialogContent
            dividers
            style={{
              padding: '0px 0px 0px 0px',
              maxWidth: 'none',
              maxHeight: 'none',
              position: 'relative',
            }}
          >
            <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5 flex justify-center items-center gap-x-4">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => {
                      setIsChecked(!isChecked);
                    }}
                  />
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    <span className="text-red-500">{currentPatient.name}</span>を削除しますか？
                  </h3>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  className={`${isChecked ? '' : 'pointer-events-none opacity-50'
                    } inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                  onClick={() => {
                    mutate({ id: currentPatient.id, clinic_id: currentPatient.clinic_id });
                  }}
                >
                  削除する
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                  onClick={() =>
                    closeDialog(currentPatient, setIsOpen, setIsChecked, setCurrentPatient)
                  }
                >
                  キャンセル
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return {
    DeletePatientDialog,
    setCurrentPatient,
  };
};
