import { ClinicSchedule, ClinicScheduleType, DayOfWeek, Week } from "@/_graphql/graphql-client";
import { CheckBox, DateRangePicker, DayOfWeekSelect, DaySelect, RuleTypeSelect, SpecificDatePicker, WeekSelect } from '../../components/parts'

// 休診/診察設定フィルター
export function filterClinicScheduleList(configList: ClinicSchedule[] | undefined, filter: keyof ClinicSchedule | null): ClinicSchedule[] {
    if (configList === undefined) return [];
    if (filter === null) return configList;

    return configList.filter(item => item[filter]);
}

export function generateScheduleFormElements(
    //ルール
    rule_type: ClinicScheduleType | null,
    setRuleType: React.Dispatch<React.SetStateAction<ClinicScheduleType | null>>,
    //曜日
    day_of_week: DayOfWeek | null,
    setDayOfWeek: React.Dispatch<React.SetStateAction<DayOfWeek | null>>,
    //毎月n週
    week: Week | null,
    setWeek: React.Dispatch<React.SetStateAction<Week | null>>,
    //特定日付
    specific_date: number,
    setSpecificDate: React.Dispatch<React.SetStateAction<number>>,
    //  毎月何日
    day: number | null,
    setDay: React.Dispatch<React.SetStateAction<number | null>>,
    //有効期間
    startDate: number | null,
    setStartDate: React.Dispatch<React.SetStateAction<number | null>>,
    endDate: number | null,
    setEndDate: React.Dispatch<React.SetStateAction<number | null>>,
    //診察・休診判定
    service_day: boolean | null,
    setServiceDay: React.Dispatch<React.SetStateAction<boolean | null>>,

    //有効期限(時間)
    // startTime: string,
    // setStartTime: React.Dispatch<React.SetStateAction<string>>,
    // endTime: string,
    // setEndTime: React.Dispatch<React.SetStateAction<string>>,
    // startTimeSlots: string[],
    // endTimeSlots: string[],
) {
    const scheduleFormElements = [
        //ルール
        {
            label: 'rule_type',
            element: <RuleTypeSelect rule_type={rule_type} setRuleType={setRuleType} />,
            visibility: true,
        },
        //曜日
        {
            label: 'day_of_week',
            element: <DayOfWeekSelect day_of_week={day_of_week} setDayOfWeek={setDayOfWeek} />,
            visibility: [ClinicScheduleType.MonthlyNthWeek, ClinicScheduleType.Weekly],
        },
        //毎月n週n曜日
        {
            label: 'week',
            element: <WeekSelect week={week} setWeek={setWeek} />,
            visibility: [ClinicScheduleType.MonthlyNthWeek],
        },

        //毎月n日
        {
            label: 'day',
            element: <DaySelect day={day} setDay={setDay} />,
            visibility: [ClinicScheduleType.MonthlyFixedDay],
        },

        //日付指定
        {
            label: 'specific_date',
            element: <SpecificDatePicker specific_date={specific_date} setSpecificDate={setSpecificDate} setStartDate={setStartDate} setEndDate={setEndDate} />,
            visibility: [ClinicScheduleType.SpecificDates]
        },

        // NOTE: 廃止中・・・202306~
        //休診/診察（時間指定）
        // {
        //     label: 'start_time',
        //     element: <TimeRangeSelect startTime={startTime} setStartTime={setStartTime} endTime={endTime} setEndTime={setEndTime} startTimeSlots={startTimeSlots} endTimeSlots={endTimeSlots} />,
        //     visibility: true,
        // },

        //有効期限
        {
            label: 'start_date',
            element: <DateRangePicker startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />,
            visibility: rule_type != ClinicScheduleType.SpecificDates,
        },

        // 診療フラグ
        {
            label: 'service_day',
            element: <CheckBox checkState={service_day} setCheckState={setServiceDay} />,
            visibility: [ClinicScheduleType.SpecificDates],
        },

        //部分休診
    ]

    return scheduleFormElements
}