import { ConfigHeaderProps } from "@/components/Common/entities/config"
import { BasicButton } from "./BasicButton"
import { SearchBox } from "./SearchBox"
import { ToggleButton } from "./ToggleButton"

export const ConfigHeader: React.FC<ConfigHeaderProps> = ({
    searchBoxProps: {
        searchWord,
        setSearchWord
    },
    toggleButtonProps: {
        isOpenAddRow,
        setIsOpenAddRow,
        onToggle,
        label
    },
    basicButtonProps,
    isTransactDelete
}) => {
    const style = "sticky z-[99] top-0 h-12 p-6 w-full flex justify-between items-center bg-white";
    return (
        <div className={style}>
            {/* 検索窓 */}
            <SearchBox
                searchWord={searchWord}
                setSearchWord={setSearchWord}
            />
            <div className="flex gap-x-2">
                <>
                    {/* 新規追加ボタン */}
                    <ToggleButton
                        label={label}
                        width="large"
                        color="red"
                        isOpen={isOpenAddRow}
                        setIsOpen={setIsOpenAddRow}
                        onClickEvent={() => onToggle()}
                    />
                    {/* //FIXME */}
                    {
                        isTransactDelete &&
                        <BasicButton
                            label="まとめて削除"
                            width="large"
                            color="gray"
                            disabled={basicButtonProps.disabled}
                            onClickEvent={basicButtonProps.onClick}
                        />
                    }
                </>
            </div>
        </div>
    )
}