import { PrinterIcon } from '@heroicons/react/solid';
import ReactToPrint from 'react-to-print';
import { PrintProps } from '../../../../entities';

// React-to-Printで予約カレンダーの印刷実行ボタン
//@print・・・印刷設定 

// A6サイズより小さくする場合は、@pageでサイズを指定
// @page {
//   size: 105mm 148mm;
// }

// @page ページスタイル設定
//   const pageStyle = `
//   @media print {
//     body {
//       -webkit-print-color-adjust: exact;
//     }
//   }
// `;


export const Print: React.FC<PrintProps> = ({ printRef }) => {

    return (
        <ReactToPrint
            trigger={() => <PrinterIcon className="h-5 w-5" />}
            content={() => printRef.current as HTMLDivElement}
        // pageStyle={pageStyle}
        />
    )
}

