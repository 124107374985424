import { Dialog, DialogContent } from "@mui/material"
import { useGraphqlClient } from "@/App"
import { useCheckAppointConfrict_service } from "@/domain/Appointment/services/hooks"
import { useFacilityNameList_service } from "@/domain"
import { StaffType } from "@/components/Staff/entities"
import { useState } from "react"
import { fromUnixTime } from "date-fns"
import { StaffIdCustom, StaffIdInput, useCheckAppointConfrictQuery } from "@/_graphql/graphql-client"
import { is } from "date-fns/locale"

export interface CheckLastUpdateDialogType {
    setOpenCheckUpdateDialog: React.Dispatch<React.SetStateAction<boolean>>,
    onSubmit: any

    isBeforeDay: boolean
    data: any
    operation: any
    mutates: any
    clinic_id: string
    menuList: any
    nowDate: Date
    navigate: any
    search: any

    onClose: () => void
    manegerList: StaffType[]
    isChecked: boolean
    setIsChecked: () => void
    id: string
    startTime: number
    endTime: number
    date: number
    facilityId: string
    staffIds: StaffIdInput[]
    appointId: string
}

type StaffId = {
    index: number
    id: string
}

export const SubmitConfirmationDialog: React.FC<CheckLastUpdateDialogType> = ({
    setOpenCheckUpdateDialog,
    onSubmit,

    isBeforeDay,
    data,
    operation,
    mutates,
    clinic_id,
    menuList,
    nowDate,
    navigate,
    search,

    onClose,
    manegerList,
    isChecked,
    setIsChecked,
    id,
    startTime,
    endTime,
    date,
    facilityId,
    staffIds,
    appointId
}) => {

    console.log('submitDialog---startTime(JP)', fromUnixTime(startTime))

    const graphqlClient = useGraphqlClient()

    // const checkAppointRes = useCheckAppointConfrict_service(graphqlClient, { clinic_id, id, startTime, endTime, date, facilityId, staffIds, appointId }, { enabled: true })
    const confrictRes = useCheckAppointConfrictQuery(graphqlClient, { inputs: { clinic_id, id, startTime, endTime, date, facilityId, staffIds, appointId } })
    const confricts = confrictRes.data?.checkAppointConfrict
    const isLoading = confrictRes.isLoading

    const { isNonAccepted, isTimeConfrict, isDoubleBooking, isStaffDoubleBooking, duplicatedStaffIdsWithFacility } = confricts || {}
    const [isCheck, setIsCheck] = useState(false)

    console.log('isNonAccepted', isNonAccepted)
    console.log('isTimeConfrict', isTimeConfrict)
    console.log('isDoubleBooking', isDoubleBooking)

    const { facilityNameList } = useFacilityNameList_service(graphqlClient, { clinic_id })

    // 施設名と担当者名をマッピングする
    const confrictedFacilityAndManagerList = duplicatedStaffIdsWithFacility?.map(dup => {
        const facilityName = facilityNameList.find(f => f.id === dup?.facilityId)?.name || '';
        const managerNames = dup?.staffIds.map(staffId => {
            const manager = manegerList.find(m => m.id === staffId);
            return manager ? manager.name : '';
        }).filter(name => name !== ''); // 空の名前をフィルタリング
        return {
            facilityName,
            managerNames
        };
    });

    const isPasted = isNonAccepted === false && isTimeConfrict === false && isDoubleBooking === false && isStaffDoubleBooking === false && isBeforeDay === true
    const isPastedWithStaffConfrict = isNonAccepted === false && isTimeConfrict === false && isDoubleBooking === false && isStaffDoubleBooking === true && isBeforeDay === true

    return (
        <>
            {!isLoading &&
                <Dialog
                    transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
                    onClose={() => { setOpenCheckUpdateDialog(false) }}
                    open={true}
                    PaperProps={{
                        style: {
                            maxWidth: 'none'
                        }
                    }}
                >
                    <DialogContent
                        dividers
                        style={{ padding: '0px 0px 0px 0px', minWidth: '40px', minHeight: '20px', position: 'relative' }}
                    >
                        <div className="h-full relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6">
                            {
                                isNonAccepted === false &&
                                isTimeConfrict === false &&
                                isDoubleBooking === false &&
                                isStaffDoubleBooking === false &&
                                isBeforeDay === false &&
                                <>
                                    <div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <div className="text-lg font-medium leading-6 text-gray-900">
                                                {/* {(isUpdated === true) && <p><span className="text-red-500">最新の予約情報が既に存在します。<br />リロードを行なってください。</span></p>} */}
                                                <p>予約情報を登録しますか？</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-center mt-5">
                                        <p
                                            onClick={() => onSubmit(data, operation, mutates, clinic_id, menuList, nowDate, navigate, setOpenCheckUpdateDialog, search)}
                                            className='bg-indigo-600 inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm'
                                        // value={'登録する'}
                                        >
                                            登録する
                                        </p>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => setOpenCheckUpdateDialog(false)}
                                        >
                                            キャンセル
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                isPasted &&
                                <>
                                    <div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <div className="text-lg font-medium leading-6 text-gray-900">
                                                {/* {(isUpdated === true) && <p><span className="text-red-500">最新の予約情報が既に存在します。<br />リロードを行なってください。</span></p>} */}
                                                <p className='text-sm'>予約情報を登録しますか？</p>
                                                <span className="text-md text-red-500 font-bold">編集対象の予約情報が過去の日付です</span><br />
                                                <input type="checkbox" checked={isCheck} onChange={(e) => setIsCheck(e.currentTarget.checked)} />
                                                過去の予約更新に同意する
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-center mt-5">
                                        <p
                                            onClick={() => onSubmit(data, operation, mutates, clinic_id, menuList, nowDate, navigate, setOpenCheckUpdateDialog, search)}
                                            className={`${isCheck ? '' : 'pointer-events-none opacity-30'} bg-indigo-600 inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                                        >
                                            更新する
                                        </p>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => setOpenCheckUpdateDialog(false)}
                                        >
                                            キャンセル
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                isPastedWithStaffConfrict &&
                                <>
                                    <div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <div className="text-lg font-medium leading-6 text-gray-900">
                                                {/* {(isUpdated === true) && <p><span className="text-red-500">最新の予約情報が既に存在します。<br />リロードを行なってください。</span></p>} */}
                                                <p className='text-sm'>予約情報を登録しますか？</p>
                                                <span className="text-md text-red-500 font-bold">編集対象の予約情報が過去の日付です</span><br />
                                                <input type="checkbox" checked={isCheck} onChange={(e) => setIsCheck(e.currentTarget.checked)} />
                                                過去の予約更新に同意する
                                            </div>
                                            <>
                                                <p className="text-xs text-red-500 font-bold">担当者が重複しています</p>
                                                <div className="flex flex-col max-h-[400px] overflow-scroll text-sm">
                                                    {confrictedFacilityAndManagerList?.map(item => (
                                                        <span key={item.facilityName}>
                                                            {item.facilityName} : {item?.managerNames?.join(', ')}
                                                        </span>
                                                    ))}
                                                </div>
                                            </>
                                        </div>


                                    </div>

                                    <div className="flex justify-center mt-5">
                                        <p
                                            onClick={() => onSubmit(data, operation, mutates, clinic_id, menuList, nowDate, navigate, setOpenCheckUpdateDialog, search)}
                                            className={`${isCheck ? '' : 'pointer-events-none opacity-30'} bg-indigo-600 inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                                        >
                                            更新する
                                        </p>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => setOpenCheckUpdateDialog(false)}
                                        >
                                            キャンセル
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                isStaffDoubleBooking === true &&
                                isNonAccepted === false &&
                                isTimeConfrict === false &&
                                isDoubleBooking === false &&
                                isBeforeDay === false &&
                                <>
                                    <div>
                                        <div className="mt-3 text-center sm:mt-5 mb-3">
                                            <div className="text-lg font-medium leading-6 text-gray-900">
                                                {/* {(isUpdated === true) && <p><span className="text-red-500">最新の予約情報が既に存在します。<br />リロードを行なってください。</span></p>} */}
                                                {
                                                    <>
                                                        <p className="text-xs text-red-500 font-bold">担当者が重複しています</p>
                                                        <div className="flex gap-3 items-center">
                                                            <input type="checkbox" checked={isChecked} onChange={setIsChecked} />
                                                            <p>予約情報を登録しますか？</p>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex flex-col max-h-[400px] overflow-scroll text-sm">
                                            {confrictedFacilityAndManagerList?.map(item => (
                                                <span key={item.facilityName}>
                                                    {item.facilityName} : {item?.managerNames?.join(', ')}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="flex justify-center mt-5">
                                        {
                                            <p
                                                onClick={() => onSubmit(data, operation, mutates, clinic_id, menuList, nowDate, navigate, setOpenCheckUpdateDialog, search)}
                                                className={`${!isChecked ? 'opacity-50 pointer-events-none' : ''} inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                                            >
                                                登録する
                                            </p>
                                        }
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => setOpenCheckUpdateDialog(false)}
                                        >
                                            キャンセル
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                isNonAccepted === true &&
                                <>
                                    <div>
                                        <div className="text-center">
                                            <div className="text-lg font-medium leading-6 text-gray-900">
                                                {
                                                    <p className="text-red-600 text-sm">ブロックと時刻が重複しています</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                isTimeConfrict === true &&
                                <>
                                    <div>
                                        <div className="text-center">
                                            <div className="text-lg font-medium leading-6 text-gray-900">
                                                {
                                                    <p className="text-red-600 text-sm">予約時刻が重複しています</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                isDoubleBooking === true &&
                                <>
                                    <div>
                                        <div className="text-center">
                                            <div className="text-lg font-medium leading-6 text-gray-900">
                                                {
                                                    <p className="text-red-600 text-sm">ダブルブッキングしています</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                    </DialogContent>
                </Dialog>
            }
        </>
    )
}