import { AddGroupRowProps } from "../../entities";
import { Checkbox, Flex, TextInput } from "@mantine/core";
import { BasicButton, $styledTR, _styledTD } from "@/components/Common/components/Config";

//新規追加
export const AddGroupRow: React.FC<AddGroupRowProps> = ({
    editingGroupName,
    setEditingGroupName,
    resetFormState,
    addGroupMutation,
}) => {

    return (
        <$styledTR border={false}>
            <_styledTD>
                {/* チェックボックス */}
                <Checkbox
                    disabled
                    // checked={false}
                    radius="xs"
                    size="xs"
                />
            </_styledTD>

            <_styledTD>
                {/* 施設グループ名 */}
                <TextInput
                    placeholder="グループ名"
                    size="xs"
                    required
                    value={editingGroupName}
                    onChange={(e) => {
                        setEditingGroupName(e.target.value)
                    }
                    }
                />
            </_styledTD>

            <_styledTD>
                {/* 空欄 */}
            </_styledTD>

            <_styledTD>
                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    gap={{ base: 'sm', sm: 'sm' }}
                    justify={{ sm: 'center' }}
                >
                    {/* 登録ボタン */}
                    <BasicButton
                        label='登録'
                        width="small"
                        color="gray"
                        disabled={editingGroupName.length === 0}
                        onClickEvent={() => {
                            addGroupMutation()
                        }}
                    />
                    {/* キャンセルボタン */}
                    <BasicButton
                        label='キャンセル'
                        width="small"
                        color="gray"
                        disabled={false}
                        onClickEvent={() => {
                            resetFormState()
                        }}
                    />
                </Flex>
            </_styledTD>

        </$styledTR>
    );

};

export default AddGroupRow;
