import { useDeleteAppointMutation } from ".graphql/types";
import { Dialog, DialogContent } from '@mui/material'
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useGraphqlClient } from '@/App'
import { japanTimeFormatDate } from '@/feature/phr-28/components/common/appointment'
import { fromUnixTime } from "date-fns";
import { DeleteAppointmentDialogType } from '@/components/Appointment/entities'
import { getPatientName } from "@/components/Common";
import { getPatientsNameById } from "@/feature/phr-28/components/common/patients";

type currentAppointmentType = {
    id: string;
    startTime: number;
}

function closeDialog(
    emptyAppointment: currentAppointmentType,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>,
    setCurrentAppointment: React.Dispatch<React.SetStateAction<currentAppointmentType>>
) {
    setCurrentAppointment(emptyAppointment);
    setIsChecked(false);
    setIsOpen(false);
}

const emptyAppointment: currentAppointmentType = {
    id: '',
    startTime: 0
}

export const useDeleteAppointmentDialog = () => {
    const queryClient = useQueryClient()
    const graphqlClient = useGraphqlClient()
    const [currentAppointment, setCurrentAppointment] = useState<currentAppointmentType>(emptyAppointment);
    const [isChecked, setIsChecked] = useState(false);

    const DeleteAppointmentDialog: React.FC<DeleteAppointmentDialogType> = ({
        patientName,
        setIsOpen
    }) => {

        const { mutate, isLoading } = useDeleteAppointMutation(graphqlClient, {
            onSettled: () => {
                queryClient.invalidateQueries('scanPeriodAppoint');
            },
            onSuccess: () => {
                closeDialog(currentAppointment, setIsOpen, setIsChecked, setCurrentAppointment);
            },
            onError: (err) => {
                alert('予約情報の削除に失敗しました。再度お試しください。')
                closeDialog(currentAppointment, setIsOpen, setIsChecked, setCurrentAppointment);
            }
        })

        return (
            <>
                {
                    isLoading &&
                    <div className="flex items-center justify-center fixed top-0 left-0 z-[9999] h-full w-full flex justify-center bg-black bg-opacity-50">
                        <div className="animate-ping h-4 w-4 bg-blue-600 rounded-full"></div>
                    </div>
                }
                <Dialog
                    transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
                    onClose={() => { closeDialog(currentAppointment, setIsOpen, setIsChecked, setCurrentAppointment) }}
                    open={true}
                    PaperProps={{
                        style: {
                            maxWidth: 'none',
                        },
                    }}
                >
                    <DialogContent
                        dividers
                        style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', position: 'relative' }}
                    >
                        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6">
                            <div>
                                <div className="mt-3 text-center sm:mt-5 flex justify-center items-center gap-x-4">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                                        <input type="checkbox" checked={isChecked} onChange={() => { setIsChecked(!isChecked) }} />
                                        予約を削除しますか？<br />
                                        {patientName}<br />
                                        {japanTimeFormatDate(fromUnixTime(currentAppointment.startTime))}<br />
                                    </h3>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="button"
                                    className={`${isChecked ? '' : 'pointer-events-none opacity-50'} inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                                    onClick={() => { mutate({ id: currentAppointment.id, startTime: currentAppointment.startTime }) }}
                                >
                                    削除する
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                    onClick={() => closeDialog(currentAppointment, setIsOpen, setIsChecked, setCurrentAppointment)}
                                >
                                    キャンセル
                                </button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    return {
        DeleteAppointmentDialog,
        setCurrentAppointment
    }
}