import { useState, useEffect } from 'react';

export function useRefreshObserver(appointIsLoading: boolean): { isRefresh: boolean, setRefreshTriggered: React.Dispatch<React.SetStateAction<boolean>> } {
    const [refreshTriggered, setRefreshTriggered] = useState(false);

    useEffect(() => {
        if (refreshTriggered) {
            setRefreshTriggered(false);
        }
    }, [refreshTriggered]);

    const isRefresh = refreshTriggered && appointIsLoading;

    return { isRefresh, setRefreshTriggered }
}
