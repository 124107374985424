// cssスタイル: input
export function getStyle() {
    const commonStyle = 'transition-all duration-200 w-full h-full border box-border'
    const defaultStyle = `bg-gray-200 ${commonStyle}`
    const editStyle = `bg-white ${commonStyle}`
    const styles = {
        [defaultStyle]: false,
        [editStyle]: true
    }
    return styles
}

export function getButtonStyle() {
    // cssスタイル: 登録ボタン
    const commonButtonStyle = 'transition-all duration-200 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
    const buttonStyle = `opacity-0 pointer-events-none ${commonButtonStyle}`
    const submitStyle = `cursor-pointer ${commonButtonStyle}`
    const buttonStyles = {
        [buttonStyle]: false,
        [submitStyle]: true
    }
    return buttonStyles
}

export function getCommentStyle(matches: boolean) {
    const commonCommentStyle = 'w-full overflow-y-hidden relative'
    const defaultCommentStyle = `h-1/3 ${commonCommentStyle}`
    const SPCommentStyle = `h-[65%] ${commonCommentStyle}`
    const commentStyles = {
        [defaultCommentStyle]: matches === false,
        [SPCommentStyle]: matches === true
    }
    return commentStyles
}

