// メモ: Mutationがある場合は追記
// useInitMutateでContextに登録するMutationを定義する
import {
    useAddClinicScheduleMutation,
    useDeleteClinicScheduleMutation,
    useUpdateClinicScheduleMutation
} from "@/_graphql/graphql-client"

import { GraphQLClient } from 'graphql-request'
import { QueryClient } from "react-query"

export function clinicScheduleMutations(graphqlClient: GraphQLClient, queryClient: QueryClient) {
    const option = {
        onSettled: async () => {
            await queryClient.invalidateQueries();
            await queryClient.invalidateQueries('getClinicScheduleByClinicId');
            await queryClient.invalidateQueries('listClinicSchedulesByClinicId');
        }
    }

    const addClinicSchedule = useAddClinicScheduleMutation(graphqlClient, option)
    const updateClinicSchedule = useUpdateClinicScheduleMutation(graphqlClient, option)
    const deleteClinicSchedule = useDeleteClinicScheduleMutation(graphqlClient, option);

    return {
        addClinicSchedule,
        updateClinicSchedule,
        deleteClinicSchedule
    }
}