import { _styledComponentProps } from '@/components/Common'
import React from 'react'

export const _styledDivLeftBottom: React.FC<_styledComponentProps> = ({
    children
}) => {
    return (
        <div className="w-1/2 text-xl">
            {children}
        </div>
    )
}
