import { WeekSelectType } from "@/components/ClinicSettings/entities"
import { optionSlots } from "@/components/ClinicSettings/utils"
import { Week } from '@/_graphql/graphql-client'
import { XCircleIcon } from "@heroicons/react/outline"
import { nullableOptionSlots } from "@/components/ClinicSettings/utils/libs/optionSlots"

// [フォームパーツ]週選択
export function WeekSelect({
    week,
    setWeek,
    isNullable
}: WeekSelectType): JSX.Element {

    const baseOptionSlots = isNullable ? nullableOptionSlots : optionSlots

    return (
        <td>
            <div className="h-full w-full relative flex items-center">
                {
                    <select
                        className='w-full h-full text-xs'
                        onChange={(e) => {
                            setWeek(e.currentTarget.value as Week)
                        }}
                    >
                        {baseOptionSlots.week.map((slot, index) => (
                            // FIXME: any型のtype定義
                            <option key={index} value={slot.value as any} selected={week === slot.value}>{slot.label}</option>
                        ))}
                    </select>
                }
                {((isNullable && week !== null)) &&
                    <XCircleIcon className="h-4 w-4 absolute right-2"
                        onClick={() => {
                            setWeek(null)
                        }
                        }
                    />
                }
            </div>
        </td>
    )
}