import { AllotmentHandle } from "allotment";

export function togglePane(
    ref: React.RefObject<AllotmentHandle>,
    toggleTarget: 'top' | 'bottom' | 'left',
    isPaneOpen: boolean
) {
    if (isPaneOpen) {
        closePane(ref, toggleTarget);
    } else {
        openPane(ref, toggleTarget, window.innerHeight, window.innerWidth);
    }
}

//Paneのクローズ
export function closePane(
    ref: React.RefObject<AllotmentHandle>,
    closeTarget: 'top' | 'bottom' | 'left'
) {
    if (ref?.current) {
        switch (closeTarget) {
            case 'top':
                ref.current.resize([0, window.innerHeight]);
                break;
            case 'bottom':
                ref.current.resize([window.innerHeight, 80]);
                break;
            case 'left':
                ref.current.resize([0, window.innerWidth]);
                break;
        }
    }
}

export function openPane(
    ref: React.RefObject<AllotmentHandle>,
    openTarget: 'top' | 'bottom' | 'left',
    height: number,
    width: number
) {
    if (ref?.current) {
        let openSize;

        switch (openTarget) {
            case 'top':
                openSize = (((height - 33) / 100) * 35);
                ref.current.resize([(((height - 33) / 100) * 32), (((height - 33) / 100) * 68)]);
                break;
            case 'bottom':
                openSize = (((height - 33) / 100) * 70);
                console.log(openSize)
                // FIXME: なんで0,0で100%になるのかわからない
                ref.current.resize([(((height - 33) / 100) * 32), (((height - 33) / 100) * 68)]);
                break;
            case 'left':
                // メモ: 左メニューは開閉だけなのでresetで良さそう
                ref.current.reset();
                break;
        }
    }
}

export function resetPane(
    ref: React.RefObject<AllotmentHandle>,
    openTarget: 'top' | 'bottom' | 'left',
    height: number,
    width: number
) {
    if (ref?.current) {
        ref.current.reset();
    }
}