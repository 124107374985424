import axios from "axios";
import { useEffect, useState } from "react";
import { HolidaysType } from "@/domain/Common/entities/types";

export async function getHolidays() {
    const url = 'https://holidays-jp.github.io/api/v1/datetime.json'
    const holidays = await axios.get(url);
    return holidays.data;
}

export const useGetHolidays = () => {
    const [holidays, setHolidays] = useState<HolidaysType>({ 1: '' });

    useEffect(() => {
        async function fetchHolidays() {
            try {
                const holidays = await getHolidays();
                setHolidays(holidays);
            } catch (error) {
                console.error("Holidaysフェッチエラー", error);
            }
        }
        fetchHolidays();
    }, []);

    return holidays;
}
