import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// import { ShiftCsvImport } from "./ShiftCsvImport";
import { useAddShiftMutation } from '../../../../../../../.graphql/types'
import { useQueryClient } from 'react-query'
import {
  unixTimeFromNowDate,
  dateFromUnixTime,
  amShifts as tmpAmShifts,
  pmShifts as tmpPmShifts,
  weeks
} from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon'
import { shiftType, listManegerType } from '@/feature/phr-28/components/common/type'
import {
  workingTimeAm,
  workingTimePm
} from '@/feature/phr-28/components/common/emptyData'
import { useGraphqlClient } from '@/App'

interface ShiftFixedType {
  setShiftFix: (isShow: boolean) => void
  manegerLists: listManegerType[]
  shiftIdentificationNumber: string
  nowDate: Date
}

export const ShiftFixed: React.FC<ShiftFixedType> = ({
  setShiftFix,
  manegerLists,
  shiftIdentificationNumber,
  nowDate
}) => {
  const graphqlClient = useGraphqlClient()
  // const [isRegistByCSV, setIsRegistByCSV] = useState(false);
  const [startDate, setStartDate] = useState(nowDate)
  const [endDate, setEndDate] = useState(nowDate)
  const [amShifts, setAmShifts] = useState(tmpAmShifts)
  const [pmShifts, setPmShifts] = useState(tmpPmShifts)
  const [isEveryDay, setIsEveryDay] = useState(false)
  const [isWeeks, setIsWeeks] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ])
  function updateIsWeeks(dayNumber: number): void {
    const tmpWeeks: boolean[] = isWeeks.slice()
    tmpWeeks.splice(dayNumber, 1, !tmpWeeks[dayNumber])
    setIsWeeks(tmpWeeks)
  }

  const updateShifts = (i: number, isAm: boolean): void => {
    if (isAm) {
      setAmShifts(
        amShifts.map((amShift, index) =>
          index !== i ? amShift : !amShift
        )
      )
    } else {
      setPmShifts(
        pmShifts.map((pmShift, index) =>
          index !== i ? pmShift : !pmShift
        )
      )
    }
  }

  // 登録対象名を決定
  const filteredShiftTarget: listManegerType[] = []
  manegerLists.forEach((maneger: listManegerType) => {
    if (maneger.id === shiftIdentificationNumber) {
      filteredShiftTarget.push(maneger)
    }
  })
  const shiftTarget = (
    <>
      <div className="flex">
        <select
          id="shiftTarget"
          name="shiftTarget"
          className="w-3/5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value={filteredShiftTarget[0].name}>
            {filteredShiftTarget[0].name}
          </option>
        </select>
        <div className="leading-10 pl-1 text-sm">
          {'(' + filteredShiftTarget[0].role + ')'}
        </div>
        {/* {filteredShiftTarget !== null && `${filteredShiftTarget.name} - ${filteredShiftTarget.role}`} */}
      </div>
    </>
  )

  function registrationConfirmation(timestamp: number): boolean {
    if (isEveryDay) {
      return true
    } else {
      const tmpDate: Date = dateFromUnixTime(timestamp)
      return isWeeks[tmpDate.getDay()]
    }
  }

  const queryClient = useQueryClient()
  const { mutate } = useAddShiftMutation(graphqlClient, {
    onSettled: () => {
      queryClient.invalidateQueries('listShift').catch(() => console.log('on Settled'))
    },
    onSuccess: (res) => {
      console.log(res)
    },
    onError: (error) => {
      throw error
    },
    retry: 3
  })
  const addShiftsData: shiftType[] = []
  for (
    let i = unixTimeFromNowDate(startDate);
    i <= unixTimeFromNowDate(endDate);
    i += 86400
  ) {
    const isRegistrationConfirmation = registrationConfirmation(i)
    if (isRegistrationConfirmation) {
      addShiftsData.push({
        date: i,
        id: shiftIdentificationNumber,
        name: filteredShiftTarget[0].name,
        shifts: amShifts.concat(pmShifts),
        workingTimes: { am: workingTimeAm, pm: workingTimePm }
      })
    }
  }
  const addShift = (): void => {
    addShiftsData.forEach((data: shiftType) => {
      mutate(data)
    })
  }

  const shiftTime = (): JSX.Element => {
    const amTimes = []
    let tmpAmTimes = []
    const pmTimes = []
    let tmpPmTimes = []

    for (let i = 0; i < amShifts.length; i++) {
      tmpAmTimes.push(
        <div className="flex items-center mb-4 w-20">
          <input
            id="default-checkbox"
            type="checkbox"
            value={i}
            onChange={() => {
              updateShifts(i, true)
            }}
            checked={amShifts[i]}
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="default-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {workingTimeAm[i]}
          </label>
        </div>
      )
      if (i % 4 === 3) {
        amTimes.push(<div className="flex flex-row">{tmpAmTimes}</div>)
        tmpAmTimes = []
      }
    }
    for (let i = 0; i < pmShifts.length; i++) {
      tmpPmTimes.push(
        <div className="flex items-center mb-4 w-20">
          <input
            id="default-checkbox"
            type="checkbox"
            value={i}
            onChange={() => {
              updateShifts(i, false)
            }}
            checked={pmShifts[i]}
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="default-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {workingTimePm[i]}
          </label>
        </div>
      )
      if (i % 4 === 3) {
        pmTimes.push(<div className="flex flex-row">{tmpPmTimes}</div>)
        tmpPmTimes = []
      }
    }
    useEffect(() => {
      setAmShifts(tmpAmShifts)
      setPmShifts(tmpPmShifts)
    }, [])

    return (
      <>
        <div className="p-2 h-96 overflow-scroll">
          <div>
            <label
              htmlFor="am"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              勤務時間:AM
            </label>
            <div className="flex flex-col mb-4">{amTimes}</div>
          </div>
          <div>
            <label
              htmlFor="pm"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              勤務時間:PM
            </label>
            <div className="flex flex-col mb-4">{pmTimes}</div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {/* {isRegistByCSV && <ShiftCsvImport setIsRegistByCSV={setIsRegistByCSV} />} */}
      {/* {!isRegistByCSV && ( */}
      {/* <form action="/masta"> */}
      <div className="p-1 box-border flex flex-row justify-center">
        <div className="w-1/3 h-full box-border border-2 border-gray-700">
          <div className="h-15 p-2 box-border border-b-2 border-gray-700">
            {shiftTarget}
          </div>
          {shiftTime()}
        </div>

        <div className="w-1/2 ml-5">
          <div className="p-2">
            <h2>シフト登録期間</h2>

            <div date-rangepicker className="flex items-center">
              <div className="relative">
                <DatePicker
                  name="shiftStartDate"
                  dateFormat="yyyy-MM-dd"
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                />
              </div>
              <span className="mx-4 text-gray-500">to</span>
              <div className="relative">
                <DatePicker
                  name="shiftEndDate"
                  dateFormat="yyyy-MM-dd"
                  selected={endDate}
                  onChange={(date: Date) => setEndDate(date)}
                />
              </div>
            </div>
          </div>

          <div className="p-2">
            <>
              <h2>曜日指定</h2>
              <div className="flex items-center mb-2 pl-2">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  checked={isEveryDay}
                  onChange={() =>
                    setIsEveryDay(!isEveryDay)
                  }
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  毎日
                </label>
              </div>
              {weeks.map((daily: string, index: number) => (
                <div className="flex items-center pl-2" key={index}>
                  <input
                    id="checked-checkbox"
                    type="checkbox"
                    checked={isWeeks[index]}
                    onChange={() => updateIsWeeks(index)}
                    value="index"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="checked-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {daily}曜日
                  </label>
                </div>
              ))}
            </>
          </div>

          <div className="text-red-700 font-bold">
            ※シフト登録期間内に登録済みのシフトがある場合は上書きされます。ご確認の上、確定して下さい。
          </div>

          <div className="flex justify-end p-3">
            <div className="flex flex-row">
              <button
                type="submit"
                onClick={() => {
                  addShift()
                }}
                className="h-10 w-24 mr-2 font-bold text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 rounded-lg text-sm px-2 py-1 text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
              >
                確定
              </button>
              <button
                type="button"
                onClick={() => {
                  setShiftFix(false)
                }}
                className="h-10 w-24 mr-2 text-green-500 hover:text-white border border-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-100 font-medium rounded-lg text-sm px-2 py-1 text-center dark:border-green-300 dark:text-green-300 dark:hover:text-white dark:hover:bg-green-400 dark:focus:ring-green-600"
              >
                キャンセル
              </button>
              {/* <button
                    type="submit"
                    onClick={() => {
                      setIsRegistByCSV(true);
                    }}
                    className="h-10 w-24 mr-2 font-bold text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 rounded-lg text-sm px-2 py-1 text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
                  >
                    CSV入力
                  </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* </form> */}
      {/* )} */}
    </>
  )
}
