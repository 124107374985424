import React from 'react'

interface PlusIconType {
  size: number
}

export const PlusIcon: React.FC<PlusIconType> = ({ size }) => {
  const iconSize: string = 'h-' + size.toString() + ' w-' + size.toString()

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={iconSize}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </svg>
    </>
  )
}
