/**
 * AppointmentListByPatientsViewsコンポーネント内の機関検索表示部分のコンポーネント
 */

import DatePicker from "react-datepicker";
import { AppointmentListByPatientsControlPanelPresenterPropsType } from "@/components/Appointment/entities";

export const AppointmentListByPatientsControlPanelPresenter: React.FC<AppointmentListByPatientsControlPanelPresenterPropsType> = ({
    startDate,
    endDate,
    setDateRange,
    appointsNum,
    patientInitiatedCancelNum,
    unannouncedCancelNum,
    lateNum
}) => {
    return (
        <div className="flex items-center justify-between px-2">
            <div>
                <p className="text-xs">期間検索</p>
                <div className="flex justify-between mb-2 w-1/2">
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate ? startDate : null}
                        endDate={endDate ? endDate : null}
                        onChange={(update: any) => {
                            setDateRange(update);
                        }}
                        isClearable={true}
                        className="rounded border-gray-400 py-1 text-center text-xs"
                    />
                </div>
            </div>
            <div className="text-xs flex gap-2">
                <div>
                    <p>予約: {appointsNum}件</p>
                    <p>遅刻: {lateNum}件</p>
                </div>
                <div>
                    <p>無断: {unannouncedCancelNum}件</p>
                    <p>患者都合: {patientInitiatedCancelNum}件</p>
                </div>
            </div>
        </div>
    )
}