import { ReactNode, useState } from 'react';
import { ClinicSchedule, ClinicScheduleType, DayOfWeek, Week } from '@/_graphql/graphql-client';
import { ClinicSchedulePresenterType, FilterDatesType, FilterHeadsType, TableHeadsType } from '../../entities';
import { UpsertScheduleViews } from '../tmp/UpsertScheduleViews';
import { optionSlots, toggleRowState } from '../../utils';
import { format, fromUnixTime, set } from 'date-fns';
import { generateSortedConfigList, isSelection, scheduleTableHeads } from '../../utils/libs/common';
import { ClinicType } from '@/components/Common';
import { deleteClinicScheduleType } from '@/components/Common/entities/common/types';
import { DeleteValidateDialog } from '../tmp/DeleteValidateDialog';
import { FilterRow } from '../parts/formParts/common/FilterRow';
import { CheckBox, DateRangePicker, DayOfWeekSelect, DaySelect, RuleTypeSelect, SpecificDatePicker, WeekSelect } from '../parts';
import { is } from 'date-fns/locale';

// スタイル
const deleteBtnCss: string = 'cursor-pointer leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-red-700 hover:bg-red-900'
const disableBtnCss: string = 'leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'

export const scheduleFilterTableHeads: FilterHeadsType[] = [
    { label: '#', prop: null },
    { label: 'ルール種別', prop: "rule_type" },
    { label: '曜日', prop: "day_of_week" },
    { label: '週', prop: "week" },
    { label: '日', prop: "day" },
    { label: '日付指定', prop: "specific_date" },
    { label: '有効期間(開始日)', prop: "start_date" },
    { label: '有効期間(終了日)', prop: "end_date" },
    { label: '診療/休診', prop: "service_day" },
    { label: '作成日', prop: "created_at" },
    { label: '更新日', prop: "updated_at" },
    { label: '', prop: null },
]

export const ClinicSchedulePresenter: React.FC<ClinicSchedulePresenterType> = ({
    clinic,
    clinicScheduleList,
    deleteClinicSchedule,
}) => {

    // const [filteredClinicScheduleList, setFilteredClinicScheduleList] = useState<ClinicSchedule[]>(clinicScheduleList)

    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
    const [sortBy, setSortBy] = useState<'created_at' | 'updated_at'>('created_at')

    // フィルター条件
    const [filterRuleType, setFilterRuleType] = useState<ClinicScheduleType | null>(null)
    const [filterDayOfWeek, setFilterDayOfWeek] = useState<DayOfWeek | null>(null)
    const [filterWeek, setFilterWeek] = useState<Week | null>(null)
    const [filterDay, setFilterDay] = useState<number | null>(null)
    const [filterStartDate, setFilterStartDate] = useState<number | null>(null)
    const [filterEndDate, setFilterEndDate] = useState<number | null>(null)
    const [filterServiceDay, setFilterServiceDay] = useState<boolean | null>(null)
    const [filterStartCreatedDate, setFilterStartCreatedDate] = useState<number | null>(null)
    const [filterEndCreatedDate, setFilterEndCreatedDate] = useState<number | null>(null)

    function resetAllFilters(
        setFilterRuleType: React.Dispatch<React.SetStateAction<ClinicScheduleType | null>>,
        setFilterDayOfWeek: React.Dispatch<React.SetStateAction<DayOfWeek | null>>,
        setFilterWeek: React.Dispatch<React.SetStateAction<Week | null>>,
        setFilterDay: React.Dispatch<React.SetStateAction<number | null>>,
        setFilterStartDate: React.Dispatch<React.SetStateAction<number | null>>,
        setFilterEndDate: React.Dispatch<React.SetStateAction<number | null>>,
        setFilterServiceDay: React.Dispatch<React.SetStateAction<boolean | null>>,
        setFilterStartCreatedDate: React.Dispatch<React.SetStateAction<number | null>>,
        setFilterEndCreatedDate: React.Dispatch<React.SetStateAction<number | null>>,
    ) {
        setFilterRuleType(null)
        setFilterDayOfWeek(null)
        setFilterWeek(null)
        setFilterDay(null)
        setFilterStartDate(null)
        setFilterEndDate(null)
        setFilterServiceDay(null)
        setFilterStartCreatedDate(null)
        setFilterEndCreatedDate(null)
    }

    const filteredClinicScheduleList = filteringClinicScheduleList(
        clinicScheduleList,
        filterRuleType,
        filterDayOfWeek,
        // filterWeek,
        filterDay,
        filterStartDate,
        filterEndDate,
        filterServiceDay,
        filterStartCreatedDate,
        filterEndCreatedDate)

    const sortedScheduleList = generateSortedConfigList(filteredClinicScheduleList, sortOrder, sortBy)

    const scheduleFilterTableDates: FilterDatesType[] = [
        {
            prop: "rule_type", element: <RuleTypeSelect isNullable={true} resetAllFilters={
                () => resetAllFilters(
                    setFilterRuleType,
                    setFilterDayOfWeek,
                    setFilterWeek,
                    setFilterDay,
                    setFilterStartDate,
                    setFilterEndDate,
                    setFilterServiceDay,
                    setFilterStartCreatedDate,
                    setFilterEndCreatedDate,
                )} rule_type={filterRuleType} setRuleType={setFilterRuleType} />
        },
        { prop: "day_of_week", element: filterRuleType === ClinicScheduleType.MonthlyNthWeek || filterRuleType === ClinicScheduleType.Weekly ? <DayOfWeekSelect isNullable={true} day_of_week={filterDayOfWeek} setDayOfWeek={setFilterDayOfWeek} /> : <td></td> },
        { prop: "week", element: filterRuleType === ClinicScheduleType.MonthlyNthWeek ? <WeekSelect isNullable={true} week={filterWeek} setWeek={setFilterWeek} /> : <td></td> },
        { prop: "day", element: filterRuleType === ClinicScheduleType.MonthlyFixedDay ? <DaySelect isNullable={true} day={filterDay} setDay={setFilterDay} /> : <td></td> },
        { prop: "start_date", element: <DateRangePicker isNullable={true} startDate={filterStartDate} setStartDate={setFilterStartDate} endDate={filterEndDate} setEndDate={setFilterEndDate} /> },
        { prop: "service_day", element: filterRuleType === ClinicScheduleType.SpecificDates ? <CheckBox checkState={filterServiceDay} setCheckState={setFilterServiceDay} /> : <td></td> },
        { prop: "created_at", element: <DateRangePicker isNullable={true} startDate={filterStartCreatedDate} setStartDate={setFilterStartCreatedDate} endDate={filterEndCreatedDate} setEndDate={setFilterEndCreatedDate} /> },
    ]

    function filteringClinicScheduleList(
        clinicScheduleList: ClinicSchedule[],
        filterRuleType: ClinicScheduleType | null,
        filterDayOfWeek: DayOfWeek | null,
        // filterWeek: Week | null,
        filterDay: number | null,
        filterStartDate: number | null,
        filterEndDate: number | null,
        filterServiceDay: boolean | null,
        filterStartCreatedDate: number | null,
        filterEndCreatedDate: number | null,
    ) {
        const ruleTypeFilteredList = filterRuleType ? clinicScheduleList.filter((item) => item.rule_type === filterRuleType) : clinicScheduleList

        const weeklyFilteredList = filterRuleType === ClinicScheduleType.Weekly ? ruleTypeFilteredList.filter((item) => item.week === filterWeek) : ruleTypeFilteredList

        const dayOfWeekFilteredList = filterRuleType === ClinicScheduleType.MonthlyNthWeek ? weeklyFilteredList.filter((item) => item.day_of_week === filterDayOfWeek) : weeklyFilteredList
        const fixedDayFilteredList = filterRuleType === ClinicScheduleType.MonthlyFixedDay ? dayOfWeekFilteredList.filter((item) => item.day === filterDay) : dayOfWeekFilteredList;

        // alert(filterDayOfWeek)
        const weekFilteredList = filterDayOfWeek ? fixedDayFilteredList.filter((item) => item.day_of_week === filterDayOfWeek) : fixedDayFilteredList
        const dayFilteredList = filterDay ? weekFilteredList.filter((item) => item.day === filterDay) : weekFilteredList

        const startDateFilteredList = filterStartDate ? dayFilteredList.filter((item) => item.start_date >= filterStartDate) : dayFilteredList
        const endDateFilteredList = filterEndDate ? startDateFilteredList.filter((item) => item.end_date <= filterEndDate) : startDateFilteredList
        const serviceDayFilteredList = filterServiceDay !== null ? endDateFilteredList.filter((item) => item.service_day === filterServiceDay) : endDateFilteredList
        const startCreatedDateFilteredList = filterStartCreatedDate ? serviceDayFilteredList.filter((item) => item.created_at >= filterStartCreatedDate) : serviceDayFilteredList
        const endCreatedDateFilteredList = filterEndCreatedDate ? startCreatedDateFilteredList.filter((item) => item.created_at <= filterEndCreatedDate) : startCreatedDateFilteredList

        return endCreatedDateFilteredList
    }

    // const _clinicSchedules = filterdClinicScheduleList(clinicScheduleList, filterRuleType, filterStartDate, filterEndDate, filterServiceDay, filterStartCreatedDate, filterEndCreatedDate)

    // チェックボックス
    const [selections, setSelections] = useState(['']);
    // 編集モード
    const [editing, setEditing] = useState('');
    // フィルターモード
    const [isOpenFilterRow, setIsOpenFilterRow] = useState(false);
    // 新規追加
    const [isOpenAddRecord, setIsOpenAddRecord] = useState(false);
    // 診療時間リスト-有効期間で降順ソート
    // const sortedClinicTimeList = clinicScheduleList.sort((a, b) => b.end_date - a.end_date)

    // バリデーションアラートの開閉
    const [openValidateRule, setOpenValidateRule] = useState(false)
    const [confirmed, setConfirmed] = useState(false)

    function toggleRow(
        id: string,
        setSelection: React.Dispatch<React.SetStateAction<string[]>>
    ) {
        setSelection((current) =>
            current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
        )
    }

    function toggleAll(
        items: any[],
        key: string,
        setSelection: React.Dispatch<React.SetStateAction<string[]>>
    ) {
        setSelection((current) => (current.length === items.length ? [] : items.map((item: any) => item[key])));
    }

    function onSort(sortBy: 'created_at' | 'updated_at') {
        if (sortOrder === 'asc') {
            setSortOrder('desc')
        } else {
            setSortOrder('asc')
        }

        setSortBy(sortBy)
    }

    return (
        <>
            <Table>
                <TableHead>
                    <HeadRow>
                        <HeadColCheckbox
                            toggleAll={() => { toggleAll(filteredClinicScheduleList, "clinic_schedule_id", setSelections) }}
                            selections={selections}
                            itemLength={filteredClinicScheduleList?.length}
                        />
                        {
                            scheduleTableHeads.map((item, idx) => {
                                return <HeadCol
                                    key={idx}
                                    item={item.label}
                                    sortOrder={sortOrder}
                                    {...(item.prop === 'created_at' || item.prop === 'updated_at' ? { onClickSort: () => onSort(item.prop as 'created_at' || 'updated_at') } : {})}
                                />
                            })
                        }
                        <HeadColAddButton
                            isOpenAddRow={isOpenAddRecord}
                            isOpenFilterRow={isOpenFilterRow}
                            setIsOpenAddRow={setIsOpenAddRecord}
                            setIsOpenEditRow={setEditing}
                            setIsOpenFilterRow={setIsOpenFilterRow}
                        />
                    </HeadRow>
                </TableHead>
                <TableBody
                    clinic={clinic}
                    scheduleFilterTableDates={scheduleFilterTableDates}
                    deleteClinicSchedule={deleteClinicSchedule}
                    clinicSchedules={filteredClinicScheduleList}
                    selections={selections}
                    setSelections={setSelections}
                    toggleRow={toggleRow}
                    isOpenAddRecord={isOpenAddRecord}
                    setIsOpenAddRecord={setIsOpenAddRecord}
                    editing={editing}
                    setEditing={setEditing}
                    isOpenFilterRow={isOpenFilterRow}
                    setIsOpenFilterRow={setIsOpenFilterRow}
                    confirmed={confirmed}
                    setConfirmed={setConfirmed}
                    openValidateRule={openValidateRule}
                    setOpenValidateRule={setOpenValidateRule}
                />
                {/* <TableBody clinicSchedules={[{ clinic_schedule_id: 123 }]} /> */}

                {/* <TableRows>
                        <TableRow />
                    </TableRow> */}
            </Table>
        </>
    );
}


// ↓↓↓↓↓↓↓テーブルコンポーネントのパーツの定義↓↓↓↓↓↓↓↓
// テーブルアウトラインのスタイルドコンポーネント
function Table({ children }: { children?: ReactNode }): JSX.Element {

    const tableStyle = "w-full text-sm text-left text-gray-500 dark:text-gray-400"

    return <table className={tableStyle}>
        {children}
    </table>
}

// テーブルヘッダーのスタイルドコンポーネント
function TableHead({ children }: { children?: ReactNode }): JSX.Element {
    const tableHeadStyle = "text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
    return <thead className={tableHeadStyle}>
        {children}
    </thead>
}

// 
function HeadRow({ children }: { children?: ReactNode }): JSX.Element {
    return (
        <tr>
            {children}
        </tr>
    )
}

function HeadCol({ item, onClickSort, sortOrder }: { item: String, onClickSort?: () => void, sortOrder: 'asc' | 'desc' }): JSX.Element {
    return (
        <th
            scope="col"
            onClick={() => onClickSort && onClickSort()}
        >
            <div className="flex">
                {item}
                {
                    onClickSort &&
                    <span>
                        {sortOrder === 'asc' ? <span className="ml-2">▲</span> : <span className="ml-2">▼</span>}
                    </span>
                }
            </div>
        </th>
    )
}


interface HeadColCheckboxType {
    toggleAll: () => void,
    selections: string[],
    itemLength: number
}

/* １列目:チェックボックス */
/* <th style={{ width: rem(40) }}> */
function HeadColCheckbox({
    toggleAll,
    selections,
    itemLength
}: HeadColCheckboxType): JSX.Element {
    return <th scope="col" className="w-4 p-4">
        <input
            className="cursor-pointer"
            type="checkbox"
            onChange={() => toggleAll()}
            checked={selections.length === itemLength}
        />
    </th>
}

interface HeadColAddButtonType {
    isOpenAddRow: boolean,
    setIsOpenAddRow: React.Dispatch<React.SetStateAction<boolean>>,
    setIsOpenEditRow: React.Dispatch<React.SetStateAction<string>>,
    isOpenFilterRow: boolean,
    setIsOpenFilterRow: React.Dispatch<React.SetStateAction<boolean>>,
}

/* 新規追加ボタン */
function HeadColAddButton({
    isOpenAddRow,
    setIsOpenAddRow,
    setIsOpenEditRow,
    isOpenFilterRow,
    setIsOpenFilterRow,
}: HeadColAddButtonType): JSX.Element {

    const isActiveStyle = "bg-red-600"
    const isInActiveStyle = "bg-gray-600"

    const dynamicStyleAddRow = isOpenAddRow ? isActiveStyle : isInActiveStyle
    const dynamicStyleFilterRow = isOpenFilterRow ? isActiveStyle : isInActiveStyle
    const staticStyle = "text-xs p-0"

    return <th scope="col">
        <div className="flex">
            <p
                className={deleteBtnCss + ' ' + staticStyle + ' ' + dynamicStyleAddRow}
                onClick={() => toggleRowState(setIsOpenAddRow, setIsOpenEditRow, setIsOpenFilterRow, true)}
            >
                新規追加
            </p>
            <p
                className={deleteBtnCss + ' ' + staticStyle + ' ' + dynamicStyleFilterRow}
                onClick={() => toggleRowState(setIsOpenAddRow, setIsOpenEditRow, setIsOpenFilterRow, false)}
            >
                フィルター
            </p>
        </div>
    </th>
}

// // テーブルボディのスタイルドコンポーネント
// function TableBody({children}: { children?: ReactNode }): JSX.Element{
//     return <tbody className="text-xs">
//          {children}
//      </tbody>
//  }

interface TableBodyType {
    scheduleFilterTableDates: FilterDatesType[]
    clinic: ClinicType
    deleteClinicSchedule: deleteClinicScheduleType
    clinicSchedules: ClinicSchedule[],
    toggleRow: (id: string, setSelection: React.Dispatch<React.SetStateAction<string[]>>) => void,
    selections: string[]
    setSelections: React.Dispatch<React.SetStateAction<string[]>>
    isOpenAddRecord: boolean,
    editing: string,
    setIsOpenAddRecord: React.Dispatch<React.SetStateAction<boolean>>,
    isOpenFilterRow: boolean,
    setIsOpenFilterRow: React.Dispatch<React.SetStateAction<boolean>>,
    setEditing: React.Dispatch<React.SetStateAction<string>>
    confirmed: boolean
    setConfirmed: React.Dispatch<React.SetStateAction<boolean>>
    openValidateRule: boolean
    setOpenValidateRule: React.Dispatch<React.SetStateAction<boolean>>
}

// テーブルレコードのラッパー
function TableBody({
    scheduleFilterTableDates,
    clinic,
    deleteClinicSchedule,
    clinicSchedules,
    toggleRow,
    selections,
    setSelections,
    isOpenAddRecord,
    editing,
    setIsOpenAddRecord,
    setEditing,
    isOpenFilterRow,
    setIsOpenFilterRow,
    confirmed,
    setConfirmed,
    openValidateRule,
    setOpenValidateRule
}: TableBodyType): JSX.Element {
    return <tbody className="text-xs">
        {// フィルターボタンを押したときに表示されるフォーム
            // 新規追加or編集ボタンクリック時には表示しない
            isOpenFilterRow &&
            editing === '' &&
            isOpenAddRecord === false &&
            //FilterRow
            <FilterRow
                tableHeads={scheduleFilterTableHeads}
                tableDates={scheduleFilterTableDates}
            />
        }
        {
            // 新規追加ボタンを押したときに表示されるフォーム
            isOpenAddRecord &&
            <UpsertScheduleViews
                setIsOpenAddRecord={setIsOpenAddRecord}
                setEditing={setEditing}

            />
        }
        {
            clinicSchedules?.map((clinicSchedule, index) => {
                return (
                    <>
                        {
                            // 編集ボタンを押したときに表示されるフォーム
                            editing === clinicSchedule.clinic_schedule_id ?
                                <UpsertScheduleViews
                                    setIsOpenAddRecord={setIsOpenAddRecord}
                                    setEditing={setEditing}
                                    clinicSchedule={clinicSchedule}
                                /> :
                                // 通常のテーブルレコード
                                <BodyRow
                                    key={index}
                                    clinic={clinic}
                                    deleteClinicSchedule={deleteClinicSchedule}
                                    clinicSchedule={clinicSchedule}
                                    toggleRow={() => toggleRow(clinicSchedule.clinic_schedule_id, setSelections)}
                                    tableHeads={scheduleTableHeads}
                                    selection={selections}
                                    setEditing={setEditing}
                                    confirmed={confirmed}
                                    setConfirmed={setConfirmed}
                                    openValidateRule={openValidateRule}
                                    setOpenValidateRule={setOpenValidateRule}
                                />
                        }
                    </>
                )
            })
        }
        {/* {clinicSchedules.length === 0 &&
            // テーブルレコードがないときに表示される
            <tr>
                <td></td>
            </tr>
        } */}
    </tbody>
}

interface BodyRowType {
    clinic: ClinicType,
    deleteClinicSchedule: deleteClinicScheduleType
    clinicSchedule: ClinicSchedule,
    tableHeads: TableHeadsType
    toggleRow: () => void,
    selection: string[],
    setEditing: React.Dispatch<React.SetStateAction<string>>
    confirmed: boolean,
    setConfirmed: React.Dispatch<React.SetStateAction<boolean>>,
    openValidateRule: boolean,
    setOpenValidateRule: React.Dispatch<React.SetStateAction<boolean>>
}

function BodyRow({
    clinic,
    deleteClinicSchedule,
    clinicSchedule,
    tableHeads,
    toggleRow,
    selection,
    setEditing,
    confirmed,
    setConfirmed,
    openValidateRule,
    setOpenValidateRule
}: BodyRowType): JSX.Element {
    const style = "bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
    console.log(Object.entries(clinicSchedule));

    return (
        <tr key={clinicSchedule.clinic_schedule_id} className={style}>
            <td className="w-4 p-4 flex">
                <input
                    className="cursor-pointer"
                    type="checkbox"
                    checked={selection.includes(clinicSchedule.clinic_schedule_id)}
                    onChange={() => toggleRow()}
                />
            </td>
            <td>

            </td>
            {
                // 通常時
                tableHeads.map((head, index) => {
                    return (
                        <>
                            {typeof head.prop !== 'boolean' &&
                                <td key={index}>
                                    {findLabel(optionSlots, head.prop, clinicSchedule[head.prop]) || clinicSchedule[head.prop]}
                                </td>
                            }
                        </>
                    )
                })
            }

            <td>
                <div className="flex gap-2 h-full">
                    {/* 編集&削除ボタン */}
                    <p
                        className={disableBtnCss + ' cursor-pointer'}
                        onClick={() => setEditing(clinicSchedule.clinic_schedule_id)}
                    >
                        編集
                    </p>
                    <p
                        className={`${isSelection(selection, clinicSchedule.clinic_schedule_id) ? deleteBtnCss + ' cursor-pointer' : disableBtnCss})}`}
                        onClick={() => {
                            // ruleTypeが日付指定&診療日がtrueの場合バリデーション
                            if (
                                clinicSchedule.service_day === true &&
                                clinicSchedule.rule_type === ClinicScheduleType.SpecificDates
                            ) {
                                setOpenValidateRule(true)
                                return
                            }

                            // 削除
                            if (isSelection(selection, clinicSchedule.clinic_schedule_id)) {
                                setEditing('')
                                deleteClinicSchedule.mutate({ clinic_id: clinic.clinic_id, clinic_schedule_id: clinicSchedule.clinic_schedule_id })
                            }
                        }
                        }
                    >
                        削除
                    </p>
                </div>
            </td>

            {
                openValidateRule &&
                <DeleteValidateDialog
                    setOpenValidateRule={setOpenValidateRule}
                    ruleStartDate={clinicSchedule.start_date}
                    clinicStartTime={{ hours: 0, minutes: 0 }}
                    clinicEndTime={{ hours: 0, minutes: 0 }}
                    isUpdate={undefined}
                    confirmed={confirmed}
                    setConfirmed={setConfirmed}
                    deleteClinicTime={() => deleteClinicSchedule.mutate({ clinic_id: clinic.clinic_id, clinic_schedule_id: clinicSchedule.clinic_schedule_id })}
                    setEditing={setEditing}
                />
            }

        </tr>
    )
}

function findLabel(optionSlots: any, optionKey: string, value: any) {
    if (typeof value === 'number') {
        if (value <= 32) {
            return value
        }
        return format(fromUnixTime(value), 'yyyy/MM/dd')
    }
    if (typeof value === 'boolean') {
        return value ? '診察' : '休診'
    }
    const options = optionSlots[optionKey];
    if (!options) {
        return
    }

    const option = options.find((option: any) => option.value === value);
    if (!option) {
        return
    }

    return option.label;
}



// function BodyRow(
//     clinicSchedules, 
//     deleteClinicSchedule,
//     ): JSX.Element {
//     return clinicSchedules.map((rule, index) => {
//         // const selected = selection.includes(clinicTime.config_id);
//         return (<>
//             {/* {editing === clinicTime.config_id ?
//                 : */}
//             <tr key={rule.clinic_schedule_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
//                 {/* １列目・・・チェックボックス */}
//                 <td className="w-4 p-4 flex">
//                     <input
//                         className="cursor-pointer"
//                         type="checkbox"
//                     // checked={selection.includes(clinicTime.config_id)}
//                     // onChange={() => toggleRow(clinicTime.config_id)}
//                     />
//                 </td>
//                 <td>
//                     {index}
//                 </td>
//                 {/* データ部分 */}
//                 <td className="p-4">{rule.config.clinicTime.startTime.hours.toString().padStart(2, '0')}:{rule.config.clinicTime.startTime.minutes.toString().padStart(2, '0')}</td>
//                 <td className="p-4">{rule.config.clinicTime.endTime.hours.toString().padStart(2, '0')}:{rule.config.clinicTime.endTime.minutes.toString().padStart(2, '0')}</td>
//                 {/* <td className="p-4">{format(fromUnixTime(clinicTime.startDate), 'yyyy/MM/dd')}</td>
//                     <td className="p-4">{format(fromUnixTime(clinicTime?.endDate), 'yyyy/MM/dd')}</td> */}
//                 <td className="p-4">
//                     {/* 空白欄 */}
//                 </td>
//                 <td>
//                     <div className="flex gap-2 h-full">
//                         {/* 編集&削除ボタン */}
//                         <p
//                             className={disableBtnCss + ' cursor-pointer'}
//                         // onClick={() => setEditing(clinicTime.config_id)}
//                         >
//                             編集
//                         </p>
//                         <p
//                             className={`${isSelection(selections, clinicTime.config_id) ? deleteBtnCss + ' cursor-pointer' : disableBtnCss})}`}
//                             onClick={() => {
//                                 if (isSelection(selections, clinicTime.config_id)) {
//                                     //削除
//                                     deleteClinicSchedule.mutate({ clinic_id: clinic.clinic_id, config_id: clinicTime.config_id, config_type: 'clinic_time' })
//                                 }
//                             }
//                             }
//                         >
//                             削除
//                         </p>
//                     </div>
//                 </td>
//             </tr>
//             {/* } */}
//         </>
//         );
//     });
// }

type optionSlotsItem = {
    label: string,
    value: string
}
type optionSlotsKeys = 'rule_types' | 'day_of_week' | 'weekSlot' | 'start_time' | 'end_time'
type optionSlotsType = { [key in optionSlotsKeys]: optionSlotsItem[] }


//MEMO: ここから先はWIP--------
// type FormElementType = 'check' | 'select' | 'date'

// function FormElement(
//     type: FormElementType,
//     optionSlots: optionSlotsItem[],
//     state: string | TimeSlotValue,
//     setState: React.Dispatch<React.SetStateAction<any>>,
// ) {
//     return (
//         <>
//             {type === 'select' &&
//                 Pulldown(state, setState, optionSlots)
//             }

//             {
//                 type === 'check' &&
//                 CheckBox(setState)
//             }

//             {
//                 type === 'date' &&
//                 <>
//                     <div></div>
//                     <div></div>
//                 </>
//             }
//         </>
//     )
// }

// function Pulldown(
//     state: string | TimeSlotValue,
//     setState: React.Dispatch<React.SetStateAction<any>>,
//     optionSlots: optionSlotsItem[],
// ): JSX.Element {

//     return (
//         <select
//             onChange={(e) => { setState(JSON.parse(e.currentTarget.value)) }}
//             className="border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//             value={JSON.stringify(state)}
//         >
//             {
//                 optionSlots.map((slot, index) => {
//                     return <option key={slot.label + index} value={JSON.stringify(slot.value)}>{slot.label}</option>
//                 })
//             }
//         </select>
//     )
// }

// function CheckBox(
//     setState: React.Dispatch<React.SetStateAction<any>>,
// ): JSX.Element {

//     return (
//         <input
//             className="cursor-pointer"
//             type="checkbox"
//             onChange={(e) => { setState(e.currentTarget.checked) }}
//         />
//     )
// }

// function DateSelectCalendar(

// ): JSX.Element {

//     return (
//         <>
//         </>
//     )
// }