import { addDays, endOfDay, format, getUnixTime, startOfDay, subDays, addYears, set, addMinutes, fromUnixTime } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"

export const weeks = ['日', '月', '火', '水', '木', '金', '土']

//日付情報のフォーマット yyyy-MM-dd
export function formatDate(date: any, boundary: string = '-'): string {
  return (
    String(date.getFullYear()) +
    boundary +
    (String(Number(date.getMonth()) + 1)).padStart(2, '0') +
    boundary +
    String(date.getDate())
  )
}

//dateを文字列に変換
//yyyy年MM月DD日hours時minutes分
export function japanTimeFormatDate(date: any): string {
  return (
    String(date.getFullYear()) + '年' +
    (String(Number(date.getMonth()) + 1)).padStart(2, '0') + '月' +
    String(date.getDate()) + '日' +
    String(date.getHours()).padStart(2, '0') + '時' +
    String(date.getMinutes()).padStart(2, '0') + '分'
  )
}

export function formatDateForJapan(date: Date) {
  const formattedDate = format(date, 'MM月dd日 yyyy');
  return formattedDate
}

//Dateから0:00分のtimestamp取得 
export function getStartTimeOfToDayTimestamp(nowDate: Date) {
  const utc = zonedTimeToUtc(nowDate, 'Asia/Tokyo')
  const startOfDayDate = startOfDay(utc)
  const startOfDayTimestamp = getUnixTime(startOfDayDate)
  return startOfDayTimestamp;
}

//Dateから23:59分のtimestamp取得 
export function getEndTimeOfToDayTimestamp(nowDate: Date) {
  const utc = zonedTimeToUtc(nowDate, 'Asia/Tokyo')
  const endOfDayDate = endOfDay(utc)
  const endOfDayTimestamp = getUnixTime(endOfDayDate)
  return endOfDayTimestamp;
}

export function addDate(nowDate: Date, setNowDate: React.Dispatch<React.SetStateAction<Date>>) {
  const addedDate = addDays(nowDate, 1);
  setNowDate(addedDate);
}

export function subDate(nowDate: Date, setNowDate: React.Dispatch<React.SetStateAction<Date>>) {
  const subedDate = subDays(nowDate, 1);
  setNowDate(subedDate);
}


// n年後の23:59を取得する関数
export function getNYearsLater(num: number) {
  const now = new Date();
  const targetDate = addYears(now, num);
  const endOfDayTargetDate = endOfDay(targetDate);
  return getUnixTime(endOfDayTargetDate);
};

// n年前の0:00を取得する関数
export function getNYearsAgo(num: number) {
  const now = new Date();
  const targetDate = addYears(now, -num);
  const startOfDayTargetDate = startOfDay(targetDate);
  return getUnixTime(startOfDayTargetDate);
};

// 日付情報のフォーマット yyyy-MM-dd-HH:MM
export function formatDateWithTime(date: any, boundary: string = '-'): string {
  const tmpDate: string = formatDate(date, boundary)
  return (
    tmpDate +
    ' ' +
    String(date.getHours()).padStart(2, '0') +
    ':' +
    String(date.getMinutes()).padStart(2, '0')
  )
}

//timestampにXX分を加算して返す
export function getEndTime(startTime: number, minutes: number): number {
  const endTime = addMinutes(fromUnixTime(startTime), minutes)
  return getUnixTime(endTime)
}

// メニューの診療時間+シフト枠のタイムスタンプから診療終了時間を取得する
export function getShiftEndTime(
  currentShifttTimestamp: number,
  treatmentMinutes: number
) {
  const currentShiftDate = fromUnixTime(currentShifttTimestamp)
  const currentShiftEndDate = addMinutes(currentShiftDate, treatmentMinutes);
  const currentShiftEndTimestamp = getUnixTime(currentShiftEndDate);
  return currentShiftEndTimestamp;
}