import React from 'react';
import { ResponsibleCardPresenterType } from '../utils/common/type';
import { EditDeleteModalsType, OutlineType, SortButtonType, SortButtonsWrapperType, StaffCardButtonsType, StaffIconType, StaffInfoType } from '../utils/components/parts';

export const ResponsibleCardPresenter: React.FC<ResponsibleCardPresenterType> = ({
  currentManeger,
  setDeleteCurrentManeger,
  lastIndexNum,
  updateManegerIndexExecute,
  editIsOpen,
  setEditIsOpen,
  ResponsibleEditModalViews,
  isOpen,
  setIsOpen,
  ResponsibleDeleteModalViews,
  setEditCurrentManeger,
  openDialog,
}) => {
  return (
    <Outline>
      <SortButtonsWrapper
        currentManeger={currentManeger}
        updateManegerIndexExecute={updateManegerIndexExecute}
        lastIndexNum={lastIndexNum}
      />
      <StaffCardOutline>
        <StaffIcon currentManeger={currentManeger} />
        <StaffInfo currentManeger={currentManeger} />
        <StaffCardButtons 
        setEditIsOpen={setEditIsOpen}
        setEditCurrentManeger={setEditCurrentManeger}
        currentManeger={currentManeger}
        openDialog={openDialog}
        setIsOpen={setIsOpen}
        setDeleteCurrentManeger={setDeleteCurrentManeger}
        />
      </StaffCardOutline>
      <EditDeleteModals 
      editIsOpen={editIsOpen}
      ResponsibleEditModalViews={ResponsibleEditModalViews}
      currentManeger={currentManeger}
      setEditIsOpen={setEditIsOpen}
      isOpen={isOpen}
      ResponsibleDeleteModalViews={ResponsibleDeleteModalViews}
      setIsOpen={setIsOpen}
      />
    </Outline>
  );
};

// NOTE コンテナ 名前はOutlineでよいのかどうか Containerはmantineやmaterialと競合する可能性もアリ
const Outline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle = 'flex';
  return <div className={outlineStyle}>{children}</div>;
};

const SortButtonsWrapper: React.FC<SortButtonsWrapperType> = ({
  currentManeger,
  updateManegerIndexExecute,
  lastIndexNum,
}) => {
  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col pr-1">
        {currentManeger.index > 1 && <UpperSortButton updateManegerIndexExecute={updateManegerIndexExecute} />}
        {currentManeger.index < lastIndexNum && currentManeger.index !== null && <DownerSortButton updateManegerIndexExecute={updateManegerIndexExecute} />}
        {currentManeger.index === null && <UnknownSortButton updateManegerIndexExecute={updateManegerIndexExecute} />}
      </div>
    </div>
  );
};

const UpperSortButton: React.FC<SortButtonType> = ({updateManegerIndexExecute}) => {
  const iconStyle = 'text-gray-100 h-8 w-8 text-center rounded-full bg-gray-400 hover:bg-gray-800 flex justify-center items-center my-1 cursor-pointer'
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={iconStyle}
    onClick={() => updateManegerIndexExecute(true)}
  >
    <path
      strokeLinecap="round"
      // strokeLinejoin="round"
      d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
  )
}

const DownerSortButton: React.FC<SortButtonType> = ({updateManegerIndexExecute}) => {
  const iconStyle = 'text-gray-100 h-8 w-8 text-center rounded-full bg-gray-400 hover:bg-gray-800 flex justify-center items-center my-1 cursor-pointer'
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={iconStyle}
    onClick={() => updateManegerIndexExecute(false)}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
  )
}

// NOTE 今は使われていない可能性アリ？？？
const UnknownSortButton: React.FC<SortButtonType> = ({updateManegerIndexExecute}) => {
  return (
    <button
    onClick={() => updateManegerIndexExecute()}
    className="text-gray-100 h-8 w-8 text-center rounded-full bg-gray-400 hover:bg-gray-800 flex justify-center items-center my-1 cursor-pointer"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
    </svg>
  </button>
  )
}

const StaffCardOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle = 'transition-all duration-400 h-28 w-full flex flex-row box-border border-2 border-gray-700 pt-4 mb-2'
  return (
    <div className={outlineStyle}>
      {children}
    </div>
  );
};

const StaffIcon: React.FC<StaffIconType> = ({ currentManeger }) => {
  const outlineStyle = 'w-1/6 flex justify-center'
  const iconStyle = 'rounded-full w-20 h-20 box-border' 
  return (
    // NOTE divのネストが不要に深くなっている可能性アリ
    <div className={outlineStyle}>
      <div
        className={iconStyle}
        style={{
          backgroundColor:
            currentManeger.color !== undefined && currentManeger.color !== null
              ? currentManeger.color
              : '#fff',
        }}
      ></div>
    </div>
  );
};

const StaffInfo: React.FC<StaffInfoType> = ({currentManeger}) => {
  const outlineStyle = 'w-4/6 flex flex-row box-border pl-4'
  const leftContainerStyle = 'pt-4'
  const rightContainerStyle = 'pt-4 pl-8'
  return (
    <div className={outlineStyle}>
    <div className={leftContainerStyle}>
      <div>名前：{currentManeger.name}</div>
      <div>権限：{currentManeger.rights}</div>
    </div>
    <div className={rightContainerStyle}>
      <div>{currentManeger.role}</div>
      <div>{currentManeger.email}</div>
    </div>
  </div>
  )
}

const StaffCardButtons: React.FC<StaffCardButtonsType> = ({setEditIsOpen, setEditCurrentManeger, currentManeger, openDialog, setIsOpen, setDeleteCurrentManeger}) => {
  const outlineStyle = 'w-1/6 flex justify-center box-border pt-4'
  const buttonStyle = 'leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-28 text-center rounded-2xl bg-gray-700 hover:bg-gray-800'
  return (
    <div className={outlineStyle}>
    <button
      onClick={() => {
        setEditIsOpen(true), setEditCurrentManeger(currentManeger);
      }}
      type="button"
      className={buttonStyle}
    >
      編集
    </button>
    <button
      onClick={() => {
        openDialog(currentManeger, setIsOpen, setDeleteCurrentManeger);
      }}
      type="button"
      className={buttonStyle}
    >
      削除
    </button>
  </div>
  )
}

const EditDeleteModals: React.FC<EditDeleteModalsType> = ({editIsOpen, ResponsibleEditModalViews, currentManeger, setEditIsOpen, isOpen, ResponsibleDeleteModalViews, setIsOpen}) => {
  return (
    <>
    {editIsOpen && (
      <ResponsibleEditModalViews currentManeger={currentManeger} setIsOpen={setEditIsOpen} />
    )}
    {isOpen && (
      <ResponsibleDeleteModalViews currentManeger={currentManeger} setIsOpen={setIsOpen} />
    )}    
    </>
  )
}