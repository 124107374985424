import { MenuType } from "./type";

export function getSortedMenuList(menuLists: MenuType[]): MenuType[] {
  const menuListIndex: MenuType[] = [];
  const menuListIndexNull: MenuType[] = [];
  menuLists.forEach((menu: MenuType) => {
    if (menu.index !== null && menu.index !== undefined) {
      menuListIndex.push(menu);
    } else {
      menuListIndexNull.push(menu);
    }
  });
  menuListIndex.sort((a, b) => a.index - b.index);
  menuListIndexNull.sort((a, b) => a.SDK_ID.localeCompare(b.SDK_ID, 'ja'));
  const returnLists: MenuType[] = menuListIndex.concat(menuListIndexNull);
  return returnLists;
}

export function getLastIndexNumFromMenuList(menuList: MenuType[]): number {
  let lastIndex: number = 0;
  menuList.forEach((menu: MenuType) => {
    if (menu.index !== null && menu.index !== undefined) {
      if (lastIndex < menu.index) {
        lastIndex = menu.index;
      }
    }
  });
  return lastIndex;
}
