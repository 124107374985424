import { useAuth0 } from "@auth0/auth0-react"
import { ScrollArea } from "@mantine/core";
import { useEffect, useState } from "react"
import logo from "@/../public/ds_logo.png"
import { emptyClinic } from "@/components/Common/utils/common/emptyData";
import { ClinicType } from "../../entities";

//FIXME type定義
const APP_METADATA = process.env.VITE_AUTH0_APP_METADATA || '';
interface ClinicSelectionType {
    clinics: ClinicType[]
    matches: boolean
}

//遷移先医院の選択 --- selectClinicステートに代入された時点で処理終了
export const useClinicSelection = (clinic_label: string, org_label: string) => {

    //遷移先の医院ステート
    const [selectClinic, setSelectClinic] = useState(emptyClinic)
    //クリックされた医院 決定ボタンが押されるとselectClinicに代入される
    const [chosenClinic, setChosenClinic] = useState(emptyClinic)

    //userメタデータからclinics取得
    const { user } = useAuth0()
    //FIXME AppMetaDataのtype定義
    const clinics: ClinicType[] = user?.[APP_METADATA].clinics

    // 1:
    //クエリパラメータ(clinic_id, org_id)でclincsから遷移先医院取得、selectClinicに代入
    //リダイレクト後のみ実行される
    useEffect(() => {
        console.log('inner-effect', clinics, clinic_label, org_label)
        //clinics未取得 or クエリパラメータ未取得 or 医院選択済みならリターン
        if (!clinics || !clinic_label || selectClinic.clinic_id) return
        //usersからlabelでfindしてclinicを取得
        //一致した医院をselectClinicに代入
        const _selectClinic = getSelectClinic(clinics, clinic_label, org_label);
        console.log('if-selectClinic', _selectClinic)
        if (_selectClinic) setSelectClinic(_selectClinic)
    }, [clinics, clinic_label])

    // 2:
    //単一医院の場合のみ実行
    //clinics[0]をselectClinicに代入
    useEffect(() => {
        //clinics未取得or複数医院or医院選択済みなら抜ける
        if (clinics?.length !== 1 || selectClinic.clinic_id.length !== 0) return;
        setSelectClinic(clinics[0]);
    }, [clinics])

    // 3:
    //リダイレクト前+複数医院の場合のみ実行
    //遷移先医院を選択するコンポーネントをマウント
    const ClinicSelection: React.FC<ClinicSelectionType> = ({
        matches
    }) => {

        return (
            <div className="w-full h-screen flex flex-col items-center justify-center gap-y-5">
                <h1 className="mr-16">
                    <img src={logo} alt="ロゴ" />
                </h1>
                <h2 className="my-3">{user?.name ? user.name : <span className="opacity-0">ユーザー名</span>}</h2>
                <div className="w-3/5 bg-gray-100 rounded-l overflow-hidden">
                    <ScrollArea style={{ height: matches ? 100 : 300, width: '100%' }}>
                        <div className="w-full flex flex-wrap">
                            {
                                clinics?.map((clinic: any) => (
                                    <div
                                        key={clinic.clinic_id + clinic.org_id}
                                        className={`cursor-pointer bg-gray-100 h-[60px] w-1/3 flex items-center justify-center p-1`}
                                        onClick={() => { setChosenClinic(clinic) }}
                                    >
                                        <p className={`${clinic.clinic_id === chosenClinic.clinic_id ? 'bg-indigo-600 text-white' : 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'} w-full truncate rounded border border-transparent px-2.5 py-1.5 font-medium focus:outline-none w-full h-full flex items-center`}>
                                            {clinic?.clinic_name || clinic.clinic_label}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </ScrollArea>
                </div>

                <div
                    className="w-3/4 flex justify-center"
                    onClick={() => { clinicSubmit(chosenClinic, setSelectClinic) }}>
                    <p className={`${chosenClinic.clinic_id.length > 0 ? 'bg-indigo-600 text-white cursor-pointer' : 'bg-indigo-600 text-white opacity-60 pointer-events-none'} hover:bg-opacity-70 block h-[60px] w-1/3 rounded border border-transparent px-2 py-2 font-medium focus:outline-none w-full h-full flex items-center justify-center`}>
                        決定
                    </p>
                </div>

            </div>
        )
    }

    //戻り値
    return {
        clinics,
        selectClinic,
        ClinicSelection
    }
}

function getSelectClinic(clinics: any, clinic_label: string, org_label: string) {
    const selectClinic = clinics.find((clinic: any) => clinic.clinic_label === clinic_label && clinic.org_label === org_label);
    return selectClinic;
}

function clinicSubmit(
    clinic: ClinicType,
    setSelectClinic: React.Dispatch<React.SetStateAction<ClinicType>>
) {
    setSelectClinic(clinic)
}