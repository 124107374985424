import { ClinicTimeConfigType } from "../../entities";

export function getSortedClinicTimeRules(
    clinicTimeConfigList: ClinicTimeConfigType[],
) {
    const sortedItems = clinicTimeConfigList.sort((a, b) => {
        const aCreatedAt = a.created_at ? Number(a.created_at) : 0;
        const bCreatedAt = b.created_at ? Number(b.created_at) : 0;
        return bCreatedAt - aCreatedAt;
    });

    return sortedItems
}