import React from 'react'
import { MedicalMenuViews } from '@/components/MedicalMenu/components/views/MedicalMenuViews'

export const Menu: React.FC = () => {
  return (
    <>
      <div className="p-5">
        <MedicalMenuViews/>
      </div>
    </>
  )
}
