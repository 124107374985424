import { ClinicListContext } from "@/App"
import { SetDateContext } from "@/components/Main/provider/MainProvider"
import { AllPanesContext } from "@/components/Main/provider/PanesProvider"
import { useViewportSize } from "@mantine/hooks"
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'
import { useLocation, useNavigate } from "@tanstack/react-location"
import { getUnixTime } from "date-fns"
import { useContext } from "react"
import { CommonHeaderPresenter } from "../presenter/CommonHeaderPresenter"

export const CommonHeaderViews: React.FC = () => {

    const navigate = useNavigate()
    const clinicList = useContext(ClinicListContext)
    const viewSizes = useViewportSize();
    const { subPanesRef } = useContext(AllPanesContext)
    const { nowDate } = useContext(SetDateContext)
    const location = useLocation()
    const pathName = location.current.pathname
    const isSettings = pathName.includes('/settings');

    const menuItems = [
        {
            label: '患者一覧',
            to: `patients-list?calendar-date=${getUnixTime(nowDate)}`,
            icon: <GroupAddOutlinedIcon />
        },
        {
            label: '予約一覧',
            to: `appoints-list?calendar-date=${getUnixTime(nowDate)}`,
            icon: <PostAddOutlinedIcon />
        }
    ]

    return (
        <CommonHeaderPresenter
            isSettings={isSettings}
            clinicList={clinicList}
            navigate={navigate}
            subPanesRef={subPanesRef}
            viewSizes={viewSizes}
            menuItems={menuItems}
        />
    )
}
