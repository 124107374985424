import { HeaderPresenter } from "@/components/Main/components/panes/LeftPane/Header/HeaderPresenter"
import { AllPanesContext } from "@/components/Main/provider/PanesProvider"
import { useLoadRoute, useLocation } from "@tanstack/react-location";
import { useContext } from "react"

export const HeaderViews: React.FC = () => {
    const { panesRef } = useContext(AllPanesContext);

    return (
        <HeaderPresenter panesRef={panesRef} />
    )
}
