import { DeleteAppointType } from "@/components/Common"
import { NavigateType } from "@tanstack/react-location"
import { UseFormReturn, useForm, useFormContext } from "react-hook-form"
import { AddAppointDefaultValuesType, ReserveType } from "../../entities"
import { AddNewAppoint } from "./parts/AddNewAppoint"
import { UpdateAppoint } from "./parts/UpdateAppoint"
import { useContext, useState } from "react"
import { getUnixTime } from "date-fns"
import { SetDateContext } from "@/components/Main/provider/MainProvider"
import { PatientDetailLinkButton } from "../parts/AppointDetail"
import { $PatientRemarksLabel } from "../parts/AppointDetail"
import { useSetAppointData } from "../../hooks"
import { AppointV2 } from "@/_graphql/graphql-client"
import { AnyObject } from "yup/lib/types"

interface AppointFormPresenterType {
    operation: 'add' | 'reference' | 'edit' | 'copy' | 'update' | undefined
    watch: any
    register: any
    getValues: any
    methods: UseFormReturn<AddAppointDefaultValuesType, any>

    navigate: NavigateType

    formData: any
    appoint: AppointV2

    displayPatientName: string
    patientRemarks: string

    menuList: any
    categoryList: AnyObject
    manegerListsIncludeDelete: any
    menuListIncludeDelete: any
    facilityList: any

    nowDate: Date
    startTime: number
    endTime: number
    patientId: string
    facilityId: string

    openCheckUpdateDialog: boolean
    setOpenCheckUpdateDialog: any

    openSearchPatientModal: boolean
    setOpenSearchPatientModal: any
    setOpenAddPatientModal: any

    openMedicalCategoryModal: boolean
    setOpenMedicalCategoryModal: any

    setIsOpenDeleteModal: () => void
}

export const AppointFormPresenter: React.FC<AppointFormPresenterType> = ({
    watch,
    register,
    getValues,
    methods,
    formData,
    operation,

    navigate,

    displayPatientName,
    patientRemarks,

    menuList,
    categoryList,
    manegerListsIncludeDelete,
    menuListIncludeDelete,
    facilityList,

    startTime,
    endTime,
    patientId,
    facilityId,

    openSearchPatientModal,
    setOpenSearchPatientModal,
    setOpenAddPatientModal,

    openMedicalCategoryModal,
    setOpenMedicalCategoryModal,


    setIsOpenDeleteModal
}) => {

    const { nowDate } = useContext(SetDateContext);

    //menu.indexで昇順ソート
    const sortedMenuList = menuList.sort((a: any, b: any) => a.index - b.index);
    //category.indexで昇順ソート
    const sortedCategoryList = categoryList.sort((a: any, b: any) => a.index - b.index);

    return (
        <>
            <div className="flex items-center justify-between gap-x-2">
                {/* 患者詳細リンク */}
                <PatientDetailLinkButton
                    onNavigate={() => {
                        navigate({ to: `/main/patients-list/patient-info?calendar-date=${getUnixTime(nowDate)}&patient-id=${patientId}` })
                    }}
                />

                {patientRemarks &&
                    <$PatientRemarksLabel
                        label={patientRemarks}
                    />
                }
            </div>

            {/* フォーム */}
            {
                <>
                    {
                        operation === 'add' &&
                        <AddNewAppoint
                            watch={watch}
                            register={register}
                            methods={methods}
                            formData={formData}

                            displayPatientName={displayPatientName}

                            menuList={sortedMenuList}
                            categoryList={sortedCategoryList}
                            manegerListsIncludeDelete={manegerListsIncludeDelete}
                            menuListIncludeDelete={menuListIncludeDelete}
                            facilityList={facilityList}

                            startTime={startTime}
                            endTime={endTime}
                            facilityId={facilityId}

                            setOpenSearchPatientModal={setOpenSearchPatientModal}
                            openSearchPatientModal={openSearchPatientModal}
                            setOpenAddPatientModal={setOpenAddPatientModal}

                            openMedicalCategoryModal={openMedicalCategoryModal}
                            setOpenMedicalCategoryModal={setOpenMedicalCategoryModal}
                        />
                    }

                    {
                        ((operation === 'reference') || (operation === 'edit') || (operation === 'update') || (operation === 'copy')) &&
                        <UpdateAppoint
                            formData={formData}
                            navigate={navigate}
                            operation={operation}

                            watch={watch}
                            register={register}
                            methods={methods}

                            patientId={patientId}

                            displayPatientName={displayPatientName}

                            facilityList={facilityList}
                            menuList={sortedMenuList}
                            menuListIncludeDelete={menuListIncludeDelete}
                            categoryList={sortedCategoryList}
                            manegerListsIncludeDelete={manegerListsIncludeDelete}

                            startTime={startTime}
                            endTime={endTime}
                            facilityId={facilityId}

                            openMedicalCategoryModal={openMedicalCategoryModal}
                            setOpenMedicalCategoryModal={setOpenMedicalCategoryModal}

                            setIsOpenDeleteModal={setIsOpenDeleteModal}
                        />
                    }
                </>
            }

        </>
    )
}
