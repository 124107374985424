import { FieldValues } from "react-hook-form";

export function getMutationPatient(
    data: FieldValues
) {
    data.address = getNonNullableObject(data.address);
    const nonNullablePatient = getNonNullableObject(data);

    nonNullablePatient.DNumber = getMedicalNumber(nonNullablePatient.DNumber)
    nonNullablePatient.SNumber = getMedicalNumber(nonNullablePatient.SNumber)
    nonNullablePatient.searchFull = getSearchName(nonNullablePatient.name)
    nonNullablePatient.searchKana = getSearchName(nonNullablePatient.kana)

    return nonNullablePatient;
}

export function getNonNullableObject(
    data: FieldValues
) {
    const keys = Object.keys(data);

    const nonNulldableObject = keys.reduce((prev, key) => {
        const values = prev;

        if (data[key] === undefined) return values;
        if (data[key] === null) return values;
        if (data[key] === 0) return values;
        if (data[key].length === 0) return values;
        values[key] = data[key];
        return values;
    }, {} as FieldValues)

    return nonNulldableObject;
}

export function getMedicalNumber(
    number: string | undefined | null
) {
    if (number === null) return 0
    if (number === undefined) return 0;
    return Number(number);
}

export function getSearchName(
    fullName: { last: string, first: string }
) {
    const searchName = `${fullName.last}${fullName.first}`
    return searchName;
}
