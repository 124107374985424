import { DaySelectType } from "@/components/ClinicSettings/entities"
import { optionSlots } from "@/components/ClinicSettings/utils"
import { nullableOptionSlots } from "@/components/ClinicSettings/utils/libs/optionSlots"
import { XCircleIcon } from "@heroicons/react/outline"

// [フォームパーツ]日選択
export function DaySelect({
    day,
    setDay,
    isNullable
}: DaySelectType): JSX.Element {

    const baseOptionSlots = isNullable ? nullableOptionSlots : optionSlots

    return (
        <td>
            <div className="h-full w-full relative flex items-center">
                {/* 日 */}
                {
                    <select
                        className='w-full h-full text-xs'
                        onChange={(e) => {
                            setDay(Number(e.currentTarget.value))
                        }}
                    >
                        {baseOptionSlots.date.map((slot, index) => (
                            <option key={index} value={slot} selected={day === slot}>{slot}</option>
                        ))}
                    </select>
                }
                {((isNullable && day !== null)) &&
                    <XCircleIcon className="h-4 w-4 absolute right-2"
                        onClick={() => {
                            setDay(null)
                        }
                        }
                    />
                }
            </div>
        </td>
    )
}