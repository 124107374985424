import { useEffect, useState } from 'react';
import {
  InputFilterOption,
  InputOrderOption,
  LastEvaluatedKey,
  OrderBy,
  useListPatientsByIdPaginatedQuery,
} from '@/_graphql/graphql-client';
import { type PatientsType } from '../../util/common/type';
import { type OrderAndFilter } from '../OrderAndFilterController';
import { type GraphQLClient } from 'graphql-request';

function getPatients(result: PatientsType[], patients: PatientsType[]): PatientsType[] {
  if (result?.length > 0) {
    return removeDuplicates(result);
  }
  if (patients?.length > 0) {
    return removeDuplicates(patients);
  }
  return [];
}

export function usePatientsPagination(graphqlClient: GraphQLClient, clinic_id: string) {
  // APIから取得したデータを結合した患者リスト
  const [result, setResult] = useState<PatientsType[]>([]);

  // APIのページネーションキー
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<LastEvaluatedKey | null>(null);

  // 検索とソートオプション
  const [orderOption, setOrderOption] = useState<InputOrderOption>({
    orderAsc: true,
    orderBy: OrderBy.KanaName,
  });
  const [filterOption, setFilterOption] = useState<InputFilterOption>({});

  const orderAndFilterController: OrderAndFilter = {
    orderOption,
    setOrderOption,
    filterOption,
    setFilterOption,
  };

  // APIからデータを取得
  const { data, error, isLoading, isFetching, isPreviousData, refetch } =
    useListPatientsByIdPaginatedQuery(
      graphqlClient,
      { clinic_id, lastEvaluatedKey, filterOption, orderOption }
      // { keepPreviousData: true }
    );

  // フェッチ済みのindexを保持
  // const [lastEvaluatedKeys, setLastEvaluatedKeys] = useState([] as LastEvaluatedKey[]);

  useEffect(() => {
    if (isFetching) return;
    // dataが存在し、lastEvaluatedKeyがnullでない場合にデータを保存して次を取得
    if (data?.listPatientsByIdPaginated && data?.listPatientsByIdPaginated.Patients.length > 0) {
      const newPatients = data.listPatientsByIdPaginated.Patients as PatientsType[];

      const newLastEvaluatedKey = data.listPatientsByIdPaginated
        .lastEvaluatedKey as LastEvaluatedKey | null;

      // const exists = checkExsitsKey(lastEvaluatedKeys, newLastEvaluatedKey);
      // console.log('exists', exists);

        setResult((prevPatients) => [...prevPatients, ...newPatients]);

        // setLastEvaluatedKeys((prevLastEvaluatedKeys: LastEvaluatedKey[]) => {
        //   return [...prevLastEvaluatedKeys, newLastEvaluatedKey];
        // });

        if (newLastEvaluatedKey !== null) {
          // console.log(123, removeNullProperties(newLastEvaluatedKey));
          setLastEvaluatedKey(removeNullProperties(newLastEvaluatedKey));
        }
    }
  }, [data, isFetching]);

  // 検索条件が変更されたら、データをリセット
  useEffect(() => {
    setResult([]);
    setLastEvaluatedKey(null);
    // setLastEvaluatedKeys([]);
  }, [filterOption, orderOption]);

  // isFetchingでlastEvaluatedKeyがnullの場合、データをリセット
  useEffect(() => {
    if (isFetching && lastEvaluatedKey === null) {
      setResult([]);
      // setLastEvaluatedKeys([]);
      // setLastEvaluatedKey(null);
    }
  }, [isFetching]);

  const patients = getPatients(result, data?.listPatientsByIdPaginated.Patients as PatientsType[]);
  return {
    patients: patients.filter((e) => {
      return e.status !== 'true';
    }) as PatientsType[],
    setResult,
    orderAndFilterController,
  };
}

function removeNullProperties(obj: LastEvaluatedKey): LastEvaluatedKey {
  return Object.entries(obj).reduce<LastEvaluatedKey>((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      switch (key) {
        case 'id':
        case 'clinicIdWithDeleted': 
        case 'clinic_id':
        case 'kanaName':
        case 'phoneNumber':
          acc[key] = value as string;
          break;
        case 'patientCardNumber':
          acc[key] = value as number;
          break;
      }
    }
    return acc;
  }, {});
}

// function checkExsitsKey(lastEvaluatedKeys, newLastEvaluatedKey) {
//   if (newLastEvaluatedKey === null) return undefined;
//   console.log(lastEvaluatedKeys.length);

//   const exists = lastEvaluatedKeys.find((lastKey) => {
//     return Object.entries(newLastEvaluatedKey).every(([key, value]) => {
//       console.log(key, lastKey[key], value);
//       return lastKey[key] === value;
//     });
//   });
//   return exists;
// }

// PatientsType[] の配列から重複を取り除く関数
function removeDuplicates(patients: PatientsType[]): PatientsType[] {
  // Set を使用して重複を取り除く
  const uniquePatientsSet = new Set(patients.map((patient) => JSON.stringify(patient)));

  // Set から配列に戻す
  const uniquePatientsArray = Array.from(uniquePatientsSet).map((patientString) =>
    JSON.parse(patientString)
  );

  return uniquePatientsArray;
}
