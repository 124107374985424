import { AppointFormPropsType } from "@/components/Reservation/entities";

export const AppointForm: React.FC<AppointFormPropsType> = ({
    children,
    onSubmit
}) => {
    const formStyle = "w-full h-full overflow-scroll px-1.5 py-1.5 flex flex-col justify-between";

    return (
        <form id="main-form" className={formStyle} onSubmit={onSubmit}>
            {children}
        </form>
    )
}
