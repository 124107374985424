import { getActiveClinicTimeConfig } from "@/components/Reservation/utils/common/util";
import { ClinicTimeConfigType, MessageType } from "../../entities"

export function ValidateDeleteRule(
    nowDate: Date,
    clinicTimeList: ClinicTimeConfigType[],
    config_id: string,
    setValidateRule: React.Dispatch<React.SetStateAction<ClinicTimeConfigType>>,
    setOpenValidateRule: React.Dispatch<React.SetStateAction<boolean>>,
) {
    //仮削除
    const deletedTimeList = clinicTimeList.filter((clinicTime) => clinicTime.config_id !== config_id)
    const activeClinicTime = getActiveClinicTimeConfig(nowDate, deletedTimeList);
    setValidateRule(activeClinicTime)
    setOpenValidateRule(true)
}

export function onSubmit(
    isUpdate: boolean | undefined,
    setEditing: React.Dispatch<React.SetStateAction<string>>,
    setOpenValidateRule: React.Dispatch<React.SetStateAction<boolean>>,
    addScheduleSubmit: () => void,
    updateScheduleSubmit: () => void,
) {
    if (isUpdate) {
        // 更新
        updateScheduleSubmit()
    } else if (isUpdate === false) {
        // 登録
        addScheduleSubmit()
    }
    // ダイアログを閉じる
    setOpenValidateRule(false)
    setEditing('')
}

export const messageList: MessageType[] = [
    {
        message: '該当予約をシフト枠から参照出来なくなる可能性があります。',
        isCaution: true
    },
    {
        message: '該当予約は削除されません。',
        isCaution: false
    },
    {
        message: '該当予約の参照は予約一覧から行うことが可能です。',
        isCaution: false
    },
]

export const scheduleMessageList: MessageType[] = [
    {
        message: '休診日に新規予約を登録することが出来なくなります。',
        isCaution: true
    },
    {
        message: '既に登録済みの予約情報の参照・変更は可能です',
        isCaution: false
    },
]