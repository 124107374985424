import React from 'react';
import { Link } from '@tanstack/react-location';
import { PatientsCardPresenterType } from '../util/common/type';
import {
  PatientInfoType,
  PatientsCardButtonsType,
  PatientsCardNumberType,
  PatientsCardOutlineType,
} from '../util/components/parts';

export const PatientsCardPresenter: React.FC<PatientsCardPresenterType> = ({
  isOpen,
  setIsOpen,
  DeletePatientDialog,
  navigations,
  seq,
  nameData,
  data,
  status,
}) => {
  return (
    <PatientsCardOutline status={status}>
      <PatientsCardNumber seq={seq} />
      <PatientInfo nameData={nameData} data={data} />
      <PatientsCardButtons navigations={navigations} />
      {/* 削除ダイアログ */}
      {isOpen && <DeletePatientDialog setIsOpen={setIsOpen} />}
    </PatientsCardOutline>
  );
};

const PatientsCardOutline: React.FC<PatientsCardOutlineType> = ({ children, status }) => {
  const listStyle = 'py-[2px] flex items-center space-x-4'
  return (
    <li
      className={`
  ${status !== null && status !== undefined && !status ? 'hidden' : ''}
      ${listStyle}`}
    >
      {children}
    </li>
  );
};

const PatientsCardNumber: React.FC<PatientsCardNumberType> = ({ seq }) => {
  return <div className="text-xs">{seq + 1}</div>;
};

const PatientInfo: React.FC<PatientInfoType> = ({ nameData, data }) => {
  return (
    <div className="flex-1 min-w-0">
      {nameData.map(({ name, property }, index) => {
        return (
          <span className="text-xs font-medium text-gray-900 truncate dark:text-white" key={index}>
            {`${name} ${property}`}
          </span>
        );
      })}
      {data.map(({ name, property }, index) => {
        return (
          <span
            className="text-xs font-medium text-gray-900 truncate dark:text-white px-3"
            key={index}
          >
            {`${name}`}
            {property !== null ? ` ${property}` : ' 未登録'}
          </span>
        );
      })}
    </div>
  );
};

const PatientsCardButtons: React.FC<PatientsCardButtonsType> = ({ navigations }) => {
  const containerStyle = "flex flex-wrap w-[46%] gap-y-4 gap-x-2 contents-between justify-end px-3"
  const buttonStyle = "h-5 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-xs px-5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
  return (
    <div className={containerStyle}>
      {navigations.map(({ name, path, func }, index) => {
        return (
          <Link
            {...(func !== undefined && {
              onClick: () => {
                func();
              },
            })}
            to={path}
            key={index}
          >
            <button
              type="button"
              className={`${buttonStyle} ${
                // NOTE 230604 詳細と削除でスタイル切り分け pathだと両方trueで切り替えられない？？？
                // path ? 'bg-blue-700 hover:bg-blue-800' : 'bg-orange-700 hover:bg-orange-800'
                name === '詳細'
                  ? 'bg-blue-700 hover:bg-blue-800'
                  : 'bg-orange-700 hover:bg-orange-800'
                }`}
            >
              {name}
            </button>
          </Link>
        );
      })}
    </div>
  );
};
