import { ClinicContext, useGraphqlClient } from "@/App";
import { FacilityViews } from "@/components/Facility/components/views/FacilityViews";
import { FacilityGroupViews } from "@/components/FacilityGroup/components/views/FacilityGroupViews";
import { getOrderedFloorList } from "@/components/Floor/utils/utils";
import { MediaQueryContext, SetDateContext } from "@/components/Main/provider/MainProvider";
import { useFloorList_service } from "@/domain";
import { BasicTabBar } from "@/feature/phr-28/components/views/BasicTabBar"
import { useContext, useState } from "react";

export const FacilityAndFloor: React.FC = () => {

    const graphqlClient = useGraphqlClient();
    const clinic_id = useContext(ClinicContext).clinic_id;
    const allFloorList = useFloorList_service(graphqlClient, { clinic_id })
    const floorList = getOrderedFloorList(allFloorList.floorList)
    const floorListIncludeDelete = getOrderedFloorList(allFloorList.floorListIncludeDelete)

    const tabTitles = ['施設一覧', 'グループ一覧'];
    const isCenter = false;
    const [tabName, setTabName] = useState(tabTitles[0]);
    const { nowDate, setNowDate } = useContext(SetDateContext);
    const matches = useContext(MediaQueryContext)

    return (
        <>
            <BasicTabBar
                tabTitles={tabTitles}
                arrangement={isCenter}
                setTabName={setTabName}
                nowDate={nowDate}
                setNowDate={setNowDate}
                matches={matches}
            />
            {
                tabName === tabTitles[0] &&
                <FacilityViews
                    floorList={floorList}
                    floorListIncludeDelete={floorListIncludeDelete}
                />
            }
            {
                tabName === tabTitles[1] &&
                <FacilityGroupViews
                />
            }
        </>
    )
}
