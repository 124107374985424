import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./index.css";
import "flowbite";
import { Auth0Provider } from "@auth0/auth0-react";

//FIXME type定義
const VITE_AUTH0_DOMAIN = process.env.VITE_AUTH0_DOMAIN || '';
const VITE_AUTH0_CLIENT_ID = process.env.VITE_AUTH0_CLIENT_ID || '';

ReactDOM.createRoot(document.getElementById("root") as Element).render(
  <React.StrictMode>
    <Auth0Provider
      domain={VITE_AUTH0_DOMAIN}
      clientId={VITE_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
    // useRefreshTokens={true}
    // cacheLocation="localstorage"
    // onRedirectCallback={(appState) =>
    //   location.href = appState?.returnTo || ''
    // }
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
