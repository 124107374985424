import { GraphQLClient } from 'graphql-request'
import { useListPatientsV2Query } from '@/_graphql/graphql-client'
import { usePatientListVariablesType } from '@/domain/Patient/entities/types';

// FIXME: MapperはEntity
import { patientListMapper } from '@/domain/Patient/services/mapper/patientListMapper';

export const usePatientList_service = (
    graphqlClient: GraphQLClient,
    variables: usePatientListVariablesType
) => {
    const patients = useListPatientsV2Query(graphqlClient, variables);
    const allPatientsList = patientListMapper(patients)
    return allPatientsList;
}
