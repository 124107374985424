import { listManegerType } from '@/components/Patient/components/util/common/type';

interface SelectStaffProps {
    id: string;
    registerProps: {
        name: string;
        ref: React.Ref<any>;
    };
    manegerListIncludeDelete: listManegerType[];
    selectedStaffId: string;
}

export const SelectStaff: React.FC<SelectStaffProps> = ({
    id,
    registerProps,
    manegerListIncludeDelete,
    selectedStaffId,
}) => {
    const divStyle = "flex flex-col";
    const selectBoxStyle = "text-xs cursor-pointer py-1 w-full inline-flex items-center justify-end text-right rounded-sm border-gray-50 text-gray-900 text-xl bg-gray-200";

    // 全ての管理者をオプションとして返却し、削除された管理者で選択されていないものは隠す
    const managerOptions = manegerListIncludeDelete.map((staff, index) => {
        // 削除されたスタッフで、選択されていない場合は隠す
        const isHidden = staff.isDelete && staff.id !== selectedStaffId;
        const optionClass = isHidden ? "hidden" : "";
        // 削除されたスタッフで選択されている場合は名前の後ろに (削除済み) を追加
        const displayName = staff.isDelete && staff.id === selectedStaffId ? `${staff.name} (削除済み)` : staff.name;

        return (
            <option value={staff.id} key={index} className={optionClass}>
                {displayName}
            </option>
        );
    });

    return (
        <div className={divStyle}>
            <select
                id={id}
                {...registerProps}
                className={selectBoxStyle}
            >
                <option value='0'>未設定</option>
                {managerOptions}
            </select>
        </div>
    );
};


