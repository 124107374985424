import { StaffNameType, StaffType } from "@/components/Staff/entities"
import { UseQueryResult } from "react-query"
import { ListManegerQuery, ListManegerNameQuery } from "@/domain/Staff/entities/types"

export const staffListMapper = (
    staffs: UseQueryResult<ListManegerQuery, unknown>
) => {
    const staffList: StaffType[] = []
    const staffListIncludeDelete: StaffType[] = []

    if (staffs.isSuccess) {
        staffs.data.listManeger.Manegers.forEach((staff: any) => {
            if (staff.isDelete !== true) {
                staffList.push(staff)
            }
            staffListIncludeDelete.push(staff)
        })
    }

    return {
        staffList,
        staffListIncludeDelete
    }
}

export const staffNameListMapper = (
    staffNames: UseQueryResult<ListManegerNameQuery, unknown>
) => {
    const staffNameList: StaffNameType[] = []

    if (staffNames.isSuccess) {
        staffNames.data.listManeger.Manegers.forEach((staffName: any) => {
            staffNameList.push(staffName)
        })
    }

    return staffNameList
}