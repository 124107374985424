import React from 'react';
import { Appoints } from '@/feature/phr-28/components/common/type';
import { AppointmentListPresenterType } from '../utils/common/type';
import { AppointmentCardViews } from '../views/AppointmentCardViews';
import { AppointmentOptionsViews } from '../views/AppointmentOptionsViews';
import { AppointmentCardProviderType } from '../utils/components/parts';
import { OutlineType } from '@/components/Common/entities/parts';
import { AppointDetails } from '@/_graphql/graphql-client';

export const AppointmentListPresenter: React.FC<AppointmentListPresenterType> = ({
  appointFilter,
  setAppointFilter,
  setDateSort,
  dateSort,
  keyword,
  setKeyword,
  setStartDate,
  setEndDate,
  setIsDaily,
  finalFilteredReservationLists,
  nowDate,
  setNowDate,
}) => {
  return (
    <Outline>
      <AppointmentOptionsViews
        appointFilter={appointFilter}
        setAppointFilter={setAppointFilter}
        setDateSort={setDateSort}
        dateSort={dateSort}
        keyword={keyword}
        setKeyword={setKeyword}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setIsDaily={setIsDaily}
      />
      <AppointmentCardProvider
        finalFilteredReservationLists={finalFilteredReservationLists}
        nowDate={nowDate}
        setNowDate={setNowDate}
      />
    </Outline>
  );
};

const Outline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'w-full h-full content-none'
  const innerOutlineContainerStyle = 'px-8 max-w-screen-lg max-h-screen-lg w-full h-full overflow-auto'
  return (
    <div className={outlineContainerStyle}>
      <div className={innerOutlineContainerStyle}>
        {children}
      </div>
    </div>
  );
};

const AppointmentCardProvider: React.FC<AppointmentCardProviderType> = ({
  finalFilteredReservationLists,
  nowDate,
  setNowDate,
}) => {
  const appointCardProviderOutlineStyle = 'flow-root w-full h-full divide-y divide-gray-200 dark:divide-gray-700 h-44'
  return (
    <div className={appointCardProviderOutlineStyle}>
      {finalFilteredReservationLists.map((list: AppointDetails, index: number) => {
        const {
          id,
          startTime,
          endTime,
          date,
          appoint,
          facilityId,
          appointId,
        } = list;
        return (
          <AppointmentCardViews
            nowDate={nowDate}
            key={index}
            id={id}
            startTime={startTime}
            endTime={endTime}
            facilityId={facilityId}
            appointId={appointId}
            date={date}
            confirmed={{ start: startTime, end: endTime }}
            appointment={appoint}
            reservation={list}
            setTabName={() => { }}
            setNowDate={setNowDate}
            patientName={list.patientName || ''}
          />
        );
      })}
    </div>
  );
};
