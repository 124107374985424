import { useMediaQuery } from "@mantine/hooks";
import { createContext, useState } from "react";
import { MainProviderType, setDateContextType } from "@/components/Main/entities";
import { PaneVisibilityContextType } from "../entities/common/types";

export const MediaQueryContext = createContext(false)
export const SetDateContext = createContext({} as setDateContextType);
export const PaneVisibilityContext = createContext({} as PaneVisibilityContextType);

export const MainProvider: React.FC<MainProviderType> = ({
    children
}) => {
    //選択日のDate
    const [nowDate, setNowDate] = useState(new Date());
    const matches = useMediaQuery('(max-width: 928px)');
    const [isPaneOpen, setIsPaneOpen] = useState(true);

    return (
        <MediaQueryContext.Provider value={matches}>
            <SetDateContext.Provider value={{ nowDate, setNowDate }}>
                <PaneVisibilityContext.Provider value={{ isPaneOpen, setIsPaneOpen }}>
                    {children}
                </PaneVisibilityContext.Provider>
            </SetDateContext.Provider>
        </MediaQueryContext.Provider>
    )
}
