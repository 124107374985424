import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
const VITE_AUDIENCE = process.env.VITE_AUTH0_AUDIENCE;

//トークン発行
export const useAuthToken = (isRedirected: boolean) => {

    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState('')
    //トークンの保持ステート

    useEffect(() => {
        //リダイレクト前なら抜ける（リダイレクト後に再認証するため、リダイレクト前の発行は無意味）
        if (!isRedirected) return
        getAccessTokenSilently({
            audience: VITE_AUDIENCE,
            scope: "read:current_user",
        }).then(res => setToken(res))

    }, [isRedirected])

    //トークン返却
    return {
        token
    }

}
