import { useEffect, useState, useRef } from "react";
import { useQueryParams } from "./useQueryParams";
import { useLocation } from "@tanstack/react-location";

// URLのクエリパラメータを監視するカスタムフック

// URLパラメータが変更された場合に、最新のクエリパラメータの値を返す
// * このhookを使用しないと、URLパラメータの変更をリアクティブに検知できない

export function useWatchQueryParam(key: string) {
    // isWatchフラグの初期値をfalseに設定
    const [isWatch, setIsWatch] = useState(false);
    const location = useLocation();
    const { getQueryParam } = useQueryParams();
    const [paramValue, setParamValue] = useState(getQueryParam(key));

    // 現在のURLのパスを参照として保持
    const currentPathRef = useRef(location.current.pathname);

    useEffect(() => {

        // 現在のパスと前回のパスが異なる場合
        // 参照のみを更新して終了
        if (currentPathRef.current !== location.current.pathname) {
            currentPathRef.current = location.current.pathname;
            return;
        }

        // 現在の参照を更新
        setParamValue(getQueryParam(key));
        currentPathRef.current = location.current.pathname;

    }, [location.current.search[key], key, getQueryParam]);

    return paramValue;
}
