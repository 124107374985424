import React from 'react'
import { listManegerType, MenuType, CategoryType } from '@/feature/phr-28/components/common/type'
import { AddPatientModal } from '@/feature/phr-29/components/Patients/AddPatientModal'
import { ReserveType } from '../../entities'
import { SearchType } from '@/components/Common/entities'
import { AppointFormViews } from './AppointFormViews'
import { UseQueryResult } from 'react-query'
import { AppointV2, GetAppointByIdV2Query, GetAppointQuery } from '@/_graphql/graphql-client'
import { FacilityType } from '@/components/Facility/entities'
import { PatientsType } from '@/components/Patient/components/util/common/type'

interface AddAppointPresenterType {
    newPatient: PatientsType
    setNewPatient: React.Dispatch<React.SetStateAction<PatientsType>>
    nowDate: Date
    isBeforeDay: boolean
    appoint: AppointV2
    req: UseQueryResult<GetAppointByIdV2Query, unknown>
    operation: 'add' | 'reference' | 'edit' | 'copy' | 'update' | undefined

    openAddPatientModal: boolean
    setOpenAddPatientModal: React.Dispatch<React.SetStateAction<boolean>>
    openSearchPatientModal: boolean
    setOpenSearchPatientModal: React.Dispatch<React.SetStateAction<boolean>>
    search: SearchType
    patientId: string
    startTime: number
    endTime: number
    facilityId: string
    appointId: string
    facilityList: FacilityType[]
    menuList: MenuType[]
    menuListIncludeDelete: MenuType[]
    categoryList: CategoryType[]
    manegerLists: listManegerType[]
    manegerListsIncludeDelete: listManegerType[]
}

export const AddAppointPresenter: React.FC<AddAppointPresenterType> = ({
    newPatient,
    setNewPatient,

    nowDate,
    isBeforeDay,

    appoint,
    req,
    operation,

    openAddPatientModal,
    setOpenAddPatientModal,
    openSearchPatientModal,
    setOpenSearchPatientModal,

    search,
    patientId,
    startTime,
    endTime,
    facilityId,
    facilityList,
    appointId,

    menuList,
    menuListIncludeDelete,
    categoryList,
    manegerLists,
    manegerListsIncludeDelete,
}) => {

    return (
        <>
            {/* コンテンツ */}
            <AppointFormViews
                // 新規追加患者
                newPatient={newPatient}

                // 選択日付
                nowDate={nowDate}
                // 過去日付判定
                isBeforeDay={isBeforeDay}

                // URLパラメータ
                search={search}
                patientId={patientId}
                startTime={startTime}
                endTime={endTime}
                facilityId={facilityId}
                appointId={appointId}

                // データ
                appoint={appoint}
                req={req}
                operation={operation}

                menuList={menuList}
                menuListIncludeDelete={menuListIncludeDelete}
                categoryList={categoryList}
                manegerLists={manegerLists}
                manegerListsIncludeDelete={manegerListsIncludeDelete}
                facilityList={facilityList}

                // 患者検索モーダル+患者新規追加モーダル
                setOpenAddPatientModal={setOpenAddPatientModal}
                openSearchPatientModal={openSearchPatientModal}
                setOpenSearchPatientModal={setOpenSearchPatientModal}
            />

            {/* 患者新規追加モーダル -------------------------- */}
            {
                openAddPatientModal &&
                <AddPatientModal
                    setIsOpen={setOpenAddPatientModal}
                    modalOptions={{ setOpenSelectPatientModal: setOpenSearchPatientModal, setNewPatient }}
                />
            }
        </>
    )
}