import { FacilityView } from '@/feature/phr-28/components/views/FacilityView'

export const Facility: React.FC = () => {
  return (
    <>
      <div className="p-5">
        <FacilityView />
      </div>
    </>
  )
}
