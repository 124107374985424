import React, { Component } from "react";

interface ErrorBoundaryType {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

// NOTE: クラスコンポーネント推奨とのことなのでそのように実装
// 一応React.16.8以降は関数コンポーネントでも実装可能とのこと
export class ErrorBoundary extends Component<ErrorBoundaryType, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryType) {
        super(props);
        this.state = { hasError: false };
    }

    // 例外処理のロジック
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        alert("エラーが発生しました.")
        this.setState({ hasError: true });
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // エラーが発生した場合に表示されるコンポーネント
            return (
                <div className="bg-white text-red text-xs font-bold">
                    エラーが発生しました
                </div>
            );
        }
        // エラーが発生しなかった場合はネストした子コンポーネントを表示
        return this.props.children;
    }
}
