import { BlockType } from "@/components/Block/entities";
import { UseQueryResult } from "react-query";
import { ScanPeriodBlockQuery } from "@/domain/Block/entities/types";
import { GetBlockQuery } from "@/_graphql/graphql-client";
import { emptyBlock } from "@/components/Common/utils/common/emptyData";

export const BlockMapper = (
    block: UseQueryResult<GetBlockQuery, unknown>
) => {
    let mappedBlock = emptyBlock;

    if (block.isSuccess) {
        const { data } = block;
        mappedBlock = Object.assign(emptyBlock, data.getBlock);
    }

    return mappedBlock;
}

export const BlockListMapper = (
    blocks: UseQueryResult<ScanPeriodBlockQuery, unknown>
) => {
    const blockingLists: BlockType[] = [];

    if (blocks.isSuccess) {
        const { data } = blocks;
        data.scanPeriodBlock.AppointBlocks?.forEach(((block: any) => {
            if (block?.isDelete === false) {
                blockingLists.push(block)
            }
        }))
    }

    return blockingLists;
}