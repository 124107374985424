import React from 'react';
import { ResponsibleEditModalPresenterType } from '../utils/common/type';
import {
  CloseEditModalButtonType,
  EditModalFormColorItemType,
  EditModalFormEmailItemType,
  EditModalFormNameItemType,
  EditModalFormRightsItemType,
  EditModalFormRoleItemType,
  EditModalFormSubmitButtonType,
  OutlineType,
} from '../utils/components/parts';

export const ResponsibleEditModalPresenter: React.FC<ResponsibleEditModalPresenterType> = ({
  setIsOpen,
  updateManegerData,
  tmpUpdateData,
  setUpdateManegerData,
  medicalRole,
  rights,
  determineRegisterable,
  disableCss,
  registerCss,
  updateManeger,
}) => {
  return (
    <Outline>
      <EditModalTitle />
      <CloseEditModalButton setIsOpen={setIsOpen} />
      <EditModalFormOutline>
        <EditModalFormRoleItem
          tmpUpdateData={tmpUpdateData}
          setUpdateManegerData={setUpdateManegerData}
          medicalRole={medicalRole}
          updateManegerData={updateManegerData}
        />
        <EditModalFormNameItem
          updateManeger={updateManeger}
          updateManegerData={updateManegerData}
          tmpUpdateData={tmpUpdateData}
          setUpdateManegerData={setUpdateManegerData}
        />
        <EditModalFormRightsItem
          tmpUpdateData={tmpUpdateData}
          setUpdateManegerData={setUpdateManegerData}
          rights={rights}
          updateManegerData={updateManegerData}
        />
        <EditModalFormEmailItem
          updateManegerData={updateManegerData}
          tmpUpdateData={tmpUpdateData}
          setUpdateManegerData={setUpdateManegerData}
        />
        <EditModalFormColorItem
          updateManegerData={updateManegerData}
          tmpUpdateData={tmpUpdateData}
          setUpdateManegerData={setUpdateManegerData}
        />
        <EditModalFormSubmitButton
          determineRegisterable={determineRegisterable}
          disableCss={disableCss}
          registerCss={registerCss}
          updateManeger={updateManeger}
          setIsOpen={setIsOpen}
        />
      </EditModalFormOutline>
    </Outline>
  );
};

// MEMO AddとEditは同じような小コンポーネントになる 将来的に共通化するのかどうか

// NOTE コンテナ 名前はOutlineでよいのかどうか Containerはmantineやmaterialと競合する可能性もアリ
const Outline: React.FC<OutlineType> = ({ children }) => {
  const id = 'overlay';
  const outlineStyle =
    'fixed top-0 z-[99] left-0 w-full h-full bg-gray-500 flex align-middle justify-center bg-opacity-50';
  const outlineContainerStyle = 'relative p-4 w-full max-w-md h-full md:h-auto';
  const innerOutlineContainerStyle = 'relative bg-white rounded-lg shadow dark:bg-gray-700';
  return (
    <div id={id} className={outlineStyle}>
      <div className={outlineContainerStyle}>
        <div className={innerOutlineContainerStyle}>{children}</div>
      </div>
    </div>
  );
};

const EditModalTitle = () => {
  return (
    <h3 className="pt-6 px-6 text-xl font-medium text-gray-900 dark:text-white">担当者の編集</h3>
  );
};

const CloseEditModalButton: React.FC<CloseEditModalButtonType> = ({ setIsOpen }) => {
  const buttonStyle = 'absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'
  return (
    <button
      type="button"
      onClick={() => {
        setIsOpen(false);
      }}
      className={buttonStyle}
      data-modal-toggle="authentication-modal"
    >
      <svg
        className="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        ></path>
      </svg>
    </button>
  );
};

const EditModalFormOutline: React.FC<OutlineType> = ({ children }) => {
  return (
    <div className="py-6 px-6 lg:px-8">
      <form className="space-y-6" action="#">
        {children}
      </form>
    </div>
  );
};

const EditModalFormRoleItem: React.FC<EditModalFormRoleItemType> = ({
  tmpUpdateData,
  setUpdateManegerData,
  medicalRole,
  updateManegerData,
}) => {
  const id = 'roles'
  const labelStyle = 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400'
  const selectStyle = 'w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
  return (
    <div>
      <label
        htmlFor={id}
        className={labelStyle}
      >
        役割
      </label>
      <select
        id={id}
        className={selectStyle}
        onChange={(e) => {
          tmpUpdateData.role = e.target.value;
          setUpdateManegerData(tmpUpdateData);
        }}
      >
        <option hidden></option>
        {medicalRole.map((role: string, index: number) => (
          <option value={role} key={index} selected={updateManegerData.role === role ?? false}>
            {role}
          </option>
        ))}
      </select>
    </div>
  );
};

const EditModalFormNameItem: React.FC<EditModalFormNameItemType> = ({
  updateManeger,
  updateManegerData,
  tmpUpdateData,
  setUpdateManegerData,
}) => {
  const id = 'name'
  const labelStyle = 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
  const selectStyle = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
  return (
    <div>
      <label
        htmlFor={id}
        className={labelStyle}
      >
        名前
      </label>
      <input
        type="text"
        name="name"
        id={id}
        value={updateManegerData.name}
        className={selectStyle}
        placeholder="名前を入力して下さい"
        required
        onChange={(e) => {
          tmpUpdateData.name = e.target.value;
          setUpdateManegerData(tmpUpdateData);
        }}
      />
    </div>
  );
};

const EditModalFormRightsItem: React.FC<EditModalFormRightsItemType> = ({
  tmpUpdateData,
  setUpdateManegerData,
  rights,
  updateManegerData,
}) => {
  const id = 'rights'
  const labelStyle = 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400'
  const selectStyle = 'w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
  return (
    <div>
      <label
        htmlFor={id}
        className={labelStyle}
      >
        権限
      </label>
      <select
        id={id}
        className={selectStyle}
        onChange={(e) => {
          tmpUpdateData.rights = e.target.value;
          setUpdateManegerData(tmpUpdateData);
        }}
      >
        <option hidden></option>
        {rights.map((right: string, index: number) => (
          <option value={right} key={index} selected={updateManegerData.rights === right ?? false}>
            {right}
          </option>
        ))}
      </select>
    </div>
  );
};

const EditModalFormEmailItem: React.FC<EditModalFormEmailItemType> = ({
  updateManegerData,
  tmpUpdateData,
  setUpdateManegerData,
}) => {
  const id = 'email'
  const labelStyle = 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
  const selectStyle = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
  return (
    <div>
      <label
        htmlFor={id}
        className={labelStyle}
      >
        email
      </label>
      <input
        type="email"
        name="email"
        id={id}
        value={updateManegerData.email}
        className={selectStyle}
        placeholder="name@company.com"
        required
        onChange={(e) => {
          tmpUpdateData.email = e.target.value;
          setUpdateManegerData(tmpUpdateData);
        }}
      />
    </div>
  );
};

const EditModalFormColorItem: React.FC<EditModalFormColorItemType> = ({
  updateManegerData,
  tmpUpdateData,
  setUpdateManegerData,
}) => {
  const id = 'color'
  const labelStyle = 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
  const selectStyle = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
  return (
    <div>
      <label
        htmlFor={id}
        className={labelStyle}
      >
        color
      </label>
      <input
        type="color"
        name="color"
        id={id}
        value={
          updateManegerData.color !== undefined && updateManegerData.color !== null
            ? updateManegerData.color
            : '#fff'
        }
        className={selectStyle}
        required
        onChange={(e) => {
          tmpUpdateData.color = e.target.value;
          setUpdateManegerData(tmpUpdateData);
        }}
      />
    </div>
  );
};

const EditModalFormSubmitButton: React.FC<EditModalFormSubmitButtonType> = ({
  determineRegisterable,
  disableCss,
  registerCss,
  updateManeger,
  setIsOpen,
}) => {
  return (
    <form action="/masta" method="get">
      <button
        disabled={determineRegisterable()}
        type="submit"
        className={determineRegisterable() ? disableCss : registerCss}
        onClick={(e) => {
          e.preventDefault();
          updateManeger();
          setIsOpen(false);
        }}
      >
        登録
      </button>
    </form>
  );
};
