import { ClinicScheduleType, DayOfWeek, Week } from "@/_graphql/graphql-client";
import { eachDayOfInterval, endOfMonth, getDate, startOfMonth } from "date-fns";

// WIP オプション(日)
const currentDate = new Date();
const interval = { start: startOfMonth(currentDate), end: endOfMonth(currentDate) }
const dateSlots = eachDayOfInterval(interval).map(getDate);

export const optionSlots = {
    // オプション（ルール種別）
    rule_type: [
        {
            label: '毎週何曜日',
            value: ClinicScheduleType.Weekly,
        },
        // NOTE:暫定廃止: 20230608~
        // {
        //     label: '毎月何週何曜日',
        //     value: ClinicScheduleType.MonthlyNthWeek,
        // },
        {
            label: '毎月何日',
            value: ClinicScheduleType.MonthlyFixedDay,
        },
        {
            label: '日付指定',
            value: ClinicScheduleType.SpecificDates
        },
        // NOTE:暫定廃止: 202305~
        // {
        //     label: '部分休診',
        //     value: ClinicScheduleType.PartialClosed
        // }
    ],
    // オプション(曜日)
    day_of_week: [
        {
            label: '日',
            value: DayOfWeek.Sunday,
        },
        {
            label: '月',
            value: DayOfWeek.Monday,
        },
        {
            label: '火',
            value: DayOfWeek.Tuesday,
        },
        {
            label: '水',
            value: DayOfWeek.Wednesday,
        },
        {
            label: '木',
            value: DayOfWeek.Thursday,
        },
        {
            label: '金',
            value: DayOfWeek.Friday,
        },
        {
            label: '土',
            value: DayOfWeek.Saturday,
        },
    ],
    // オプション(週)
    // FIXME: 一旦様子見で残す:20230608~
    // *廃止予定
    week: [
        {
            label: '第1週',
            value: Week.First,
        },
        {
            label: '第2週',
            value: Week.Second,
        },
        {
            label: '第3週',
            value: Week.Third,
        },
        {
            label: '第4週',
            value: Week.Fourth,
        },
        {
            label: '第5週',
            value: Week.Fifth,
        },
        {
            label: '最終週',
            value: Week.Last,
        },
    ],
    date: dateSlots
}

export const nullableOptionSlots = {
    // オプション（ルール種別）
    rule_type: [
        {
            label: '未設定',
            value: null,
        },
        {
            label: '毎週何曜日',
            value: ClinicScheduleType.Weekly,
        },
        // NOTE:暫定廃止: 20230608~
        // {
        //     label: '毎月何週何曜日',
        //     value: ClinicScheduleType.MonthlyNthWeek,
        // },
        {
            label: '毎月何日',
            value: ClinicScheduleType.MonthlyFixedDay,
        },
        {
            label: '日付指定',
            value: ClinicScheduleType.SpecificDates
        },
        // NOTE:暫定廃止: 202305~
        // {
        //     label: '部分休診',
        //     value: ClinicScheduleType.PartialClosed
        // }
    ],
    // オプション(曜日)
    day_of_week: [
        {
            label: '未設定',
            value: null,
        },
        {
            label: '日',
            value: DayOfWeek.Sunday,
        },
        {
            label: '月',
            value: DayOfWeek.Monday,
        },
        {
            label: '火',
            value: DayOfWeek.Tuesday,
        },
        {
            label: '水',
            value: DayOfWeek.Wednesday,
        },
        {
            label: '木',
            value: DayOfWeek.Thursday,
        },
        {
            label: '金',
            value: DayOfWeek.Friday,
        },
        {
            label: '土',
            value: DayOfWeek.Saturday,
        },
    ],
    // オプション(週)
    // FIXME: 一旦様子見で残す:20230608~
    // *廃止予定
    week: [
        {
            label: '未設定',
            value: null,
        },
        {
            label: '第1週',
            value: Week.First,
        },
        {
            label: '第2週',
            value: Week.Second,
        },
        {
            label: '第3週',
            value: Week.Third,
        },
        {
            label: '第4週',
            value: Week.Fourth,
        },
        {
            label: '第5週',
            value: Week.Fifth,
        },
        {
            label: '最終週',
            value: Week.Last,
        },
    ],
    date: dateSlots
}
