import { GraphQLClient } from 'graphql-request';
import {emptyPatients} from '@/components/Common/utils/common/emptyData'
import {
    UpdatePatientByIdV2Mutation,
    UpdatePatientByIdV2MutationVariables,
    useDeletePatientByIdV2Mutation,
    useUpdatePatientByIdV2Mutation,
    Exact,
    useGetPatientByIdV2Query,
    PatientFieldsV2Fragment,
    useCheckForDuplicateCardNumbersQuery,
} from '@/_graphql/graphql-client';
import { useForm, FormProvider, FieldValues, UseFormReset, UseFormSetValue } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';
// import { checkPatients } from '@/feature/phr-28/components/common/Api'
import { UseMutateFunction, UseMutationResult, useQueryClient } from 'react-query';
import { BuildNextOptions, DefaultGenerics, useNavigate } from '@tanstack/react-location';
// import { emptyValues } from 'utils/emptyValues'
// import { getMutationPatient } from './utils/mutation'
// import { setBaseValues, getBirthday } from './utils/updatePatients'
// import { setPostAddress } from './utils/fetchAddress'

import { ClinicContext, useGraphqlClient } from '@/App';
import { fromUnixTime, getDate, getDaysInMonth, getMonth, getUnixTime, getYear, isValid } from 'date-fns';
import axios from 'axios';
import { SearchType } from '@/components/Common/entities';
import { usePatient_service } from '@/domain/Patient';
import {
    DialogActions,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';

import { PatientsType } from "@/components/Patient/components/util/common/type";
import { getMaxDaysInMonth, invalidBirthday, setPostAddress, validateNumRange } from './utils';
import { CardNumberErrorMessage } from './CardNumberErrorMessage';
import { IntegrateP5GModal } from './components/IntegrateP5GModal';

const emptyValues = {
    addValues: {
        id: '',
        name: {
            last: '',
            first: '',
        },
        kana: {
            last: '',
            first: '',
        },
        age: 0,
        searchFull: '',
        searchKana: '',
        gender: '',
        tel: '',
        email: '',
        birthday: 0,
        address: {
            post: '',
            prefectures: '',
            house: '',
        },
        DNumber: 0,
        SNumber: 0,
        remarks: '',
        comment: '',
        group: '',
        invitationNumber: '',
        from_p5g: false,
    },
    baseValues: {
        year: '',
        month: '',
        day: '',
        DNumber: '',
        SNumber: '',
    },
};

interface PatientsEditViewsType {
    graphqlClient: GraphQLClient;
    patientId: string;
}

export const PatientsEditViews: React.FC<PatientsEditViewsType> = ({
    graphqlClient,
    patientId,
}) => {
    // モーダル開く用のフラグ
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState({});

    // getValueでformに入力された値を取得
    // currentPatientは現在の値
    // currentPatientが更新されるとbaseValueが更新される

    const clinic = useContext(ClinicContext);

    const patientByIdV2 = useGetPatientByIdV2Query(graphqlClient, { clinic_id: clinic.clinic_id, id: patientId });
    const req = patientByIdV2
    const currentPatient = patientByIdV2.data?.getPatientByIdV2 && patientByIdV2.data?.getPatientByIdV2.status === 'false' ? patientByIdV2.data?.getPatientByIdV2 : emptyPatients

    const queryClient = useQueryClient();

    useEffect(() => {
        if (currentPatient) {
            setValue('addValues', Object.assign(emptyValues.addValues, currentPatient));
            setBaseValues(currentPatient, setValue);
        }
    }, [currentPatient]);

    const methods = useForm({ mode: 'onChange', defaultValues: Object.assign({}, emptyValues) })
    const { register, setValue, getValues, watch, reset, formState: { isValid, errors }, trigger } = methods
    const navigate = useNavigate()
    const [DNumber, setDNumber] = useState(emptyPatients.DNumber)
    const [SNumber, setSNumber] = useState(emptyPatients.SNumber)

    // const isDupNumbers: boolean = checkForDuplicateCardNumbers(DNumber, SNumber, currentPatient.id || '', patientsList, currentPatient.from_p5g)
    const SNumberDuplicateData = useCheckForDuplicateCardNumbersQuery(graphqlClient, { inputs: { clinic_id: clinic.clinic_id, patient_id: patientId, cardNumber: SNumber || 0, isOptional: false } }, { enabled: SNumber != null })
    const DNumberDuplicateData = useCheckForDuplicateCardNumbersQuery(graphqlClient, { inputs: { clinic_id: clinic.clinic_id, patient_id: patientId, cardNumber: DNumber || 0, isOptional: true } }, { enabled: DNumber != null })

    const isDupSNumber = SNumberDuplicateData.data?.checkForDuplicateCardNumbers.isDuplicate || false
    const isDupDNumber = DNumberDuplicateData.data?.checkForDuplicateCardNumbers.isDuplicate || false
    const isDupNumbers = isDupSNumber || isDupDNumber

    // 診察券番号のバリデーション
    register('addValues.SNumber', { validate: value => validateNumRange(value) });
    register('addValues.DNumber', { validate: value => validateNumRange(value) });

    useEffect(() => {
        if (trigger) {
            trigger('addValues')
        }
    }, [watch('addValues'), trigger])

    useEffect(() => {
        const { year, month, day } = getValues('baseValues');
        if (year.length === 0) {
            setValue('addValues.birthday', 0);
            return;
        }
        if (month.length === 0) {
            setValue('addValues.birthday', 0);
            return;
        }
        if (day.length === 0) {
            setValue('addValues.birthday', 0);
            return;
        }
        const birthday = getBirthday(year, month, day);
        setValue('addValues.birthday', birthday);
    }, [watch('baseValues.year'), watch('baseValues.month'), watch('baseValues.day')]);

    const updatePatient = useUpdatePatientByIdV2Mutation(graphqlClient, {
        onSettled: () => {
            queryClient
                .invalidateQueries('getPatientByIdV2')
                .catch((err) => console.log('on Settled', err));
            queryClient
                .invalidateQueries('listPatientsByIdV2')
                .catch((err) => console.log('on Settled', err));
            queryClient.invalidateQueries();
        },
        onSuccess: () => {
            alert('患者情報を更新しました');
        },
    });

    const deletePatient = useDeletePatientByIdV2Mutation(graphqlClient, {
        onSettled: () => {
            queryClient
                .invalidateQueries('getPatientByIdV2')
                .catch((err) => console.log('on Settled', err));
            queryClient
                .invalidateQueries('listPatientsV2')
                .catch((err) => console.log('on Settled', err));
            queryClient.invalidateQueries('listPatientsByIdPaginated')
            queryClient.invalidateQueries();
        },
        onSuccess: () => {
            alert('患者情報を削除しました');
        },
    });

    const isInvalidBirthday = invalidBirthday(watch('baseValues.year'), watch('baseValues.month'), watch('baseValues.day'));
    const maxDaysInMonth = getMaxDaysInMonth(watch('baseValues.year'), watch('baseValues.month'));

    return (
        <div className="h-full overflow-scroll">
            <FormProvider {...methods}>
                <div>
                    <form
                        onSubmit={methods.handleSubmit((data) => {
                            onSubmit(data, currentPatient, updatePatient.mutate);
                        })}
                    >
                        <div className="overflow-hidden shadow sm:rounded-md">
                            <div className="bg-white px-4">
                                <p className="w-full text-xs flex gap-1 h-6 mb-2">
                                    <span className="my-3 inline-flex items-center mr-auto">患者情報編集</span>
                                    <span className="inline-flex items-center">
                                        <span className="mr-2">
                                            <input
                                                className="mx-1"
                                                type="checkbox"
                                                placeholder="Power5G連携"
                                                pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
                                                {...register('addValues.from_p5g')}
                                            />
                                            <span className="">電子カルテ連携</span>
                                        </span>
                                        {!currentPatient.SNumber && !currentPatient.from_p5g && (
                                            <span>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setIsOpen(!isOpen);
                                                    }}
                                                    className="inline-flex items-center justify-right rounded-sm border border-transparent bg-green-400 h-7 px-2 text-xs font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                >
                                                    電子カルテ統合
                                                </button>
                                            </span>
                                        )}
                                    </span>
                                    <br />
                                </p>
                                <div className="col-span-6 sm:col-span-3 lg:col-span-3 mb-1">
                                    <p className={`${isDupSNumber ? 'text-red-500' : ''} block text-xs font-medium text-gray-700`}>
                                        {`診察券番号${clinic.clinic_label === 'eldear' ? '-S' : ''}`}{errors.addValues?.SNumber && <CardNumberErrorMessage error={errors.addValues.SNumber} />}
                                    </p>
                                    <div className="flex flex-col gap-y-2">
                                        <input
                                            type="text"
                                            className="rounded-sm w-full text-xs h-7"
                                            {...register('baseValues.SNumber')}
                                            placeholder={clinic.clinic_label === 'eldear' ? 'S番号' : '患者番号'}
                                            onChange={(e) => {
                                                setSNumber(Number(e.currentTarget.value));
                                                setValue('addValues.SNumber', Number(e.currentTarget.value), { shouldValidate: true });
                                            }}
                                        />
                                    </div>

                                    {/* エルディアクリニックのみ診察券番号Dに対応 */}
                                    {clinic.clinic_label === 'eldear' && (
                                        <>
                                            <p className={`${isDupDNumber ? 'text-red-500' : ''} block text-xs font-medium text-gray-700`}>
                                                診察券番号D{errors.addValues?.DNumber && <CardNumberErrorMessage error={errors.addValues.DNumber} />}</p>
                                            <div className="flex flex-col gap-y-2">
                                                <input
                                                    type="text"
                                                    className="rounded-sm w-full text-xs h-7"
                                                    {...register('baseValues.DNumber')}
                                                    placeholder={true ? 'D番号' : ''}
                                                    onChange={(e) => {
                                                        setDNumber(Number(e.currentTarget.value));
                                                        setValue('addValues.DNumber', Number(e.currentTarget.value), { shouldValidate: true });
                                                    }}
                                                />
                                            </div>
                                            <div className="error-message">
                                                {errors.baseValues?.DNumber && <p>{errors.baseValues.DNumber.message}</p>}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="grid grid-cols-6 gap-1">
                                    <div className="col-span-3 sm:col-span-3">
                                        <input
                                            placeholder="性"
                                            className="rounded-sm w-full text-xs h-7"
                                            type="text"
                                            {...register('addValues.name.last', {
                                                required: '空文字で更新することはできません',
                                            })}
                                        />
                                    </div>

                                    <div className="col-span-3 sm:col-span-3">
                                        <input
                                            placeholder="名"
                                            className="rounded-sm w-full text-xs h-7"
                                            type="text"
                                            {...register('addValues.name.first', {
                                                required: '空文字で更新することはできません',
                                            })}
                                        />
                                    </div>

                                    <div className="col-span-3 sm:col-span-3">
                                        <input
                                            className="rounded-sm w-full text-xs h-7"
                                            type="text"
                                            placeholder="セイ"
                                            pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
                                            {...register('addValues.kana.last', {
                                                required: '空文字で更新することはできません',
                                            })}
                                        />
                                    </div>

                                    <div className="col-span-3 sm:col-span-3">
                                        <input
                                            type="text"
                                            placeholder="メイ"
                                            pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
                                            className="rounded-sm w-full text-xs h-7"
                                            {...register('addValues.kana.first', {
                                                required: '空文字で更新することはできません',
                                            })}
                                        />
                                    </div>

                                    <div className="col-span-3 sm:col-span-3">
                                        <p className="block text-xs font-medium text-gray-700">電話番号</p>
                                        <input
                                            type="tel"
                                            pattern="\d{2,4}\d{2,4}\d{3,4}"
                                            placeholder="例: 09012345678"
                                            className="rounded-sm w-full text-xs h-7"
                                            {...register('addValues.tel')}
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <p className="block text-xs font-medium text-gray-700">性別</p>
                                        <select
                                            {...register('addValues.gender')}
                                            className="rounded-sm w-full text-xs h-7"
                                        >
                                            {getOptionsList('性別')}
                                        </select>
                                    </div>

                                    <div className="col-span-3 sm:col-span-3">
                                        <p className="block text-xs font-medium text-gray-700">email</p>
                                        <input
                                            className="rounded-sm w-full text-xs h-7"
                                            type="email"
                                            {...register('addValues.email')}
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                                        <p className="block text-xs font-medium text-gray-700">生年月日</p>
                                        <div className="flex justify-between items-center gap-x-6">
                                            <input
                                                type="number"
                                                placeholder="年（例: 2000）"
                                                min="1"
                                                className="rounded-sm w-1/3 text-xs h-7"
                                                max={`${new Date().getFullYear()}`}
                                                {...register('baseValues.year')}
                                            />
                                            <input
                                                type="number"
                                                placeholder="月（例: 5）"
                                                className="rounded-sm w-1/3 text-xs h-7"
                                                min="1"
                                                max="12"
                                                {...register('baseValues.month')}
                                            />
                                            <input
                                                type="number"
                                                min="1"
                                                max={maxDaysInMonth}
                                                className="rounded-sm w-1/3 text-xs h-7"
                                                placeholder="日（例: 1）"
                                                {...register('baseValues.day')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-6">
                                        <p className="block font-medium text-gray-700 text-xs">住所</p>
                                        <div className="flex flex-col gap-y-2">
                                            <div className="flex gap-x-4 items-stretch">
                                                <input
                                                    type="text"
                                                    className="rounded-sm w-full text-xs h-7"
                                                    placeholder="郵便番号(例: 0001234)"
                                                    {...register('addValues.address.post')}
                                                />
                                                <p
                                                    onClick={() => {
                                                        setPostAddress(getValues('addValues.address.post'), setValue);
                                                    }}
                                                    className="rounded-sm flex items-center justify-center border-transparent bg-indigo-600 w-full text-xs font-medium text-white h-7"
                                                >
                                                    検索
                                                </p>
                                            </div>

                                            <input
                                                type="text"
                                                className="rounded-sm w-full text-xs h-7"
                                                placeholder="都道府県"
                                                {...register('addValues.address.prefectures')}
                                            />

                                            <input
                                                type="text"
                                                className="rounded-sm w-full text-xs h-7"
                                                placeholder="市区町村"
                                                {...register('addValues.address.house')}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="col-span-6 sm:col-span-3">
>>>>>>> origin/staging
                        <p className="block text-sm font-medium text-gray-700">
                          患者グループ
                        </p>
                        <select
                          {...register('addValues.group')}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          {getOptionsList('患者グループ')}
                        </select>
                      </div> */}

                                    <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                                        <p className="block text-xs font-medium text-gray-700">備考</p>
                                        <input
                                            type="text"
                                            className="rounded-sm w-full text-xs h-7"
                                            {...register('addValues.remarks')}
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                                        <p className="block text-xs font-medium text-gray-700">コメント</p>
                                        <textarea
                                            className="w-full border-[#bcbcbc] rounded"
                                            {...register('addValues.comment')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 text-right sm:px-6 flex justify-end gap-4 items-end">
                                {isDupNumbers && <span className='text-red-600 text-xs'>※既に登録されている患者情報です。</span>}
                                <div className={`${isDupNumbers || !isValid || isInvalidBirthday ? 'pointer-events-none opacity-50' : ''}`}>
                                    <button
                                        type="submit"
                                        className="flex justify-center items-center my-2 rounded-sm border border-transparent bg-indigo-600 h-7 px-12 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        登録
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <IntegrateP5GModal
                        clinic_id={clinic.clinic_id}
                        currentPatient={currentPatient}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        setSelected={setSelected}
                        // updatePatient={updatePatient.mutate}
                        // deletePatient={deletePatient.mutate}
                    />
                </div>
            </FormProvider>
        </div>
    );
}

export function getStringFromMedicalNumber(currentPatient: PatientFieldsV2Fragment) {
    // function zeroPadding(number: number | undefined | null) {
    //     if (number === undefined) return '';
    //     if (number === null) return '';
    //     let numString = ('0000' + number).slice(-4);;
    //     return numString;
    // }

    const DString = currentPatient.DNumber?.toString();
    const SString = currentPatient?.SNumber?.toString();
    const baseNumbers = {
        DNumber: DString,
        SNumber: SString,
    };

    return baseNumbers;
}

export function getBirthday(year: string, month: string, day: string): number {
    const birthdayString = `${year}/${month}/${day}`;
    const birthday = getUnixTime(new Date(birthdayString));
    console.log(getYear(birthday).toString());
    console.log((getMonth(birthday) + 1).toString());
    console.log(getDate(birthday).toString());
    console.log(birthdayString, birthday, isValid(birthday))
    return birthday;
}
interface UpdatePatientsModalType {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    patientsList: PatientsType[];
}

// 入力中のDNumberとSNumberが他の患者と重複していないかチェック
export const checkForDuplicateCardNumbers = (
    DNumber: number | null,
    SNumber: number | null,
    id: string,
    PatientsList: PatientsType[],
    fromP5G: boolean | null | undefined = false,
): boolean => {
    // ↓ の患者一覧を抽出
    // - 削除されていない
    // - 編集中の患者意外
    // - P5Gステータスが逆の患者
    const _list = PatientsList.filter(patient => {
        return (patient.id !== id) &&
            (patient?.status !== "true")
    })

    // console.log(fromP5G);

    const isDuplicationSNumber = Boolean(_list.find(patient => {
        return (patient.SNumber != null && patient.SNumber === SNumber)
    }))

    const isDuplicationDNumber = Boolean(_list.find(patient => {
        return (patient.DNumber != null && patient.DNumber === DNumber)
    }))

    // console.log(isDuplicationDNumber, isDuplicationSNumber)

    return isDuplicationSNumber || isDuplicationDNumber
}

function getMutationPatient(data: FieldValues) {
    if (data.address) {
        data.address = getNonNullableObject(data.address);
    }
    const nonNullablePatient = getNonNullableObject(data);

    nonNullablePatient.DNumber = getMedicalNumber(nonNullablePatient.DNumber);
    nonNullablePatient.SNumber = getMedicalNumber(nonNullablePatient.SNumber);
    nonNullablePatient.searchFull = getSearchName(nonNullablePatient.name);
    nonNullablePatient.searchKana = getSearchName(nonNullablePatient.kana);

    return nonNullablePatient;
}

function getNonNullableObject(data: FieldValues) {
    const keys = Object.keys(data);

    const nonNulldableObject = keys.reduce((prev, key) => {
        const values = prev;

        if (data[key] === undefined) return values;
        if (data[key] === null) return values;
        if (data[key] === 0 && key === 'birthday') return values;

        //FIXME: 20231026:リリース_一応残しておく
        // if (data[key].length === 0 && key === 'tel') return values;
        values[key] = data[key];
        return values;
    }, {} as FieldValues);

    return nonNulldableObject;
}

export function getMedicalNumber(number: string | undefined | null) {
    if (number == null) return 0;
    if (number.length === 0) return 0;
    return Number(number);
}

export function getSearchName(fullName: { last: string; first: string }) {
    const searchName = `${fullName.last}${fullName.first}`;
    return searchName;
}

function onSubmit(
    data: FieldValues,
    currentPatient: PatientFieldsV2Fragment | undefined,
    mutate: UseMutateFunction<
        UpdatePatientByIdV2Mutation,
        unknown,
        Exact<UpdatePatientByIdV2MutationVariables>,
        unknown
    >
) {
    if (currentPatient === undefined) return;
    if (currentPatient.id === undefined) return;

    // data.addValues.id = currentPatient.id
    const mutationPatient = getMutationPatient(data.addValues);

    // 旧テーブルからv2への属性名マッピング
    const { SNumber, DNumber, searchFull, searchKana, id, lineId, token, searchName, status, ..._data } =
        mutationPatient;
    _data.fullName = searchFull;
    _data.kanaName = searchKana;
    //FIXME: ロジック修正したい
    delete _data.status;

    // if (SNumber) {
    _data.patientCardNumber = SNumber;
    // }

    // if (DNumber) {
    _data.patientCardNumberOptional = DNumber;
    // }

    const input = { id: currentPatient.id || id, input: _data };

    mutate(input as UpdatePatientByIdV2MutationVariables);
}

function closeModal(
    emptyValues: FieldValues,
    reset: UseFormReset<any>,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setCurrentPatient: React.Dispatch<React.SetStateAction<PatientsType>>,
    navigate: (
        args_0: BuildNextOptions<DefaultGenerics> & {
            replace?: boolean | undefined;
            fromCurrent?: boolean | undefined;
        } & {
            replace?: boolean | undefined;
        }
    ) => void
) {
    reset(emptyValues);
    setCurrentPatient(emptyPatients);
    setIsOpen(false);
    navigate({ to: '/patients', replace: true });
}

function getOptionsList(placeholder: '性別' | '患者グループ'): JSX.Element {
    let options: JSX.Element = <></>;
    switch (placeholder) {
        case '性別':
            options = (
                <>
                    <option value="男">男</option>
                    <option value="女">女</option>
                </>
            );
            break;
        case '患者グループ':
            options = (
                <>
                    <option value="">設定しない</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                </>
            );
            break;
    }
    return options;
}

export function setBaseValues(currentPatient: PatientFieldsV2Fragment, setValue: UseFormSetValue<any>) {
    const birthday = getBirthdayFromUnixTime(currentPatient);
    const medicalNumber = getStringFromMedicalNumber(currentPatient);
    const baseValues = { ...birthday, ...medicalNumber };
    setValue('baseValues', baseValues);
}

export function getBirthdayFromUnixTime(currentPatient: PatientFieldsV2Fragment) {
    const emptyBirthday = { year: '', month: '', day: '' };

    if (currentPatient.birthday === 0) return emptyBirthday;
    if (currentPatient.birthday === undefined) return emptyBirthday;
    if (currentPatient.birthday === null) return emptyBirthday;

    const date = fromUnixTime(currentPatient.birthday);
    const year = getYear(date).toString();
    const month = (getMonth(date) + 1).toString();
    const day = getDate(date).toString();
    const birthday = { year, month, day };
    console.log(date, year, month, day)
    return birthday;
}