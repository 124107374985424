import { GraphQLClient } from 'graphql-request'
import { useGetAppointByIdV2Query, useGetAppointQuery } from "@/_graphql/graphql-client";
import { useAppointV2VariablesType, useAppointVariablesType } from '@/domain/Appointment/entities/types';
import { AppointMapper } from '@/domain/Appointment/services/mapper/AppointListMapper';
import { QueryObserverOptions, UseQueryOptions } from 'react-query';
import { AnyCnameRecord } from 'dns';

export const useAppoint_service = (
    graphqlClient: GraphQLClient,
    variables: useAppointVariablesType,
    options: any
) => {
    const req = useGetAppointQuery(graphqlClient, { id: variables.patient_id, startTime: variables.startTime }, options)
    const data = AppointMapper(req);

    return {
        req,
        data
    };
}

export const useAppointV2_service = (
    graphqlClient: GraphQLClient,
    variables: useAppointV2VariablesType,
    options: any
) => {
    const req = useGetAppointByIdV2Query(graphqlClient, { clinic_id: variables.clinic_id, id: variables.patient_id, startTime: variables.startTime }, options)
    const data = req.data?.getAppointByIdV2

    return {
        req,
        data
    };
}