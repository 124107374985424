import { SettingsViewsType } from "@/components/Settings/entities"
import { SettingsPresenter } from "@/components/Settings/components/presenter/SettingsPresenter"

export const SettingsViews: React.FC<SettingsViewsType> = ({
    children
}) => {

    return (
        <SettingsPresenter>
            {children}
        </SettingsPresenter>
    )
}
