import { RectangleButton } from "../../RectangleButton";

interface PatientDetailLinkButtonProps {
    onNavigate: () => void;
}

export const PatientDetailLinkButton: React.FC<PatientDetailLinkButtonProps> = ({ onNavigate }) => {

    return (
        <RectangleButton
            label={'患者詳細'}
            color={'blue'}
            size={'sm'}
            disable={false}
            onClickHandler={onNavigate}
        />
    );
}
