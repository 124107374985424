import { BulkDeleteDialogProps } from '@/components/Common/entities'
import { Dialog, DialogContent } from '@mui/material'

export const BulkDeleteDialog: React.FC<BulkDeleteDialogProps> = ({
    isChecked,
    setIsChecked,
    setIsOpenBulkDeleteDialog,
    allowedDeleteNames,
    transactDeleteGroupsMutation
}) => {
    return (
        <Dialog
            transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
            onClose={() => { setIsOpenBulkDeleteDialog() }}
            open={true}
            PaperProps={{
                style: {
                    maxWidth: 'none',
                },
            }}
        >
            <DialogContent
                dividers
                style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', position: 'relative' }}
            >
                <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                        <div className="mt-3 text-center sm:mt-5 flex justify-center items-center gap-x-4">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                                <input type="checkbox" checked={isChecked} onChange={() => { setIsChecked(!isChecked) }} />
                                以下のグループを一括削除しますか？<br />
                                {
                                    allowedDeleteNames.map((name, index) => (
                                        <span key={index}>{name}</span>
                                    ))
                                }
                            </h3>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                            type="button"
                            className={`${isChecked ? '' : 'pointer-events-none opacity-50'} inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                            onClick={() => {
                                transactDeleteGroupsMutation()
                                setIsOpenBulkDeleteDialog()
                            }
                            }
                        >
                            削除する
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                            onClick={() => setIsOpenBulkDeleteDialog()}
                        >
                            キャンセル
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
