
import { GraphQLClient } from 'graphql-request'
import { useListClinicTimeConfigQuery, ListClinicTimeConfigQueryVariables } from '@/_graphql/graphql-client'
import { configMapper } from '../../mapper/configMapper'

export const useClinicConfigTimeList_service = (
    graphqlClient: GraphQLClient,
    variables: ListClinicTimeConfigQueryVariables
) => {
    const _clinicConfig = useListClinicTimeConfigQuery(graphqlClient, { clinic_id: variables.clinic_id })
    const clinicConfig = configMapper(_clinicConfig)
    return clinicConfig;
}
