import { useState } from "react";
import { CloseButton, getStartTimeOfToDayTimestamp } from "@/components/Common"
import { PrimaryHeaderPresenter } from "@/components/Common/components/Header/presenter/PrimaryHeaderPresenter"
import { CommonHeaderPresenterType } from "@/components/Common/entities"
import { SubPanesHeaderPresenter } from "@/components/Main/components/panes/TopPane/Header/SubPanesHeaderPresenter"
import { ArrowSmRightIcon } from '@heroicons/react/solid'
import { Link } from "@tanstack/react-location"

export const CommonHeaderPresenter: React.FC<CommonHeaderPresenterType> = ({
    isSettings,
    clinicList,
    navigate,
    subPanesRef,
    viewSizes,
    menuItems
}) => {

    return (
        <div className="flex w-full justify-between border-b px-2 py-1">
            {isSettings ?
                <div></div>
                :
                <SubPanesHeaderPresenter navigate={navigate} subPanesRef={subPanesRef} viewHeight={viewSizes.height} viewWidth={viewSizes.width} menuItems={menuItems} />
            }
            <div className="flex items-center gap-4">
                {/* top pane上のヘッダーの閉じるボタン */}
                {isSettings === false && <CloseButton panesRef={subPanesRef} closeTarget='top' />}
                <PrimaryHeaderPresenter clinicList={clinicList} />
                {isSettings === true &&
                    <Link to={`/main/?calendar-date=${getStartTimeOfToDayTimestamp(new Date())}`}>
                        <div className="flex items-center gap-1">
                            <ArrowSmRightIcon className="h-6 w-6" />
                            <p className="text-sm font-bold text-gray-500">カレンダーへ戻る</p>
                        </div>
                    </Link>
                }
            </div>
        </div>
    )
}
