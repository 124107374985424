import { uuid4 } from '@sentry/utils';
import { type AppointCardPresenterType } from '../../entities';
import { getStatusBadgeStyle } from '../../utils';
import { ToothFormula, cleanTeethData, formatBabyDentalSequence, formatDentalSequence } from '../parts/AppointDetail/common/SelectDentalFormulaLabel';

export const AppointCardPresenter: React.FC<AppointCardPresenterType> = ({
  oparation,

  appointId,
  reservation,
  reservationCardHigh,
  startTime,
  endTime,

  onClickHandler,
}) => {
  console.log(reservation.dentalFormula);

  const { dentalFormula } = reservation;

  // 静的クラス
  const staticStyle = 'absolute w-full p-1 overflow-hidden text-xs';

  // 条件付きクラス
  // 編集モード判定 + 編集中予約情報と予約id一致判定
  const isEditClass =
    oparation === 'edit' && appointId === reservation.appointId
      ? 'opacity-50 pointer-events-none'
      : '';
  // 担当者登録判定
  const hasStaffClass = reservation.staffNames[0].name ? '' : 'text-white';
  const dynamicClass = `${isEditClass} ${hasStaffClass}`;
  const isSelected = appointId === reservation.appointId;
  const isActive = endTime !== reservation.endTime;

  // 全体クラス
  const className = `${staticStyle} ${dynamicClass}`;

  // 動的スタイル:
  // 診療時間とカードのheightが連動
  // 担当者のcolorプロパティを予約カードのbackgroundに反映
  const dynamicStyle = {
    border: isSelected ? '3px solid #000000' : 'none',
    top: `${startTime}px`,
    height: `${reservationCardHigh}px`,
    backgroundColor: reservation.staffNames[0].color || 'rgb(100,116,139)',
    boxShadow: isSelected
      ? '0 10px 15px -3px rgba(0, 0, 0, 0.4) inset'
      : '0 6px 10px -3px rgba(0, 0, 0, 0.4)',
    width: '99%',
    ...(!isActive && { zIndex: 100 }),
  };

  const _tooth = [
    ...ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.rightTop ?? [])), "rightTop", 'white', 'white'),
    ...ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.leftTop ?? [])), "leftTop", 'white', 'white'),
    ...ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.rightBottom ?? [])), "rightBottom", 'white', 'white'),
    ...ToothFormula(formatDentalSequence(cleanTeethData(dentalFormula?.permanent?.leftBottom ?? [])), "leftBottom", 'white', 'white'),
    ...ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.rightTop ?? [])), "rightTop", 'white', 'white'),
    ...ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.leftTop ?? [])), "leftTop", 'white', 'white'),
    ...ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.rightBottom ?? [])), "rightBottom", 'white', 'white'),
    ...ToothFormula(formatBabyDentalSequence(cleanTeethData(dentalFormula?.baby?.leftBottom ?? [])), "leftBottom", 'white', 'white'),
]


  return (
    <div key={uuid4()} className={className} style={dynamicStyle} onClick={onClickHandler}>
      <div className="break-words">
        <p className="flex gap-1 items-center font-bold text-base text-sm">
          <StatusBadge status={reservation.appoint} />
          {`${reservation?.patientCardNumber ?? '未登録'}`}
        </p>

        <p className="font-bold text-base text-sm">{`${reservation.patientName}`}</p>
        {`
          ${reservation.menuAbbreviation} /
        `}
          <span className="text-white">{_tooth}</span> /
        {`  
          ${reservation.staffNames[0].name} /
          ${reservation.staffNames[1].name} /
          ${reservation.staffNames[2].name} /
          ${reservation.remarks === null ? '※' : '※' + reservation.remarks}
        `}     
        </div>
    </div>
  );
};

const StatusBadge: React.FC<{ status: string }> = ({ status }) => {
  const { text, color } = getStatusBadgeStyle(status);
  return (
    <span className={`text-xs h-6 w-6 flex justify-center items-center border ${color}`}>
      {text}
    </span>
  );
};

