import React, { useState } from 'react'
import { useUpDateMenuMutation } from '@/_graphql/graphql-client'
import { useQueryClient } from 'react-query'
import { useGraphqlClient } from '@/App'
import { MedicalMenuRecordPresenter } from '../presenter/MedicalMenuRecordPresenter'
import { CategoryType, MedicalMenuRecordViewsType, MenuType } from '../util/common/type'
import { useDeleteMenuDialog } from '@/feature/phr-28/components/template/medicalCareRegistration/useDeleteMenuDialog'
import { useEditMenuModal } from '@/feature/phr-28/components/template/medicalCareRegistration/useEditMenuModal'

export const MedicalMenuRecordViews: React.FC<
  MedicalMenuRecordViewsType
> = ({ 
  record, 
  bulkDeleteArr, 
  setBulkDeleteArr, 
  index,
  filteredLists, 
  categoryList, 
  lastIndexNum, 
 }) => {
  const graphqlClient = useGraphqlClient()
  const { EditMenuModal, setCurrentMenu } = useEditMenuModal()
  const { DeleteMenuDialog, setDeleteMenu } = useDeleteMenuDialog()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [editMode, setEditMode] = useState(false)
  // NOTE 230322 *** CSSを文字列で渡しているのが怪しみ
  const deleteBtnCss: string = 'leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-20 text-center rounded-2xl bg-red-700 hover:bg-red-900'
  const disableBtnCss: string = 'leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-20 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'
  // *** 
  const currentCategory: CategoryType[] = categoryList.filter((category: CategoryType) => {
    return category.id === record.subCategoryId
  })

  const queryClient = useQueryClient()
  const updateMenu = useUpDateMenuMutation(graphqlClient, {
    onSettled: () => {
      queryClient.invalidateQueries('listMenu').catch(() => console.log('on Settled'))
    },
    onSuccess: res => {
      console.log(res)
    },
    onError: error => {
      throw error
    },
    retry: 3
  })


  const updateMenuIndexExecute = (isUp: boolean = false): void => {
    // NOTE 0421 メニュー順序入れ替えで全体のチェックを外す
    setBulkDeleteArr([]);
    let isRecord2: boolean = true
    if (record.index === 1) {
      record.index++
    } else if (record.index > 1 && record.index < lastIndexNum) {
      if (isUp) {
        record.index--
      } else {
        record.index++
      }
    } else if (record.index === lastIndexNum) {
      record.index--
    } else {
      record.index = lastIndexNum + 1
      isRecord2 = false
    }
    // 2つ入ってきてる
    const record2: MenuType[] = filteredLists.filter((menu: MenuType) => {
      return menu.index === record.index
    })
    if (isRecord2) {
      if (isUp) {
        record2[0].index++
        updateMenu.mutate(record2[0])
      } else {
        record2[1].index--
        updateMenu.mutate(record2[1])
      }
    }
    updateMenu.mutate(record)
  }

  return (
    <MedicalMenuRecordPresenter
    editMode={editMode}
    setEditMode={setEditMode}
    EditMenuModal={EditMenuModal}
    categoryList={categoryList}
    bulkDeleteArr={bulkDeleteArr}
    setBulkDeleteArr={setBulkDeleteArr}
    isCheck={isCheck}
    setIsCheck={setIsCheck}
    updateMenuIndexExecute={updateMenuIndexExecute}
    record={record}
    index={index}
    lastIndexNum={lastIndexNum}
    currentCategory={currentCategory}
    disableBtnCss={disableBtnCss}
    deleteBtnCss={deleteBtnCss}
    setCurrentMenu={setCurrentMenu}
    DeleteMenuDialog={DeleteMenuDialog}
    setDeleteMenu={setDeleteMenu}
    openDeleteDialog={openDeleteDialog}
    setOpenDeleteDialog={setOpenDeleteDialog}
    filteredLists={filteredLists}
    />

  )
}
