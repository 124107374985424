import React from 'react';
import { ResponsibleDeleteModalPresenterType } from '../utils/common/type';
import {
  DeleteButtonType,
  DeleteCancelButtonType,
  DeleteConfirmMessageType,
  OutlineType,
} from '../utils/components/parts';

export const ResponsibleDeleteModalPresenter: React.FC<ResponsibleDeleteModalPresenterType> = ({
  setIsOpen,
  Dialog,
  closeDialog,
  emptyManeger,
  setDeleteCurrentManeger,
  DialogContent,
  currentManeger,
  deleteManeger,
}) => {
  return (
    <>
      <Dialog
        transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
        onClose={() => {
          closeDialog(emptyManeger, setIsOpen, setDeleteCurrentManeger);
        }}
        open={true}
        PaperProps={{
          style: {
            maxWidth: 'none',
          },
        }}
      >
        <DialogContent
          dividers
          style={{
            padding: '0px 0px 0px 0px',
            maxWidth: 'none',
            maxHeight: 'none',
            position: 'relative',
          }}
        >
          <DeleteDialogContentOutline>
            <DeleteConfirmMessage currentManeger={currentManeger} />
            <DeleteDialogButtonsWrapper>
              <DeleteButton deleteManeger={deleteManeger} setIsOpen={setIsOpen} />
              <DeleteCancelButton
                closeDialog={closeDialog}
                emptyManeger={emptyManeger}
                setIsOpen={setIsOpen}
                setDeleteCurrentManeger={setDeleteCurrentManeger}
              />
            </DeleteDialogButtonsWrapper>
          </DeleteDialogContentOutline>
        </DialogContent>
      </Dialog>
    </>
  );
};

const DeleteDialogContentOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle =
    'relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6';
  return <div className={outlineStyle}>{children}</div>;
};

const DeleteConfirmMessage: React.FC<DeleteConfirmMessageType> = ({ currentManeger }) => {
  const outlineStyle = 'mt-3 text-center sm:mt-5'
  const messageStyle = 'text-lg font-medium leading-6 text-gray-900'
  return (
    <div className={outlineStyle}>
      <h3 className={messageStyle}>
        <span className="text-red-500">{currentManeger.name}</span>を削除しますか？
      </h3>
    </div>
  );
};

const DeleteDialogButtonsWrapper: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle = 'mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'
  return (
    <div className={outlineStyle}>
      {children}
    </div>
  );
};

const DeleteButton: React.FC<DeleteButtonType> = ({ deleteManeger, setIsOpen }) => {
  const buttonStyle = 'inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm'
  return (
    <button
      type="button"
      className={buttonStyle}
      onClick={() => {
        deleteManeger();
        setIsOpen(false);
      }}
    >
      削除する
    </button>
  );
};

const DeleteCancelButton: React.FC<DeleteCancelButtonType> = ({
  closeDialog,
  emptyManeger,
  setIsOpen,
  setDeleteCurrentManeger,
}) => {
  const buttonStyle = 'mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm'
  return (
    <button
      type="button"
      className={buttonStyle}
      onClick={() => closeDialog(emptyManeger, setIsOpen, setDeleteCurrentManeger)}
    >
      キャンセル
    </button>
  );
};
