export interface InputRemarksPropsType {
    registerProps: {
        name: string;
        ref: React.Ref<any>;
    };
}

export const InputRemarks: React.FC<InputRemarksPropsType> = ({
    registerProps
}) => {
    return (
        <div className='h-14 w-full box-border'>
            <textarea
                {...registerProps}
                placeholder="コメントを入力してください"
                className='bg-white text-xs p-1 w-full h-full'>
            </textarea>
        </div>
    )
}
