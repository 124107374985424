import { UseQueryResult } from "react-query";
import { ListCategorysQuery } from "@/domain/Category/entities/types";
import { CategoryType } from "@/components/Menu/entities";

export const categoryListMapper = (
    categorys: UseQueryResult<ListCategorysQuery, unknown>
) => {
    const categoryList: CategoryType[] = [];
    const categoryListIncludeDelete: CategoryType[] = []

    if (categorys.isSuccess) {
        const { data } = categorys

        data.listCategorys.Categorys?.forEach((category: any) => {
            if (category?.isDelete === false) {
                categoryList.push(category)
            }
            categoryListIncludeDelete.push(category)
        })
    }

    return {
        categoryList,
        categoryListIncludeDelete
    };
}