import { AddFacilityRowProps } from '../../entities';
import { Checkbox, Flex, TextInput } from '@mantine/core';
import { BasicButton, $styledTR, _styledTD } from '@/components/Common/components/Config';
import { MultiSelectModalOpener } from './MultiSelectModalOpener';

// 新規追加
export const AddFacilityRow: React.FC<AddFacilityRowProps> = ({
  editingGroupList,
  editingFacilityName,
  setEditingFacilityName,
  editingStatus,
  setEditingStatus,
  resetFormState,
  addFacilityMutation,
  setIsOpenSelectModal
}) => {
  return (
    <$styledTR border={false}>
      <_styledTD>
        {/* チェックボックス */}
        <Checkbox
          disabled
          // checked={false}
          radius="xs"
          size="xs"
        />
      </_styledTD>

      <_styledTD>
      </_styledTD>

      <_styledTD>
        {/* 施設名 */}
        <TextInput
          placeholder="施設名"
          size="xs"
          required
          value={editingFacilityName}
          onChange={(e) => {
            setEditingFacilityName(e.target.value);
          }}
        />
      </_styledTD>

      {/* 施設グループ選択 */}
      <_styledTD>
        <MultiSelectModalOpener
          editingGroupList={editingGroupList}
          setIsOpenSelectModal={() => setIsOpenSelectModal()}
        />
      </_styledTD>

      <_styledTD>
        {/* ステータス */}
        <Checkbox
          checked={editingStatus}
          onChange={setEditingStatus}
          radius="xs"
          size="xs"
        />
      </_styledTD>

      <_styledTD>
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          gap={{ base: 'sm', sm: 'sm' }}
          justify={{ sm: 'center' }}
        >
          {/* 登録ボタン */}
          <BasicButton
            label="登録"
            width="small"
            color="gray"
            disabled={editingFacilityName.length === 0}
            onClickEvent={() => {
              editingFacilityName &&
                addFacilityMutation()
            }}
          />
          {/* キャンセルボタン */}
          <BasicButton
            label="キャンセル"
            width="small"
            color="gray"
            disabled={false}
            onClickEvent={() => {
              resetFormState();
            }}
          />
        </Flex>
      </_styledTD>
    </$styledTR>
  );
};

export default AddFacilityRow;
