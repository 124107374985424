import { GroupSelect } from '@/components/Reservation/components/parts/AppointSchedule/AppointHeader/GroupSelect';
import { CloseButton } from '@/components/Common';
import { useMediaQuery } from "@mantine/hooks";
import { useQueryClient } from "react-query";
import { StatusNumbers } from "./StatusNumbers";
import { DateSelector } from "./DateSelector";
import { AppointRefreshControler } from "./AppointRefreshControler";
import { Print } from "./Print";
import { useRefreshObserver } from "../../../../hooks";
import { $styledDiv } from "./$styledDiv";
import { AppointHeaderPresenterPropsType } from '../../../../entities';
import { countAppointStatuses } from '../../../presenter/AppointPresenter';

// [予約]ヘッダー
export const AppointHeader: React.FC<AppointHeaderPresenterPropsType> = ({
  // 印刷
  printRef,
  // ペイン開閉
  subPanesRef,
  // 日付選択
  nowDate,
  setNowDate,
  // 予約ステータス集計
  allReservationList,
  // 時刻表示切り替え(暫定廃止中)
  isAllTime,
  setIsAllTime,
  //フロアセレクト
  setFacilitiesFilter,
  appointIsLoading
}) => {

  const queryClient = useQueryClient();
  // 予約更新ボタン押下時に使用: ローディングアニメーションのフラグ管理
  const { isRefresh, setRefreshTriggered } = useRefreshObserver(appointIsLoading)

  //予約ステータス集計
  const {
    numberOfReserved,
    numberOfCheckedIn,
    numberOfCompleted,
    numberOfCanceled
  } = countAppointStatuses(allReservationList)

  // useMediaQueryでmaxをiPhone14ProMaxのサイズにする
  const isMobile = useMediaQuery('(max-width: 928px)');

  return (
    <$styledDiv styleType="wrapper" isMobile={isMobile}>

      <$styledDiv styleType="left" isMobile={isMobile}>
        {/* 日付選択 */}
        <DateSelector
          nowDate={nowDate}
          setNowDate={setNowDate}
          isMobile={isMobile}
        />

        {/* 停止中:20230621 */}
        {/* 時刻表示切り替えボタン */}
        {/* <Switch
            checked={isAllTime}
            onChange={(e) => {
              setIsAllTime(prev => !prev)
            }}
          /> */}

        {/* 予約ステータス集計 */}
        <StatusNumbers
          numberOfReserved={numberOfReserved}
          numberOfCheckedIn={numberOfCheckedIn}
          numberOfCompleted={numberOfCompleted}
          numberOfCanceled={numberOfCanceled}
        />
      </$styledDiv>

      <$styledDiv styleType="right">
        {/* 予約更新ボタン */}
        <AppointRefreshControler
          isRefresh={isRefresh}
          onRefresh={() => {
            console.log('予約更新ボタン押下')
            queryClient.invalidateQueries()
            setRefreshTriggered(true)
          }}
        />

        {/* フロアセレクト */}
        <GroupSelect
          isMobile={isMobile}
          setFacilitiesFilter={setFacilitiesFilter}
        />

        {/* 印刷ボタン */}
        <div className="flex items-bottom justify-end gap-1">
          <Print printRef={printRef} />

          {/* ペイン開閉ボタン */}
          <CloseButton panesRef={subPanesRef} closeTarget="bottom" />
        </div>
      </$styledDiv>

    </$styledDiv>
  );
};