import { ReserveType } from "@/components/Reservation/entities"
import { BlockType } from "@/components/Block/entities"
import { ClinicTimeType, ClinicType } from "@/components/Common/entities"
import { CategoryType, MenuType } from "@/components/Menu/entities"
import { PatientsType } from "@/components/Patient/components/util/common/type";
import { shiftType, StaffType } from "@/components/Staff/entities"
import { CommentType } from "@/components/Comment/entities"
import { ClinicScheduleType, ClinicTimeConfigType } from "@/components/ClinicSettings/entities"

export const emptyClinic: ClinicType = {
    clinic_id: '',
    org_id: '',
    clinic_label: '',
    org_label: '',
    clinic_name: '医院名未登録',
    clinic_time: {
        startTime: {
            hours: 0,
            minutes: 0,
        },
        endTime: {
            hours: 21,
            minutes: 0
        }
    }
}

export const emptyClinicTime: ClinicTimeType = {
    startTime: {
        hours: 0,
        minutes: 0,
    },
    endTime: {
        hours: 21,
        minutes: 0
    }
}

export const emptyClinicTimeConfig: ClinicTimeConfigType = {
    clinic_id: '',
    config_id: '',
    config_type: '',
    config: {
        clinicTime: {
            startTime: {
                hours: 8,
                minutes: 15,
            },
            endTime: {
                hours: 23,
                minutes: 0
            }
        },
    },
    startDate: 0,
    endDate: 0,
    created_at: 0,
    updated_at: 0
}

export const emptyClinicSchedule: ClinicScheduleType = {
    clinic_id: "",
    clinic_schedule_id: "",
    start_date: "",
    end_date: "",
    rule_type: "MONTHLY_FIXED_DAY",
    day_of_week: "MONDAY",
    week: "FIRST",
    specific_date: null,
    start_time: "09:00",
    end_time: "17:00",
    service_day: true,
};

export const emptyComment: CommentType = {
    clinic_id: '',
    date: 1,
    comment: '',
    commentId: ''
}

export const emptyAppoint: ReserveType = {
    appoint: '0',
    appointId: '0',
    date: 0,
    endTime: 0,
    id: '0',
    menuId: '0',
    name: '0',
    staffIds: [{ index: 0, id: '0' }, { index: 1, id: '0' }, { index: 2, id: '0' }],
    startTime: 0,
    type: '0',
    secondStartTime: 0,
    secondEndTime: 0,
    thirdStartTime: 0,
    thirdEndTime: 0,
    remarks: '',
    facilityId: '',
    price: 0,
    confirmedTime: 0,
    confirmedEndTime: 0,
    created_at: 1,
    updated_at: 1,
    cancel: {
        cancelReason: '',
        radioCancelReason: 0,
        isCancel: false,
    },
    isLate: false,
}

export const emptyAppointV2 = {
    clinic_id: '',
    appoint: '0',
    appointId: '0',
    date: 0,
    endTime: 0,
    id: '0',
    menuId: '0',
    staffIds: [{ index: 0, id: '0' }, { index: 1, id: '0' }, { index: 2, id: '0' }],
    startTime: 0,
    type: '0',
    secondStartTime: 0,
    secondEndTime: 0,
    thirdStartTime: 0,
    thirdEndTime: 0,
    remarks: '',
    facilityId: '',
    price: 0,
    confirmedTime: 0,
    confirmedEndTime: 0,
    created_at: 1,
    updated_at: 1,
    cancel: {
        cancelReason: '',
        radioCancelReason: 0,
        isCancel: false,
    },
    isLate: false,
    patientName: '',
    patientKana: '',
    patientRemarks: '',
    menuName: '',
    facilityName: '',
    staffNames: [
        {
            index: 0,
            name: ''
        },
        {
            index: 1,
            name: ''
        },
        {
            index: 2,
            name: ''
        },
    ]
}

export const emptyBlock: BlockType = {
    id: '',
    startTime: 1,
    endTime: 1,
    facilityId: '',
    remarks: '',
    color: '',
    isNonAccepted: false,
    isDelete: false
}

export const emptyManeger: StaffType = {
    id: '',
    role: '',
    name: '',
    rights: '',
    email: '',
    color: '',
    isDelete: false,
    index: 0
}

export const emptyPatients: PatientsType = {
    DNumber: null,
    SNumber: null,
    address: {
        house: '',
        post: '',
        prefectures: ''
    },
    age: 0,
    birthday: 0,
    comment: '',
    email: '',
    gender: '',
    group: '',
    id: '',
    invitationNumber: '',
    kana: { last: '', first: '' },
    lineId: '',
    name: { first: '', last: '' },
    remarks: '',
    searchName: '',
    tel: '',
    token: '',
    searchFull: '',
    searchKana: '',
    status: "false",  // これはisDeletedのこと。こといずれ直す
    from_p5g: false
}

export const emptyCategory: CategoryType = {
    id: '',
    name: '',
    mainCategory: '',
    isDelete: false
}

export const emptyMenu: MenuType = {
    SDK_ID: '',
    name: '',
    abbreviation: '',
    subCategoryId: '',
    parts: '',
    amount: 1,
    time: 1,
    type: '',
    role: '',
    status: '',
    courponId: '',
    isDelete: false,
    index: 0,
}

export const workingTimeAm: string[] = [
    '8:00',
    '8:15',
    '8:30',
    '8:45',
    '9:00',
    '9:15',
    '9:30',
    '9:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45'
]
export const workingTimePm: string[] = [
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
    '21:15',
    '21:30',
    '21:45',
    '22:00',
    '22:15',
    '22:30',
    '22:45'
]

export const emptyShiftData: shiftType = {
    date: 0,
    id: '',
    name: '',
    shifts: [],
    workingTimes: { am: workingTimeAm, pm: workingTimePm }
}