import { HeaderViews } from '@/components/Main/components/panes/LeftPane/Header/HeaderViews'
import { CommentViews } from '@/components/Comment'
import { MonthCalendarViews } from '@/components/MonthCalendar'
import { ErrorBoundary } from '@/components/Common/components'
import { Group, Switch } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { MutateContext } from '@/provider/common/MutateProvider';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import { ClinicScheduleType, useGetActiveScheduleQuery, useGetSpecificDateRuleQuery } from '@/_graphql/graphql-client';
import { ClinicContext, useGraphqlClient } from '@/App';
import { endOfDay, getUnixTime, startOfDay } from 'date-fns';
import { Radio } from '@mantine/core';

// FIXME
interface LeftPaneType {
  isOpen: boolean
}

export const LeftPane: React.FC<LeftPaneType> = ({ isOpen }) => {

  const { nowDate } = useContext(SetDateContext)
  const clinic = useContext(ClinicContext)
  const graphqlClient = useGraphqlClient()

  const activeSchedule = useGetActiveScheduleQuery(graphqlClient, { clinic_id: clinic.clinic_id, current_date: getUnixTime(startOfDay(nowDate)) })
  const specificDate = useGetSpecificDateRuleQuery(graphqlClient, { clinic_id: clinic.clinic_id, current_date: getUnixTime(startOfDay(nowDate)) })

  useEffect(() => {
    if (activeSchedule.isSuccess) {
      const isHoliday = activeSchedule.data.getActiveSchedule?.isHoliday
      setIsHoliday(Boolean(isHoliday))
    }
  }, [activeSchedule])

  useEffect(() => {
    if (specificDate.isSuccess) {
      const hasSpecificDateRule = specificDate.data.getSpecificDateRule
      setHasSpecificDateRule(Boolean(hasSpecificDateRule))
    }
  }, [specificDate])

  const { addClinicSchedule, deleteClinicSchedule } = useContext(MutateContext)

  const [isHoliday, setIsHoliday] = useState(false)
  const [hasSpecificDateRule, setHasSpecificDateRule] = useState(true)

  return (
    <div className="bg-gray-200 w-full min-w-[210px]">
      <div className={isOpen ? "flex justify-center" : ""}>
        <div className="w-full h-screen flex flex-col justify-center">
          {/* カレンダー */}
          <ErrorBoundary>
            <HeaderViews />
          </ErrorBoundary>
          {
            isOpen &&
            <ErrorBoundary>
              {/* カレンダー */}
              <MonthCalendarViews />

              {/* 診察/休診ルール(特定日付) */}
              <div className="flex flex-col items-start">
                {/* スイッチ: ルール有りなら更新:なしなら新規追加 */}
                <Switch
                  onLabel="Holiday"
                  offLabel="Service"
                  checked={isHoliday}
                  onChange={(e) => {
                    setIsHoliday(isHoliday => !isHoliday)
                    //mutate
                    if (hasSpecificDateRule) {
                      //delete
                      deleteClinicSchedule.mutate({
                        clinic_id: clinic.clinic_id,
                        clinic_schedule_id: specificDate.data?.getSpecificDateRule?.clinic_schedule_id || ''
                      })
                    } else {
                      //add
                      addClinicSchedule.mutate({
                        input: {
                          clinic_id: clinic.clinic_id,
                          rule_type: ClinicScheduleType.SpecificDates,
                          specific_date: getUnixTime(startOfDay(nowDate)),
                          start_date: getUnixTime(startOfDay(nowDate)),
                          end_date: getUnixTime(endOfDay(nowDate)),
                          service_day: isHoliday ? true : false
                        }
                      })
                    }
                  }}
                />
              </div>
            </ErrorBoundary>
          }
          {/* コメント */}
          {isOpen &&
            <ErrorBoundary>
              <CommentViews />
            </ErrorBoundary>
          }
        </div>
      </div>
    </div>
  )
}