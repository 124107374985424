import React, { useState } from 'react'
import { DailyCalendar } from './DailyCalendar/DailyCalendar'
import { ShiftFixed } from './ShiftFixed'
import { listManegerType } from '@/feature/phr-28/components/common/type'

interface ShiftRegistrationType {
  manegerLists: listManegerType[]
}

export const ShiftRegistration: React.FC<ShiftRegistrationType> = ({
  manegerLists
}) => {
  // シフト登録画面を開くフラグ
  const [isShowShiftFex, setShiftFix] = useState(false)
  // 誰のシフト編集なのか
  const [shiftIdentificationNumber, setShiftIdentificationNumber] =
    useState('0')
  const [nowDate, setNowDate] = useState(new Date())

  return (
    <>
      <div className="overflow-hidden h-[80vh]">
        {isShowShiftFex
          ? (
          <ShiftFixed
            setShiftFix={setShiftFix}
            manegerLists={manegerLists}
            shiftIdentificationNumber={shiftIdentificationNumber}
            nowDate={nowDate}
          />
            )
          : (
          <DailyCalendar
            setShiftFix={setShiftFix}
            setShiftIdentificationNumber={setShiftIdentificationNumber}
            nowDate={nowDate}
            setNowDate={setNowDate}
          />
            )}
      </div>
    </>
  )
}
