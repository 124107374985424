import { RectangleButton } from '../../RectangleButton'

export interface DeleteButtonProps {
    disable: boolean
    openDeleteConfirmDialog: () => void
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({
    disable,
    openDeleteConfirmDialog
}) => {
    return (
        <RectangleButton
            label='削除'
            color='red'
            size='md'
            disable={disable}
            onClickHandler={openDeleteConfirmDialog}
        />
    )
}
