import { useContext, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { emptyClinicTimeConfig } from '@/components/Common/utils/common/emptyData';
import { getUnixTime, startOfDay } from 'date-fns';
import { ClinicContext, useGraphqlClient } from '@/App';
import { AppointBlockPresenter } from '../presenter/AppointBlockPresenter';
import { useNavigate } from '@tanstack/react-location';
import { useBlock_service } from '@/domain/Block/services/hooks';
import { useFacilityNameList_service } from '@/domain';
import { MutateContext } from '@/provider/common/MutateProvider';
import { getEndTime } from '@/components/Common/utils';
import { GetBlockQuery, useGetLatestClinicConfigQuery } from '@/_graphql/graphql-client';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import { useUpdateEndTimeParam } from '@/components/Reservation/components/parts/AppointDetail/hooks/useUpdateEndTimeParam';
import { useCommonParams } from '@/domain/Common/useCommonParams';
import { BlockType } from '../../entities';
import { UseQueryResult } from 'react-query';

interface AppointBlockViewsType {
    blockId?: string;
    startTime: number;
    endTime: number;
    facilityId: string;
    operation: "add" | "reference" | "edit" | "copy" | "update" | undefined
    block: BlockType
    req: UseQueryResult<GetBlockQuery, unknown>
}

interface BlockFormType {
    block: {
        baseStartTime: number
        startTime: number;
        facilityId: string;
        endTime: number;
        clinic_id: string;
        faclityId: string;
        remarks: string;
        color: string;
        isNonAccept: boolean;
        isDelete: boolean;
        id: string;

    };
}

export const AppointBlockViews: React.FC<AppointBlockViewsType> = ({
    blockId,
    startTime,
    endTime,
    facilityId,
    operation,
    block,
    req
}) => {
    const clinic = useContext(ClinicContext);
    const navigate = useNavigate();

    // FIXME: blockが表示された時にformを初期化する
    // blockidがある場合はfetch
    const graphqlClient = useGraphqlClient();

    // formの値の初期化
    const methods = useForm<BlockFormType>({
        defaultValues: {},
    });

    const isBlockLoading = Boolean(blockId) && (req.status === 'loading')

    useEffect(() => {
        if (operation === 'reference') {
            methods.setValue('block', { ...block } as any)
            methods.setValue('block.baseStartTime', block.startTime)
            methods.setValue('block.clinic_id', clinic.clinic_id)
        }
        //FIXME: 依存引数をコンパクトにしたい
    }, [operation, block.id, block.endTime, block.color, block.facilityId, block.remarks])

    // 新規の場合と参照の場合でformを更新する
    // MEMO: 施設と開始時間の変更に反応している
    useEffect(() => {
        switch (operation) {
            case 'add':
                //initNewBlock
                methods.setValue('block.id', '')
                methods.setValue('block.facilityId', facilityId);
                methods.setValue('block.startTime', startTime);
                methods.setValue('block.endTime', Number(endTime));
                methods.setValue('block.clinic_id', clinic.clinic_id);
                methods.setValue('block.color', '#ddd')
                methods.setValue('block.remarks', '')
                break;

            case 'edit':
                //編集モード中の処理はシフト枠(ReservationShiftPresenter)が担保
                alert('editBlocks')
                break;

            case 'update':
                // methods.setValue('block', { ...block } as any)
                methods.setValue('block.facilityId', facilityId);
                methods.setValue('block.startTime', startTime);
                methods.setValue('block.endTime', Number(endTime));
                break

            default:
                break;
        }
    }, [startTime, facilityId])



    const { setEndTime } = useCommonParams()
    // 予約終了時間のクエリパラメータを更新する
    // useUpdateEndTimeParam(setEndTime, 'block', methods, req.status === 'loading');

    const { nowDate } = useContext(SetDateContext)
    const activeClinicTimeConfig = useGetLatestClinicConfigQuery(graphqlClient, { clinic_id: clinic.clinic_id, current_date: getUnixTime(startOfDay(nowDate)), config_type: 'clinic_time' })
    const activeClinicTime = activeClinicTimeConfig.data?.getLatestClinicConfig?.config.clinicTime
    const clinicTime = activeClinicTime ? activeClinicTime : emptyClinicTimeConfig.config.clinicTime

    const mutates = useContext(MutateContext);

    const allFacilityNameList = useFacilityNameList_service(graphqlClient, {
        clinic_id: clinic.clinic_id,
    });
    const facilityNameList = allFacilityNameList.facilityNameList;

    const clinic_ready = clinic.clinic_id !== undefined && clinic.clinic_id !== '';


    const _formData = methods.watch('block');


    return clinic_ready && startTime !== undefined ? (
        <FormProvider {...methods}>
            {_formData?.endTime && req.status !== 'loading' ? (
                <AppointBlockPresenter
                    clinic={clinic}
                    clinicTime={clinicTime}
                    methods={methods}
                    facilityNameList={facilityNameList}
                    mutates={mutates}
                    formData={_formData}
                    navigate={navigate}
                    operation={operation}
                    setEndTime={setEndTime}
                    isBlockLoading={isBlockLoading}
                />
            ) : (
                <></>
            )}
        </FormProvider>
    ) : (
        <></>
    );
};