import { _styledComponentProps } from '@/components/Common'
import React from 'react'

const style = "flex flex-col gap-2"
export const _styledDivRightTop: React.FC<_styledComponentProps> = ({
    children
}) => {
    return (
        <div className={style}>
            {children}
        </div>
    )
}
