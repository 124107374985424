import { FacilityType } from '@/components/Facility/entities';

export type FacilityFrameProps = {
    facility: FacilityType;
    facilityFrameWidth: number;
};
const frameClassName = "border-r-2 border-white text-center border-b-2 h-5 flex justify-center overflow-hidden text-xs";

export const $FacilityLabel: React.FC<FacilityFrameProps> = ({ facility, facilityFrameWidth }) => {
    const frameStyle = {
        width: `${facilityFrameWidth}%`
    };

    return (
        <div style={frameStyle} className={frameClassName}>
            {facility.name}
        </div>
    );
};
