import { FacilityGroupTableElementProps } from "../../../../FacilityGroup/entities"


export const _styledTH: React.FC<FacilityGroupTableElementProps> = ({
    children
}) => {
    return (
        <th scope="col" className="min-w-1/3 bg-gray-50 p-4">
            {children}
        </th>
    )
}
