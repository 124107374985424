import { ClinicConfig, ClinicSchedule, useValidateBookingRuleQuery } from "@/_graphql/graphql-client";
import { ClinicTimeConfigType, TableHeadsType } from "../../entities";
import { ClinicType } from "@/components/Common/entities";
import { GraphQLClient } from "graphql-request";

//hh:mm形式の時間を変換
export function isSomeTime(time1: string, time2: string) {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    const date1 = new Date();
    date1.setHours(hours1, minutes1, 0, 0);

    const date2 = new Date();
    date2.setHours(hours2, minutes2, 0, 0);

    return date1.getTime() === date2.getTime();
}

export function isSelection(selection: string[], config_id: string) {
    return selection.find((selectedConfig_id) => selectedConfig_id === config_id)
}

export const scheduleTableHeads: TableHeadsType = [
    { label: '#', prop: false },
    { label: 'ルール種別', prop: "rule_type" },
    { label: '曜日', prop: "day_of_week" },
    { label: '週', prop: "week" },
    { label: '日', prop: "day" },
    { label: '日付指定', prop: "specific_date" },
    // { label: '有効時間(開始)', prop: "start_time" },
    // { label: '有効時間(終了)', prop: "end_time" },
    { label: '有効期間(開始日)', prop: "start_date" },
    { label: '有効期間(終了日)', prop: "end_date" },
    { label: '診療/休診', prop: "service_day" },
    { label: '作成日', prop: "created_at" },
    { label: '更新日', prop: "updated_at" },
    { label: '', prop: false },
]

export function validateBooking(
    graphqlClient: GraphQLClient,
    clinic: ClinicType,
    _clinicTimeList: ClinicTimeConfigType[],
    // AppointList: ReserveType[],
    updateType: 'add' | 'update' | 'delete',
    // FIXME:型定義
    upsertClinicTime?: any,
) {
    if (_clinicTimeList.length === 0) {
        return true
    }

    // １・コンフィグ一覧からを対象を仮削除or仮更新or仮追加
    let clinicTimeListTmp = _clinicTimeList;
    // ２・条件分岐
    switch (updateType) {
        case 'add':
            clinicTimeListTmp.push(upsertClinicTime)
            break;

        case 'update':
            const updateId = upsertClinicTime.config_id;
            // 対象フィルターで更新
            clinicTimeListTmp = clinicTimeListTmp.filter(
                clinicTime => {
                    if (clinicTime.config_id !== updateId) {
                        return clinicTime
                    } else {
                        return upsertClinicTime
                    }
                }
            )
            break;

        case 'delete':
            // 対象フィルターで削除
            const deleteConfigId = upsertClinicTime.config_id;
            clinicTimeListTmp = clinicTimeListTmp.filter(
                clinicTime => clinicTime.config_id !== deleteConfigId
            )
            break;
    }

    // ３・clinicTimeList一覧から最新のコンフィグを取得
    const sortedItem = clinicTimeListTmp.sort((a, b) => b.startDate - a.startDate);
    const latestItem = sortedItem[0];

    // 4・validateBookingRule（予約バリデーション）の引数を作成
    const validateBookingArgs = {
        clinic_id: clinic.clinic_id,
        ruleStartDate: latestItem.startDate,
        clinicStartTime: latestItem.config.clinicTime.startTime,
        clinicEndTime: latestItem.config.clinicTime.endTime,
    }

    const bookingRule = useValidateBookingRuleQuery(graphqlClient, validateBookingArgs);

    // 5・validateBookingRuleの結果を返却
    const isValid = bookingRule.data?.validateBookingRule?.isValid;
    return isValid;
}

// 設定ソート用関数
export function generateSortedConfigList<T extends ClinicSchedule | ClinicConfig>(configList: T[] | undefined, sortOrder: 'asc' | 'desc', sortBy: 'created_at' | 'updated_at'): T[] {
    if (configList === undefined) return [];

    const sortedConfigList = configList.sort((a, b) => sortByOrder(a, b, sortOrder, sortBy));
    return sortedConfigList;
}

function sortByOrder(a: ClinicSchedule | ClinicConfig, b: ClinicSchedule | ClinicConfig, sortOrder: 'asc' | 'desc', sortBy: 'created_at' | 'updated_at') {
    const factor = sortOrder === 'asc' ? 1 : -1;
    return (a[sortBy] - b[sortBy]) * factor;
};