import { GraphQLClient } from 'graphql-request'
import { useListCategorysQuery } from '@/_graphql/graphql-client'
import { useCategoryListVariablesType } from '@/domain/Category/entities/types';
import { categoryListMapper } from '@/domain/Category/services/mapper/categoryListMapper';

export const useCategoryList_service = (
    graphqlClient: GraphQLClient,
    variables: useCategoryListVariablesType
) => {
    const categorys = useListCategorysQuery(graphqlClient, variables)

    const allCategoryList = categoryListMapper(categorys);
    return allCategoryList;
}