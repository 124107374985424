import { MainProvider } from "@/components/Main"
import FormModeProvider from "@/components/Main/provider/FormModeProvider"
import { PanesProvider } from "@/components/Main/provider/PanesProvider"
import { useFacilityGroup } from "@/domain/FacilityGroup"
import { FacilityGroupContextProvider } from "@/domain/FacilityGroup/useFacilityGroup"

export const MainWindow = (): React.ReactElement => {

    return (
        <MainProvider>
            <FormModeProvider>
                <PanesProvider />
            </FormModeProvider>
        </MainProvider>
    )
}
