import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AddAppointDefaultValuesType } from '../entities';
import { PatientsType } from '@/components/Patient/components/util/common/type';

export const useSetNewPatient = (
    methods: any,
    newPatient: PatientsType
) => {
    useEffect(() => {
        methods.setValue('appointData.id', newPatient.id);
    }, [methods, newPatient]);
};

export default useSetNewPatient;
