import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'

export interface AppointDetailLabelPropsType {
    label: string
}

export const $AppointDetailLabel: React.FC<AppointDetailLabelPropsType> = ({
    label
}) => {
    return (
        <div className="flex items-end">
            <PostAddOutlinedIcon />
            <p className="text-xs">
                <span className="text-red-500 font-bold">
                    {label}
                </span>
            </p>
        </div>
    )
}
