import React from 'react'
import { AppointmentOptionsPresenter } from '../presenter/AppointmentOptionsPresenter'
import { AppointmentOptionsViewsType } from '../utils/common/type'

export const AppointmentOptionsViews: React.FC<AppointmentOptionsViewsType> = ({
  appointFilter,
  setAppointFilter,
  setDateSort,
  dateSort,
  keyword,
  setKeyword,
  setStartDate,
  setEndDate,
  setIsDaily,
}) => {
  return (
    <AppointmentOptionsPresenter 
    appointFilter={appointFilter}
    setAppointFilter={setAppointFilter}
    keyword={keyword}
    setKeyword={setKeyword}
    setStartDate={setStartDate}
    setIsDaily={setIsDaily}
    setEndDate={setEndDate}
    setDateSort={setDateSort}
    dateSort={dateSort}
    />
  )
}
