import { useLocation } from "@tanstack/react-location";

export function useQueryParams() {
    const location = useLocation();

    const getQueryParam = (
        key: string
    ) => {
        return new URLSearchParams(location.current.searchStr).get(key);
    };

    const setQueryParam = (
        key: string,
        value: any
    ) => {
        const searchParams = new URLSearchParams(location.current.searchStr);
        searchParams.set(key, value);

        // URLを新しいクエリパラメータで更新
        location.navigate({
            ...location.current, // 現在のlocationのプロパティをすべてコピー
            searchStr: searchParams.toString() // searchStrだけを上書き
        });
    };

    const resetQueryParams = (paramToReset?: string) => {
        const searchParams = new URLSearchParams(location.current.searchStr);

        if (paramToReset) {
            // 引数で指定されたクエリパラメータだけを削除
            searchParams.delete(paramToReset);
        } else {
            // すべてのクエリパラメータを削除
            searchParams.forEach((value, key) => {
                if (key !== 'calendar-date') {
                    searchParams.delete(key);
                }
            });
        }

        location.navigate({
            ...location.current,
            searchStr: searchParams.toString()
        });
    };


    return { getQueryParam, setQueryParam, resetQueryParams };
}