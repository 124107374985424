export interface RectangleButtonPropsType {
    label: string
    color: 'red' | 'blue'
    size: 'sm' | 'md'
    disable: boolean,
    onClickHandler: () => void
}

export const RectangleButton: React.FC<RectangleButtonPropsType> = ({
    label,
    color,
    size,
    disable,
    onClickHandler
}) => {
    const disabledStyle = disable ? 'opacity-30 pointer-events-none' : '';
    const colorStyle = color === 'red' ? 'bg-red-600' : 'bg-blue-700'

    const smallStyle = size === 'sm' ? 'py-1 px-1 hover:bg-opacity-70 text-white font-medium rounded-sm inline-flex justify-center items-center text-xs' : ''
    const middleStyle = size === 'md' ? 'py-1 w-20 text-white inline-flex justify-center items-center text-xs bg-blue-700 hover:bg-opacity-70 font-medium rounded-sm text-center' : ''

    const commonStyle = 'cursor-pointer'

    const style = `${commonStyle} ${smallStyle} ${middleStyle} ${colorStyle} ${disabledStyle}`

    return (
        <button
            type="button"
            onClick={onClickHandler}
            className={style}
        >
            {label}
        </button>
    )
}