import React, { useState, useEffect } from 'react'
import 'rc-time-picker/assets/index.css'
import { useUpdateShiftMutation } from '../../../../../../../../.graphql/types'
import { useQueryClient } from 'react-query'
import {
  dateFromUnixTime,
  formatDate
} from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon'
import { shiftType } from '@/feature/phr-28/components/common/type'
import {
  workingTimeAm,
  workingTimePm
} from '@/feature/phr-28/components/common/emptyData'
import { useGraphqlClient } from '@/App'

interface ShiftEditModalType {
  isOpen: boolean
  setShowEditModal: (isOpen: boolean) => void
  shift: shiftType
  editElementsNumber: number
}

export const ShiftEditModal: React.FC<ShiftEditModalType> = ({
  isOpen,
  setShowEditModal,
  shift,
  editElementsNumber
}) => {
  const graphqlClient = useGraphqlClient()
  const [shiftsTime, setShiftsTime] = useState(shift.shifts.slice())

  const updateShifts = (i: number): void => {
    setShiftsTime(
      shiftsTime.map((shiftTime, index) =>
        index !== i ? shiftTime : !shiftTime
      )
    )
  }
  // シフト編集
  const queryClient = useQueryClient()
  const { mutate } = useUpdateShiftMutation(graphqlClient, {
    onSettled: () => {
      queryClient.invalidateQueries('listShift').catch(() => console.log('on Settled'))
    },
    onSuccess: (res) => {
      console.log(res)
    },
    onError: (error) => {
      throw error
    },
    retry: 3
  })
  const editShiftData: shiftType = {
    date: shift.date,
    id: shift.id,
    name: shift.name,
    shifts: shiftsTime,
    workingTimes: { am: shift.workingTimes.am, pm: shift.workingTimes.pm }
  }
  const editShift = (): void => {
    mutate(editShiftData)
  }

  const shiftTime = (): JSX.Element => {
    const shiftTime = []

    for (let i = 0; i < shiftsTime.length; i++) {
      if (editElementsNumber === i) {
        shiftTime.push(
          <div className="flex items-center mb-4 w-20">
            <input
              id="default-checkbox"
              type="checkbox"
              value={i}
              onChange={() => {
                updateShifts(i)
              }}
              checked={shiftsTime[i]}
              className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="default-checkbox"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              {i < 28 && workingTimeAm[i]}
              {i >= 28 && workingTimePm[i - 28]}
            </label>
          </div>
        )
      }
    }

    useEffect(() => {
      setShiftsTime(shift.shifts.slice())
    }, [shift])

    return (
      <>
        <div className="p-2">
          <div>
            <label
              htmlFor="am"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              選択勤務時間:
            </label>
            <div className="flex flex-col mb-4">{shiftTime}</div>
          </div>
        </div>
      </>
    )
  }

  const modalElement = (
    <>
      <div>
        <div
          id="overlay"
          className="fixed top-0 z-[99] left-0 w-full h-full bg-gray-500 flex align-middle justify-center bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                onClick={() => setShowEditModal(false)}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="authentication-modal"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>

              <div className="py-6 px-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  シフト編集
                </h3>
                <form className="space-y-3" action="/masta">
                  <div>
                    <label
                      htmlFor="roles"
                      className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-400"
                    >
                      登録日付
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      value={formatDate(dateFromUnixTime(shift.date))}
                      disabled
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      名前
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      value={shift.name}
                      disabled
                    />
                  </div>

                  {shiftTime()}

                  <button
                    type="submit"
                    className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={() => editShift()}
                  >
                    保存
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  return <>{isOpen && modalElement}</>
}
