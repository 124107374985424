import { ListClinicTimeConfigQuery } from "@/_graphql/graphql-client";
import { ClinicTimeConfigType } from "@/components/ClinicSettings/entities";
import { AddClinicTimeConfigType } from "@/components/Common/entities/common/types";
import { UseQueryResult } from "react-query";
import { emptyClinicTimeConfig } from "@/components/Common/utils/common/emptyData";

export const configMapper = (
    _clinicConfig: UseQueryResult<ListClinicTimeConfigQuery, unknown>
) => {
    let clinicConfig: ClinicTimeConfigType[] = [];

    _clinicConfig.data?.listClinicConfig.forEach((clinicTimeConfig) => {
        if (clinicTimeConfig) {
            const mappedClinicTimeConfig = Object.assign({ ...emptyClinicTimeConfig }, clinicTimeConfig)
            clinicConfig.push(mappedClinicTimeConfig)
        }
    })

    return clinicConfig;
}