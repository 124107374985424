import { AddAppointType, DeleteAppointType, UpdateAppointInfoType, UpdateAppointType } from '@/components/Common';
import { useEffect } from 'react';
import { UseFormReset } from 'react-hook-form';
import { handleAddAppointError, handleAddAppointSuccess, handleDeleteAppointError, handleDeleteAppointSuccess, handleUpdateAppointError, handleUpdateAppointInfoError, handleUpdateAppointInfoSuccess, handleUpdateAppointSuccess } from '../components/ModalContents/utils/AddAppointSubmit';
import { AddAppointDefaultValuesType } from '../entities';

export function useAppointHandlers(addAppoint: AddAppointType, updateAppoint: UpdateAppointType, updateAppointInfo: UpdateAppointInfoType, deleteAppoint: DeleteAppointType, reset: UseFormReset<AddAppointDefaultValuesType>) {
  // 新規予約成功時の処理
  useEffect(() => {
    if (addAppoint.isSuccess)
      handleAddAppointSuccess(addAppoint);
    reset()
  }, [addAppoint.isSuccess]);

  // 予約更新成功時の処理
  useEffect(() => {
    if (updateAppoint.isSuccess)
      handleUpdateAppointSuccess(updateAppoint);
    reset()

  }, [updateAppoint.isSuccess]);

  // 予約情報更新成功時の処理
  useEffect(() => {
    if (updateAppointInfo.isSuccess)
      handleUpdateAppointInfoSuccess(updateAppointInfo);
    reset()
  }, [updateAppointInfo.isSuccess]);

  // 予約削除成功時の処理
  useEffect(() => {
    if (deleteAppoint.isSuccess)
      handleDeleteAppointSuccess(deleteAppoint);
    reset()
  }, [deleteAppoint.isSuccess]);

  // 新規予約エラー時の処理
  useEffect(() => {
    if (addAppoint.isError)
      handleAddAppointError(addAppoint);
    reset()
  }, [addAppoint.isError]);

  // 予約更新エラー時の処理
  useEffect(() => {
    if (updateAppoint.isError)
      handleUpdateAppointError(updateAppoint);
    reset()
  }, [updateAppoint.isError]);

  // 予約情報更新エラー時の処理
  useEffect(() => {
    if (updateAppointInfo.isError)
      handleUpdateAppointInfoError(updateAppointInfo);
    reset()
  }, [updateAppointInfo.isError]);

  // 予約削除エラー時の処理
  useEffect(() => {
    if (deleteAppoint.isError)
      handleDeleteAppointError(deleteAppoint);
    reset()
  }, [deleteAppoint.isError]);
}
