import { DateRangePickerType } from "@/components/ClinicSettings/entities";
import { endOfDay, fromUnixTime, getUnixTime, startOfDay } from "date-fns";
import DatePicker from "react-datepicker";
import XCircle from "@heroicons/react/outline/XCircleIcon";

// [フォームパーツ]日付範囲選択
export function DateRangePicker({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isNullable
}: DateRangePickerType): JSX.Element {
    return (
        <>
            <td>
                <div className="relative h-full flex items-center">
                    {/* 開始 */}
                    <DatePicker
                        popperProps={{ strategy: 'fixed' }}
                        className="text-xs w-[75%]"
                        selected={startDate ? fromUnixTime(startDate) : null}
                        onChange={(date) => {
                            if (date === null) return

                            setStartDate(getUnixTime(startOfDay(date)))
                            // 終了日が開始日より前の場合、開始日の翌日に自動的に設定する
                            if (endDate && date >= fromUnixTime(endDate)) {
                                setEndDate(getUnixTime(endOfDay(date)));
                            }
                        }}
                        dateFormat="yyyy/MM/dd"
                        minDate={isNullable ? null : startOfDay(new Date())}
                    />
                    {((isNullable && startDate !== null)) &&
                        <XCircle className="h-4 w-4 absolute right-2"
                            onClick={() => {
                                setStartDate(null)
                                setEndDate(null)
                            }
                            }
                        />
                    }
                </div>
            </td>
            <td>
                <div className="relative h-full flex items-center">
                    {/* 終了 */}
                    <DatePicker
                        popperProps={{ strategy: 'fixed' }}
                        className="text-xs w-[75%]"
                        selected={endDate ? fromUnixTime(endDate) : null}
                        onChange={(date) => {
                            if (date !== null) { // データがnullではないことを確認します
                                setEndDate(getUnixTime(endOfDay(date)))
                            } else {
                                setEndDate(null); // 日付が無効または削除された場合、nullをセット
                            }
                        }}

                        dateFormat="yyyy/MM/dd"
                        minDate={isNullable ? null : fromUnixTime(startDate || 0)}
                    />
                    {((isNullable && endDate !== null)) &&
                        <XCircle className="h-4 w-4 absolute right-2"
                            onClick={() => {
                                setEndDate(null)
                            }
                            }
                        />}
                </div>
            </td>
        </>
    )
}