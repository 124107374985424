import { _styledComponentProps } from "@/components/Common"

const outerDivStyle = "flex w-full items-center gap-1 pb-1 pl-1 text-xl spacing-ls tracking-wider border-b border-gray-400"

export const _styledDivTop: React.FC<_styledComponentProps> = ({
    children
}) => {
    return (
        <div className={outerDivStyle}>
            {children}
        </div>
    )
}
