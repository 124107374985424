/**
 * AppointmentListByPatientsViewsコンポーネント内の予約履歴表示部分のコンポーネント
 */

import { listManegerType, MenuType } from "@/feature/phr-28/components/common/type";
import { ScrollArea } from "@mantine/core";
import { CalendarIcon, UsersIcon } from "@heroicons/react/solid";
import { Link, useLocation, useNavigate } from '@tanstack/react-location';
import { getMenuAbbreviation } from "@/components/Common/utils";
import { japanTimeFormatDate } from "@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon";
import { fromUnixTime, getUnixTime } from "date-fns";
import { getManegerNameById } from '@/components/Common/utils';

import { AppointmentStatusBadge } from "@/components/Appointment/components/AppointmentListByPatients/presenter/AppointmentStatusBadge";
import { AppointmentListByPatientsPresenterPropsType } from "@/components/Appointment/entities"
import { useContext } from "react";
import { AppointModeContext, MethodsContext } from "@/components/Main/provider/FormModeProvider";
import { useAppointParams } from "@/domain/Appointment/useAppointParams";
import { Appoint } from "@/_graphql/graphql-client";

function rewindPathname(pathname: string) {
    const editIndex = pathname.lastIndexOf('/edit');
    const updateIndex = pathname.lastIndexOf('/update');

    const index = Math.max(editIndex, updateIndex);

    if (index === -1) {
        // URLに /edit または /update が含まれていない場合は /edit を末尾に追加する
        const newPathname = pathname.endsWith('/') ? `${pathname}edit` : `${pathname}/edit`;
        return newPathname;
    } else {
        // URLに /edit または /update が含まれている場合は、その手前までのパスを返す
        const pathList = pathname.slice(0, index).split('/');
        const newPathname = `${pathList.join('/')}/edit`;
        return newPathname;
    }
}

function removeEditAndUpdate(pathname: string) {
    let newPathname = pathname.replace(/\/edit/g, '').replace(/\/update/g, '');
    newPathname = newPathname.replace(/\/$/, ''); // 末尾のスラッシュを削除
    return newPathname;
}


export const AppointmentListByPatientsPresenter: React.FC<AppointmentListByPatientsPresenterPropsType> = ({
    nowDate,
    appoints,
    pathname
}) => {

    //FIXME: url周辺リファクタリング
    const { setAppointParams } = useAppointParams()
    const { setIsAppointEdit } = useContext(AppointModeContext)
    const { methods } = useContext(MethodsContext)
    const searchStr = useLocation().current.searchStr
    const navigate = useNavigate()
    const path = rewindPathname(pathname);
    const contentsType = pathname.includes('appoints-list') ? 'appoints-list' : 'patients-list'
    const patientInfo = pathname.includes('appoints-list') ? '' : '/patient-info/'

    const cancelReasons = ['患者都合', '医院都合', '無断キャンセル']

    return (
        <>
            {
                <div className="bg-white shadow border border-gray-200 rounded-md">
                    <ScrollArea style={{ height: 500 }} type="scroll" scrollbarSize={6} scrollHideDelay={300}>
                        {
                            appoints &&
                            <ul role="list">
                                {/* FIXME: ネストが深すぎるので予約アイテムは別コンポーネントに分割を検討 */}
                                {appoints.map((appoint, index) => (
                                    <li
                                        key={appoint?.id + appoint?.startTime + appoint?.facilityId}
                                        className={`border-b border-gray-200`}
                                    >
                                        <div className="flex items-center px-4 py-2 sm:px-6">
                                            <div className="mr-4">
                                                <p className="text-xs">{index + 1}</p>
                                            </div>
                                            <div className="flex-1">
                                                <div className="flex">
                                                    <div className="flex items-center justify-between">
                                                        <p className="truncate text-sm font-medium text-indigo-600">{appoint.menuName}</p>
                                                        <div className="ml-2 flex flex-shrink-0">
                                                            <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-end gap-2">
                                                    {
                                                        appoint.isLate === true &&
                                                        <span className="text-red-600 text-xs font-bold">遅刻</span>
                                                    }
                                                    {appoint.cancel?.isCancel === true &&
                                                        <span className="text-red-600 text-xs font-bold">{cancelReasons[appoint?.cancel?.radioCancelReason || 0]}</span>
                                                    }
                                                    <AppointmentStatusBadge
                                                        appoint={appoint?.appoint || ''}
                                                        id={appoint.id}
                                                        confirmed={{ start: appoint.startTime, end: appoint.endTime }}
                                                    />
                                                </div>
                                                <div>
                                                    <div className="mt-2 flex items-center text-sm sm:mt-0 ml-2">
                                                        <CalendarIcon className="mr-1.5 h-4 w-4 flex-shrink-0" aria-hidden="true" />
                                                        <p className="text-xs">
                                                            <>
                                                                {/* 
                                                                FIXME: ここで担当者名の付き合わせをせずに、Viewer側でAppointListにプロパティを追加する方がベター
                                                            */}
                                                                {japanTimeFormatDate(fromUnixTime(appoint?.startTime))} ~
                                                            </>
                                                        </p>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="sm:flex ml-2">
                                                            <p className="flex items-center text-xs">
                                                                <UsersIcon className="mr-1.5 h-4 w-4 flex-shrink-0" aria-hidden="true" />
                                                                {/* FIXME: ここで担当者名の付き合わせをせずに、Viewer側でAppointListにプロパティを追加する方がベター...? */}
                                                                {appoint.staffNames[0].name}
                                                            </p>
                                                            <p className="mt-2 flex items-center text-xs text-gray-500 sm:mt-0 sm:ml-6">
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-1 justify-end items-end gap-x-2">
                                                            <Link to={`/main/${contentsType}${patientInfo}/appoint-info?calendar-date=${getUnixTime(nowDate)}&patient-id=${appoint.id}&start-time=${appoint.startTime}&end-time=${appoint.endTime}&facility-id=${appoint.facilityId}&appoint-id=${appoint.appointId}`}>
                                                                <button type="button" className="h-5 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-xs px-5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 bg-blue-700 hover:bg-blue-800">
                                                                    詳細
                                                                </button>
                                                            </Link>
                                                            <button
                                                                onClick={() => {
                                                                    const copyAppoint = { ...appoint, appointId: '0' }
                                                                    methods.setValue('appointData', { ...copyAppoint as Appoint, appoint: '予約済み', isLate: false })
                                                                    setAppointParams(getUnixTime(nowDate), appoint.id, appoint.startTime, appoint.endTime, appoint.facilityId, appoint.appointId, true)
                                                                }}
                                                                type="button" className="h-5 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-xs px-5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 bg-blue-700 hover:bg-blue-800">
                                                                追加
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>}
                    </ScrollArea>
                </div>
            }
        </>
    )
}