import { GraphQLClient } from 'graphql-request'
import { useListFacilitieNamesQuery } from "@/_graphql/graphql-client"
import { facilityNameListMapper } from '@/domain/Facility/services/mapper/facilityListMapper';
import { useFacilityNameListVariablesType } from '@/domain/Facility/entities/types';

export const useFacilityNameList_service = (
    graphqlClient: GraphQLClient,
    variables: useFacilityNameListVariablesType
) => {
    const facilityNames = useListFacilitieNamesQuery(graphqlClient, variables);
    const allFacilityNameLists = facilityNameListMapper(facilityNames);
    return allFacilityNameLists;
}
