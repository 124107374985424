import { ErrorBoundary } from "@/components/Common/components"
import { AppointHeader } from "../parts/AppointSchedule/AppointHeader/AppointHeader"
import { PrintWrapper } from "../parts/AppointSchedule/AppointHeader/PrintWrapper"
import { useRefreshTimer } from "../../hooks/useRefreshTimer"
import { ReserveType } from "../../entities"
import { AppointDetails } from "@/_graphql/graphql-client"
import { AppointShiftPresenter } from "./AppointShiftPresenter"

//FIXME: type定義
interface AppointPresenterPropsType {
    //ヘッダー
    printRef: any
    subPanesRef: any
    isRefetching: boolean
    nowDate: Date
    setNowDate: React.Dispatch<React.SetStateAction<Date>>
    isAllTime: boolean
    setIsAllTime: React.Dispatch<React.SetStateAction<boolean>>
    setFacilitiesFilter: React.Dispatch<React.SetStateAction<string>>
    //シフト枠
    isHoliday: boolean | null | undefined
    isBeforeDay: boolean | null | undefined
    setIsPaneOpen: React.Dispatch<React.SetStateAction<boolean>> | null
    shiftFrameCount: { start: number, end: number }
    queryClient: any
    search: Partial<any>
    pathname: string
    operation: "add" | "edit" | "update" | "copy" | "reference" | undefined
    shiftTime: { startTime: { hours: number, minutes: number }, endTime: { hours: number, minutes: number } }
    patientId: string
    blockId: string

    startTime: number
    endTime: number

    facilityId: string
    appointId: string
    viewSizes: { width: number, height: number }
    navigate: any
    searchStr: string
    clinic: any
    allReservationList: AppointDetails[]
    allBlockList: any
    orderedFacilityList: any
}

export const AppointPresenter: React.FC<AppointPresenterPropsType> = ({
    //ヘッダー
    printRef,
    subPanesRef,
    isRefetching,
    nowDate,
    setNowDate,
    isAllTime,
    setIsAllTime,
    setFacilitiesFilter,

    //シフト枠
    isHoliday,
    isBeforeDay,
    setIsPaneOpen,
    shiftFrameCount,
    queryClient,
    search,
    pathname,
    operation,
    shiftTime,
    patientId,
    blockId,

    startTime,
    endTime,

    facilityId,
    appointId,

    viewSizes,
    navigate,
    searchStr,
    clinic,
    allReservationList,
    allBlockList,
    orderedFacilityList,
}) => {


    //画面の定期更新
    useRefreshTimer(setNowDate);

    return (
        <>
            {/* ヘッダー */}
            <ErrorBoundary>
                <AppointHeader
                    printRef={printRef}
                    appointIsLoading={isRefetching}
                    subPanesRef={subPanesRef}
                    nowDate={nowDate}
                    setNowDate={setNowDate}
                    allReservationList={allReservationList}
                    isAllTime={isAllTime}
                    setIsAllTime={setIsAllTime}
                    setFacilitiesFilter={setFacilitiesFilter}
                />
            </ErrorBoundary>

            {/* シフト枠 */}
            <ErrorBoundary>
                {
                    <AppointShiftPresenter
                        // pring
                        printRef={printRef}
                        // 休診日判定
                        isHoliday={isHoliday || false}
                        isBeforeDay={isBeforeDay || false}
                        // pain関連
                        viewSizes={viewSizes}
                        subPanesRef={subPanesRef}
                        setIsPaneOpen={setIsPaneOpen}
                        // shift枠生成
                        clinicTime={clinic.clinicTime}
                        isAllTime={isAllTime}
                        setIsAllTime={setIsAllTime}
                        shiftFrameStart={shiftFrameCount.start}
                        shiftFrameEnd={shiftFrameCount.end}
                        //シフト枠開始時間
                        shiftStartHours={shiftTime.startTime.hours}
                        shiftEndHours={shiftTime.endTime.hours}
                        shiftStartMinutes={shiftTime.startTime.minutes}
                        shiftEndMinutes={shiftTime.endTime.minutes}
                        // FIXME: viewsで処理
                        // queryClient
                        queryClient={queryClient}
                        // オペレーション
                        search={search}
                        pathname={pathname}
                        operation={operation}
                        // searchパラメータ
                        patientId={patientId}
                        blockId={blockId}
                        startTime={startTime}
                        endTime={endTime}
                        facilityId={facilityId}
                        appointId={appointId}
                        navigate={navigate}
                        searchStr={searchStr}
                        //医院メタデータ
                        clinic={clinic}
                        // メディアクエリ
                        matches={false}
                        // 選択日付
                        nowDate={nowDate}
                        setNowDate={setNowDate}
                        // 各種一覧
                        allReservationList={allReservationList}
                        allBlockList={allBlockList}
                        facilityList={orderedFacilityList}
                    />
                }
            </ErrorBoundary>
        </>
    )
}

export function countAppointStatuses(
    appointList: AppointDetails[],
) {
    const numberOfReserved = appointList?.filter(appoint => appoint.appoint == '予約済み').length || 0
    const numberOfCheckedIn = appointList?.filter(appoint => appoint.appoint == '受付済み').length || 0
    const numberOfCompleted = appointList?.filter(appoint => appoint.appoint == '診療完了').length || 0
    const numberOfCanceled = appointList?.filter(appoint => appoint.appoint == 'キャンセル').length || 0

    return {
        numberOfReserved,
        numberOfCheckedIn,
        numberOfCompleted,
        numberOfCanceled
    }
}
