import { ClinicScheduleType } from "@/_graphql/graphql-client"
import { RuleTypeSelectType } from "@/components/ClinicSettings/entities"
import { optionSlots } from "@/components/ClinicSettings/utils"
import { nullableOptionSlots } from "@/components/ClinicSettings/utils/libs/optionSlots"
import { XCircleIcon } from "@heroicons/react/outline"

// [フォームパーツ]ルールタイプ選択
export const RuleTypeSelect = ({
    rule_type,
    resetAllFilters,
    setRuleType,
    isNullable
}: RuleTypeSelectType): JSX.Element => {

    const baseOptionSlots = isNullable ? nullableOptionSlots : optionSlots

    return (
        <td>
            <div className="h-full w-full relative flex items-center">
                {/* ルールタイプ */}
                {
                    <select
                        className='w-full h-full text-xs'
                        onChange={(e) => {
                            if (resetAllFilters) {
                                resetAllFilters()
                            }
                            setRuleType(e.currentTarget.value as ClinicScheduleType)
                        }}
                    >
                        {baseOptionSlots.rule_type.map((slot, index) => (
                            // FIXME: any型のtype定義
                            <option key={index} value={slot.value as any} selected={rule_type === slot.value}>{slot.label}</option>
                        ))}
                    </select>
                }
                {((isNullable && rule_type !== null)) &&
                    <XCircleIcon className="h-4 w-4 absolute right-2 bg-white"
                        onClick={() => {
                            if (resetAllFilters) {
                                resetAllFilters()
                            }
                        }
                        }
                    />
                }
            </div>
        </td>
    )
}