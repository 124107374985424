import { Menu, Transition } from '@headlessui/react'
import DotsVerticalIcon from '@heroicons/react/solid/DotsVerticalIcon'
import LogoutIcon from '@heroicons/react/solid/LogoutIcon'
import { useAuth0 } from '@auth0/auth0-react'
import Settings from '@mui/icons-material/SettingsOutlined'
import { useNavigate } from '@tanstack/react-location'
import { ClinicType } from '@/components/Common'

interface KebabMenuType {
    clinicList: ClinicType[]
}

//役割
//各医院への遷移 + ログアウト
//備考: ヘッダー右端に配置している縦三点アイコンがこのコンポーネントです
export const KebabMenu: React.FC<KebabMenuType> = ({
    clinicList
}) => {
    const { logout } = useAuth0();
    const navigate = useNavigate()

    return (
        <Menu as="div" className="relative inline-block text-left z-[9999]">
            <div>
                <Menu.Button className="flex items-center text-gray-600 focus:outline-none">
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>
            <Transition
                as={'div'}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                className="z-1000"
            >
                <>
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {/* 医院リスト */}
                        {
                            clinicList.length ?
                                <div className="py-1">
                                    {
                                        clinicList.map(clinic => {
                                            const clinicUrl = getClinicUrl(clinic);
                                            return (
                                                <Menu.Item key={clinic.clinic_id + 1}>
                                                    <a
                                                        href={clinicUrl}
                                                        className='hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm'
                                                    >
                                                        {`${clinic.clinic_name}`}
                                                    </a>
                                                </Menu.Item>
                                            )
                                        })
                                    }
                                </div>
                                :
                                null
                        }

                        {/* セッティングス */}
                        <Menu.Item>
                            <p
                                onClick={() => { navigate({ to: '/settings', fromCurrent: false }) }}
                                className='flex items-center gap-x-2 cursor-pointer border-t border-gray-200 hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm'
                            >
                                <Settings className="h-5 text-gray-500" />
                                <span>設定</span>
                            </p>
                        </Menu.Item>
                        {/* ログアウト */}
                        <Menu.Item>
                            <p
                                onClick={() => { logout() }}
                                className='flex items-center gap-x-2 cursor-pointer border-t border-gray-200 hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm'
                            >
                                <LogoutIcon className="h-5 text-gray-500" />
                                <span>ログアウト</span>
                            </p>
                        </Menu.Item>
                    </Menu.Items>
                </>
            </Transition>
        </Menu>
    )
}

function getClinicUrl(clinic: ClinicType) {
    const VITE_FRONTEND_DOMAIN = process.env.VITE_FRONTEND_DOMAIN
    let clinicUrl = `https://${clinic.clinic_label}.${clinic.org_label}.${VITE_FRONTEND_DOMAIN}`

    if (location.origin.includes('local')) {
        clinicUrl = `https://${clinic.clinic_label}.${clinic.org_label}.local.${VITE_FRONTEND_DOMAIN}`
    }

    return clinicUrl
}