import { useMediaQuery } from '@mantine/hooks';
import React, { createContext, useContext, ReactNode } from 'react';

// IsMobileContextの型定義
interface IsMobileContextType {
    isMobile: boolean;
}

// IsMobileContextの初期値と型を設定
const IsMobileContext = createContext<IsMobileContextType | undefined>(undefined);

export const IsMobileContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    //iPhoneXのサイズ
    const isMobile = useMediaQuery('(max-width: 928px)');

    return (
        <IsMobileContext.Provider value={{ isMobile }}>
            {children}
        </IsMobileContext.Provider>
    );
}

// IsMobileContextの値を取得するためのカスタムフック
export const useIsMobileContext = (): IsMobileContextType => {
    const context = useContext(IsMobileContext);
    if (!context) {
        throw new Error('useIsMobileContext must be used within an IsMobileContextProvider');
    }
    return context;
};
