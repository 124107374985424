import { ToggleButtonProps } from "@/components/Common/entities";

export const ToggleButton: React.FC<ToggleButtonProps> = ({
    label,
    color,
    width,
    isOpen,
    setIsOpen,
    onClickEvent
}) => {
    //ボタン色
    const buttonColor = color === 'red' ? 'bg-red-400 hover:bg-red-500' : 'bg-gray-700 hover:bg-gray-700'
    //ボタン幅
    const buttonWidth = width === 'small' ? 'w-24' : 'w-32'
    //共通スタイル
    const commonStyle = "leading-8 mr-2 text-gray-100 text-sm font-medium h-8 text-center rounded-2xl"
    //スタイル
    const style = `${commonStyle} ${buttonWidth} ${buttonColor}`

    return (
        <button
            type="button"
            onClick={() => {
                onClickEvent()
                setIsOpen(!isOpen)
            }}
            className={style}
        >
            {isOpen ? 'キャンセル' : label}
        </button>
    );
}