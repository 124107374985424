import React from 'react';
import { AppointmentOptionsPresenterType } from '../utils/common/type';
import { OutlineType } from '@/components/Common/entities/parts';
import {
  CheckboxFilterItemType,
  DateRangeEndInputType,
  DateRangeStartInputType,
  KeywordInputType,
  SwitchSortType,
} from '../utils/components/parts';

export const AppointmentOptionsPresenter: React.FC<AppointmentOptionsPresenterType> = ({
  appointFilter,
  setAppointFilter,
  keyword,
  setKeyword,
  setStartDate,
  setIsDaily,
  setEndDate,
  setDateSort,
  dateSort,
}) => {
  return (
    <Outline>
      {/* チェックボックス検索 */}
      <CheckboxFilterOutline>
        <CheckboxFilterUpperSectionOutline>
          {/* 全て */}
          <CheckboxFilterAllAppointItem
            appointFilter={appointFilter}
            setAppointFilter={setAppointFilter}
          />
          {/* 受付済み */}
          <CheckboxFilterAccetptedAppointItem
            appointFilter={appointFilter}
            setAppointFilter={setAppointFilter}
          />
        </CheckboxFilterUpperSectionOutline>

        <CheckboxFilterLowerSectionOutline>
          {/* 予約済み */}
          <CheckboxFilterConfirmAppointItem
            appointFilter={appointFilter}
            setAppointFilter={setAppointFilter}
          />
          {/* キャンセル */}
          <CheckboxFilterUnsettledAppointItem
            appointFilter={appointFilter}
            setAppointFilter={setAppointFilter}
          />
        </CheckboxFilterLowerSectionOutline>
      </CheckboxFilterOutline>

      {/* キーワード検索 */}
      <KeywordFilterOutline>
        <KeywordInput
          name="keyword-search"
          id="keyword-search"
          placeholder="ワード検索"
          keyword={keyword}
          setKeyword={setKeyword}
        />
        <SearchIcon />
      </KeywordFilterOutline>

      {/* 日付範囲検索 */}
      <DateRangeFilterOutline>
        <DateRangeStartInput setStartDate={setStartDate} setIsDaily={setIsDaily} />
        <p className="mx-2 text-sm">〜</p>
        <DateRangeEndInput setEndDate={setEndDate} setIsDaily={setIsDaily} />
      </DateRangeFilterOutline>

      {/* ソート */}
      <SwitchSort setDateSort={setDateSort} dateSort={dateSort}>
        {dateSort === 'ASC' && <AscSortIcon />}
        {dateSort === 'DESC' && <DescSortIcon />}
      </SwitchSort>
    </Outline>
  );
};

const Outline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'flex items-center justify-between pb-2 sm:pb-2';
  return <li className={outlineContainerStyle}>{children}</li>;
};

const CheckboxFilterOutline: React.FC<OutlineType> = ({ children }) => {
  const textStyle = 'mb-1 text-xs';
  return (
    <div>
      <p className={textStyle}>フィルタリング</p>
      {children}
    </div>
  );
};

const CheckboxFilterUpperSectionOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'flex';
  return <div className={outlineContainerStyle}>{children}</div>;
};

const CheckboxFilterAllAppointItem: React.FC<CheckboxFilterItemType> = ({
  appointFilter,
  setAppointFilter,
}) => {
  const containerStyle = 'relative flex items-center h-5';
  const inputStyle = 'focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded';
  const labelStyle = 'font-medium text-gray-700 ml-3 text-xs w-20';
  return (
    <div className={containerStyle}>
      <input
        id="all"
        aria-describedby="comments-description"
        name="all"
        type="checkbox"
        checked={appointFilter === '全て'}
        onChange={() => setAppointFilter('全て')}
        className={inputStyle}
      />
      <label htmlFor="all" className={labelStyle}>
        全て
      </label>
    </div>
  );
};

const CheckboxFilterAccetptedAppointItem: React.FC<CheckboxFilterItemType> = ({
  appointFilter,
  setAppointFilter,
}) => {
  const containerStyle = 'relative flex items-center h-5';
  const inputStyle = 'focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded';
  const labelStyle = 'font-medium text-gray-700 ml-3 text-xs w-20';
  return (
    <div className={containerStyle}>
      <input
        id="accepted"
        aria-describedby="comments-description"
        name="accepted"
        type="checkbox"
        checked={appointFilter === '受付済み'}
        onChange={() => setAppointFilter('受付済み')}
        className={inputStyle}
      />
      <label htmlFor="accepted" className={labelStyle}>
        受付済み
      </label>
    </div>
  );
};

const CheckboxFilterLowerSectionOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'flex';
  return <div className={outlineContainerStyle}>{children}</div>;
};

const CheckboxFilterConfirmAppointItem: React.FC<CheckboxFilterItemType> = ({
  appointFilter,
  setAppointFilter,
}) => {
  const containerStyle = 'relative flex items-center h-5';
  const inputStyle = 'focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded';
  const labelStyle = 'font-medium text-gray-700 ml-3 text-xs w-20';
  return (
    <div className={containerStyle}>
      <input
        id="appoint-confirm"
        aria-describedby="comments-description"
        name="appoint-confirm"
        type="checkbox"
        checked={appointFilter === '予約済み'}
        onChange={() => setAppointFilter('予約済み')}
        className={inputStyle}
      />
      <label htmlFor="appoint-confirm" className={labelStyle}>
        予約済み
      </label>
    </div>
  );
};

const CheckboxFilterUnsettledAppointItem: React.FC<CheckboxFilterItemType> = ({
  appointFilter,
  setAppointFilter,
}) => {
  const containerStyle = 'relative flex items-center h-5';
  const inputStyle = 'focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded';
  const labelStyle = 'font-medium text-gray-700 ml-3 text-xs w-20';
  return (
    <div className={containerStyle}>
      <input
        id="appoint-Unsettled"
        aria-describedby="comments-description"
        name="appoint-Unsettled"
        type="checkbox"
        checked={appointFilter === 'キャンセル'}
        onChange={() => setAppointFilter('キャンセル')}
        value="キャンセル"
        className={inputStyle}
      />
      <label htmlFor="appoint-Unsettled" className={labelStyle}>
        キャンセル
      </label>
    </div>
  );
};

const KeywordFilterOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'relative flex h-6 w-48 overflow-hidden';
  return <div className={outlineContainerStyle}>{children}</div>;
};

const KeywordInput: React.FC<KeywordInputType> = ({
  setKeyword,
  keyword,
  name,
  id,
  placeholder,
}) => {
  const inputStyle = 'focus:ring-indigo-500 focus:border-indigo-500 block pr-10 border-gray-300 rounded h-full w-full py-[0.5rem] px-[0.75rem]'
  return (
    <input
      onChange={(e) => setKeyword(e.currentTarget.value)}
      value={keyword}
      type="text"
      name={name}
      id={id}
      className={inputStyle}
      placeholder={placeholder}
    />
  );
};

const SearchIcon: React.FC = () => {
  const containerStyle= 'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'
  return (
    <div className={containerStyle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </div>
  );
};

const DateRangeFilterOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineContainerStyle = 'flex items-center flex h-6 w-96 overflow-hidden'
  return <div className={outlineContainerStyle}>{children}</div>;
};

const DateRangeStartInput: React.FC<DateRangeStartInputType> = ({ setStartDate, setIsDaily }) => {
  const inputStyle = 'flex justify-center items-center border border-[#ccc] text-[#aaa] rounded h-full w-full py-[0.5rem] px-[0.75rem]'
  return (
    <input
      className={inputStyle}
      type="date"
      name="startDate"
      onChange={(e) => {
        setStartDate(e.target.value);
        setIsDaily(false);
      }}
    />
  );
};

const DateRangeEndInput: React.FC<DateRangeEndInputType> = ({ setEndDate, setIsDaily }) => {
  const inputStyle = 'flex justify-center items-center border border-[#ccc] text-[#aaa] rounded h-full w-full py-[0.5rem] px-[0.75rem]'
  return (
    <input
      className={inputStyle}
      type="date"
      name="endDate"
      onChange={(e) => {
        setEndDate(e.target.value);
        setIsDaily(false);
      }}
    />
  );
};

const SwitchSort: React.FC<SwitchSortType> = ({ setDateSort, dateSort, children }) => {
  const containerStyle = 'flex items-center gap-2'
  const textStyle = 'text-xs'
  return (
    <div
      className={containerStyle}
      onClick={() => setDateSort(dateSort === 'ASC' ? 'DESC' : 'ASC')}
    >
      {children}
      <p className={textStyle}>日付ソート</p>
    </div>
  );
};

const AscSortIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
      />
    </svg>
  );
};

const DescSortIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
    </svg>
  );
};
