
import { GraphQLClient } from 'graphql-request'
import {
    // get関連
    useGetClinicScheduleByClinicIdQuery,        // GraphQLクライアント
    GetClinicScheduleByClinicIdQueryVariables,  // 引数型
    ClinicSchedule,                             // 戻り値型

    // list関連
    useListClinicSchedulesByClinicIdQuery,
    ListClinicSchedulesByClinicIdQueryVariables,
    ClinicSchedules,
} from '@/_graphql/graphql-client'

export const useGetClinicSchedule_service = (
    graphqlClient: GraphQLClient,
    variables: GetClinicScheduleByClinicIdQueryVariables
): ClinicSchedule => {
    // graphqlクライアントでフェッチしたデータをmapperに渡す
    const clinicSchedule = useGetClinicScheduleByClinicIdQuery(
        graphqlClient,
        {
            clinic_id: variables.clinic_id,
            clinic_schedule_id: variables.clinic_schedule_id
        }
    )

    return clinicSchedule as unknown as ClinicSchedule;
}

export const useListClinicSchedule_service = (
    graphqlClient: GraphQLClient,
    variables: ListClinicSchedulesByClinicIdQueryVariables
): ClinicScheduleServiceResponse => {
    // graphqlクライアントでフェッチしたデータをmapperに渡す
    const clinicSchedules = useListClinicSchedulesByClinicIdQuery(
        graphqlClient,
        {
            clinic_id: variables.clinic_id,
        }
    )
    return {
        status: clinicSchedules.status,
        clinicSchedules: clinicSchedules.data?.listClinicSchedulesByClinicId
    }
}

interface ClinicScheduleServiceResponse {
    status: string;
    clinicSchedules: ClinicSchedules | undefined;
}