import React from 'react'
import 'rc-time-picker/assets/index.css'
import { useDeleteShiftMutation } from '../../../../../../../../.graphql/types'
import { useQueryClient } from 'react-query'
import {
  dateFromUnixTime,
  formatDate
} from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon'
import { shiftType } from '@/feature/phr-28/components/common/type'
import { useGraphqlClient } from '@/App'

interface ShiftDeleteModalType {
  isOpen: boolean
  setShowDeleteModal: (isOpen: boolean) => void
  shift: shiftType
}

export const ShiftDeleteModal: React.FC<ShiftDeleteModalType> = ({
  isOpen,
  setShowDeleteModal,
  shift
}) => {
  // シフト削除
  const queryClient = useQueryClient()
  const graphqlClient = useGraphqlClient()
  const { mutate } = useDeleteShiftMutation(graphqlClient, {
    onSettled: () => {
      queryClient.invalidateQueries('listShift').catch(() => console.log('on Settled'))
    },
    onSuccess: (res) => {
      console.log(res)
    },
    onError: (error) => {
      throw error
    },
    retry: 3
  })
  const deleteShift = (): void => {
    mutate(shift)
  }

  const modalElement = (
    <>
      <div>
        <div
          id="overlay"
          className="fixed top-0 z-[99] left-0 w-full h-full bg-gray-500 flex align-middle justify-center bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                onClick={() => setShowDeleteModal(false)}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="authentication-modal"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>

              <div className="py-6 px-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  シフト削除
                </h3>
                <form className="space-y-6" action="/masta">
                  <div>
                    <label
                      htmlFor="roles"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                    >
                      登録日付
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      value={formatDate(dateFromUnixTime(shift.date))}
                      disabled
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      名前
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      value={shift.name}
                      disabled
                    />
                  </div>
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="w-2/5 mx-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      onClick={() => deleteShift()}
                    >
                      削除
                    </button>
                    <button
                      type="button"
                      className="w-2/5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      キャンセル
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  return <>{isOpen && modalElement}</>
}
