import { SelectViews } from '@/components/Common/components/parts/Select/views/SelectTimeForBlockViews';
import { MedicalCategoryModal } from '@/feature/phr-28/components/template/Appointment/ModalContents/MedicalCategoryModal';
import type { PatientsType } from '@/components/Patient/components/util/common/type';
import { listManegerType } from '@/components/Patient/components/util/common/type';
import { CategoryType, MenuType } from '@/components/Menu/entities';
import { FacilityType } from '@/components/Facility/entities';
import { UseFormReturn } from 'react-hook-form';
import { AddAppointDefaultValuesType } from '@/components/Reservation/entities';
import { ClinicContext, useGraphqlClient } from '@/App';
import { useContext, useState } from 'react';
import { NavigateType } from '@tanstack/react-location';
import { AppointStatusBudgePresenter } from '@/components/Appointment/components/AppointmentListByPatients/presenter/AppointStatusBudgePresenter';
import { fromUnixTime, getUnixTime, startOfDay } from 'date-fns';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import {
  useGetActiveScheduleQuery,
  useGetLatestClinicConfigQuery,
} from '@/_graphql/graphql-client';
import { emptyClinicTimeConfig } from '@/components/Common/utils/common/emptyData';
import { MutateContext } from '@/provider/common/MutateProvider';

import { sortAndExtractIds } from '../utils/util';
import { japanTimeFormatDate } from '@/components/Common/utils';

import {
  _styledDivTop,
  _styledDivRightTop,
  _styledDivMiddle,
  _styledDivBottom,
  _styledDivLeftBottom,
  $AppointDetailLabel,
  $FacilityName,
  $ConsultationStartTime,
  CheckIsLate,
  DeleteButton,
  DateChangeButton,
  InputRemarks,
  SelectMenuLabel,
  SelectPatientLabel,
  StaffSelector,
  SubmitButton,
} from '@/components/Reservation/components/parts/AppointDetail';
import { useUpdateEndTimeParam } from '../../parts/AppointDetail/hooks/useUpdateEndTimeParam';
import { useCommonParams } from '@/domain/Common/useCommonParams';
import { isWithoutEndTime } from '../utils/AddAppoint';
import { SelectDentalFormulaLabel } from '../../parts/AppointDetail/common/SelectDentalFormulaLabel';
import { DentalFormulaModal } from '@/feature/phr-28/components/template/Appointment/ModalContents/DentalFormulaModal';

interface UpdateAppointType {
  formData: any;

  navigate: NavigateType;
  operation: 'add' | 'reference' | 'edit' | 'copy' | 'update' | undefined;

  displayPatientName: string;

  menuList: MenuType[];
  menuListIncludeDelete: MenuType[];
  categoryList: CategoryType[];
  manegerListsIncludeDelete: listManegerType[];
  facilityList: FacilityType[];

  patientId: string;
  startTime: number;
  endTime: number;
  facilityId: string;

  openMedicalCategoryModal: boolean;
  setOpenMedicalCategoryModal: React.Dispatch<React.SetStateAction<boolean>>;

  watch: any;
  register: any;

  methods: UseFormReturn<AddAppointDefaultValuesType, any>;
  setIsOpenDeleteModal: () => void;
}

function rewindPathnameAndSearch(pathname: string, search: any) {
  const editIndex = pathname.lastIndexOf('/edit');
  const updateIndex = pathname.lastIndexOf('/update');

  const index = editIndex !== -1 ? editIndex : updateIndex;

  if (index === -1) {
    // URLに /edit または /update が含まれていない場合はそのまま返す
    return `${pathname}${search}`;
  } else {
    // URLに /edit または /update が含まれている場合は、その手前までのパスを返す
    const pathList = pathname.slice(0, index).split('/');
    const queryParam = search;
    const newPathname = `${pathList.join('/')}/edit`;
    return `${newPathname}${queryParam}`;
  }
}

export const UpdateAppoint: React.FC<UpdateAppointType> = ({
  navigate,
  operation,

  formData,

  displayPatientName,

  menuList,
  menuListIncludeDelete,
  categoryList,
  manegerListsIncludeDelete,
  facilityList,

  patientId,
  startTime,
  endTime,

  openMedicalCategoryModal,
  setOpenMedicalCategoryModal,

  // openDentalFormulaModal,
  // setOpenDentalFormulaModal,

  watch,
  register,
  methods,
  setIsOpenDeleteModal,
}) => {
  const [openDentalFormulaModal, setOpenDentalFormulaModal] = useState(false);

  //キャンセル理由
  const [selectedCancelReason, setSelectedCancelReason] = useState(0);

  const clinic = useContext(ClinicContext);
  const { setValue } = methods;
  const { nowDate } = useContext(SetDateContext);

  const graphqlClient = useGraphqlClient();
  const activeClinicTimeConfig = useGetLatestClinicConfigQuery(graphqlClient, {
    clinic_id: clinic.clinic_id,
    current_date: getUnixTime(startOfDay(nowDate)),
    config_type: 'clinic_time',
  });
  const activeClinicTime = activeClinicTimeConfig.data?.getLatestClinicConfig?.config.clinicTime;
  const { changeAppointStatus } = useContext(MutateContext);
  const clinicTime = activeClinicTime ? activeClinicTime : emptyClinicTimeConfig.config.clinicTime;
  const withoutEndTime = isWithoutEndTime(clinicTime, endTime, getUnixTime(nowDate));

  const { setEndTime } = useCommonParams();

  // // 予約終了時間のクエリパラメータを更新する
  // useUpdateEndTimeParam(setEndTime, 'appointData', methods);

  const schedule = useGetActiveScheduleQuery(graphqlClient, {
    clinic_id: clinic.clinic_id,
    current_date: startTime,
  });
  const isBeforeDay = schedule.data?.getActiveSchedule?.isBeforeDay;

  return (
    <>
      {/* 上段 */}
      <_styledDivTop>
        {/* 施設名 */}
        <$FacilityName facilityList={facilityList} facilityId={watch('appointData.facilityId')} />

        {/* 予約詳細ラベル: '新規追加' || '予約参照' */}
        <$AppointDetailLabel label="予約参照" />

        <_styledDivRightTop>
          {/* 遅刻判定 */}
          <CheckIsLate
            checked={watch('appointData.isLate')}
            operation={operation}
            appointStatus={formData.appoint}
            onCheckboxChange={(checked) => {
              changeAppointStatus.mutate({
                id: patientId,
                startTime: startTime,
                isLate: checked,
                appoint: formData.appoint,
              });
            }}
          />

          {/* 予約ステータス管理 */}
          {operation === 'reference' && (
            <AppointStatusBudgePresenter
              id={formData.id}
              selectedCancelReason={selectedCancelReason}
              setSelectedCancelReason={setSelectedCancelReason}
              startTime={formData.startTime}
              appoint={formData.appoint}
              isLate={formData.isLate}
            />
          )}
        </_styledDivRightTop>
      </_styledDivTop>

      {/* 中断 */}
      <_styledDivMiddle>
        {/* 診療開始時間 */}
        <$ConsultationStartTime
          appointStartTime={japanTimeFormatDate(fromUnixTime(watch('appointData.startTime')))}
        />

        {/* FIXME: 診療時間セレクト */}
        {
          <SelectViews
            formType="appoint"
            formData={formData}
            setValue={setValue as any}
            register={register}
            setEndTime={setEndTime}
          />
        }

        {/* 日時変更 */}
        {!isBeforeDay && (
          <DateChangeButton
            disable={operation === 'edit'}
            onDateChange={() => {
              navigate({
                to: `appoints-list/appoint-info/edit${location.search.toString()}`,
                fromCurrent: false,
              });
            }}
          />
        )}

        {/* 予約削除 */}
        {/* 過去予約の場合は削除不可 */}
        {!isBeforeDay && (
          <DeleteButton
            disable={operation === 'edit' || operation === 'update'}
            openDeleteConfirmDialog={() => {
              if (operation !== 'edit' && operation !== 'update') {
                setIsOpenDeleteModal();
              }
            }}
          />
        )}
      </_styledDivMiddle>

      {/* 下段 */}
      <_styledDivBottom>
        {/* 左カラム */}
        <_styledDivLeftBottom>
          {/* <div className="w-1/2 text-xl"> */}

          {/* 患者選択 */}
          <SelectPatientLabel
            displayPatientName={displayPatientName}
            disabled={true}
            //患者未選択時にハイライト
            isHighlighted={watch('appointData.id') === 0}
            openSearchPatientModal={() => {}}
          />

          <>
            {/* メニュー選択 */}
            <SelectMenuLabel
              allMenuList={menuListIncludeDelete}
              menuId={watch('appointData.menuId')}
              openSearchMenuModal={() => {
                setOpenMedicalCategoryModal(true);
              }}
            />
            {/* メニュー選択モーダル */}
            {openMedicalCategoryModal && formData.startTime && (
              <MedicalCategoryModal
                currentShiftTimestamp={formData.startTime}
                categoryList={categoryList}
                MenuList={menuList}
                setOpenMedicalCategoryModal={setOpenMedicalCategoryModal}
              />
            )}
          </>

          {/* 歯式選択 */}
          <SelectDentalFormulaLabel
            openSearchMenuModal={() => {
              setOpenDentalFormulaModal(true);
            }}
            dentalFormula={formData.dentalFormula}
          />
          {openDentalFormulaModal && (
            <DentalFormulaModal
              dentalFormula={formData.dentalFormula}
              setOpenDentalFormulaModal={setOpenDentalFormulaModal}
              setValue={setValue}
              setDentalFormula={() => {}}
            />
          )}

          {/* コメント入力 */}
          <InputRemarks registerProps={register('appointData.remarks')} />
        </_styledDivLeftBottom>
        <_styledDivLeftBottom>
          {/* 担当者選択 */}
          <StaffSelector
            register={register}
            manegerListIncludeDelete={manegerListsIncludeDelete}
            // staffIds配列をindex順にソート&idのみをmapで取り出した配列を返却
            selectedStaffIds={sortAndExtractIds(watch('appointData.staffIds'))}
          />
          {/* 登録ボタン */}
          <SubmitButton disabled={watch('appointData.id') === '0' || withoutEndTime} label="更新" />
        </_styledDivLeftBottom>
      </_styledDivBottom>
    </>
  );
};
