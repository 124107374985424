/* eslint-disable max-lines-per-function */
import { Dialog, DialogContent, Button, Grid, Typography, Divider } from '@mui/material';
import { CategoryType, MenuType } from '@/feature/phr-28/components/common/type';
import React, { useEffect, useState } from 'react';
import { useSelectMenu } from '../useSelectMenu';
import { emptyMenu } from '../../../common/emptyData';
import { MedicalMenuModal } from './MedicalMenuModal';
// import { type DentalFormulaInput } from '@/_graphql/graphql-client';


interface DentalFormulaInput {
  permanent: {
    rightTop: boolean[];
    rightBottom: boolean[];
    leftTop: boolean[];
    leftBottom: boolean[];
  };
  baby?: {
    rightTop?: boolean[];
    rightBottom?: boolean[];
    leftTop?: boolean[];
    leftBottom?: boolean[];
  };
}

interface DentalFormulaInputProps {
  initialData: DentalFormulaInput;
  onUpdate: (data: DentalFormulaInput) => void;
}

interface DentalFormulaModalType {
  dentalFormula: DentalFormulaInput;
  setOpenDentalFormulaModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDentalFormula: React.Dispatch<React.SetStateAction<DentalFormulaInput>>;
  setValue: any;
}

// function createHandleClose(
//     setOpenDentalFormulaModal: React.Dispatch<React.SetStateAction<boolean>>,
//     setDentalFormula: React.Dispatch<React.SetStateAction<'medical' | 'dental'>>
// ) {
//     return function handleClose() {
//         setCategory('medical')
//         setOpenMedicalCategoryModal(false)
//     }
// }

// function clearState(
//     setOpenMedicalCategoryModal: React.Dispatch<React.SetStateAction<boolean>>,
//     setCategory: React.Dispatch<React.SetStateAction<'medical' | 'dental'>>
// ) {
//     setCategory('medical')
//     setOpenMedicalCategoryModal(false)
// }

// function openMenu(
//     categoryId: string,
//     categoryName: string,
//     menuList: MenuType[],
//     setOpenMedicalMenuModal: React.Dispatch<React.SetStateAction<boolean>>,
//     setCurrentCategoryName: React.Dispatch<React.SetStateAction<string>>,
//     setCurrentMenuList: React.Dispatch<React.SetStateAction<MenuType[]>>
// ) {
//     const currentCategoryMenuList = getCurrentCategoryMenuList(categoryId, menuList)
//     setCurrentMenuList(currentCategoryMenuList)
//     setCurrentCategoryName(categoryName)
//     setOpenMedicalMenuModal(true)
// }

// function getCurrentCategoryMenuList(categoryId: string, menuList: MenuType[]) {
//     const currentCategoryMenuList = menuList.filter(menu => menu.subCategoryId === categoryId)
//     return currentCategoryMenuList
// }

// function getFilteredCategoryList(
//     category: 'dental' | 'medical',
//     tmpCategoryList: CategoryType[]
// ) {
//     let categoryList: CategoryType[] = [];
//     switch (category) {
//         case 'dental':
//             categoryList = tmpCategoryList.filter(category => category.mainCategory === '歯科')
//             return categoryList;
//         case 'medical':
//             categoryList = tmpCategoryList.filter(category => category.mainCategory === '医科')
//             return categoryList;
//     }
// }

export const DentalFormulaModal: React.FC<DentalFormulaModalType> = ({
  dentalFormula,
  setOpenDentalFormulaModal,
  setValue,
}) => {
    const [_dentalFormula, setDentalFormula] = useState<DentalFormulaInput>({
        ..._dentalFormulaModel,
        ...dentalFormula,
    });
    
  return (
    <>
      <Dialog
        transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
        open={true}
        PaperProps={{
          style: {
            maxWidth: 'none',
            width: '80%',
            transition: 'all 0',
            zIndex: 9999,
          },
        }}
      >
        <_DialogContent
          dentalFormula={_dentalFormula}
          setDentalFormula={setDentalFormula}
          setOpenDentalFormulaModal={setOpenDentalFormulaModal}
          setValue={setValue}
        />
      </Dialog>
    </>
  );
};

const _DialogContent: React.FC<DentalFormulaModalType> = ({
  dentalFormula,
  setOpenDentalFormulaModal,
  setDentalFormula,
  setValue,
}) => {

  return (
    <DialogContent
      dividers
      style={{
        padding: '0px 0px 0px 0px',
        maxWidth: 'none',
        maxHeight: 'none',
        height: '90vh',
        position: 'relative',
      }}
    >
      <div className="wrapper">
        <div className="pb-8 px-8">
          {/* ----ヘッダー----- */}
          <header className="sticky top-0 pt-8 bg-white bg-opacity-90">
            <div className="flex justify-between mb-8 gap-1">
              <div>
                {/* <h2 onClick={() => { setCategory('dental') }} className={`${category === 'dental' ? 'bg-red-700' : 'bg-gray-400'} w-64 text-white inline-flex justify-center items-center text-xl h-12 rounded-md rounded-r-none`}>歯科</h2>
                                        <h2 onClick={() => { setCategory('medical') }} className={`${category === 'medical' ? 'bg-red-700' : 'bg-gray-400'} w-64 h-12 text-white text-xl h-12 rounded-md inline-flex justify-center items-center rounded-l-none`}>医科</h2> */}
              </div>
              <button
                type="button"
                className="text-white w-48 bg-red-700 font-medium rounded-md inline-flex justify-center items-center text-xl h-12 mr-6"
                onClick={() => {
                    console.log(dentalFormula)
                    console.log(setValue);
                    setValue('appointData.dentalFormula', dentalFormula);
                      setOpenDentalFormulaModal(false);
                }}
              >
                閉じる
              </button>
            </div>
            <p className="text-xl spacing-ls tracking-wider border-b border-gray-600 pb-2 mb-8">
              <span className="text-red-500">治療部位</span>を選択してください。
            </p>
          </header>
          <DentalFormulaInputComponent initialData={dentalFormula} onUpdate={setDentalFormula} />
        </div>
      </div>

    </DialogContent>
  );
};

// DentalFormulaButtons.tsx
interface DentalFormulaButtonsProps {
  section: "baby" | "permanent";
  part: "leftBottom" | "leftTop" | "rightBottom" | "rightTop";
  values: boolean[] | [];  // Do not allow empty array type separately
  labels: string[];
  onToggle: (
    section: "baby" | "permanent",
    part: "leftBottom" | "leftTop" | "rightBottom" | "rightTop",
    index: number
  ) => void;
  reverse: boolean;
}

const DentalFormulaButtons: React.FC<DentalFormulaButtonsProps> = ({
  section,
  part,
  values,
  labels,
  onToggle,
  reverse
}) => (
  <Grid container spacing={1} width={"95%"} 

      direction={reverse ? "row-reverse" : "row"}
      // justifyContent={reverse? "flex-end": "flex-start"}
    >
    {values.map((value, index) => (
      <Grid item key={index}>
        <Button
          variant={value ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => {
            onToggle(section, part, index);
          }}
          size="small"
        >
          {labels[index]}
        </Button>
      </Grid>
    ))}
  </Grid>
);

// DentalFormulaSection.tsx
interface DentalFormulaSectionProps {
  section: 'permanent' | 'baby';
  dentalFormula: DentalFormulaInput;
  handleToggle: (
    section: 'permanent' | 'baby',
    part: "leftBottom" | "leftTop" | "rightBottom" | "rightTop",
    index: number
  ) => void;
}

export const babyLabels = ['A', 'B', 'C', 'D', 'E'];


const DentalFormulaSection: React.FC<DentalFormulaSectionProps> = ({
  section,
  dentalFormula,
  handleToggle,
}) => {

  return (
    <Grid item xs={12}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} >
          <Grid container spacing={1} justifyContent="flex-end">
            {section === 'permanent' && (
              
              <DentalFormulaButtons
                section="baby"
                part="rightTop"
                values={dentalFormula?.baby?.rightTop ?? []}
                labels={babyLabels}
                onToggle={handleToggle}
                reverse={true}
              />
            )}
            <DentalFormulaButtons
              section="permanent"
              part="rightTop"
              values={dentalFormula?.permanent?.rightTop}
              labels={dentalFormula?.permanent?.rightTop.map((_, index) => (index + 1).toString())}
              onToggle={handleToggle}
              reverse={true}
            />
            <DentalFormulaButtons
              section="permanent"
              part="rightBottom"
              values={dentalFormula?.permanent?.rightBottom}
              labels={dentalFormula?.permanent?.rightBottom.map((_, index) => (index + 1).toString())}
              onToggle={handleToggle}
              reverse={true}
            />
            {section === 'permanent' && (
              <DentalFormulaButtons
                section="baby"
                part="rightBottom"
                values={dentalFormula?.baby?.rightBottom ?? []}
                labels={babyLabels}
                onToggle={handleToggle}
                reverse={true}
              />
            )}
          </Grid>
        </Grid>

        {/* 縦グリッド */}
        <Grid item xs={6}>
          <Grid container spacing={1}>
            {section === 'permanent' && (
              <DentalFormulaButtons
                section="baby"
                part="leftTop"
                values={dentalFormula?.baby?.leftTop ?? []}
                labels={babyLabels}
                onToggle={handleToggle}
                reverse={false}
              />
            )}
            <DentalFormulaButtons
              section="permanent"
              part="leftTop"
              values={dentalFormula?.permanent?.leftTop}
              labels={dentalFormula?.permanent?.leftTop?.map((_, index) => (index + 1).toString())}
              onToggle={handleToggle}
              reverse={false}
            />
            <DentalFormulaButtons
              section="permanent"
              part="leftBottom"
              values={dentalFormula?.permanent?.leftBottom}
              labels={dentalFormula?.permanent?.leftBottom?.map((_, index) => (index + 1).toString())}
              onToggle={handleToggle}
              reverse={false}
            />
            {section === 'permanent' && (
              <DentalFormulaButtons
                section="baby"
                part="leftBottom"
                values={dentalFormula?.baby?.leftBottom ?? []}
                labels={babyLabels}
                onToggle={handleToggle}
                reverse={false}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// DentalFormulaInputComponent.tsx
const DentalFormulaInputComponent: React.FC<DentalFormulaInputProps> = ({
  initialData,
  onUpdate,
}) => {
  const [dentalFormula, setDentalFormula] = useState<DentalFormulaInput>(initialData);

  const handleToggle = (
    section: 'permanent' | 'baby',
    part: 'leftTop' | 'rightTop' | 'leftBottom' | 'rightBottom',
    index: number
  ): void => {
    setDentalFormula((prevData) => {
      // ディープコピーを行い、ミュータビリティの問題を避ける
      const newData = JSON.parse(JSON.stringify(prevData));
  
      // 安全なアクセスを保証
      if (Boolean(newData[section]) && Boolean(newData[section]?.[part]) && typeof newData[section][part][index] === 'boolean') {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        newData[section][part][index] = !newData[section][part][index];
        onUpdate(newData);
        return newData;
      } 
        console.error("Invalid access or type mismatch");
        return prevData;
      
    });
  };
  
  return (
    <Grid container spacing={1}>
        <DentalFormulaSection
          key={"permanent"}
          section={"permanent"}
          dentalFormula={dentalFormula}
          handleToggle={handleToggle}
        />
    </Grid>
  );
};

const _dentalFormulaModel: DentalFormulaInput = {
  permanent: {
    leftTop: [...Array(8)].map(() => false),
    leftBottom: [...Array(8)].map(() => false),
    rightTop: [...Array(8)].map(() => false),
    rightBottom: [...Array(8)].map(() => false),
  },
  baby: {
    leftTop: [...Array(5)].map(() => false),
    leftBottom: [...Array(5)].map(() => false),
    rightTop: [...Array(5)].map(() => false),
    rightBottom: [...Array(5)].map(() => false),
  },
};
