// 必要なモジュールをインポート
import { CommonParamsContextType, useCommonParams } from "../Common/useCommonParams";
import { useQueryParams, useWatchQueryParam } from "../queryParams"
import { createContext, useContext, ReactNode } from 'react';

// Context の型定義
interface AppointParamsContextType extends Omit<CommonParamsContextType, 'calendarDate' | 'setCalendarDate'> {
    patient_id: string | null;
    appointId: string | null;
    setPatientId: (patientId: string) => void;
    setAppointId: (appointId: string) => void;
    setAppointParams: setAppointParamsType;
    resetAppointId: () => void;
}

export type setAppointParamsType = (calendarDate: number, patientId: string, startTime: number, endTime: number, facilityId: string, appointId: string, isCopy?: boolean) => void;

// Contextの初期値と型を設定
const AppointParamsContext = createContext<AppointParamsContextType>({
    patient_id: null,
    startTime: null,
    endTime: null,
    facilityId: null,
    appointId: null,
    setPatientId: () => { },
    setStartTime: () => { },
    setEndTime: () => { },
    setFacilityId: () => { },
    setAppointId: () => { },
    setAppointParams: () => { },
    setCommonParams: () => { },
    resetAppointId: () => { }
});

export const useAppointParams = (): AppointParamsContextType => {
    const { setQueryParam, resetQueryParams } = useQueryParams();
    const commonParams = useCommonParams();
    const patient_id = useWatchQueryParam("patient-id");
    const appointId = useWatchQueryParam("appoint-id");

    const setPatientId = (patientId: string) => {
        setQueryParam("patient-id", patientId);
    };

    const setAppointId = (appointId: string) => {
        setQueryParam("appoint-id", appointId);
    }

    const resetAppointId = () => {
        resetQueryParams("appoint-id");
    }

    const setAppointParams = (calendarDate: number, patientId: string, startTime: number, endTime: number, facilityId: string, appointId: string, isCopy?: boolean) => {
        resetQueryParams();
        commonParams.setCalendarDate(calendarDate)
        setPatientId(patientId);
        commonParams.setStartTime(startTime);
        commonParams.setEndTime(endTime);
        commonParams.setFacilityId(facilityId);
        setAppointId(appointId);
        if (isCopy) {
            setQueryParam("copy", "true");
        }
    }

    return {
        ...commonParams,
        patient_id,
        appointId,
        setPatientId,
        setAppointId,
        setAppointParams,
        resetAppointId
    };
}

export const AppointParamsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const appointParams = useAppointParams();

    return (
        <AppointParamsContext.Provider value={appointParams}>
            {children}
        </AppointParamsContext.Provider>
    );
}

// 他のコンポーネントでContextの値を取得するためのカスタムフック
export const useAppointParamsContext = (): AppointParamsContextType => {
    return useContext(AppointParamsContext);
};
