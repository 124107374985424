// TODO 全体的にHTMLタグの組み方が怪しい気がする（不要なタグや構造）
import React from 'react';
import { ResponsibleAddModalPresenterType } from '../utils/common/type';
import {
  AddModalFormColorItemType,
  AddModalFormEmailItemType,
  AddModalFormNameItemType,
  AddModalFormRightsItemType,
  AddModalFormRoleItemType,
  AddModalFormSubmitButtonType,
  CloseAddModalButtonType,
  OutlineType,
} from '../utils/components/parts';

export const ResponsibleAddModalPresenter: React.FC<ResponsibleAddModalPresenterType> = ({
  setShow,
  addManegerData,
  medicalRole,
  firstElement,
  rights,
  clearState,
  setSelectRole,
  setSelectRights,
  setInputName,
  setInputEmail,
  setInputPass,
  inputEmail,
  inputPass,
  determineRegisterable,
  disableCss,
  registerCss,
  submitCount,
  setSubmitCount,
  addManeger,
}) => {
  return (
    <Outline>
      <AddModalTitle />
      <CloseAddModalButton
        addManegerData={addManegerData}
        medicalRole={medicalRole}
        rights={rights}
        firstElement={firstElement}
        clearState={clearState}
        setSelectRole={setSelectRole}
        setSelectRights={setSelectRights}
        setInputName={setInputName}
        setInputEmail={setInputEmail}
        setInputPass={setInputPass}
        setShow={setShow}
      />
      <AddModalFormOutline>
        <AddModalFormRoleItem
          setSelectRole={setSelectRole}
          medicalRole={medicalRole}
          addManegerData={addManegerData}
        />
        <AddModalFormNameItem addManegerData={addManegerData} setInputName={setInputName} />
        <AddModalFormRightsItem
          setSelectRights={setSelectRights}
          rights={rights}
          addManegerData={addManegerData}
        />
        <AddModalFormEmailItem inputEmail={inputEmail} setInputEmail={setInputEmail} />
        <AddModalFormColorItem
          inputPass={inputPass}
          addManegerData={addManegerData}
          setInputPass={setInputPass}
        />
        <AddModalFormSubmitButton
          determineRegisterable={determineRegisterable}
          disableCss={disableCss}
          registerCss={registerCss}
          submitCount={submitCount}
          setSubmitCount={setSubmitCount}
          addManeger={addManeger}
          clearState={clearState}
          setSelectRole={setSelectRole}
          setSelectRights={setSelectRights}
          setInputName={setInputName}
          setInputEmail={setInputEmail}
          setInputPass={setInputPass}
          setShow={setShow}
        />
      </AddModalFormOutline>
    </Outline>
  );
};

// MEMO AddとEditは同じような小コンポーネントになる 将来的に共通化するのかどうか

// NOTE コンテナ 名前はOutlineでよいのかどうか Containerはmantineやmaterialと競合する可能性もアリ
const Outline: React.FC<OutlineType> = ({ children }) => {
  const id = 'overlay';
  const outlineStyle =
    'fixed top-0 z-[99] left-0 w-full h-full bg-gray-500 flex align-middle justify-center bg-opacity-50';
  const outlineContainerStyle = 'relative p-4 w-full max-w-md h-full md:h-auto';
  const innerOutlineContainerStyle = 'relative bg-white rounded-lg shadow dark:bg-gray-700';
  return (
    <div id={id} className={outlineStyle}>
      <div className={outlineContainerStyle}>
        <div className={innerOutlineContainerStyle}>{children}</div>
      </div>
    </div>
  );
};

const AddModalTitle = () => {
  const titleStyle = 'pt-6 px-6 text-xl font-medium text-gray-900 dark:text-white';
  return <h3 className={titleStyle}>担当者の登録</h3>;
};

const CloseAddModalButton: React.FC<CloseAddModalButtonType> = ({
  addManegerData,
  medicalRole,
  rights,
  firstElement,
  clearState,
  setSelectRole,
  setSelectRights,
  setInputName,
  setInputEmail,
  setInputPass,
  setShow,
}) => {
  const buttonStyle =
    'absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white';
  return (
    <button
      type="button"
      onClick={() => {
        addManegerData.role = medicalRole[firstElement];
        addManegerData.rights = rights[firstElement];
        clearState(setSelectRole, setSelectRights, setInputName, setInputEmail, setInputPass);
        setShow(false);
      }}
      className={buttonStyle}
      data-modal-toggle="authentication-modal"
    >
      <svg
        className="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        ></path>
      </svg>
    </button>
  );
};

const AddModalFormOutline: React.FC<OutlineType> = ({ children }) => {
  return (
    <div className="py-6 px-6 lg:px-8">
      <form className="space-y-6" action="#">
        {children}
      </form>
    </div>
  );
};

const AddModalFormRoleItem: React.FC<AddModalFormRoleItemType> = ({
  setSelectRole,
  medicalRole,
  addManegerData,
}) => {
  const id = 'roles';
  const labelStyle = 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400';
  const selectStyle =
    'w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500';
  return (
    <div>
      <label htmlFor={id} className={labelStyle}>
        役割
      </label>
      <select id={id} className={selectStyle} onChange={(e) => setSelectRole(e.target.value)}>
        <option hidden></option>
        {medicalRole.map((role: string, index: number) => (
          <option value={role} key={index} selected={addManegerData.role === role ?? false}>
            {role}
          </option>
        ))}
      </select>
    </div>
  );
};

const AddModalFormNameItem: React.FC<AddModalFormNameItemType> = ({
  addManegerData,
  setInputName,
}) => {
  const id = 'name';
  const labelStyle = 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300';
  const selectStyle =
    'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white';
  return (
    <div>
      <label htmlFor={id} className={labelStyle}>
        名前
      </label>
      <input
        type="text"
        name="name"
        id={id}
        value={addManegerData.name}
        className={selectStyle}
        placeholder="名前を入力して下さい"
        required
        onChange={(e) => setInputName(e.target.value)}
      />
    </div>
  );
};

const AddModalFormRightsItem: React.FC<AddModalFormRightsItemType> = ({
  setSelectRights,
  rights,
  addManegerData,
}) => {
  const id = 'rights';
  const labelStyle = 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400';
  const selectStyle =
    'w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500';
  return (
    <div>
      <label htmlFor={id} className={labelStyle}>
        権限
      </label>
      <select id={id} className={selectStyle} onChange={(e) => setSelectRights(e.target.value)}>
        <option hidden></option>
        {rights.map((right: string, index: number) => (
          <option value={right} key={index} selected={addManegerData.rights === right ?? false}>
            {right}
          </option>
        ))}
      </select>
    </div>
  );
};

const AddModalFormEmailItem: React.FC<AddModalFormEmailItemType> = ({
  inputEmail,
  setInputEmail,
}) => {
  const id = 'email';
  const labelStyle = 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300';
  const selectStyle =
    'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white';
  return (
    <div>
      <label htmlFor={id} className={labelStyle}>
        email
      </label>
      <input
        type="email"
        name="email"
        id={id}
        value={inputEmail}
        className={selectStyle}
        placeholder="name@company.com"
        required
        onChange={(e) => setInputEmail(e.target.value)}
      />
    </div>
  );
};

const AddModalFormColorItem: React.FC<AddModalFormColorItemType> = ({
  inputPass,
  addManegerData,
  setInputPass,
}) => {
  const id = 'color';
  const labelStyle = 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300';
  const selectStyle =
    'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white';
  return (
    <div>
      <label htmlFor={id} className={labelStyle}>
        color
      </label>
      <input
        type="color"
        name="color"
        id="color"
        value={inputPass}
        className={selectStyle}
        required
        onChange={(e) => {
          console.log(addManegerData);
          setInputPass(e.target.value);
        }}
      />
    </div>
  );
};

const AddModalFormSubmitButton: React.FC<AddModalFormSubmitButtonType> = ({
  determineRegisterable,
  disableCss,
  registerCss,
  submitCount,
  setSubmitCount,
  addManeger,
  clearState,
  setSelectRole,
  setSelectRights,
  setInputName,
  setInputEmail,
  setInputPass,
  setShow,
}) => {
  return (
    <form action="/masta" method="get">
      <button
        disabled={determineRegisterable()}
        type="submit"
        className={determineRegisterable() ? disableCss : registerCss}
        onClick={(e) => {
          e.preventDefault();
          setSubmitCount(submitCount + 1);
          addManeger();
          clearState(setSelectRole, setSelectRights, setInputName, setInputEmail, setInputPass);
          setShow(false);
          // if (submitCount === 0) {
          // }
        }}
      >
        登録
      </button>
    </form>
  );
};
