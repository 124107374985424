export interface InteractionCardProps {
    firstCardTime: number;
    quarterTimeStamp: number;
    startTime: number;
    endTime: number;
}

export const InteractionCard: React.FC<InteractionCardProps> = (props) => {
    const { firstCardTime, quarterTimeStamp, startTime, endTime } = props;
    const { staticStyle, dynamicStyle } = computeStyles(firstCardTime, quarterTimeStamp, startTime, endTime);

    return (
        <div>
            <div
                className={staticStyle}
                style={dynamicStyle}
            >
            </div>
        </div>
    );
}

type InteractionCardStyles = {
    staticStyle: string;
    dynamicStyle: React.CSSProperties;
};

export function computeStyles(firstCardTime: number, quarterTimeStamp: number, startTime: number, endTime: number): InteractionCardStyles {
    //静的スタイル
    const opacityStyle = 'opacity-70 pointer-events-none bg-white';
    const staticStyle = `${opacityStyle} px-3 absolute z-[50] w-full h-full overflow-y-hidden text-xs text-black text-[0.925rem] leading-6`;

    //動的スタイル
    const cardBoxNum = (endTime - startTime) / quarterTimeStamp;
    // １時間スロットがheight:200px
    // １５分スロットがheight:40px, margin-bottom:10px
    const height = cardBoxNum * 100 - 10;
    const positionTop = (startTime - firstCardTime) / quarterTimeStamp * 100;
    const dynamicStyle = {
        top: `${positionTop}px`,
        height: `${height}px`
    };

    return { staticStyle, dynamicStyle };
};