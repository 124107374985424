import React from 'react'

interface MinusIconType {
  size: number
}

export const MinusIcon: React.FC<MinusIconType> = ({ size }) => {
  const iconSize: string = 'h-' + size.toString() + ' w-' + size.toString()

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={iconSize}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
      </svg>
    </>
  )
}
