export interface IconLabelProps {
    icon: React.ComponentType; // 表示するアイコン
    label: string; // 表示するラベル
    disable: boolean; // ボタンを無効化するかどうかのフラグ
    isHighlighted: boolean; // ラベルをハイライトするかどうかのフラグ
    onClickHandler: () => void; // クリック時のハンドラ
    font?: 'regular' | 'bold'; // フォントの種類
}

export const IconLabel: React.FC<IconLabelProps> = ({ icon: Icon, label, disable, font = 'regular', isHighlighted = false, onClickHandler }) => {

    const highlightedStyle = isHighlighted ? 'text-red-500 font-bold' : 'text-gray-900'
    const fontStyle = font === 'regular' ? '' : 'font-bold'
    const commonStyle = 'text-xs text-left'
    const style = `${commonStyle} ${highlightedStyle} ${fontStyle}`

    const outlineDivStyle = disable ? 'pointer-events-none' : 'cursor-pointer'
    const innerDivStyle = "w-full px-4 inline-flex items-center gap-4 text-right rounded-sm border-gray-50  text-xs bg-gray-200"

    return (
        <div className={outlineDivStyle} onClick={onClickHandler}>
            <div className={innerDivStyle}>
                <Icon />
                <p className={style}>
                    {label}
                </p>
            </div>
        </div>
    );
}