import { _styledComponentProps } from "@/components/Common"

const style = "flex items-center gap-1"
export const _styledDivMiddle: React.FC<_styledComponentProps> = ({
    children
}) => {
    return (
        <div className={style}>
            {children}
        </div>
    )
}
