import { set } from "date-fns"
import { EditingGroupsType } from "../../entities"

export function resetFormState(
    setEditingFacilityId: React.Dispatch<React.SetStateAction<string>>,
    setEditingFacilityName: React.Dispatch<React.SetStateAction<string>>,
    setSelectedGroupOptions: React.Dispatch<React.SetStateAction<EditingGroupsType[]>>,
    setEditingStatus: React.Dispatch<React.SetStateAction<boolean>>
): void {
    setEditingFacilityId('')
    setEditingFacilityName('')
    setSelectedGroupOptions([])
    setEditingStatus(true)
}

export function openEditForm(
    facilityId: string,
    facilityName: string,
    groupOptions: EditingGroupsType[],
    editingStatus: boolean,
    setEditingFacilityName: React.Dispatch<React.SetStateAction<string>>,
    setEditingFacilityId: React.Dispatch<React.SetStateAction<string>>,
    setSelectedGroupOptions: React.Dispatch<React.SetStateAction<EditingGroupsType[]>>,
    setEditingStatus: React.Dispatch<React.SetStateAction<boolean>>
): void {
    setEditingFacilityName(facilityName)
    setEditingFacilityId(facilityId)
    setSelectedGroupOptions(groupOptions)
    setEditingStatus(editingStatus)
}
