import type { PatientsType } from '@/components/Patient/components/util/common/type';
import { emptyPatients } from '@/feature/phr-28/components/common/emptyData';

export function getPatientById(patientsList: PatientsType[], patientId: string): PatientsType {
  let returnPatient: PatientsType = emptyPatients;
  patientsList.forEach((patient: PatientsType) => {
    if (patient.id === patientId) {
      returnPatient = patient;
    }
  });
  return returnPatient;
}