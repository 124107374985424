import { Router, Outlet } from '@tanstack/react-location'
import { routes, location } from '@/routes/Router'
// import { StatePropvider } from './StatePropvider'
// import { Sidebar } from '@/containers/Common/components/parts/Sidebar/Sidebar'
interface RouteProviderProps {
  children: React.ReactNode
}

export const RouteProvider = ({
  children
}: RouteProviderProps): JSX.Element => {

  return (
    <Router routes={routes} location={location}>
      {children}
    </Router>
  )
}
