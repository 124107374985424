export function clearState(
    setMainCategory: React.Dispatch<React.SetStateAction<"all" | "dental" | "medical">>,
    setDeletable: React.Dispatch<React.SetStateAction<string[]>>,
    setEditable: React.Dispatch<React.SetStateAction<string>>
) {
    setMainCategory('all');
    setDeletable(['']);
    setEditable('');
}

export function checkIsInDeletable(categoryId: string, deletables: string[]) {
    const isInDeletables = deletables.find(deletable => {
        return deletable === categoryId
    })
    return Boolean(isInDeletables);
}

export function setCategoryIdInDeletables(categoryId: string, deletables: string[], checkIsInDeletable: (categoryId: string, deletables: string[]) => boolean, setDeletable: React.Dispatch<React.SetStateAction<string[]>>) {
    const isInDeletables = checkIsInDeletable(categoryId, deletables);
    if (isInDeletables === false) {
        setDeletable([...deletables, categoryId])
    } else {
        const _deletables = deletables.filter(deletable => {
            return deletable !== categoryId;
        })
        setDeletable(_deletables);
    }
}

export function onClickOpenDeleteDialog(
    deletables: string[],
    setCurrentCategorys: React.Dispatch<React.SetStateAction<string[]>>,
    setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>
) {
    const _currentCategorys = deletables.filter(deletable => {
        return deletable.length !== 0
    })
    setCurrentCategorys(_currentCategorys);
    setOpenDeleteDialog(true);
}
