import { CheckBoxType } from "@/components/ClinicSettings/entities"
import { XCircleIcon } from "@heroicons/react/outline"

// [フォームパーツ]チェックボックス[診察・休診判定]
export const CheckBox = ({
    checkState,
    setCheckState
}: CheckBoxType): JSX.Element => {

    return (
        <td>
            <div className="h-full w-full relative">
                {/* 診察・休診判定 */}
                {
                    <input
                        type="checkbox"
                        checked={checkState ? true : false}
                        onChange={(e) => {
                            setCheckState(e.currentTarget.checked)
                        }}
                    />
                }
            </div>
        </td>
    )
}