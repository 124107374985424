import axios from "axios";
import { getDaysInMonth } from "date-fns";
import { UseFormSetValue } from "react-hook-form";

// GraphQL Int型の最大値
const GRAPHQL_INT_MAX = 2147483647;

/**
 * GraphQLのInt型の範囲内にあるかどうかを検証する関数
 * @param {string} value - 検証する値
 * @param {number} [maxValue=GRAPHQL_INT_MAX] - 許容する最大値
 * @returns {true|string} - 検証が成功した場合はtrue、失敗した場合はエラーメッセージ
 */
export const validateNumRange = (value: string | number, maxValue = GRAPHQL_INT_MAX) => {
    if (!value) return true; // 空文字を許容
    const numberValue = Number(value);
    const isWithinRange = numberValue >= 0 && numberValue <= maxValue;
    return isWithinRange || "入力された数値が上限値を超えています。";
};

export function invalidBirthday(year: string, month: string, day: string) {
    const birthdayFieldsCount = [year, month, day].filter(value => value).length;
    // 1901年以前の年だと登録できない
    const isInvalidBirthday = birthdayFieldsCount !== 0 && birthdayFieldsCount !== 3 || 1902 > parseInt(year);
    return isInvalidBirthday;
}

export async function setPostAddress(postNumber: string, setValue: UseFormSetValue<any>) {
    const address = await getPostAddress(postNumber);
    if (address) {
        setValue('addValues.address', address.address);
    }
}

export const getPostAddress = async (postNumber: string) => {
    const url = ` https://zip-cloud.appspot.com/api/search?zipcode=${postNumber}`;
    const res = await axios.get(url);
    const results = res.data.results;
    if (results?.[0] == null) { { alert('該当の住所が見つかりませんでした。'); return } }

    const { address1, address2, address3 } = results[0];
    const _address = {
        post: postNumber,
        prefectures: address1,
        house: `${address2}${address3}`,
    };
    const address = {
        address: _address,
    };

    return address;
};

export function getMaxDaysInMonth(year: string, month: string) {
    const daysInMonth = getDaysInMonth(new Date(Number(year), Number(month) - 1));
    return daysInMonth || 31;
}