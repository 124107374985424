import { FacilityType } from "@/components/Facility/entities"
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined'
import { getFacilityName } from '@/components/Common/utils';

export interface FacilityNamePropsType {
    facilityList: FacilityType[]
    facilityId: string
}

export const $FacilityName: React.FC<FacilityNamePropsType> = ({
    facilityList,
    facilityId
}) => {
    return (
        <div className="flex items-end mb-1">
            <DomainAddOutlinedIcon className="h-3 w-3" />
            <p className="text-xs font-bold">{getFacilityName(facilityList, facilityId)}</p>
        </div>
    )
}
