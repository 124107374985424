import { useContext } from 'react';
import { ClinicContext, useGraphqlClient } from '@/App';
import { useFacilityList_service } from '@/domain';
import { getLastIndexNumFromFacilityList, getOrderedFacilityList } from '../../util';
import { FacilityViewsType } from '../../entities/common/types';
import { FacilityPresenter } from '../presenter/FacilityPresenter';
import { useListFacilityGroupsQuery, useListGroupsByFacilityIdsQuery } from '@/_graphql/graphql-client';
import { MutateContext } from '@/provider/common/MutateProvider';

export const FacilityViews: React.FC<FacilityViewsType> = ({
}) => {
  const graphqlClient = useGraphqlClient();
  const clinic_id = useContext(ClinicContext).clinic_id;

  const allFacilityList = useFacilityList_service(graphqlClient, { clinic_id });
  const facilityList = getOrderedFacilityList(allFacilityList.facilityListIncludeInactive);
  const lastIndexNum = getLastIndexNumFromFacilityList(facilityList);
  const { addFacility, deleteFacility, updateFacility, updateFacilityIdx } = useContext(MutateContext)

  //faciliityListからid配列を生成
  const facilityIds = facilityList.map(facility => facility.id);

  //FIXME: domain_serviceへ移行
  const _allGroupList = useListFacilityGroupsQuery(graphqlClient, { inputs: { clinic_id } }).data?.listFacilityGroups.FacilityGroups || []
  // isDeleteがtrueのものは除外
  const allGroupList = _allGroupList?.filter(group => !group?.isDelete) || []
  const groups = useListGroupsByFacilityIdsQuery(graphqlClient, { input: { clinic_id: clinic_id, facilityIds: facilityIds } }).data?.listGroupsByFacilityIds.listGroupsByFacilityIdsReturns || []

  //facility_idをキーにしてグループをまとめる
  const _groupsObj = groups.reduce<{ [key: string]: any[] }>((acc, curr) => {
    if (curr && curr.facility_id && curr.groups) {
      acc[curr.facility_id] = curr.groups;
    }
    return acc;
  }, {});

  // isDeleteがtrueのものは除外
  const groupsObj = Object.keys(_groupsObj).reduce<{ [key: string]: any[] }>((acc, curr) => {
    if (_groupsObj[curr]) {
      acc[curr] = _groupsObj[curr].filter((group: any) => !group?.isDelete);
    }
    return acc;
  }, {});


  return (
    <FacilityPresenter
      clinic_id={clinic_id}
      facilityList={facilityList}
      allGroupList={allGroupList}
      groupsObj={groupsObj}
      lastIndexNum={lastIndexNum}
      addFacilityMutation={addFacility.mutate}
      deleteFacilityMutation={deleteFacility.mutate}
      updateFacilityMutation={updateFacility.mutate}
      updateFacilityIdxMutation={updateFacilityIdx.mutate}
    />
  );
};
