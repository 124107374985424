export function getStatusBadgeStyle(status: string) {
    switch (status) {
        case "予約済み":
            return {
                text: "予",
                color: "bg-green-200 text-green-900 border-green-500"
            };
        case "受付済み":
            return {
                text: "受",
                color: "bg-blue-300 text-blue-900 border-blue-500"
            };
        case "診療完了":
            return {
                text: "完",
                color: "bg-yellow-200 text-yellow-900 border-yellow-500"
            };
        case "キャンセル":
            return {
                text: "キ",
                color: "bg-red-300 text-red-900 border-red-500"
            };
        default:
            return {
                text: "/",
                color: "bg-black"
            };
    }
}