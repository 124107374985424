import { UseQueryResult } from "react-query";
import { GetPatientByIdV2Query, ListPatientsV2Query } from "@/domain/Patient/entities/types";
import { PatientsType } from "@/components/Patient/components/util/common/type";
import { emptyPatients } from "@/components/Common/utils/common/emptyData";

export const patientMapper = (
    patient: UseQueryResult<GetPatientByIdV2Query, unknown>
) => {
    let mappedPatient = emptyPatients;

    if (patient.isSuccess) {
        const { data } = patient;
        mappedPatient = Object.assign(emptyPatients, data.getPatientByIdV2)
    }

    return mappedPatient
}

export const patientListMapper = (
    patients: UseQueryResult<ListPatientsV2Query, unknown>
) => {
    let patientsList: PatientsType[] = []
    let patientsListIncludeDelete: PatientsType[] = []

    if (patients.isSuccess) {
        const { data } = patients;

        data.listPatientsV2.Patients.forEach((patient: any) => {
            if (patient?.status !== "true") {
                patientsList.push(patient);
            }
            patientsListIncludeDelete.push(patient)
        })
    }

    return {
        patientsList,
        patientsListIncludeDelete
    }
}