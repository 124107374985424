import { AddClinicScheduleMutationVariables, ClinicScheduleType, UpdateClinicScheduleMutationVariables, Week } from "@/_graphql/graphql-client";
import { scheduleTableHeads } from '../../utils';
import { useState } from 'react';
import { ValidateScheduleDialog } from './ValidateScheduleDialog';
import { generateScheduleFormElements } from "../../utils/libs/clinicSchedule";
import { BodyRow, SubmitBtns, UpsertBtn, CancelBtn } from "../parts";
import { UpsertSchedulePresenterType } from "../../entities/components/ClinicSchedule";

export const UpsertSchedulePresenter: React.FC<UpsertSchedulePresenterType> = ({
    isUpdate,

    addClinicSchedule,
    updateClinicSchedule,
    deleteClinicSchedule,
    clinic,
    clinic_schedule_id,

    startTime,
    endTime,
    startDate,
    endDate,
    rule_type,
    day_of_week,
    week,
    specific_date,
    day,
    service_day,

    setStartTime,
    setEndTime,
    setStartDate,
    setEndDate,
    setRuleType,
    setDayOfWeek,
    setWeek,
    setSpecificDate,
    setDay,
    setServiceDay,

    startTimeSlots,
    endTimeSlots,

    setIsOpenAddRecord,
    setEditing,

    enableBtnCss,
}) => {

    const [openValidateRule, setOpenValidateRule] = useState(false)
    const [confirmed, setConfirmed] = useState(false)

    // フォーム要素の生成
    const formElements = generateScheduleFormElements(
        rule_type,
        setRuleType,
        day_of_week,
        setDayOfWeek,
        week,
        setWeek,
        specific_date || 0,
        setSpecificDate,
        day,
        setDay,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        service_day,
        setServiceDay,
    )

    // mutateの引数(新規追加)
    const addScheduleVariables: AddClinicScheduleMutationVariables = {
        input: {
            clinic_id: clinic.clinic_id,
            rule_type: rule_type as ClinicScheduleType,
            day_of_week: day_of_week,
            week: week,
            specific_date: specific_date,
            day: day,
            start_time: startTime,
            end_time: endTime,
            start_date: startDate,
            end_date: endDate,
            service_day: service_day,
        }
    }

    // mutateの引数(更新)
    const updateScheduleVariables: UpdateClinicScheduleMutationVariables = {
        clinic_schedule_id: clinic_schedule_id,
        input: {
            clinic_id: clinic.clinic_id,
            rule_type: rule_type as ClinicScheduleType,
            day_of_week: day_of_week,
            week: week,
            specific_date: specific_date,
            day: day,
            start_time: startTime,
            end_time: endTime,
            start_date: startDate,
            end_date: endDate,
            service_day: service_day,
        }
    }

    return (
        <>

            {
                openValidateRule &&
                <ValidateScheduleDialog
                    clinic_schedule_id={clinic_schedule_id}
                    rule_type={rule_type}
                    day_of_week={day_of_week}
                    week={week}
                    specific_date={specific_date}
                    day={day}
                    service_day={service_day}
                    start_date={startDate}
                    end_date={endDate}

                    setEditing={setEditing}
                    setOpenValidateRule={setOpenValidateRule}
                    isUpdate={isUpdate}

                    confirmed={confirmed}
                    setConfirmed={setConfirmed}
                    addScheduleSubmit={() => addClinicSchedule.mutate(addScheduleVariables)}
                    updateScheduleSubmit={() => updateClinicSchedule.mutate(updateScheduleVariables)}
                />
            }
            <BodyRow>

                <td>
                </td>
                <td>
                </td>

                {/* フォーム要素 */}
                {
                    scheduleTableHeads.map((head, index) => (
                        formElements.map((element) => {
                            //以下二点を満たした場合フォーム要素を返却
                            //１・head.propとelement.labelが一致する場合はelement.elementを表示
                            //２・visibilityがrule_typeと一致の場合は表示、falseの場合は非表示
                            if ((element.label === head.prop && element.visibility === true) || (element.label === head.prop && typeof element.visibility !== 'boolean' && element.visibility.some(visibility => visibility === rule_type))) {
                                // trueの場合
                                return element.element
                            } else if ((element.label === head.prop && element.visibility === false) || (element.label === head.prop && typeof element.visibility !== 'boolean' && element.visibility.some(visibility => visibility !== rule_type))) {
                                // falseの場合
                                return <td>ーーー</td>
                            }
                        })
                    ))
                }

                <td></td>
                <td></td>
                <td></td>

                {/* サブミットボタン */}
                <SubmitBtns>
                    <UpsertBtn
                        clinic_schedule_id={clinic_schedule_id}
                        setOpenValidateRule={setOpenValidateRule}
                        enableBtnCss={enableBtnCss}
                    />
                    <CancelBtn
                        setEditing={setEditing}
                        setIsOpenAddRecord={setIsOpenAddRecord}
                        enableBtnCss={enableBtnCss}
                    />
                </SubmitBtns>
            </BodyRow>

            {/* ------------ */}
        </>
    )
}