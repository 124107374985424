import { Fragment, useContext, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CheckIcon } from '@heroicons/react/outline'
import { ClinicContext, useGraphqlClient } from '@/App';
import { GroupSelectViewsType } from '../../../../entities/common/types';
import { useListFacilityGroupsQuery } from '@/_graphql/graphql-client';
import { useFacilityGroupContext } from '@/domain/FacilityGroup/useFacilityGroup';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export const GroupSelect: React.FC<GroupSelectViewsType> = ({
    isMobile,
    setFacilitiesFilter
}) => {

    const graphqlClient = useGraphqlClient()
    const clinic = useContext(ClinicContext)
    const { group_id, setFacilityGroupId } = useFacilityGroupContext()

    //初期化
    useEffect(() => {
        setFacilitiesFilter(getFilterType(group_id))
    }, [])

    // グループ一覧
    const allGroupList = useListFacilityGroupsQuery(graphqlClient, { inputs: { clinic_id: clinic.clinic_id } }).data?.listFacilityGroups.FacilityGroups || []
    // isDeleteがtrueのものは除外
    const activeGroupList = getNonDeletedGroupList(allGroupList)
    // option上に表示されるグループ名
    const displayGroupName = getDisplayGroupName(group_id || '', activeGroupList);

    return (
        <Listbox
            value={group_id}
            onChange={(value) => {
                setFacilitiesFilter(getFilterType(value))
                setFacilityGroupId(value || '')
            }}>
            {({ open }) => (
                <>
                    <div className={`${isMobile ? 'min-w-[80px] text-xs' : 'min-w-[150px]'} relative`}>
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6">
                            <span className="block truncate">{displayGroupName}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ArrowDropDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <Listbox.Option
                                    key={'undefined'}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'bg-blue-300 text-blue-900' : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                        )
                                    }
                                    value={undefined}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                全ての施設
                                            </span>

                                            {selected ? (
                                                <span
                                                    className={classNames(
                                                        active ? 'text-blue-900' : 'text-blue-300',
                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                                <Listbox.Option
                                    key={'unassigned'}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'bg-blue-300 text-blue-900' : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                        )
                                    }
                                    value={'unassigned'}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                グループ未所属
                                            </span>

                                            {selected ? (
                                                <span
                                                    className={classNames(
                                                        active ? 'text-blue-900' : 'text-blue-300',
                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                                {activeGroupList?.map((group) => (
                                    <Listbox.Option
                                        key={group?.group_id}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'bg-blue-300 text-blue-900' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={group?.group_id}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                    {group?.name}
                                                </span>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-blue-900' : 'text-blue-300',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}


export function getDisplayGroupName(group_id: string, activeGroupList: any[]): string {
    const groupNameMapping: { [key: string]: string } = {
        '': '全ての施設',
        'undefined': '全ての施設',
        'unassigned': 'グループ未所属'
    };

    const selectedGroupName = activeGroupList?.find(group => group?.group_id === group_id)?.name;
    //全施設 / グループ未所属 / グループ / いずれにも該当しなければ削除済みグループとなる
    return groupNameMapping[group_id || ''] || selectedGroupName || '削除済みグループ';
}

export function getNonDeletedGroupList(
    activeGroupList: any[]
) {
    return activeGroupList?.filter(group => !group?.isDelete) || []
}

export function getFilterType(group_id?: string | null): 'all' | 'withoutGroup' | 'withGroup' {
    if (group_id == undefined || group_id.length === 0) {
        return 'all';
    } else if (group_id === 'unassigned') {
        return 'withoutGroup';
    } else {
        return 'withGroup';
    }
}