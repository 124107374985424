/**
 * 患者詳細ダイアログで予約履歴を表示するコンポーネント
 */

import { useGraphqlClient, ClinicContext } from "@/App";
import { useEffect, useState, useContext } from "react";
import { useLocation } from "@tanstack/react-location";
import { emptyAppoint, emptyAppointV2 } from "@/components/Common/utils/common/emptyData";
import { endOfDay, endOfToday, getUnixTime, startOfDay } from "date-fns";
import { AppointmentListByPatientsControlPanelPresenter } from "@/components/Appointment/components/AppointmentListByPatients/presenter/AppointmentListByPatientsControlPanelPresenter";
import { AppointmentListByPatientsPresenter } from "@/components/Appointment/components/AppointmentListByPatients/presenter/AppointmentListByPatientsPresenter";
// NOTE 230401 Modal開閉不具合のため一旦、旧コンポーネントからの呼び出しに戻す
import { useAppointmentStatusConfirm } from "@/components/Appointment/hooks/useAppointmentStatusConfirm";
import { AppointStatusType } from "@/components/Appointment/entities";
import { useAppointList_service } from "@/domain/Appointment";
import { useMenuNameList_service, useStaffNameList_service } from "@/domain";
import { getNYearsAgo, getNYearsLater } from "@/components/Common/utils";
import { SetDateContext } from "@/components/Main/provider/MainProvider";
import { useAppointListByPatient_service } from "@/domain/Appointment/services/hooks";
import { useAppointForPatientInfo_service, useAppointListByIdV2_service } from "@/domain/Appointment/services/hooks/Query/useAppointList_service";
import { AppointV2 } from "@/_graphql/graphql-client";

const appointStatus: AppointStatusType[] = [
    {
        status: '予約済み',
        statusName: '予約',
        styles: 'bg-green-200 text-green-900 hover:bg-green-300 cursor-default'
    },
    {
        status: '受付済み',
        statusName: '受付',
        styles: 'bg-blue-300 text-blue-900 hover:bg-blue-400 cursor-default'
    },
    {
        status: '診療完了',
        statusName: '完了',
        styles: 'bg-yellow-200 text-yellow-900 hover:bg-yellow-300 cursor-default'
    },
    {
        status: 'キャンセル',
        statusName: 'キャンセル',
        styles: 'bg-red-300 text-red-900 hover:bg-red-400 cursor-default'
    }
]


export const AppointmentListByPatientsViews: React.FC = () => {
    const graphqlClient = useGraphqlClient();
    const { nowDate } = useContext(SetDateContext);

    // NOTE 230401 Modal開閉不具合のため一旦、旧コンポーネントからの呼び出しに戻す
    // const { AppointmentStatusConfirm, openModal, setOpenModal } = useAppointmentStatusConfirm();
    const [dateRange, setDateRange] = useState<(Date | null)[]>([null, null]);
    const [startDate, endDate] = dateRange;
    // const [appoints, setAppoints] = useState([emptyAppoint]);

    // FIXME: locationをPlaneでも参照しているのに改めてuseLocationしているのでpropsで受け取るようにする
    //        useLocationへの依存が多すぎてコンポーネントの単体テストができない
    const location = useLocation();
    const pathname = location.current.pathname;
    const searchStr = location.current.searchStr;
    const search: any = location.current.search;
    const patientId = search?.['patient-id'] || '';

    const clinicId = useContext(ClinicContext).clinic_id;
    const allReservationList = useAppointListByIdV2_service(graphqlClient, { clinic_id: clinicId, patient_id: patientId }) as AppointV2[] | undefined

    const reservationList = getFilteredReservations(allReservationList, startDate, endDate);


    function getFilteredReservations(
        allReservations: AppointV2[] | undefined,
        startDate: Date | null,
        endDate: Date | null
    ) {
        if (!allReservations) {
            return allReservations
        }
        if (startDate && endDate) {
            return allReservations?.filter(appoint => {
                return (appoint?.startTime >= getUnixTime(startOfDay(startDate))) && (appoint?.startTime <= getUnixTime(endOfDay(endDate)));
            });
        } else if (startDate === null && endDate === null) {
            return [...allReservations]?.sort((a, b) => b.startTime - a.startTime);
        }
        return allReservations;
    }

    const allCancel = allReservationList?.filter(appoint => appoint?.appoint === 'キャンセル')
    //「医院都合」を除くキャンセルの数をフィルタリングする
    const patientInitiatedCancelNum = allCancel?.filter(cancel => cancel?.cancel?.radioCancelReason === 0).length || 0;
    const unannouncedCancelNum = allCancel?.filter(cancel => cancel?.cancel?.radioCancelReason === 2).length || 0;

    const appointsNum = allReservationList?.length || 0
    const lateNum = allReservationList?.filter(appoint => appoint?.isLate).length || 0;


    return (
        <div className="h-full overflow-scroll">
            <div className="w-full">
                <AppointmentListByPatientsControlPanelPresenter
                    startDate={startDate}
                    endDate={endDate}
                    setDateRange={setDateRange}
                    appointsNum={appointsNum}
                    patientInitiatedCancelNum={patientInitiatedCancelNum}
                    unannouncedCancelNum={unannouncedCancelNum}
                    lateNum={lateNum}
                />
                <AppointmentListByPatientsPresenter
                    nowDate={nowDate}
                    appoints={reservationList}
                    // NOTE 230401 Modal開閉不具合のため一旦、旧コンポーネントからの呼び出しに戻す
                    // AppointmentStatusConfirm={AppointmentStatusConfirm}
                    pathname={pathname}
                />
            </div>
        </div>
    )
}