import React from 'react';
import { MedicalCareMenuAddRecord } from '@/feature/phr-28/components/template/medicalCareRegistration/MedicalCareMenuAddRecord';
import { SearchBox } from '@/feature/phr-28/components/parts/SearchBox';
import { BasicTabBar } from '@/feature/phr-28/components/views/BasicTabBar';
import { MedicalCategoryViews } from '@/components/MedicalCategory/components/views/MedicalCategoryViews';
import { MedicalMenuPresenterType } from '../util/common/type';
import { MedicalMenuTableViews } from '../views/MedicalMenuTableViews';

export const MedicalMenuPresenter: React.FC<MedicalMenuPresenterType> = ({
  tabTitles,
  isCenter,
  tabName,
  setTabName,
  nowDate,
  setNowDate,
  matches,
  setFilterKeyword,
  bulkDeleteArr,
  setBulkDeleteArr,
  deleteBtnCss,
  disableBtnCss,
  show,
  setShow,
  lastIndexNum,
  categoryList,
  isOpenDeleteDialog,
  setIsOpenDeleteDialog,
  DeleteMenusDialog,
  filteredLists,
  setSortingFactorNumber,
  isSort,
  setIsSort,
  setOrder,
}): JSX.Element => {
  return (
    <>
      <BasicTabBar
        tabTitles={tabTitles}
        arrangement={isCenter}
        setTabName={setTabName}
        nowDate={nowDate}
        setNowDate={setNowDate}
        matches={matches}
      />

      {tabName === '診療メニュー登録' && (
        <>
          <form action="/medical_menu" method="get">
            <div className="flex justify-between p-2">
              <SearchBox setFilterKeyword={setFilterKeyword} />
              <div className="flex gap-4">
                <div className="pt-2 flex justify-end">
                  <button
                    type="button"
                    className={bulkDeleteArr.length > 0 ? deleteBtnCss : disableBtnCss}
                  >
                    {show ? (
                      <p
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        キャンセル
                      </p>
                    ) : (
                      <p
                        className={disableBtnCss}
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        治療内容の追加
                      </p>
                    )}
                  </button>
                </div>
                <div className="flex justify-end items-center">
                  <button
                    type="button"
                    className={bulkDeleteArr.length > 0 ? deleteBtnCss : disableBtnCss}
                    // className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-[10%] text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                    onClick={() => setIsOpenDeleteDialog(true)}
                  >
                    まとめて削除
                  </button>
                </div>
              </div>
            </div>
            {/* menu add */}
            {show && (
              <MedicalCareMenuAddRecord
                setShow={setShow}
                indexNum={lastIndexNum + 1}
                categoryList={categoryList}
              />
            )}
            {isOpenDeleteDialog && (
              <DeleteMenusDialog
                filteredLists={filteredLists}
                bulkDeleteArr={bulkDeleteArr}
                setBulkDeleteArr={setBulkDeleteArr}
                setIsOpen={setIsOpenDeleteDialog}
              />
            )}
          </form>
          {/* table */}
          <MedicalMenuTableViews
            filteredLists={filteredLists}
            categoryList={categoryList}
            setSortingFactorNumber={setSortingFactorNumber}
            isSort={isSort}
            setIsSort={setIsSort}
            setOrder={setOrder}
            setBulkDeleteArr={setBulkDeleteArr}
            bulkDeleteArr={bulkDeleteArr}
            lastIndexNum={lastIndexNum}
          />
        </>
      )}
      {tabName === '診療カテゴリ登録' && <MedicalCategoryViews categoryList={categoryList} />}
    </>
  );
};
