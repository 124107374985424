import { Dialog, DialogContent } from '@mui/material'
import { useState } from "react";
import { UseMutationResult, useQueryClient } from "react-query";
import { emptyMenu } from "../../common/emptyData";
import { MenuType } from "../../common/type";
import { Exact, UpDateMenuMutation, UpDateMenuMutationVariables, useUpDateMenuMutation } from "../../../../../../.graphql/types";
import { useGraphqlClient } from '@/App';

interface DeleteMenuDialogType {
    filteredLists: MenuType[],
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setBulkDeleteArr: React.Dispatch<React.SetStateAction<number[]>>
}

function closeDialog(
    emptyMenu: MenuType,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>,
    setDeleteMenu: React.Dispatch<React.SetStateAction<MenuType>>
) {
    setDeleteMenu(emptyMenu);
    setIsChecked(false);
    setIsOpen(false);
}

const deleteMenuExecute = (
    deleteMenu: MenuType,
    filteredLists: MenuType[],
    updateMenu: UseMutationResult<UpDateMenuMutation, unknown, Exact<UpDateMenuMutationVariables>, unknown>,
    setBulkDeleteArr: React.Dispatch<React.SetStateAction<number[]>>,
): void => {
    // NOTE 0421 メニュー削除で全体のチェックを外す
    setBulkDeleteArr([]);
    deleteMenu.isDelete = true;
    // const test = { SDK_ID: deleteMenu.SDK_ID, isDelete: true }
    // console.log(test)
    updateMenu.mutate(deleteMenu)
    const tmpMenuList: MenuType[] = filteredLists.filter((menu: MenuType) => {
        return menu.SDK_ID !== deleteMenu.SDK_ID
    })
    modifyIndexWhenDelete(tmpMenuList, updateMenu)
}

// 削除した後にindexを再振り
const modifyIndexWhenDelete = (
    tmpMenuList: MenuType[],
    updateMenu: UseMutationResult<UpDateMenuMutation, unknown, Exact<UpDateMenuMutationVariables>, unknown>
): void => {
    let tmpIndex: number = 0
    tmpMenuList.forEach((menu: MenuType) => {
        menu.index = ++tmpIndex
        updateMenu.mutate(menu)
    })
}

export const useDeleteMenuDialog = () => {
    const graphqlClient = useGraphqlClient()
    const queryClient = useQueryClient()
    const [deleteMenu, setDeleteMenu] = useState(emptyMenu);
    const [isChecked, setIsChecked] = useState(false);

    const DeleteMenuDialog: React.FC<DeleteMenuDialogType> = ({
        filteredLists,
        setIsOpen,
        setBulkDeleteArr,
    }) => {
        const updateMenu = useUpDateMenuMutation(graphqlClient, {
            onSettled: () => {
                queryClient.invalidateQueries('listMenu')
            },
            onSuccess: () => {
                closeDialog(deleteMenu, setIsOpen, setIsChecked, setDeleteMenu);
            },
            onError: (err) => {
                alert('診療メニューの削除に失敗しました。再度お試しください。')
                console.log(err);
                closeDialog(deleteMenu, setIsOpen, setIsChecked, setDeleteMenu);
            }
        })

        return (
            <>
                {
                    updateMenu.isLoading &&
                    <div className="flex items-center justify-center fixed top-0 left-0 z-[9999] h-full w-full flex justify-center bg-black bg-opacity-50">
                        <div className="animate-ping h-4 w-4 bg-blue-600 rounded-full"></div>
                    </div>
                }
                <Dialog
                    transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
                    onClose={() => { closeDialog(deleteMenu, setIsOpen, setIsChecked, setDeleteMenu) }}
                    open={true}
                    PaperProps={{
                        style: {
                            maxWidth: 'none',
                        },
                    }}
                >
                    <DialogContent
                        dividers
                        style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', position: 'relative' }}
                    >
                        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6">
                            <div>
                                <div className="mt-3 text-center sm:mt-5 flex justify-center items-center gap-x-4">
                                    <input type="checkbox" checked={isChecked} onChange={() => { setIsChecked(!isChecked) }} />
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">{deleteMenu.name}</span>を削除しますか？
                                    </h3>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="button"
                                    className={`${isChecked ? '' : 'pointer-events-none opacity-50'} inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                                    onClick={() => { deleteMenuExecute(deleteMenu, filteredLists, updateMenu,  setBulkDeleteArr) }}
                                >
                                    削除する
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                    onClick={() => closeDialog(deleteMenu, setIsOpen, setIsChecked, setDeleteMenu)}
                                >
                                    キャンセル
                                </button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    return {
        DeleteMenuDialog,
        setDeleteMenu
    }
}
