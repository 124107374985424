import { useState } from "react";
import { AddGroupRow, EditGroupRow, ReferenceGroupRow } from "../parts";
import { openEditForm, resetFormState } from "../../util";
import { ConfigHeader } from "../../../Common/components/Config/components/ConfigHeader";
import { Table } from "@mantine/core";
import { $styledTR, _styledTH, _styledTBody, _styledTHead, BulkDeleteDialog } from "@/components/Common/components/Config";
import { FacilityGroupPresenterType } from "../../entities";
import { FacilitiesObjType } from "../../entities/components/presenter";


const facilityGroupTableHeadData = [
    '',
    'グループ',
    '所属施設',
    ''//編集・削除用カラム
]

//[施設グループ]プレゼンター
export const FacilityGroupPresenter: React.FC<FacilityGroupPresenterType> = ({
    clinic_id,
    facilityGroupList,
    facilitiesObj,
    addGroupMutation,
    deleteGroupMutation,
    updateGroupMutation,
    transactDeleteGroupsMutation
}) => {
    //新規登録行の開閉
    const [isOpenAddGroupRow, setIsOpenAddGroupRow] = useState(false)
    //編集・参照行の切り替え・・・編集中の行のid
    const [editingGroupId, setEditingGroupId] = useState('')
    //フォームバリュー(編集中のグループ名)
    const [editingGroupName, setEditingGroupName] = useState('')
    //削除許可フラグ・・・チェックボックスにチェックが入っている行のid
    const [allowedDeletes, setAllowedDeletes] = useState<string[]>([])
    // 一括削除ダイアログの開閉
    const [isOpenBulkDeleteDialog, setIsOpenBulkDeleteDialog] = useState(false)
    const [isChecked, setIsChecked] = useState(false)

    //検索ワード
    const [searchWord, setSearchWord] = useState('')
    const nonDeletedGroups = facilityGroupList.filter((group) => !group?.isDelete)
    // 検索ワードが入力されている場合は、検索ワードに一致するグループのみ表示
    const wordFilteredGroups = searchWord ? nonDeletedGroups.filter((group) => group?.name.includes(searchWord)) : nonDeletedGroups

    // nameプロパティの配列を作成
    const allowedDeleteNames = allowedDeletes.map((id) => facilityGroupList.find((group) => group?.group_id === id)?.name)

    const nonDeletedFacilitiesObj = Object.entries(facilitiesObj).reduce<FacilitiesObjType>((acc, [key, value]) => {
        acc[key] = value.filter(item => !item.isDelete);
        return acc;
    }, {});


    return (
        <>
            {
                // 一括削除ダイアログ
                isOpenBulkDeleteDialog &&
                <BulkDeleteDialog
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    setIsOpenBulkDeleteDialog={() => setIsOpenBulkDeleteDialog(false)}
                    allowedDeleteNames={allowedDeleteNames}
                    transactDeleteGroupsMutation={() => transactDeleteGroupsMutation({ inputs: { clinic_id, group_ids: allowedDeletes } })}
                />
            }
            {/* 設定画面共通ヘッダー */}
            <ConfigHeader
                searchBoxProps={{
                    searchWord,
                    setSearchWord
                }}
                toggleButtonProps={{
                    label: 'グループ追加',
                    isOpenAddRow: isOpenAddGroupRow,
                    setIsOpenAddRow: setIsOpenAddGroupRow,
                    onToggle: () => {
                        setIsOpenAddGroupRow(!isOpenAddGroupRow)
                        resetFormState(setEditingGroupId, setEditingGroupName)
                    }
                }}
                basicButtonProps={{
                    onClick: () => {
                        setIsOpenBulkDeleteDialog(true)
                    },
                    disabled: allowedDeletes.length === 0
                }}
                //FIXME
                isTransactDelete={true}
            />

            {/* 施設グループテーブル */}
            <Table verticalSpacing="xs" fontSize="xs">
                {/* テーブルヘッダー*/}
                <_styledTHead>
                    {/* 新規追加フォーム */}
                    {isOpenAddGroupRow &&
                        <AddGroupRow
                            editingGroupName={editingGroupName}
                            setEditingGroupName={setEditingGroupName}
                            resetFormState={() => {
                                resetFormState(setEditingGroupId, setEditingGroupName)
                                setIsOpenAddGroupRow(false)
                            }}
                            addGroupMutation={() => {
                                addGroupMutation({ inputs: { clinic_id, name: editingGroupName } })
                                resetFormState(setEditingGroupId, setEditingGroupName)
                                setIsOpenAddGroupRow(false)
                            }}
                        />}
                    <$styledTR border={false}>
                        {facilityGroupTableHeadData.map((label, index) => (
                            <_styledTH key={index}>
                                {label}
                            </_styledTH>
                        ))}
                    </$styledTR>
                </_styledTHead>

                {/* テーブルボディ*/}
                <_styledTBody>
                    {/* 施設グループ一覧 */}
                    {wordFilteredGroups.map((group) => (
                        //施設グループ
                        <>
                            {editingGroupId === group?.group_id &&
                                //編集フォーム
                                <EditGroupRow
                                    key={group.group_id}
                                    groupFacilities={[]}
                                    allowedDeletes={allowedDeletes}
                                    editingGroupName={editingGroupName}
                                    setEditingGroupName={setEditingGroupName}
                                    resetFormState={() => {
                                        setIsOpenAddGroupRow(false)
                                        resetFormState(setEditingGroupId, setEditingGroupName)
                                    }}
                                    isAllowed={allowedDeletes.find((id) => id === group?.group_id) ? true : false}
                                    updateGroupMutation={() => {
                                        updateGroupMutation({ inputs: { clinic_id, group_id: group?.group_id, name: editingGroupName } })
                                        resetFormState(setEditingGroupId, setEditingGroupName)
                                    }
                                    }
                                />}
                            {editingGroupId !== group?.group_id &&
                                //参照
                                <ReferenceGroupRow
                                    key={group?.group_id}
                                    groupId={group?.group_id || ''}
                                    groupFacilities={nonDeletedFacilitiesObj?.[group?.group_id || ''] || []}
                                    groupName={group?.name || ''}
                                    editingGroupId={editingGroupId}
                                    openEditForm={() => {
                                        setIsOpenAddGroupRow(false)
                                        openEditForm(group?.group_id || '', group?.name || '', setEditingGroupName, setEditingGroupId)
                                    }}
                                    deleteFacilityGroup={() => deleteGroupMutation({ inputs: { clinic_id, group_id: group?.group_id } })}

                                    setAllowedDeletes={() => {
                                        setAllowedDeletes((prev) => {
                                            const updatedDeletes = prev.filter(id => id !== '');

                                            if (updatedDeletes.includes(group?.group_id || '')) {
                                                return updatedDeletes.filter(id => id !== group?.group_id);
                                            } else {
                                                return [...updatedDeletes, group?.group_id || ''];
                                            }
                                        })
                                    }}

                                    isAllowed={allowedDeletes.find((id) => id === group?.group_id) ? true : false}
                                />
                            }
                        </>
                    ))}
                </_styledTBody>
            </Table>
        </>
    );
}