import { uuid4 } from '@sentry/utils'
import type { FacilityType } from '@/components/Facility/entities';
import type { ReserveType, AppointShiftPresenterPropsType } from '@/components/Reservation/entities';
import { hourTimeStamp, quarterTimeStamp } from '@/components/Reservation/utils';
import { getPatientInfo } from '@/components/Common/utils/common/getInfo'
import { getUnixTime } from 'date-fns';
import { openPane } from '@/components/Main/util';
import { BlockType } from '@/components/Block/entities';
import { MethodsContext } from '@/components/Main/provider/FormModeProvider';
import { useContext, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { ErrorBoundary } from '@/components/Common/components';
import { AppointCardPresenter } from './AppointCardPresenter';

//FIXME :phr-28からimportされている
import { NotificateEditMode } from '@/feature/phr-28/components/template/Appointment/ModalContents/NotificateEditMode';
import { startOfTodayTimestamp, unixTimeFromNowDate } from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon';
import { useSPAppoint } from '@/feature/phr-28/components/template/Appointment/smartPhone/useSPAppoint';
import { useSPBlockInfo } from '@/feature/phr-28/components/template/Appointment/smartPhone/useBlockInfo';
import { $FacilityFrame, $FacilitiesLabel, _AppointShiftWrapper, $TimeBar, QuarterSlot, ShiftFrames, BlockCard, OneHourSlots, InteractionCard } from '../parts/AppointSchedule/AppointShift';
import { viewSizesType } from '@/components/Common/entities';
import { Flex } from '@mantine/core';
import { useCommonParams } from '@/domain/Common/useCommonParams';
import { useAppointParams } from '@/domain/Appointment/useAppointParams';
import { useBlockParams } from '@/domain/Block/useBlockParams';
import { AppointDetails } from '@/_graphql/graphql-client';

export const AppointShiftPresenter: React.FC<AppointShiftPresenterPropsType> = ({
    printRef,
    isHoliday,
    isBeforeDay,

    setIsPaneOpen,

    shiftFrameStart,
    shiftFrameEnd,

    operation,

    shiftStartHours,
    shiftEndHours,
    shiftStartMinutes,
    shiftEndMinutes,

    appointId,
    blockId,

    viewSizes,
    subPanesRef,
    navigate,

    clinic,
    nowDate,

    startTime,
    endTime,

    allReservationList,
    allBlockList,

    facilityList,
}) => {

    const matches = useMediaQuery('(max-width: 926px)')

    const { methods } = useContext(MethodsContext)
    const { facilityId, setCommonParams } = useCommonParams()
    const { setAppointParams, resetAppointId } = useAppointParams()
    const { block_id, setBlockParams, resetBlockId } = useBlockParams()

    const { watch, setValue, reset } = methods

    const { SPAppointModal } = useSPAppoint()
    const { BlockInfoModal } = useSPBlockInfo()
    const [openAppointInfoForSP, setOpenAppointInfoForSP] = useState(false)
    const [openBlockInfoForSP, setOpenBlockInfoForSP] = useState(false)

    // appoint.statusが'キャンセル'の予約を除外したリスト
    const reservationList = allReservationList?.filter(appoint => appoint.appoint !== 'キャンセル')
    const facilityFrameWidth = 100 / facilityList.length;

    type BlockMapType = {
        [key: string]: BlockType[];
    };
    type AppointMapType = {
        [key: string]: AppointDetails[];
    };

    const blockMapByFacilityId = allBlockList.reduce((acc, block) => {
        if (!acc[block.facilityId]) {
            acc[block.facilityId] = [];
        }
        acc[block.facilityId].push(block);
        return acc;
    }, {} as BlockMapType);

    const AppointMapByFacilityId = reservationList?.reduce((acc, reservation) => {
        if (!acc[reservation.facilityId]) {
            acc[reservation.facilityId] = [];
        }
        acc[reservation.facilityId].push(reservation);
        return acc;
    }, {} as AppointMapType);

    const currentBlock = allBlockList?.find(block => block.id === blockId)
    const currentAppoint = reservationList?.find(appoint => appoint.appointId === appointId)
    const isOpenAppointModal = openAppointInfoForSP && currentAppoint
    const isOpenBlockModal = openBlockInfoForSP && currentBlock

    return (
        <>
            {isOpenAppointModal &&
                <ErrorBoundary>
                    <SPAppointModal
                        appointDetail={currentAppoint}
                        closeModal={() => {
                            setOpenAppointInfoForSP(false)
                            resetAppointId()
                        }} />
                </ErrorBoundary>
            }

            {isOpenBlockModal &&
                <ErrorBoundary>
                    <BlockInfoModal
                        block={currentBlock}
                        facilityList={facilityList}
                        closeModal={() => {
                            setOpenBlockInfoForSP(true);
                            resetBlockId()
                        }}
                    />
                </ErrorBoundary>
            }

            {/* コンポーネントwrapper */}
            {(operation === 'edit' || operation === 'copy') &&
                <ErrorBoundary>
                    <NotificateEditMode
                        navigate={navigate}
                        operation={operation}
                    />
                </ErrorBoundary>
            }

            {/* プリント対象 */}
            <_AppointShiftWrapper printRef={printRef}>
                {/* // 施設ラベル一覧 */}
                <$FacilitiesLabel
                    facilityList={facilityList}
                    facilityFrameWidth={facilityFrameWidth}
                />

                <Flex direction='row' justify='around' className='box-border p-1'>
                    {/* シフト時間 */}
                    <$TimeBar
                        shiftStartTime={shiftFrameStart}
                        shiftEndTime={shiftFrameEnd}
                        shiftEndMinutes={shiftEndMinutes}
                    />

                    {/* シフト枠: 空枠・予約カード・ブロックカードを施設単位で表示 */}
                    {
                        facilityList.map((facility: FacilityType) => {
                            const firstCardTime: number = startOfTodayTimestamp(nowDate) + hourTimeStamp * shiftStartHours

                            // 予約カード
                            const relatedAppoints = AppointMapByFacilityId?.[facility.id] || [];
                            const appointCards = relatedAppoints.map((appoint) => {
                                const cardBoxNum: number = (appoint.endTime - appoint.startTime) / quarterTimeStamp;
                                const appointCardHigh: number = cardBoxNum * 100 - 10;
                                const startTime: number = (appoint.startTime - firstCardTime) / quarterTimeStamp * 100;

                                return (
                                    <ErrorBoundary key={appoint.appointId + appoint.id}>
                                        <AppointCardPresenter
                                            oparation={operation}
                                            appointId={appointId}
                                            reservation={appoint}
                                            reservationCardHigh={appointCardHigh}
                                            startTime={startTime}
                                            endTime={endTime}

                                            onClickHandler={() => {
                                                if (matches) {
                                                    setAppointParams(getUnixTime(nowDate), appoint.id, appoint.startTime, appoint.endTime, appoint.facilityId, appoint.appointId)
                                                    setOpenAppointInfoForSP(true);
                                                    return;
                                                }
                                                if (operation === "edit") {
                                                    return;
                                                }
                                                reset();
                                                setIsPaneOpen && setIsPaneOpen(true);
                                                openPane(subPanesRef, 'top', viewSizes.height, viewSizes.width);
                                                navigate({
                                                    to: `appoints-list/appoint-info`,
                                                    fromCurrent: false,
                                                });
                                                setAppointParams(getUnixTime(nowDate), appoint.id, appoint.startTime, appoint.endTime, appoint.facilityId, appoint.appointId)

                                            }}
                                        />
                                    </ErrorBoundary>
                                );
                            });

                            // ブロックカード
                            const relatedBlocks = blockMapByFacilityId[facility.id] || [];
                            const blockCards = relatedBlocks.map((block: BlockType) => {
                                return (
                                    <ErrorBoundary key={block.id}>
                                        <BlockCard
                                            block={block}
                                            firstCardTime={firstCardTime}
                                            quarterTimeStamp={quarterTimeStamp}
                                            isBlockEdit={operation === 'edit' && blockId === block.id}
                                            blockId={blockId}
                                            endTime={endTime}
                                            onClickHandler={() => {
                                                if (matches) {
                                                    setOpenBlockInfoForSP(true);
                                                    setBlockParams(getUnixTime(nowDate), block.id, block.startTime, block.endTime, block.facilityId);
                                                    return;
                                                }
                                                navigate({ to: `add-appoint`, fromCurrent: false });
                                                setBlockParams(getUnixTime(nowDate), block.id, block.startTime, block.endTime, block.facilityId);
                                                handleResetAndOpenPane(subPanesRef, viewSizes, reset, setIsPaneOpen);
                                            }}
                                        />
                                    </ErrorBoundary>
                                )
                            });

                            const interactionCard =
                                <InteractionCard
                                    firstCardTime={firstCardTime}
                                    quarterTimeStamp={quarterTimeStamp}
                                    startTime={startTime || 0}
                                    endTime={endTime || 0}
                                />


                            //シフト枠
                            const clinicTimeRange = Array.from({ length: shiftFrameEnd - shiftFrameStart + 1 }, (_, i) => i + shiftFrameStart);
                            const shiftFrames = clinicTimeRange.map(currentHour => {
                                const isShiftStart = currentHour === shiftStartHours;
                                const isShiftEnd = currentHour === shiftEndHours;
                                const isShiftEndOnHourBoundary = isShiftEnd && shiftEndMinutes === 0;

                                // シフト開始のインデックス
                                const shiftStartQuarterIndex = (isShiftStart ? shiftStartMinutes : 0) / 15;
                                // シフト終了のインデックス
                                const shiftEndQuarterIndex = (isShiftEnd ? shiftEndMinutes : 60) / 15;

                                return (
                                    <>
                                        {
                                            isShiftEndOnHourBoundary === false &&
                                            <OneHourSlots
                                                key={currentHour}
                                                intervalMinute={15}
                                                shiftStartHours={shiftStartHours}
                                                shiftStartMinutes={shiftStartMinutes}
                                                shiftEndHours={shiftEndHours}
                                                shiftEndMinutes={shiftEndMinutes}
                                                currentHour={currentHour}
                                                isShiftStart={isShiftStart}
                                                isShiftEnd={isShiftEnd}
                                                shiftStartQuarterIndex={shiftStartQuarterIndex}
                                                shiftEndQuarterIndex={shiftEndQuarterIndex}
                                                isHoliday={isHoliday}
                                                isBeforeDay={isBeforeDay}
                                                operation={operation}
                                                nowDate={nowDate}
                                                facility={facility}
                                                blockId={block_id || ''}
                                                appointId={appointId}
                                                startTime={startTime || 0}
                                                endTime={endTime || 0}
                                                setValue={setValue}
                                                navigate={navigate}
                                                setIsPaneOpen={setIsPaneOpen}
                                                openPane={openPane}
                                                watch={watch}
                                                subPanesRef={subPanesRef}
                                                viewSizes={viewSizes}
                                                setCommonParams={setCommonParams}
                                                setBlockParams={setBlockParams}
                                                setAppointParams={setAppointParams}
                                                resetAppointId={resetAppointId}
                                                resetBlockId={resetBlockId}
                                                location={location}
                                            />
                                        }
                                    </>
                                );
                            })

                            {/* 戻り値 */ }
                            return (
                                // 施設枠
                                <$FacilityFrame key={facility.id} facilityFrameWidth={facilityFrameWidth}>
                                    {shiftFrames}
                                    {
                                        facilityId === facility.id && endTime &&
                                        interactionCard
                                    }
                                    {blockCards}
                                    {appointCards}
                                </$FacilityFrame>
                            )
                        })}

                </Flex>
            </_AppointShiftWrapper>
        </>
    )
}

// 
function handleMatch(
    block: BlockType,
    setBlock: React.Dispatch<React.SetStateAction<BlockType>>,
    setOpenBlockInfoForSP: React.Dispatch<React.SetStateAction<boolean>>
): void {
    setBlock(block);
    setOpenBlockInfoForSP(true);
}

function handleResetAndOpenPane(
    subPanesRef: any,
    viewSizes: viewSizesType,
    reset: any,
    setIsPaneOpen: React.Dispatch<React.SetStateAction<boolean>> | null,
): void {
    reset();
    setIsPaneOpen && setIsPaneOpen(true);
    openPane(subPanesRef, 'top', viewSizes.height, viewSizes.width);
}

function handleNavigation(
    navigate: any
): void {
    navigate({ to: `add-appoint`, fromCurrent: false });
}