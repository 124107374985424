import { _styledComponentProps } from "@/components/Common"

export interface _AppointShiftWrapperProps {
    printRef: React.MutableRefObject<HTMLDivElement | null>
    children: React.ReactNode
}

const style = 'h-[85%] scale-x-100 w-full overflow-scroll box-border'
export const _AppointShiftWrapper: React.FC<_AppointShiftWrapperProps> = ({
    printRef,
    children,
}) => {
    return (
        <div ref={printRef} className={style}>
            {children}
        </div>
    )
}
