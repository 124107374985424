import { SelectViews } from '@/components/Common/components/parts/Select/views/SelectTimeForBlockViews';
import { japanTimeFormatDate } from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon'
import { SearchPatientModal } from '@/feature/phr-28/components/template/Appointment/ModalContents/SearchPatientModal'
import { MedicalCategoryModal } from '@/feature/phr-28/components/template/Appointment/ModalContents/MedicalCategoryModal'
import type { PatientsType } from '@/components/Patient/components/util/common/type';
import { listManegerType } from '@/components/Patient/components/util/common/type';
import { UseFormReturn } from 'react-hook-form';
import { AddAppointDefaultValuesType } from '@/components/Reservation/entities';
import React, { useContext, useEffect, useState } from 'react';
import { ClinicContext, useGraphqlClient } from '@/App';
import { ClinicTime, useGetLatestClinicConfigQuery } from '@/_graphql/graphql-client';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import { fromUnixTime, getHours, getMinutes, getUnixTime, getYear, isAfter, setHours, setMinutes, startOfDay } from 'date-fns';
import { emptyClinicTimeConfig } from '@/components/Common/utils/common/emptyData';

import { $FacilityName, $AppointDetailLabel, $ConsultationStartTime, SelectPatientLabel, SelectMenuLabel, InputRemarks, StaffSelector, SubmitButton } from '../../parts/AppointDetail';
import { _styledDivTop, _styledDivMiddle, _styledDivBottom, _styledDivLeftBottom } from '@/components/Reservation/components/parts/AppointDetail';
import { sortAndExtractIds } from '../utils/util';
import { useCommonParams } from '@/domain/Common/useCommonParams';
import { useUpdateEndTimeParam } from '../../parts/AppointDetail/hooks/useUpdateEndTimeParam';
import { isWithoutEndTime } from '../utils/AddAppoint';

import { SelectDentalFormulaLabel } from '../../parts/AppointDetail/common/SelectDentalFormulaLabel';
import { DentalFormulaModal } from '@/feature/phr-28/components/template/Appointment/ModalContents/DentalFormulaModal';

interface AddNewAppointType {
  formData: any
  watch: any;
  register: any;
  methods: UseFormReturn<AddAppointDefaultValuesType, any>,

  displayPatientName: string
  menuList: any;
  categoryList: any;
  manegerListsIncludeDelete: listManegerType[];
  menuListIncludeDelete: any;
  facilityList: any;

  startTime: number;
  endTime: number;

  facilityId: string;

  setOpenSearchPatientModal: any;
  openSearchPatientModal: boolean;
  setOpenAddPatientModal: any;
  openMedicalCategoryModal: boolean;
  setOpenMedicalCategoryModal: any;

  // openDentalFormulaModal: boolean;
  // setOpenDentalFormulaModal: React.Dispatch<React.SetStateAction<boolean>>;
}


export const AddNewAppoint: React.FC<AddNewAppointType> = ({
  watch,
  register,
  methods,
  formData,

  displayPatientName,

  menuList,
  categoryList,
  manegerListsIncludeDelete,
  menuListIncludeDelete,
  facilityList,

  startTime,
  endTime,
  facilityId,

  setOpenSearchPatientModal,
  openSearchPatientModal,
  setOpenAddPatientModal,

  openMedicalCategoryModal,
  setOpenMedicalCategoryModal,

  // openDentalFormulaModal,
  // setOpenDentalFormulaModal, 
}) => {

  const [openDentalFormulaModal, setOpenDentalFormulaModal] = useState(false);


  const { setValue } = methods;
  const { nowDate } = useContext(SetDateContext)
  const { setEndTime } = useCommonParams()
  const clinic = useContext(ClinicContext)
  const graphqlClient = useGraphqlClient()

  const activeClinicTimeConfig = useGetLatestClinicConfigQuery(graphqlClient, { clinic_id: clinic.clinic_id, current_date: getUnixTime(startOfDay(nowDate)), config_type: 'clinic_time' })
  const activeClinicTime = activeClinicTimeConfig.data?.getLatestClinicConfig?.config.clinicTime

  const clinicTime = activeClinicTime ? activeClinicTime : emptyClinicTimeConfig.config.clinicTime
  const withoutEndTime = isWithoutEndTime(clinicTime, endTime, getUnixTime(nowDate))

  // 予約終了時間のクエリパラメータを更新する
  useUpdateEndTimeParam(setEndTime, 'appointData', methods);


  console.log(formData);

  return (
    <>
      {/* 上段 */}
      <_styledDivTop>
        {/* 施設名 */}
        <$FacilityName
          facilityList={facilityList}
          facilityId={watch('appointData.facilityId')}
        />
        {/* 予約詳細ラベル: '新規追加' || '予約参照' */}
        <$AppointDetailLabel
          label='新規追加'
        />
      </_styledDivTop>

      {/* 中段 */}
      <_styledDivMiddle>
        <$ConsultationStartTime
          appointStartTime={
            japanTimeFormatDate(
              fromUnixTime(watch('appointData.startTime'))
            )
          }
        />

        {/* 診療時間セレクト */}
        <div className='flex text-xl'>
          <div className="flex items-end">
            <SelectViews
              formType='appoint'
              setValue={setValue as any}
              register={register}
              formData={formData}
              setEndTime={setEndTime}
            />
          </div>
        </div>
      </_styledDivMiddle>

      {/* 下段 */}
      <_styledDivBottom>
        {/* 左カラム */}
        <_styledDivLeftBottom>

          {/* 患者選択 */}
          <SelectPatientLabel
            displayPatientName={displayPatientName}
            disabled={false}
            //患者未選択時にハイライト
            isHighlighted={watch('appointData.id') === '0'}
            openSearchPatientModal={() => { setOpenSearchPatientModal(true) }}
          />
          {/* 患者選択モーダル */}
          {openSearchPatientModal && <SearchPatientModal setOpenAddPatientModal={setOpenAddPatientModal} setOpenSearchPatientModal={setOpenSearchPatientModal} />}


          {/* メニュー選択 */}
          <SelectMenuLabel
            allMenuList={menuListIncludeDelete}
            menuId={watch('appointData.menuId')}
            openSearchMenuModal={() => { setOpenMedicalCategoryModal(true) }}
          />
          {
            openMedicalCategoryModal &&
            <MedicalCategoryModal
              currentShiftTimestamp={formData.startTime}
              categoryList={categoryList}
              MenuList={menuList}
              setOpenMedicalCategoryModal={setOpenMedicalCategoryModal} />
          }

          {/* 歯式選択 */}
          <SelectDentalFormulaLabel
            openSearchMenuModal={() => { setOpenDentalFormulaModal(true) }}
            dentalFormula={formData.dentalFormula}
          />
          {
            openDentalFormulaModal &&
            <DentalFormulaModal
              dentalFormula={formData.dentalFormula}
              setOpenDentalFormulaModal={setOpenDentalFormulaModal}
              setValue={setValue}
              setDentalFormula={()=>{}}
              />
          }

          {/* コメント入力 */}
          <InputRemarks
            registerProps={register('appointData.remarks')}
          />
        </_styledDivLeftBottom>

        <_styledDivLeftBottom>

        {/* 担当者選択 */}
        <StaffSelector
          register={register}
          manegerListIncludeDelete={manegerListsIncludeDelete}
          // staffIds配列をindex順にソート&idのみをmapで取り出した配列を返却
          selectedStaffIds={sortAndExtractIds(watch('appointData.staffIds'))}
        />
      {/* ボタン */}
      <SubmitButton
        disabled={(watch('appointData.id') === '0') || withoutEndTime}
        label='登録'
      />

        </_styledDivLeftBottom>

      </_styledDivBottom>

    </>
  )
}