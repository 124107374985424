export interface TimeSlotProps {
    hour: number;
    min: string;
    isEndOfShift: boolean; // 現在の時間がシフトの終了時間かどうか
    isEndOfShiftWithoutMinutes: boolean; // シフトの終了時間で、終了分が0で、現在の時間が30分単位かどうか
}

export const $TimeSlot: React.FC<TimeSlotProps> = ({
    hour,
    min,
    isEndOfShift,
    isEndOfShiftWithoutMinutes
}) => {

    // スタイルを変数化
    const heightStyle = isEndOfShift ? '0' : '100px';
    const transformStyle = isEndOfShift ? 'translateY(-1.5rem)' : undefined;
    const combinedStyle = {
        height: heightStyle,
        transform: transformStyle
    };

    if (isEndOfShiftWithoutMinutes) {
        return null;
    }

    return (
        <div style={combinedStyle}>
            {hour}:{min}
        </div>
    );
}
