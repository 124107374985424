import { $styledTRProps } from "@/components/Common/entities/config";

export const $styledTR: React.FC<$styledTRProps> = ({ children, border }) => {
    const borderStyle = border ? 'border-b' : '';
    const commonStyle = " w-full bg-white dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600";
    const style = `${commonStyle} ${borderStyle}`;

    return (
        <tr className={style}>
            {children}
        </tr>
    )
};