import { useState } from "react";
import { Link } from "@tanstack/react-location";
import { HeaderPresenterPropsType } from "@/components/Main/entities";
import { CloseButton } from "@/components/Common";
import { openPane } from "@/components/Main/util";

export const SubPanesHeaderPresenter: React.FC<HeaderPresenterPropsType> = ({
    subPanesRef,
    menuItems,
    viewHeight,
    viewWidth
}) => {
    const [active, setActive] = useState(0);

    return (
        <div className="flex">
            <ul className="flex">
                {
                    menuItems.map((item, index) => {
                        return (
                            <Link key={item.to} to={item.to} replace={true}>
                                <li onClick={() => { openPane(subPanesRef, 'top', viewHeight, viewWidth); setActive(index) }} className={`flex items-end text-sm font-bold px-2 ${active === index ? "bg-gray-200 rounded-t" : ""}`}>
                                    {item.icon}
                                    {item.label}
                                </li>
                            </Link>
                        )
                    })
                }
            </ul>
        </div>
    )
}
