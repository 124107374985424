import { useEffect } from 'react';

export const useUpdateEndTimeParam = (
    setEndTime: (time: any) => void,
    target: 'appointData' | 'block',
    methods: any,
    isLoading?: boolean // 追加: isLoadingパラメータ
) => {
    const { watch } = methods;

    useEffect(() => {
        // isLoadingがtrueの場合、処理をスキップ
        if (isLoading) return;

        if (methods) {
            setEndTime(watch(`${target}.endTime`));
        }
    }, [watch(`${target}.endTime`), isLoading]); // isLoadingを依存配列に追加
};
