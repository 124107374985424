export interface ConsultationStartTimePropsType {
    appointStartTime: string
}

export const $ConsultationStartTime: React.FC<ConsultationStartTimePropsType> = ({
    appointStartTime
}) => {
    return (
        <div className="pl-1 text-xs">
            診療時間：{appointStartTime}〜
        </div>
    )
}
