import React, { useState } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import { emptyBlock } from '../../../common/emptyData'
import { getFacilityName, japanTimeFormatDate } from '@/components/Common/utils'
import { fromUnixTime } from 'date-fns'
import { BlockInfoModalType, BlockInfoType } from '@/components/Reservation/entities/components'
import { clearBlockInfoState } from '@/components/Reservation/utils'

export const useSPBlockInfo = () => {

    const BlockInfoModal: React.FC<BlockInfoModalType> = ({
        block,
        facilityList,
        closeModal
    }) => {

        const blockInfo: BlockInfoType[] = [
            {
                label: "施設名",
                getter: getFacilityName,
                data: block.facilityId,
                list: facilityList
            },
            {
                label: "カラー",
                getter: (color: string) => (
                    <div
                        style={{ backgroundColor: color }}
                        className={`h-4 w-10 border border-black`}
                    ></div>
                ),
                data: block.color as string
            }
        ];

        return (
            <>
                <Dialog
                    transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
                    onClose={closeModal}
                    open={true}
                    PaperProps={{
                        style: {
                            maxWidth: 'none',
                            width: '90%',
                            transition: 'all 0',
                            zIndex: 9999
                        }
                    }}
                >
                    {/* content */}
                    <DialogContent
                        dividers
                        style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', height: '90vh', position: 'relative' }}
                    >
                        <div className="h-full">
                            <div className="h-[10%] text-sm text-gray-600 flex items-center pl-4">
                                {`${japanTimeFormatDate(fromUnixTime(block.startTime))} - ${japanTimeFormatDate(fromUnixTime(block.endTime))}`}
                            </div>
                            <dl className="h-[90%]">
                                {blockInfo.map((item, index) => (
                                    <div className={`h-[10%] px-4 sm:grid items-center sm:grid-cols-4 sm:gap-4 sm:px-6 ${index % 2 === 0 ? 'bg-gray-100' : ''}`} key={index}>
                                        <dt className="text-sm font-medium text-gray-600">{item.label}</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            {item.getter(item.list ? item.list as any : item.data, item.data)}
                                        </dd>
                                    </div>
                                ))}
                                <div className="h-[80%] overflow-y-scroll py-5 bg-gray-100 px-4 text-sm text-gray-900">
                                    {block.remarks}
                                </div>
                            </dl>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    return { BlockInfoModal }

}
