import { ReactLocation, Route } from '@tanstack/react-location'
import { DashBoard, Facility, Menu, QR, Staff } from '@/pages'
import { MainWindow } from '@/pages/Main/MainWindow'
import { SettingsViews } from '@/components/Settings/components/views/SettingsViews'
import { FacilityAndFloor } from '@/routes/components/FacilityAndFloor'
import { ClinicSettingsViews } from '@/components/ClinicSettings/components/views/ClinicSettingsViews'
export const location = new ReactLocation()

export const routes: Route[] = [
  {
    path: '/',
    element:
      <div className="opacity-0 pointer-events-none">
        <DashBoard />
      </div>
  },
  {
    path: '/main/*',
    element: <MainWindow />
  },
  {
    path: '/settings',
    children: [
      {
        path: '/',
        element:
          <SettingsViews>
            <ClinicSettingsViews />
          </SettingsViews>
      },
      {
        path: '/facility',
        element:
          <SettingsViews>
            <FacilityAndFloor />
          </SettingsViews>
      },
      {
        path: '/staff',
        element:
          <SettingsViews>
            <Staff />
          </SettingsViews>
      },
      {
        path: '/menu',
        element:
          <SettingsViews>
            <Menu />
          </SettingsViews>
      },
    ]
  },
  {
    path: 'masta',
    children: [
      {
        path: '/',
        element: <Staff />
      }
    ]
  },
  {
    path: 'medical_menu',
    children: [
      {
        path: '/',
        element: <Menu />
      }
    ]
  },
  {
    path: '/qr-recept',
    element: <QR />
  },
  {
    path: 'facility',
    children: [
      {
        path: '/',
        element: <Facility />
      }
    ]
  }
]
