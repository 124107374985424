import { GraphQLClient } from 'graphql-request'
import { useBlockListVariablesType } from '@/domain/Block/entities/types'
import { useScanPeriodBlockQuery } from '@/_graphql/graphql-client'
import { BlockListMapper } from '@/domain/Block/services/mapper/blockListMapper';

export const useBlockList_service = (
    graphqlClient: GraphQLClient,
    variables: useBlockListVariablesType
) => {
    const blocks = useScanPeriodBlockQuery(graphqlClient, variables);

    const blockList = BlockListMapper(blocks)
    return blockList
}