import { ClinicContext, useGraphqlClient } from "@/App";
import { ClinicTimePresenter } from "@/components/ClinicSettings/components/presenter/ClinicTimePresenter"
import { useClinicConfigTimeList_service } from "@/domain/ClinicConfig/service/hooks/Query/useClinicConfig"
import { MutateContext } from "@/provider/common/MutateProvider";
import { useContext } from "react";
import { SetDateContext } from "@/components/Main/provider/MainProvider";
import { endOfDay, getUnixTime, startOfDay } from "date-fns";
import { getSortedClinicTimeRules } from "../../utils/libs/clinicTimeConfig";

export const ClinicTimeViews: React.FC = () => {
    const { nowDate } = useContext(SetDateContext)

    const currentTimestamp = {
        start: getUnixTime(startOfDay(nowDate)),
        end: getUnixTime(endOfDay(nowDate))
    }

    const clinic = useContext(ClinicContext)
    const graphqlClient = useGraphqlClient();
    const clinicTimeList = useClinicConfigTimeList_service(graphqlClient, { clinic_id: clinic.clinic_id });
    const sortedClinicTimeList = getSortedClinicTimeRules(clinicTimeList)

    const { deleteClinicConfig } = useContext(MutateContext)

    return (
        <>
            {
                clinicTimeList &&
                <ClinicTimePresenter
                    currentStartTimestamp={currentTimestamp.start}
                    currentEndTimestamp={currentTimestamp.end}
                    clinic={clinic}
                    clinicTimeList={sortedClinicTimeList}
                    deleteClinicConfig={deleteClinicConfig}
                />
            }
        </>
    )
}
