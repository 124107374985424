import { ClinicContext, useGraphqlClient } from "@/App"
import { useContext, useEffect, useState } from "react"
import { generateTimeSlots, isOverlapping, updateEndTimeIfNeeded } from '@/components/ClinicSettings/utils'
import { UpsertRecordPresenter } from "@/components/ClinicSettings/components/presenter/UpsertRecordPresenter"
import { UpsertRecordViewsType } from "../../entities"
import { add, addBusinessDays, addMinutes, endOfDay, getUnixTime, startOfDay } from "date-fns"
import { MutateContext } from "@/provider/common/MutateProvider"
import { useClinicConfigTimeList_service } from "@/domain/ClinicConfig/service/hooks/Query/useClinicConfig"

export const UpsertRecordViews: React.FC<UpsertRecordViewsType> = ({
    setIsOpenAddRecord,
    setEditing,
    clinicTime,
    clinicTimeList,
}) => {

    const clinic = useContext(ClinicContext)
    const { addClinicTimeConfig, updateClinicTimeConfig } = useContext(MutateContext)
    const isUpdate = clinicTime != undefined

    // フォームステート
    // 編集ならClinicTimeConfigから値参照 新規の場合はデフォルト値
    const [startHours, setStartHours] = useState(isUpdate ? clinicTime.config.clinicTime.startTime.hours : 8)
    const [startMinutes, setStartMinutes] = useState(isUpdate ? clinicTime.config.clinicTime.startTime.minutes : 0)
    const [endHours, setEndHours] = useState(isUpdate ? clinicTime.config.clinicTime.endTime.hours : 23)
    const [endMinutes, setEndMinutes] = useState(isUpdate ? clinicTime.config.clinicTime.endTime.minutes : 0)
    const [startDate, setStartDate] = useState(isUpdate ? clinicTime.startDate : getUnixTime(startOfDay(new Date())))
    const [endDate, setEndDate] = useState(isUpdate ? clinicTime.endDate : null)

    const graphqlClient = useGraphqlClient()
    // const clinicTimeList = useClinicConfigTimeList_service(graphqlClient, { clinic_id: clinic.clinic_id })

    // オプション
    const [startTimeSlots, setStartTimeSlots] = useState(generateTimeSlots(15, true, "24:00"));
    const [endTimeSlots, setEndTimeSlots] = useState(generateTimeSlots(15, false, `${startHours}:${startMinutes}`));
    // 有効期間の重複判定
    // const isOverlap = clinicTime ? isOverlapping(startDate, endDate, clinicTimeList, clinicTime.config_id) : isOverlapping(startDate, endDate, clinicTimeList)

    //スタイル
    const enableBtnCss: string = 'cursor-pointer leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'
    const disableBtnCss: string = 'opacity-50 pointer-events-none leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'

    // 開始時間が変更されたときにendTimeSlotsを更新
    useEffect(() => {
        setEndTimeSlots(generateTimeSlots(15, false, `${startHours}:${startMinutes}`));
        updateEndTimeIfNeeded(startHours, startMinutes, endHours, endMinutes, setEndHours, setEndMinutes);
    }, [startHours, startMinutes]);

    return (
        <UpsertRecordPresenter
            // isOverlap={isOverlap}
            isUpdate={isUpdate}

            graphqlClient={graphqlClient}
            clinicTimeList={clinicTimeList}

            addClinicTimeConfig={addClinicTimeConfig}
            updateClinicTimeConfig={updateClinicTimeConfig}
            clinic={clinic}
            config_id={clinicTime?.config_id}
            //フォームステート
            startHours={startHours}
            startMinutes={startMinutes}
            endHours={endHours}
            endMinutes={endMinutes}
            startDate={startDate}
            endDate={endDate}
            setStartHours={setStartHours}
            setStartMinutes={setStartMinutes}
            setEndHours={setEndHours}
            setEndMinutes={setEndMinutes}
            setStartDate={setStartDate}
            setEndDate={setEndDate}

            // オプションバリュー
            startTimeSlots={startTimeSlots}
            endTimeSlots={endTimeSlots}
            // 編集モード切り替えステート
            setIsOpenAddRecord={setIsOpenAddRecord}
            setEditing={setEditing}
            //スタイル
            enableBtnCss={enableBtnCss}
            disableBtnCss={disableBtnCss}
        />
    )
}