import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined'
import { IconLabel } from "../../IconLabel"

export interface SelectPatientLabelPropsType {
    displayPatientName: string
    disabled: boolean
    isHighlighted: boolean
    openSearchPatientModal: () => void
}

export const SelectPatientLabel: React.FC<SelectPatientLabelPropsType> = ({
    displayPatientName,
    disabled,
    isHighlighted,
    openSearchPatientModal,
}) => {

    return (
        <IconLabel
            icon={PersonAddOutlinedIcon}
            label={displayPatientName}
            disable={disabled}
            font="bold"
            isHighlighted={isHighlighted}
            onClickHandler={openSearchPatientModal}
        />
    )
}
