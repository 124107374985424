import { addDays } from 'date-fns'
import getUnixTime from 'date-fns/getUnixTime'
import fromUnixTime from 'date-fns/fromUnixTime'
import {
  Appoints
} from '@/feature/phr-28/components/common/type'
import { endOfDay, startOfDay } from 'date-fns/esm'
import { emptyPatients } from '@/feature/phr-28/components/common/emptyData'

import {PatientsType} from '@/components/Patient/components/util/common/type'

export const shiftStartTime: number = 8
export const shiftEndTime: number = 21
export const weeks = ['日', '月', '火', '水', '木', '金', '土']
export const hourTimeStamp: number = 60 * 60
export const quarterTimeStamp: number = hourTimeStamp / 4
export const oneDayTimestamp: number = 60 * 60 * 24
export const todayTimeStamp: number = unixTimeFromNowDate(new Date())

export function startOfTodayTimestamp(
  nowDate: Date
) {
  const startOfTodayTimestamp = getUnixTime(startOfDay(nowDate))
  return startOfTodayTimestamp;
}

export function endOfTodayTimestamp(
  nowDate: Date
) {
  const endOfTodayTimestamp = getUnixTime(endOfDay(nowDate))
  return endOfTodayTimestamp;
}

// nowDateの00:00:00のタイムスタンプを返却
export function unixTimeFromNowDate(nowDate: Date): number {
  return getUnixTime(
    new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate())
  )
}

export function dateFromUnixTime(unixTimeStamp: number): Date {
  return fromUnixTime(unixTimeStamp)
}

// 日付情報のフォーマット yyyy-MM-dd-HH:MM
export function formatDateWithTime(date: any, boundary: string = '-'): string {
  const tmpDate: string = formatDate(date, boundary)
  return (
    tmpDate +
    ' ' +
    String(date.getHours()).padStart(2, '0') +
    ':' +
    String(date.getMinutes()).padStart(2, '0')
  )
}

// //日付情報のフォーマット yyyy-MM-dd
export function formatDate(date: any, boundary: string = '-'): string {
  return (
    String(date.getFullYear()) +
    boundary +
    (String(Number(date.getMonth()) + 1)).padStart(2, '0') +
    boundary +
    String(date.getDate())
  )
}

export function japanTimeFormatDate(date: any): string {
  return (
    String(date.getFullYear()) + '年' +
    (String(Number(date.getMonth()) + 1)).padStart(2, '0') + '月' +
    String(date.getDate()) + '日' +
    String(date.getHours()).padStart(2, '0') + '時' +
    String(date.getMinutes()).padStart(2, '0') + '分'
  )
}

export function japanTimeFormatDay(date: any): string {
  return (
    String(date.getFullYear()) + '年' +
    (String(Number(date.getMonth()) + 1)).padStart(2, '0') + '月' +
    String(date.getDate()) + '日'
  )
}

export function japanTimeFormatHours(date: any): string {
  return (
    String(date.getHours()).padStart(2, '0') + '時' +
    String(date.getMinutes()).padStart(2, '0') + '分'
  )
}

export function getAddDay(date: Date, plusDays: number): Date {
  return addDays(date, plusDays)
}

export function createWeeklyDays(date: Date): string[][] {
  const tmpWeeklyDays: string[] = []
  const dayOfWeekStrArr: string[] = []
  const tmpDate = new Date(date.getTime())
  let value: number = 0
  for (let i = 0; i < 7; i++) {
    tmpDate.setDate(tmpDate.getDate() + value)
    tmpWeeklyDays.push(formatDate(tmpDate))
    dayOfWeekStrArr.push(weeks[tmpDate.getDay()])
    if (i === 0) {
      value = 1
    }
  }
  return [dayOfWeekStrArr, tmpWeeklyDays]
}

export function getPatientInfo(list: Appoints, patientsList: PatientsType[]): PatientsType {
  let currentPatient: PatientsType = emptyPatients
  patientsList.forEach((patient: PatientsType) => {
    if (list.id === patient.id) {
      currentPatient = patient
    }
  })

  return currentPatient
}

export const amShifts: boolean[] = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false
]
export const pmShifts: boolean[] = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false
]
