import { DayOfWeek } from "@/_graphql/graphql-client"
import { DayOfWeekSelectType } from "@/components/ClinicSettings/entities"
import { optionSlots } from "@/components/ClinicSettings/utils"
import { nullableOptionSlots } from "@/components/ClinicSettings/utils/libs/optionSlots"
// import { generateOptionSlots } from "@/components/ClinicSettings/utils/libs/optionSlots";
import { XCircleIcon } from "@heroicons/react/outline"

// [フォームパーツ]曜日選択
export function DayOfWeekSelect({
    day_of_week,
    setDayOfWeek,
    isNullable
}: DayOfWeekSelectType): JSX.Element {

    const baseOptionSlots = isNullable ? nullableOptionSlots : optionSlots

    return (
        <td>
            <div className="h-full w-full relative flex items-center">
                {
                    <select
                        className='w-full h-full text-xs'
                        onChange={(e) => {
                            setDayOfWeek(e.currentTarget.value as DayOfWeek)
                        }}
                    >
                        {baseOptionSlots.day_of_week.map((slot, index) => (
                            // FIXME: any型のtype定義
                            <option key={index} value={slot.value as any} selected={day_of_week === slot.value}>{slot.label}</option>
                        ))}
                    </select>
                }
                {((isNullable && day_of_week !== null)) &&
                    <XCircleIcon className="h-4 w-4 absolute right-2"
                        onClick={() => {
                            setDayOfWeek(null)
                        }
                        }
                    />
                }
            </div>
        </td>
    )
}

