import { Fragment, useContext, useState } from 'react'
import { Transition } from '@headlessui/react'
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from '@tanstack/react-location';
import { getStartTimeOfToDayTimestamp } from '@/components/Common';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import { NavigateType } from '@/components/Common/entities';

interface NotificateEditModeType {
    navigate: NavigateType
    operation: any
    // setIsAppointEdit: React.Dispatch<React.SetStateAction<boolean>>
    // isAppointEdit: boolean
    // isBlockEdit: boolean
    // setIsBlockEdit: React.Dispatch<React.SetStateAction<boolean>>
    // setIsAppointUpdated: React.Dispatch<React.SetStateAction<boolean>>
    // setIsBlockUpdated: React.Dispatch<React.SetStateAction<boolean>>
}

export const NotificateEditMode: React.FC<NotificateEditModeType> = ({
    navigate,
    operation
    // setIsAppointEdit,
    // setIsBlockEdit,
    // isAppointEdit,
    // isBlockEdit,
    // //FIXME: リファクタ後削除でOK
    // setIsAppointUpdated,
    // setIsBlockUpdated
}) => {

    const subTitle = operation === 'copy' ? '追加中・・・' : '編集中・・・'
    const message = operation === 'copy' ? '追加先のシフト枠を選択してください。' : '変更先のシフト枠を選択してください。'

    return (
        <>
            <div
                aria-live="assertive"
                className="pointer-events-none fixed top-0 left-0 z-[99] inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    <Transition
                        show={true}
                        as={Fragment}
                        enter="transform ease-out duration-300 delay-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        <UpdateIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-bold text-green-400">{subTitle}</p>
                                        <p className="mt-1 text-sm text-gray-500">{message}</p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex flex-col items-center rounded-md bg-white"
                                            onClick={() => {
                                                history.back()
                                            }}
                                        >
                                            <CloseIcon className="h-5 w-5" aria-hidden="true" />
                                            <span className="block">cancel</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}
