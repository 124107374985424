import { GraphQLClient } from 'graphql-request'
import { useScanPeriodAppointQuery, useListAppointByIdQuery, useListAppointForAppointShiftQuery, useListAppointForAppointListQuery, useListAppointForPatientInfoQuery, AppointDetails, useListAppointByIdV2Query } from '@/_graphql/graphql-client'
import { AppointListMapper } from '@/domain/Appointment/services/mapper/AppointListMapper';
import { useAppointDetailsByIdVariablesType, useAppointDetailsVariablesType, useAppointListByPatientVariablesType, useAppointListVariablesType } from '@/domain/Appointment/entities/types';

export const useAppointList_service = (
    graphqlClient: GraphQLClient,
    variables: useAppointListVariablesType
) => {
    const appoints = useScanPeriodAppointQuery(graphqlClient, variables)

    const appointList = AppointListMapper(appoints);
    return {
        appointList: appointList,
        req: appoints
    };
}

//シフト枠
export const useAppointForAppointShift_service = (
    graphqlClient: GraphQLClient,
    variables: useAppointDetailsVariablesType
) => {
    const appoints = useListAppointForAppointShiftQuery(graphqlClient, { inputs: variables }, { cacheTime: 0 })

    return {
        appointList: appoints.data?.listAppointDetails as AppointDetails[],
        req: appoints
    };
}

//予約一覧ページ
export const useAppointForAppointListQuery_service = (
    graphqlClient: GraphQLClient,
    variables: useAppointDetailsVariablesType
) => {
    const appoints = useListAppointForAppointListQuery(graphqlClient, { inputs: variables })

    return {
        appointList: appoints.data?.listAppointDetails,
        req: appoints
    };
}

//患者詳細内の予約一覧
export const useAppointForPatientInfo_service = (
    graphqlClient: GraphQLClient,
    variables: useAppointDetailsByIdVariablesType
) => {
    const appoints = useListAppointForPatientInfoQuery(graphqlClient, { inputs: variables })

    return {
        appointList: appoints.data?.listAppointDetailsById,
        req: appoints
    };
}

export const useAppointListByPatient_service = (
    graphqlClient: GraphQLClient,
    variables: useAppointListByPatientVariablesType
) => {
    const appoints = useListAppointByIdQuery(graphqlClient, { id: variables.patient_id })

    //FIXME: 将来的にマッピング廃止
    const appointList = AppointListMapper(appoints as any);
    return appointList;
}

export const useAppointListByIdV2_service = (
    graphqlClient: GraphQLClient,
    variables: useAppointListByPatientVariablesType
) => {
    const appoints = useListAppointByIdV2Query(graphqlClient, { clinic_id: variables.clinic_id, id: variables.patient_id })
    const appointList = appoints.data ? appoints.data.listAppointByIdV2?.Appoints : undefined;
    return appointList;
}