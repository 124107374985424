import { useDeleteCategoryMutation } from '@/_graphql/graphql-client';
import { useEffect, useState } from 'react';
import { useAddCategoryModal } from '@/feature/phr-28/components/template/medicalCareRegistration/useAddCategoryModal';
import { useQueryClient } from 'react-query';
import { useDeleteCategorysDialog } from '@/feature/phr-28/components/template/medicalCareRegistration/useDeleteCategorysDialog';
import { useGraphqlClient } from '@/App';
import { MedicalCategoryViewsType } from '../util/common/type';
import { MedicalCategoryPresenter } from '../presenter/MedicalCategoryPresenter';
import { clearState } from '../util/common/util';

export const MedicalCategoryViews: React.FC<MedicalCategoryViewsType> = ({ categoryList }) => {
  const graphqlClient = useGraphqlClient();
  const { AddCategoryModal, setOpenAddCategory, setCurrentCategory } = useAddCategoryModal();
  const { DeleteCategoryDialog, setCurrentCategorys } = useDeleteCategorysDialog();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [mainCategory, setMainCategory] = useState<'all' | 'dental' | 'medical'>('all');
  const [selectedCategoryList, setSelectedCategoryList] = useState(categoryList);
  const [deletable, setDeletable] = useState(['']);
  const [editable, setEditable] = useState('');

  useEffect(() => {
    //初期化
    clearState(setMainCategory, setDeletable, setEditable);
  }, []);

  useEffect(() => {
    switch (mainCategory) {
      case 'all':
        setSelectedCategoryList(categoryList);
        break;
      case 'medical':
        const medicalCategoryList = categoryList.filter(
          (category) => category.mainCategory === '医科'
        );
        setSelectedCategoryList(medicalCategoryList);
        break;
      case 'dental':
        const dentalCategoryList = categoryList.filter(
          (category) => category.mainCategory === '歯科'
        );
        setSelectedCategoryList(dentalCategoryList);
        break;
    }
  }, [mainCategory, categoryList]);

  const queryClient = useQueryClient();
  const { mutate } = useDeleteCategoryMutation(graphqlClient, {
    onSettled: () => {
      queryClient.invalidateQueries('listCategorys');
    },
    onSuccess: () => {
      setDeletable(['']);
    },
    onError: () => {
      setDeletable(['']);
    },
  });

  return (
    <MedicalCategoryPresenter
      openDeleteDialog={openDeleteDialog}
      DeleteCategoryDialog={DeleteCategoryDialog}
      categoryList={categoryList}
      setOpenDeleteDialog={setOpenDeleteDialog}
      mainCategory={mainCategory}
      setMainCategory={setMainCategory}
      deletable={deletable}
      setDeletable={setDeletable}
      setCurrentCategorys={setCurrentCategorys}
      selectedCategoryList={selectedCategoryList}
      setOpenAddCategory={setOpenAddCategory}
      setCurrentCategory={setCurrentCategory}
      mutate={mutate}
      AddCategoryModal={AddCategoryModal}
    />
  );
};
