import { useEffect, useRef, useState } from 'react';
import { getUnixTime, startOfMonth } from 'date-fns';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { weeks, formatDate } from '@/components/Common/utils';
import type {
  MonthCalendarPresenterPropsType,
  daysType,
} from '@/components/MonthCalendar/entities';
import { classNames, getAddDay, getIsHoliday } from '@/components/MonthCalendar/utils';
import {
  DisplayNowDateType,
  MonthDayButtonsType,
  MoveDateButtonType,
  MoveMonthButtonType,
  MoveToNowDateButtonType,
} from '../../utils/components/parts';

export const MonthCalendarPresenter: React.FC<MonthCalendarPresenterPropsType> = ({
  nowDate,
  setNowDate,
  holidays,
}) => {
  const container = useRef<HTMLDivElement>(null);
  const containerNav = useRef<HTMLDivElement>(null);
  const containerOffset = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      container.current != null &&
      containerNav.current != null &&
      containerOffset.current != null
    ) {
      // Set the container scroll position based on the current time.
      const currentMinute = new Date().getHours() * 60;
      container.current.scrollTop =
        ((container.current.scrollHeight -
          containerNav.current.offsetHeight -
          containerOffset.current.offsetHeight) *
          currentMinute) /
        1440;
    }
  }, []);

  const [days, setDays] = useState<daysType[]>();
  // 日付移動
  function calcDate(value: number): void {
    const _nowDate = new Date(nowDate.setDate(nowDate.getDate() + value));
    setNowDate(_nowDate);
  }

  // 月移動
  function calcMonth(value: number): void {
    // 計算前日付
    // const preDate = nowDate.getDate()
    const _nowDate: Date = startOfMonth(new Date(nowDate.setMonth(nowDate.getMonth() + value)));

    // if (_nowDate.getDate() !== preDate) {
    //   // 計算後日付が一致しない場合、前月月末指定
    //   // _nowDate = nowDate.setDate(0);
    // }
    setNowDate(_nowDate);
  }

  function createCalendar(): void {
    const date = nowDate;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const lastMonth = date.getMonth();
    const nextMonth = date.getMonth() + 2;
    const startDate = new Date(year, month - 1, 1); // 月の最初の日を取得
    const endDate = new Date(year, month, 0); // 月の最後の日を取得
    const endDayCount = endDate.getDate(); // 月の末日
    const lastMonthEndDate = new Date(year, month - 1, 0); // 前月の最後の日の情報
    const lastMonthendDayCount = lastMonthEndDate.getDate(); // 前月の末日
    const startDay = startDate.getDay(); // 月の最初の日の曜日を取得
    // 予約不可日数と定休日をoffにする値
    const limitCancellationNum: number = -1;
    const reservationNotPossibleDays: number = limitCancellationNum;
    const regularHoliday: number = limitCancellationNum;
    let dayCount = 1; // 日にちのカウント
    let numCount;

    // week分ループ
    const days: daysType[] = [];
    for (let weekLine = 0; weekLine < 6; weekLine++) {
      // console.log(`${w}ループ目, ${dayCount}カウント`)
      for (let dayBox = 0; dayBox < 7; dayBox++) {
        // 先月の日程
        if (weekLine === 0 && dayBox < startDay) {
          // 1行目で1日の曜日の前
          const num = lastMonthendDayCount - startDay + dayBox + 1;
          numCount = num;
          const contents: daysType = {
            date: `${year}/${lastMonth}/${numCount}`,
            isDisabled: true,
            isCurrentMonth: false,
            isHoliday: false,
          };
          days.push(contents);
        } else if (dayCount > endDayCount) {
          // 来月の日程
          const num = dayCount - endDayCount;
          numCount = num;
          const contents: daysType = {
            date: `${year}/${nextMonth}/${numCount}`,
            isDisabled: true,
            isCurrentMonth: false,
            isHoliday: false,
          };
          days.push(contents);
          dayCount++;
        } else {
          // 今月の日程
          const roopDate: Date = new Date(`${year}/${month}/${dayCount}`);
          // isDisabledには予約不可日数後で定休日でない、今月の日程のみfalseになる
          const contents: daysType = {
            date: `${year}/${month}/${dayCount}`,
            isDisabled:
              getAddDay(new Date(), reservationNotPossibleDays) < roopDate
                ? roopDate.getDay() === regularHoliday
                : true,
            isCurrentMonth: true,
            // isSelected: new Date(nowDate).getMonth() === new Date().getMonth() && new Date(nowDate).getFullYear() === new Date().getFullYear() ? formatDate(new Date()) === formatDate(new Date(`${year}-${month}-${dayCount}`)) : dayCount === 1,
            isSelected: new Date(nowDate).getDate() === dayCount,
            isToday: formatDate(new Date()) === formatDate(roopDate),
            isHoliday: roopDate.getDay() === 0 || getIsHoliday(roopDate, holidays),
          };
          days.push(contents);
          dayCount++;
        }
      }
    }

    setDays(days);
  }

  const navigate = useNavigate();
  //FIXME: PaneからPropsで渡すよう修正

  useEffect(() => {
    // date更新の度にカレンダー修正
    createCalendar();
    const params = new URLSearchParams(location.search);
    params.set('calendar-date', getUnixTime(nowDate).toString());
    navigate({ to: `${location.pathname}?${params.toString()}`, fromCurrent: true });
  }, [nowDate, holidays]);

  return (
    <Outline>
      <MoveMonthButtonsOutline>
        <MoveToPrevMonthButton calcMonth={calcMonth} />
        <DisplayNowDate nowDate={nowDate} />
        <MoveToNextMonthButton calcMonth={calcMonth} />
      </MoveMonthButtonsOutline>

      <WeekDays />

      <MonthDayButtons days={days} setNowDate={setNowDate} />

      <MoveDateButtonsOutline>
        <MoveToPrevWeekButton calcDate={calcDate} />
        <MoveToPrevDayButton calcDate={calcDate} />
        <MoveToNowDateButton setNowDate={setNowDate} />
        <MoveToNextDayButton calcDate={calcDate} />
        <MoveToNextWeekButton calcDate={calcDate} />
      </MoveDateButtonsOutline>
    </Outline>
  );
};

// NOTE 230529 Commonからインポートする（まだmainブランチに入ってない）
export interface OutlineType {
  children?: React.ReactNode;
}

const Outline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle = "h-1/2 gap-x-4 gap-y-2 text-sm overflow-y-scroll w-full flex-col flex box-border"
  const containerStyle = "hidden max-w-md flex-none md:block"
  return (
    <div className={outlineStyle}>
      {/* <p className="self-end text-gray-900 font-semibold">予約: {numberOfReservations} 件</p> */}
      <div className={containerStyle}>{children}</div>
    </div>
  );
};

const MoveMonthButtonsOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle = "grid grid-cols-5 items-center text-center text-gray-900"
  return <div className={outlineStyle}>{children}</div>;
};

const MoveToPrevMonthButton: React.FC<MoveMonthButtonType> = ({ calcMonth }) => {
  const buttonStyle = "text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-l text-[0.7rem] h-7 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
  return (
    <button
      onClick={() => calcMonth(-1)}
      type="button"
      className={buttonStyle}
    >
      先月
    </button>
  );
};

const DisplayNowDate: React.FC<DisplayNowDateType> = ({ nowDate }) => {
  const containerStyle = "col-span-3 text-[0.7rem] flex-auto font-semibold leading-3"
  return (
    <div className={containerStyle}>
      <span>{`${nowDate.getFullYear()}`}</span>
      <br />
      <span>{`${new Date(nowDate).getMonth() + 1}月${new Date(nowDate).getDate()}日(${weeks[nowDate.getDay()]
        })`}</span>
    </div>
  );
};

const MoveToNextMonthButton: React.FC<MoveMonthButtonType> = ({ calcMonth }) => {
  const buttonStyle = "text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-r text-[0.7rem] h-7 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
  return (
    <button
      onClick={() => calcMonth(1)}
      type="button"
      className={buttonStyle}
    >
      翌月
    </button>
  );
};

const WeekDays: React.FC = () => {
  const containerStyle = "mt-1 grid grid-cols-7 text-center text-xs leading-6 text-gray-500"
  return (
    <div className={containerStyle}>
      <div>日</div>
      <div>月</div>
      <div>火</div>
      <div>水</div>
      <div>木</div>
      <div>金</div>
      <div>土</div>
    </div>
  );
};

const MonthDayButtons: React.FC<MonthDayButtonsType> = ({ days, setNowDate }) => {
  const containerStyle = "isolate mt-1 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200"
  return (
    <div className={containerStyle}>
      {days?.map((day, dayIdx) => (
        <button
          onClick={(e) => {
            if (day.isCurrentMonth ?? false) {
              setNowDate(new Date(e.currentTarget.value));
            } else {
              setNowDate(new Date(e.currentTarget.value));
            }
          }}
          value={day.date}
          key={day.date}
          type="button"
          className={classNames(
            'py-0.5 hover:bg-gray-100 focus:z-10',
            day.isDisabled && day.isCurrentMonth === false
              ? 'bg-gray-100'
              : day.isHoliday !== undefined && day.isHoliday
                ? 'bg-red-200'
                : 'bg-white',
            ((day.isSelected ?? false) || (day.isToday ?? false)) && 'font-semibold',
            (day.isSelected ?? false) && 'text-white',
            (day.isSelected === undefined || !day.isSelected) &&
            (day.isCurrentMonth ?? false) &&
            (day.isToday === undefined || !day.isToday) &&
            'text-gray-900',
            (day.isSelected === undefined || !day.isSelected) &&
            (day.isCurrentMonth === undefined || !day.isCurrentMonth) &&
            (day.isToday === undefined || !day.isToday) &&
            'text-gray-400',
            (day.isToday ?? false) &&
            (day.isSelected === undefined || !day.isSelected) &&
            'text-indigo-600',
            dayIdx === 0 && 'rounded-tl-lg',
            dayIdx === 6 && 'rounded-tr-lg',
            dayIdx === days.length - 7 && 'rounded-bl-lg',
            dayIdx === days.length - 1 && 'rounded-br-lg'
          )}
        >
          <time
            dateTime={day.date}
            className={classNames(
              'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
              (day.isSelected ?? false) && (day.isToday ?? false) && 'bg-indigo-600',
              (day.isSelected ?? false) &&
              day.isToday !== undefined &&
              !day.isToday &&
              'bg-gray-900'
            )}
          >
            {day.date.split('/').pop()?.replace(/^0/, '')}
          </time>
        </button>
      ))}
    </div>
  );
};

const MoveDateButtonsOutline: React.FC<OutlineType> = ({ children }) => {
  const outlineStyle = "flex flex-row justify-between relative z-0 shadow-sm rounded-md w-full grid grid-cols-6 gap-0.5 h-8 mt-3"
  return (
    <div className={outlineStyle}>
      {children}
    </div>
  );
};

const MoveToPrevWeekButton: React.FC<MoveDateButtonType> = ({ calcDate }) => {
  const buttonStyle = "text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-l text-[0.7rem] text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
  return (
    <button
      onClick={() => calcDate(-7)}
      type="button"
      className={buttonStyle}
    >
      先週
    </button>
  );
};

const MoveToPrevDayButton: React.FC<MoveDateButtonType> = ({ calcDate }) => {
  const buttonStyle = "text-white bg-gray-700 hover:bg-gray-800 font-medium text-[0.7rem] text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
  return (
    <button
      onClick={() => calcDate(-1)}
      type="button"
      className={buttonStyle}
    >
      前日
    </button>
  );
};

const MoveToNowDateButton: React.FC<MoveToNowDateButtonType> = ({ setNowDate }) => {
  const buttonStyle = "col-span-2 text-white bg-indigo-600 hover:bg-indigo-700 font-medium text-[0.7rem] text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
  return (
    <button
      onClick={() => {
        setNowDate(new Date());
      }}
      type="button"
      className={buttonStyle}
    >
      当日
    </button>
  );
};
const MoveToNextDayButton: React.FC<MoveDateButtonType> = ({ calcDate }) => {
  const buttonStyle = "text-white bg-gray-700 hover:bg-gray-800 font-medium text-[0.7rem] text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
  return (
    <button
      onClick={() => calcDate(1)}
      type="button"
      className={buttonStyle}
    >
      翌日
    </button>
  );
};

const MoveToNextWeekButton: React.FC<MoveDateButtonType> = ({ calcDate }) => {
  const buttonStyle = "text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-r text-[0.7rem] text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
  return (
    <button
      onClick={() => calcDate(7)}
      type="button"
      className={buttonStyle}
    >
      翌週
    </button>
  );
};
