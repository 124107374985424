import { useEffect, useState } from 'react'
import { ClinicType } from '../../entities';

const VITE_FRONTEND_DOMAIN = process.env.VITE_FRONTEND_DOMAIN;

export const useRedirect = (selectClinic: ClinicType) => {
    const [isRedirected, setIsRedirected] = useState(false);

    useEffect(() => {
        //医院未選択なら抜ける
        if (!selectClinic.clinic_id) return

        const { clinic_label, org_label } = selectClinic;
        const redirectUrl = `https://${clinic_label}.${org_label}.${VITE_FRONTEND_DOMAIN}`;
        const redirectLocalUrl = `https://${clinic_label}.${org_label}.local.${VITE_FRONTEND_DOMAIN}`;

        // リダイレクト済みなら遷移しない
        // originと医院ドメイン一致でリダイレクト済み判定
        if (location.origin === redirectUrl || location.origin === redirectLocalUrl) {
            setIsRedirected(true)
            return
        }

        //リダイレクト(ローカル)
        if (location.origin.includes('local')) {
            location.href = `${redirectLocalUrl}`
            return
        }
        //リダイレクト
        location.href = `${redirectUrl}`

    }, [selectClinic, isRedirected])

    return { isRedirected }
}
