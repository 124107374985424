import React, { useState } from 'react'
import { PlusIcon } from '../../../../parts/PlusIcon'
import { MinusIcon } from '../../../../parts/MinusIcon'
import { ShiftAddModal } from './ShiftAddModal'
import { ShiftDeleteModal } from './ShiftDeleteModal'
import { ShiftEditModal } from './ShiftEditModal'
import {
  unixTimeFromNowDate,
  shiftStartTime,
  shiftEndTime,
  createWeeklyDays,
  oneDayTimestamp
} from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon'
import {
  listManegerType,
  shiftType
} from '@/feature/phr-28/components/common/type'
import {
  workingTimeAm,
  workingTimePm,
  emptyShiftData
} from '@/feature/phr-28/components/common/emptyData'
import { getDailyWeekly } from '@/feature/phr-28/components/common/Api'
import { useGraphqlClient } from '@/App'

interface ShiftRegistrationType {
  setShiftFix: (isShow: boolean) => void
  setShiftIdentificationNumber: (identification: string) => void
  nowDate: Date
  manegerLists: listManegerType[]
}

export const ShiftWeekly: React.FC<ShiftRegistrationType> = ({
  setShiftFix,
  setShiftIdentificationNumber,
  nowDate,
  manegerLists
}) => {
  const graphqlClient = useGraphqlClient()
  const [selectPerson, setSelectPerson] = useState('')
  const [show, setShow] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [modifyShift, setModifyShift] = useState(emptyShiftData)
  const [editElementsNumber, setEditElementsNumber] = useState(0)
  const weeklyDaysArr: string[][] = createWeeklyDays(nowDate)
  const manegerId = getSelectManegerId(manegerLists, selectPerson)
  const tmpWeekShifts: shiftType[] = getDailyWeekly(graphqlClient, nowDate, manegerId)
  const weekShifts: shiftType[] = createWeeklyShifts(
    nowDate,
    tmpWeekShifts,
    manegerId,
    selectPerson
  )

  return (
    <>
      <div className="flex pb-2">
        <select
          id="shiftTarget"
          name="shiftTarget"
          onChange={(e) => {
            setSelectPerson(e.target.value)
          }}
          className="w-2/5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option hidden></option>
          {manegerLists.map((maneger: listManegerType) =>
            maneger.name === selectPerson
              ? (
                <option selected value={maneger.name}>
                  {maneger.name}[{maneger.role}]
                </option>
              )
              : (
                <option value={maneger.name}>
                  {maneger.name}[{maneger.role}]
                </option>
              )
          )}
        </select>
        <div className="leading-10 pl-3">
          {weeklyDaysArr[1][0]} ~ {weeklyDaysArr[1][6]}
        </div>
      </div>
      <div className="flex flex-auto flex-col h-80 box-border p-1 overflow-scroll">
        <div className="flex flex-row">
          {getTimes()}
          {weekShifts.map((shift: shiftType, index: number) => {
            const shiftFrames = []
            if (shift.shifts.length !== 0) {
              let tmpShiftFrames = []
              for (let i = shiftStartTime; i < shiftEndTime; i++) {
                for (let p = 0; p < 4; p++) {
                  let roopNumber = 0
                  if (i === 8) {
                    roopNumber = p
                  } else {
                    roopNumber = 4 * (i - 8) + p
                  }

                  tmpShiftFrames.push(
                    <div
                      key={roopNumber}
                      onClick={() => {
                        setModifyShift(shift)
                        setShowEditModal(true)
                        setEditElementsNumber(roopNumber)
                      }}
                      className={
                        shift.shifts[roopNumber]
                          ? 'bg-blue-300 h-4 w-full hover:bg-blue-400'
                          : 'bg-blue-50 h-4 w-full hover:bg-blue-400'
                      }
                    ></div>
                  )
                }
                shiftFrames.push(
                  <div
                    className="h-20 border border-dotted border-gray-300 box-border p-1 flex flex-col justify-between"
                    key={100 + i}
                  >
                    {tmpShiftFrames}
                  </div>
                )
                tmpShiftFrames = []
              }
            }
            return (
              <>
                <div
                  className="w-full h-full box-border flex flex-col justify-between pt-1"
                  key={index}
                >
                  <div className="flex flex-col">
                    <div className="text-center border-b-2 h-5 flex justify-center">
                      <span className="text-xs mx-1">
                        {weeklyDaysArr[0][index]}
                      </span>
                      {shift.shifts.length !== 0 && (
                        <div
                          className="bg-gray-400 rounded-2xl cursor-pointer"
                          onClick={() => {
                            setModifyShift(shift)
                            setShowDeleteModal(true)
                          }}
                        >
                          <MinusIcon size={5} />
                        </div>
                      )}
                      {shift.shifts.length === 0 && (
                        <div
                          className="bg-yellow-200 rounded-2xl cursor-pointer"
                          onClick={() => {
                            setModifyShift(shift)
                            setShow(true)
                          }}
                        >
                          <PlusIcon size={5} />
                        </div>
                      )}
                    </div>
                    <div className="my-1 flex justify-center items-center">
                      <button
                        type="button"
                        onClick={() => {
                          setShiftFix(true)
                          setShiftIdentificationNumber(shift.id)
                        }}
                        className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-2 py-1 text-center"
                      >
                        まとめて登録
                      </button>
                    </div>
                    <div className="mx-1">{shiftFrames}</div>
                  </div>
                </div>
              </>
            )
          })}
          <ShiftAddModal isOpen={show} setShow={setShow} shift={modifyShift} />
          <ShiftEditModal
            isOpen={showEditModal}
            setShowEditModal={setShowEditModal}
            shift={modifyShift}
            editElementsNumber={editElementsNumber}
          />
          <ShiftDeleteModal
            isOpen={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            shift={modifyShift}
          />
        </div>
      </div>
    </>
  )
}

function createWeeklyShifts(
  nowDate: Date,
  weekShifts: shiftType[],
  manegerId: string,
  selectPerson: string
): shiftType[] {
  const emptyShifts: shiftType[] = []

  for (let i = 0; i < 7; i++) {
    const emptyShift: shiftType = {
      date: unixTimeFromNowDate(nowDate) + i * oneDayTimestamp,
      id: manegerId,
      name: selectPerson,
      shifts: [],
      workingTimes: { am: workingTimeAm, pm: workingTimePm }
    }
    emptyShifts.push(emptyShift)
  }
  emptyShifts.forEach((emptyShift: shiftType) => {
    let isExistDate: boolean = false
    weekShifts.forEach((shift: shiftType) => {
      if (emptyShift.date === shift?.date) {
        isExistDate = true
      }
    })
    if (!isExistDate) {
      weekShifts.push(emptyShift)
    }
  })
  weekShifts.sort((a, b) => a.date - b.date)
  return weekShifts
}

function getSelectManegerId(
  manegerLists: listManegerType[],
  selectPerson: string
): string {
  let id: string = ''
  manegerLists.forEach((maneger: listManegerType, index: number) => {
    if (maneger.name === selectPerson) {
      id = maneger.id
    }
  })
  return id
}

function getTimes(): JSX.Element {
  const items = []
  for (let i = shiftStartTime; i < shiftEndTime; i++) {
    items.push(
      <div className="h-20 box-border p-1" key={i}>
        {i}:00
      </div>
    )
  }
  return (
    <div className="w-12">
      <div className="text-center h-12" />
      {items}
    </div>
  )
}
