import { GraphQLClient } from 'graphql-request'
import { useListMenuQuery } from '@/_graphql/graphql-client'
import { useMenuListVariablesType } from '@/domain/Menu/entities/types';
import { menuListMapper } from '@/domain/Menu/services/mapper/menuListMapper';

export const useMenuList_service = (
    graphqlClient: GraphQLClient,
    variables: useMenuListVariablesType
) => {
    const menus = useListMenuQuery(graphqlClient, variables);
    const allMenuList = menuListMapper(menus);

    return allMenuList;
}