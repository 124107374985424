import React, { useContext, useState } from 'react';
import { type PatientsListViewsType } from '../util/common/type';
import {
  getFacilityNameList,
  getManegerNameList,
  getMenuNameList,
} from '@/feature/phr-28/components/common/Api';

import { ClinicContext, useGraphqlClient } from '@/App';
import { PatientsListPresenter } from '../presenter/PatientsListPresenter';
import { useViewportSize } from '@mantine/hooks';
import { usePatientsPagination } from './hooks/usePatientsPagination';

export const PatientsListViews: React.FC<PatientsListViewsType> = () => {
  // 新規登録
  const graphqlClient = useGraphqlClient();
  const clinic_id = useContext(ClinicContext).clinic_id;

  const [isOpen, setIsOpen] = useState(false);

  const { patients, setResult, orderAndFilterController } = usePatientsPagination(graphqlClient, clinic_id);

  // FIXME: 予約カード内の都度フェッチに修正する
  const menuNameList = getMenuNameList(graphqlClient, clinic_id);
  const manegerNameList = getManegerNameList(graphqlClient, clinic_id);
  const facilityNameList = getFacilityNameList(graphqlClient, clinic_id);

  // FIXME: 検索とソートをAPI側に移植
  const viewSizes = useViewportSize();
  // fetchサイズとページサイズは分けとく

  return (
    <PatientsListPresenter
      viewSizes={viewSizes}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      patients={patients}
      result={patients}
      menuNameList={menuNameList}
      manegerNameList={manegerNameList}
      facilityNameList={facilityNameList}
      orderAndFilterController={orderAndFilterController}
    />
  );
};


