// FIXME: 使用していない変数を削除する

// 時間選択コンポーネント
// 15分ごとの時間を選択してunixtimeを返す
// 選択時間が更新されたら親のformに値をセットする
import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { SelectPresenter } from '@/components/Common/components/parts/Select/presenter/SelectForBlockPresenter'
import { useFormContext } from 'react-hook-form'
import { useLocation } from '@tanstack/react-location'
import { getEndTime, getTreatmentMinutes } from '@/components/Common/utils'
import { ClinicTimeType } from '@/components/Common/entities'
import { treatmentTimes } from '@/feature/phr-28/components/common/medicalMenu'
import { ClinicContext, useGraphqlClient } from '@/App'
import { MenuType } from '@/components/Menu/entities'
import { addMinutes, getUnixTime, startOfDay } from 'date-fns'
import { useGetLatestClinicConfigQuery } from '@/_graphql/graphql-client'
import { SetDateContext } from '@/components/Main/provider/MainProvider'
import { emptyClinicTimeConfig } from '@/components/Common/utils/common/emptyData'
import { useCommonParams } from '@/domain/Common/useCommonParams'

interface SelectViewsType {
    formType: 'appoint' | 'block'
    formData: any // FIXME: BlockFormType | AppointFormTypeを指定する
    setValue: (name: string, value: any) => void
    register: any
    setEndTime: any
}

export const SelectViews: React.FC<SelectViewsType> = ({
    formType,
    formData,
    setValue,
    register,
    setEndTime
}) => {
    const registerType = formType === 'appoint' ? 'appointData' : 'block'
    const [selected, setSelected] = useState<number>(formData.endTime);

    const graphqlClient = useGraphqlClient()
    const { startTime, endTime } = useCommonParams()
    const clinic = useContext(ClinicContext)
    const { nowDate } = useContext(SetDateContext)
    const activeClinicTimeConfig = useGetLatestClinicConfigQuery(graphqlClient, { clinic_id: clinic.clinic_id, current_date: getUnixTime(startOfDay(nowDate)), config_type: 'clinic_time' })
    const activeClinicTime = activeClinicTimeConfig.data?.getLatestClinicConfig?.config.clinicTime
    const clinicTime = activeClinicTime ? activeClinicTime : emptyClinicTimeConfig.config.clinicTime

    useEffect(() => {
        setValue(`${registerType}.endTime`, Number(selected))
    }, [selected])

    //FIXME
    useEffect(() => {
        if (endTime !== 0) {
            setSelected(Number(endTime))
        }
    }, [startTime])

    return (

        <SelectPresenter
            setSelected={setSelected}
            registerType={registerType}
            clinic_time={clinicTime}
            register={register}
            treatmentStartTime={startTime || 0}
            setEndTime={setEndTime}
        />
    )
}


