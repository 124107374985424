import { CancelBtnType, SubmitBtnsType, UpsertBtnType } from "@/components/ClinicSettings/entities"

// [フォームパーツ]サブミットボタン[ラッパー]
export function SubmitBtns({
    children
}: SubmitBtnsType): JSX.Element {
    return (
        <td>
            <div className="flex gap-2 h-full">
                {children}
            </div>
        </td>
    )
}

// [フォームパーツ]ボタン[登録/更新]
export function UpsertBtn({
    enableBtnCss,
    setOpenValidateRule,
    clinic_schedule_id
}: UpsertBtnType): JSX.Element {
    return (
        <p
            className={enableBtnCss}
            onClick={() => {
                setOpenValidateRule(true)
            }}
        >
            {clinic_schedule_id ? '更新' : '登録'}
        </p>
    )
}

// [フォームパーツ]ボタン[キャンセル]
export function CancelBtn({
    setEditing,
    setIsOpenAddRecord,
    enableBtnCss
}: CancelBtnType): JSX.Element {
    return (
        <p
            className={enableBtnCss + ' cursor-pointer'}
            onClick={() => {
                setEditing('')
                setIsOpenAddRecord(false)
            }}
        >
            戻る
        </p>
    )
}