// import type { listManegerType } from '../../common/type'
import { Dialog, DialogContent } from '@mui/material';
import { useQueryClient } from 'react-query';
import React, {
  useContext,
  useState,
  // useEffect, useSyncExternalStore
} from 'react';
import { getUnixTime } from 'date-fns';
import { useGraphqlClient } from '@/App';
import { useNavigate } from '@tanstack/react-location';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import { useChangeStatusMutation } from '@/_graphql/graphql-client';
import { ApppointmentStatusConfirmType } from '../entities/types/type';
import { SelectStatusType, SubmitButtonType } from '../entities/types/parts';

export const AppointmentStatusConfirm: React.FC<ApppointmentStatusConfirmType> = ({
  id,
  confirmed,
  isConfirm,
  setIsConfirm,
}) => {
  const queryClient = useQueryClient();
  const graphqlClient = useGraphqlClient();

  // FIXME
  const navigate = useNavigate();
  const { nowDate } = useContext(SetDateContext);

  // キャンセル理由
  const [selectedCancelReason, setSelectedCancelReason] = useState(0);
  const radioCancelReason = [
    { reasonNum: 0, label: '患者都合' },
    { reasonNum: 1, label: '医院都合' },
    { reasonNum: 2, label: '無断キャンセル' },
  ];

  const changeStatusMutation = (date: number): void => {
    const statusArg = {
      id,
      startTime: confirmed.start,
      appoint: selectStatus,
      ...(selectStatus === '予約済み' && { receptionTime: date, isLate: false, cancel: { isCancel: false } }),
      ...(selectStatus === '受付済み' && { receptionTime: date, cancel: { isCancel: false } }),
      ...(selectStatus === '診療完了' && { consultationTime: date, cancel: { isCancel: false } }),
      ...(selectStatus === 'キャンセル' && { cancelTime: date }),
      ...(selectStatus === 'キャンセル' && {
        cancel: {
          isCancel: true,
          cancelReason,
          radioCancelReason: selectedCancelReason,
        },
      }),
      ...((selectStatus === '予約済み' || selectStatus === 'キャンセル') && { isLate: false })
    };
    changeStatus.mutate(statusArg);
  };

  // 2・更新フロー終了
  const changeStatus = useChangeStatusMutation(graphqlClient, {
    onSettled: () => {
      queryClient
        .invalidateQueries()
        .then((res) => res)
        .catch((err) => err);
    },
    onSuccess: (data) => {
      console.log('changeStatusSuccess', data.changeStatus);
      setSelectStatus('予約済み');
      setIsConfirm(false);
      if (data.changeStatus?.appoint === 'キャンセル') {
        navigate({
          to: `/main/patients-list/patient-info?calendar-date=${getUnixTime(nowDate)}&patient-id=${
            // data.changeStatus.id
            // 230724 null,undefined対応
            data.changeStatus.id !== null && data.changeStatus.id !== undefined ? data.changeStatus.id : ''
            }`,
        });
      }
    },
    onError: (err) => {
      console.log(err);
      setSelectStatus('予約済み');
      setIsConfirm(false);
    },
  });

  const createHandleClose = (setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>) => {
    return function handleClose() {
      setIsConfirm(false);
    };
  };

  const [selectStatus, setSelectStatus] = useState('予約済み');

  // 230723 現状cancelReasonは使われてない？？？
  // const [cancelReason, setCancelReason] = useState('')
  const cancelReason = '';

  const submitChangeStatus = (): void => {
    const nowDate = getUnixTime(new Date());
    changeStatusMutation(nowDate);
  }

  return (
    <>
      {/* ローディング */}
      {(changeStatus.isLoading) && (
        <div className="flex items-center justify-center fixed top-0 left-0 z-[9999] h-full w-full bg-black bg-opacity-50">
          <div className="animate-ping h-4 w-4 bg-blue-600 rounded-full"></div>
        </div>
      )}

      {/* ダイアログ */}
      <Dialog
        onClose={createHandleClose(setIsConfirm)}
        open={isConfirm}
        PaperProps={{
          style: {
            maxWidth: 'none',
            width: '50%',
            transition: 'all .2s',
          },
        }}
      >
        <DialogContent
          dividers
          style={{
            padding: '0px 0px 0px 0px',
            maxWidth: 'none',
            maxHeight: 'none',
            height: '50vh',
            position: 'relative',
          }}
        >
          <SelectStatus
            selectStatus={selectStatus}
            setSelectStatus={setSelectStatus}
            selectedCancelReason={selectedCancelReason}
            setSelectedCancelReason={setSelectedCancelReason}
            radioCancelReason={radioCancelReason}
          />
          <SubmitButton
            setIsConfirm={setIsConfirm}
            selectStatus={selectStatus}
            submitChangeStatus={submitChangeStatus}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const SelectStatus: React.FC<SelectStatusType> = ({
  selectStatus,
  setSelectStatus,
  selectedCancelReason,
  setSelectedCancelReason,
  radioCancelReason,
}) => {
  return (
    <div className="h-4/6 flex flex-col justify-center items-center gap-4 text-xl">
      <p className="text-center">
        ステータスを変更しますか？
      </p>
      <select
        onChange={(e) => {
          console.log(e.target.value);
          setSelectStatus(e.target.value);
        }}
      >
        <option value="予約済み">予約済み</option>
        <option value="受付済み">受付済み</option>
        <option value="診療完了">診療完了</option>
        <option value="キャンセル">予約キャンセル</option>
      </select>
      {selectStatus === 'キャンセル' && (
        <>
          <div className="flex w-full flex-row justify-center items-center">
            <div className="flex items-center mr-2">
              <input
                id="default-radio-1"
                defaultChecked={selectedCancelReason === 0}
                onClick={() => setSelectedCancelReason(0)}
                type="radio"
                value=""
                name="default-radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
              />
              <label htmlFor="default-radio-1" className="text-sm font-medium text-gray-900">
                {radioCancelReason[0].label}
              </label>
            </div>
            <div className="flex items-center mr-2">
              <input
                id="default-radio-2"
                defaultChecked={selectedCancelReason === 1}
                onClick={() => setSelectedCancelReason(1)}
                type="radio"
                value=""
                name="default-radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
              />
              <label htmlFor="default-radio-2" className="text-sm font-medium text-gray-900">
                {radioCancelReason[1].label}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="default-radio-3"
                defaultChecked={selectedCancelReason === 2}
                onClick={() => setSelectedCancelReason(2)}
                type="radio"
                value=""
                name="default-radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
              />
              <label htmlFor="default-radio-3" className="text-sm font-medium text-gray-900">
                {radioCancelReason[2].label}
              </label>
            </div>
          </div>

        </>
      )}
    </div>
  );
};

const SubmitButton: React.FC<SubmitButtonType> = ({
  setIsConfirm,
  selectStatus,
  submitChangeStatus
}) => {
  return (
    <div className="flex justify-center gap-8">
      <button
        onClick={() => {
          setIsConfirm(false);
        }}
        type="button"
        className="w-1/4 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        やめる
      </button>
      <button
        onClick={() => submitChangeStatus(selectStatus)
        }
        type="button"
        className="w-1/4 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        変更する
      </button>
    </div>
  );
};
