import React, { useState } from 'react';
import { ActionIcon, Flex } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

const PAGE_NUMBERS_TO_SHOW = 3;

interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  setCurrentPage: (page: number) => void;
  currentPage: number;
}


export const Pagination: React.FC<PaginationProps> = ({ itemsPerPage, totalItems, currentPage, setCurrentPage }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    // const [currentPage, setCurrentPage] = useState(1);
  
    const handlePageClick = (page: number) => {
      setCurrentPage(page);
    };
  
    const handleNext = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const handlePrev = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const generatePageNumbers = () => {
      const startPage = Math.max(2, currentPage - PAGE_NUMBERS_TO_SHOW); // start from 2 because 1 is always shown
      const endPage = Math.min(totalPages - 1, currentPage + PAGE_NUMBERS_TO_SHOW); // subtract 1 because last page is always shown
  
      const pageNumbers = [];
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    };
  
    const pageNumbers = generatePageNumbers();
  
  
    return (
      <Flex>
        <ActionIcon onClick={handlePrev} disabled={currentPage === 1} aria-label="prev">
          <IconChevronLeft style={{ width: '70%', height: '70%' }} stroke={1.5} />
        </ActionIcon>
  
        <ActionIcon
          onClick={() => handlePageClick(1)}
          style={pageButtonStyle(currentPage === 1)}
        >
          <span className="m-1">1</span>
        </ActionIcon>
  
        {pageNumbers[0] > 2 && <span>... </span>}
        {pageNumbers.map((page) => (
          <ActionIcon
            onClick={() => handlePageClick(page)}
            style={pageButtonStyle(currentPage === page )}
          >
          <span className="m-1">{page}</span>
          </ActionIcon>
        ))}
        {pageNumbers[pageNumbers.length - 1] < totalPages - 1 && <span> ...</span>}
  
        {totalPages > 1 && (
          <ActionIcon
            onClick={() => handlePageClick(totalPages)}
            style={pageButtonStyle(currentPage === totalPages )}
          >
            <span className="m-1">
                {totalPages}
            </span>
          </ActionIcon>
        )}
  
        <ActionIcon onClick={handleNext} disabled={currentPage === totalPages} aria-label="next">
          <IconChevronRight style={{ width: '70%', height: '70%' }} stroke={1.5} />
        </ActionIcon>
      </Flex>
    );
  };
  
  
const pageButtonStyle = (isActive: boolean): any=>{
  return isActive ?{ marginRight: "4px", fontWeight: 'bold' } : {marginRight: "4px",}
}