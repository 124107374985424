import { EditGroupRowProps } from "../../entities";
import { Checkbox, Flex, TextInput } from "@mantine/core";
import { BasicButton, $styledTR, _styledTD } from "@/components/Common/components/Config";

//編集フォーム（施設グループ）
export const EditGroupRow: React.FC<EditGroupRowProps> = ({
    groupFacilities,//所属施設リスト
    editingGroupName,//編集中のグループ名
    setEditingGroupName,//編集中のグループ名をセットする関数
    resetFormState,
    isAllowed,
    updateGroupMutation,
}) => {

    return (
        //テーブルロウ
        <$styledTR border={false}>
            {/* テーブルカラム */}
            <_styledTD>
                {/* チェックボックス */}
                <Checkbox
                    disabled
                    // checked={isAllowed}
                    radius="xs"
                    size="xs"
                />
            </_styledTD>
            <_styledTD>
                {/* 施設グループ名 */}
                <TextInput
                    placeholder="グループ名"
                    size="xs"
                    required
                    value={editingGroupName}
                    onChange={(e) => {
                        setEditingGroupName(e.target.value)
                    }
                    }
                />
            </_styledTD>
            <_styledTD>
                {/* 所属施設リスト */}
                <div className="flex flex-wrap w-full">
                    {groupFacilities.map((facility: any) => facility)}
                </div>
            </_styledTD>
            <_styledTD>
                {/* ボタン */}
                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    gap={{ base: 'sm', sm: 'sm' }}
                    justify={{ sm: 'center' }}
                >
                    {/* 更新ボタン */}
                    <BasicButton
                        label='更新'
                        width="small"
                        color="gray"
                        disabled={editingGroupName.length === 0}
                        onClickEvent={() => {
                            updateGroupMutation()
                        }
                        }
                    />
                    {/* キャンセルボタン */}
                    <BasicButton
                        label='キャンセル'
                        width="small"
                        color="gray"
                        disabled={false}
                        onClickEvent={() => {
                            resetFormState()
                        }}
                    />
                </Flex>
            </_styledTD>
        </$styledTR>
    );
};

export default EditGroupRow;
