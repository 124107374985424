import { ClinicContext } from "@/App"
import { useContext, useEffect, useState } from "react"
import { calculateEndTime, generateTimeSlots } from '@/components/ClinicSettings/utils'
import { MutateContext } from "@/provider/common/MutateProvider"
import { ClinicSchedule, ClinicScheduleType, DayOfWeek, Week } from "@/_graphql/graphql-client"
import { UpsertSchedulePresenter } from "./UpsertSchedulePresenter"
import { getUnixTime, startOfDay } from "date-fns"
import { SetDateContext } from "@/components/Main/provider/MainProvider"

interface UpsertScheduleViewsType {
    setIsOpenAddRecord: React.Dispatch<React.SetStateAction<boolean>>
    setEditing: React.Dispatch<React.SetStateAction<string>>
    clinicSchedule?: ClinicSchedule
    // clinicScheduleList: ClinicSchedule[]
}

export const UpsertScheduleViews: React.FC<UpsertScheduleViewsType> = ({
    setIsOpenAddRecord,
    setEditing,
    clinicSchedule,
    // clinicScheduleList,
}) => {

    //クリニックid・・・医院のid
    const clinic = useContext(ClinicContext)

    const { addClinicSchedule, updateClinicSchedule, deleteClinicSchedule } = useContext(MutateContext)
    const isUpdate = clinicSchedule ? true : false

    // フォームステート
    // 編集ならClinicScheduleから値参照 新規の場合はデフォルト値

    const { nowDate } = useContext(SetDateContext)

    const [ruleType, setRuleType] = useState<ClinicScheduleType | null>(clinicSchedule?.rule_type || ClinicScheduleType.Weekly)
    const [specific_date, setSpecificDate] = useState(clinicSchedule?.specific_date || getUnixTime(nowDate));
    const [day, setDay] = useState<number | null>(clinicSchedule?.day || 1);
    const [dayOfWeek, setDayOfWeek] = useState<DayOfWeek | null>(clinicSchedule?.day_of_week || DayOfWeek.Monday)
    const [week, setWeek] = useState<Week | null>(clinicSchedule?.week || Week.First)
    const [serviceDay, setServiceDay] = useState<boolean | null>(clinicSchedule?.service_day || false)

    const [startTime, setStartTime] = useState(clinicSchedule?.start_time || '9:00')
    const [endTime, setEndTime] = useState(clinicSchedule?.end_time || '23:00')

    const [startDate, setStartDate] = useState(clinicSchedule?.start_date || getUnixTime(startOfDay(new Date())))
    const [endDate, setEndDate] = useState<number | null>(clinicSchedule?.end_date || null);

    // オプション
    const [startTimeSlots, setStartTimeSlots] = useState(generateTimeSlots(15, true, "24:00"));
    const [endTimeSlots, setEndTimeSlots] = useState(generateTimeSlots(15, false, startTime));

    //スタイル
    const enableBtnCss: string = 'cursor-pointer leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'
    const disableBtnCss: string = 'opacity-50 pointer-events-none leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'

    // 開始時間が変更されたときにendTimeSlotsを更新
    useEffect(() => {
        setEndTimeSlots(generateTimeSlots(15, false, startTime));
        calculateEndTime(startTime, endTime, 15, setEndTime)
    }, [startTime, endTime]);

    return (
        <>
            <UpsertSchedulePresenter
                isUpdate={isUpdate}

                addClinicSchedule={addClinicSchedule}
                updateClinicSchedule={updateClinicSchedule}
                deleteClinicSchedule={deleteClinicSchedule}
                clinic={clinic}
                clinic_schedule_id={clinicSchedule?.clinic_schedule_id || ''}

                //フォームステート
                startTime={startTime}
                endTime={endTime}
                startDate={startDate}
                endDate={endDate}
                rule_type={ruleType}
                specific_date={specific_date}
                day={day}
                day_of_week={dayOfWeek}
                week={week}
                service_day={serviceDay}

                // フォームハンドラー
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setRuleType={setRuleType}
                setSpecificDate={setSpecificDate}
                setDay={setDay}
                setDayOfWeek={setDayOfWeek}
                setWeek={setWeek}
                setServiceDay={setServiceDay}

                // オプションバリュー
                startTimeSlots={startTimeSlots}
                endTimeSlots={endTimeSlots}

                // 編集モード切り替えステート
                setIsOpenAddRecord={setIsOpenAddRecord}
                setEditing={setEditing}

                //スタイル
                enableBtnCss={enableBtnCss}
                disableBtnCss={disableBtnCss}
            />
        </>
    )
}