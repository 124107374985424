import { useContext } from "react";
import { FacilityGroupPresenter } from "../presenter/FacilityGroupPresenter"
import { ClinicContext, graphqlContext } from "@/App";
import { MutateContext } from "@/provider/common/MutateProvider";
import { useListFacilitiesByGroupIdsQuery, useListFacilityGroupsQuery } from "@/_graphql/graphql-client";

type GroupFacility = {
    group_id: string;
    facilities: any[];
};

export const FacilityGroupViews: React.FC = () => {

    const { graphqlClient } = useContext(graphqlContext);
    const { clinic_id } = useContext(ClinicContext)

    //グループ一覧
    const facilityGroupList = useListFacilityGroupsQuery(graphqlClient, { inputs: { clinic_id } }).data?.listFacilityGroups.FacilityGroups ?? []
    const groupIds = facilityGroupList.map(group => group?.group_id) as string[];
    //FIXME  gropu_idsのタイポ
    const facilities = useListFacilitiesByGroupIdsQuery(graphqlClient, { input: { clinic_id, group_ids: groupIds } }).data?.listFacilitiesByGroupIds.ListFacilitiesByGroupIdsReturns ?? []
    const facilitiesObj = facilities.reduce<{ [key: string]: any[] }>((acc, curr) => {
        if (curr && curr.group_id && curr.facilities) {
            acc[curr.group_id] = curr.facilities;
        }
        return acc;
    }, {});

    const { addFacilityGroup, deleteFacilityGroup, updateFacilityGroup, transactDeleteGroups } = useContext(MutateContext)

    console.log('facilityGroupList', facilityGroupList)
    console.log('groupIds', groupIds)
    console.log('facilities', facilities)

    return (
        <FacilityGroupPresenter
            clinic_id={clinic_id}
            facilityGroupList={facilityGroupList}
            facilitiesObj={facilitiesObj}
            addGroupMutation={addFacilityGroup.mutate}
            deleteGroupMutation={deleteFacilityGroup.mutate}
            updateGroupMutation={updateFacilityGroup.mutate}
            transactDeleteGroupsMutation={transactDeleteGroups.mutate}
        />
    )
}
