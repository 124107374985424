import React from 'react'
import { Link } from '@tanstack/react-location'

interface SidebarLinkType {
  text: string
  path: string
  isOpen: boolean;
}

export const SidebarLink: React.FC<SidebarLinkType> = ({ text, path, isOpen }) => {
  return (
    <li>
      <Link
        to={`${path}`}
        className={'min-w-full flex items-center p-2 text-xs font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700'}
      >
        <svg
          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
          <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
        </svg>
        <span className={`${isOpen ? '' : 'opacity-0'} transition-all duration-200 flex-1 ml-3 whitespace-nowrap}`}>{text}</span>
      </Link>
    </li >
  )
}
