import React, { useCallback, useState, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useQueryClient } from 'react-query';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Paper,
  } from '@mui/material';
  
  import { ClinicContext } from '@/App';
  import { MutateContext } from '@/provider/common/MutateProvider';
  
import {
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import Encoding from 'encoding-japanese';
import { UploadDialogType } from '../presenter/PatientsListPresenter';

export function CsvDropzone({
  csvString,
  setCsvString,
  dups,
}: {
  csvString: string[][];
  setCsvString: React.Dispatch<React.SetStateAction<string[][]>>;
  dups: string[];
}): JSX.Element {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    // console.log(acceptedFiles);

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.error('file reading was aborted');
      reader.onerror = () => console.error('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;

        if (binaryStr !== null && typeof binaryStr !== 'string') {
          const csvArray = parseCSV(binaryStr);

          setCsvString(csvArray);
        }
      };
      reader.readAsArrayBuffer(file);
    });

    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv,text/csv ',
  });

  const [showDupOnly, setShowDupOnly] = useState(false);

  const dupChecked = csvString
    .map((r) => {
      return { isDup: dups.includes(r[0]), r };
    })
    .filter((r) => {
      return !showDupOnly || r.isDup;
    });

  return (
    <>
      {csvString.length !== 0 ? (
        <>
          <TableContainer className="items-start flex-col w-full h-full overflow-auto">
            <TableHead className="px-13 text-s mb-3">
              <span className="m-2">レコード数: {csvString.length}件</span>{' '}
              <span className="mr-12">重複番号: {dups.length}件</span>{' '}
              <span>
                <input type="checkbox" onClick={() => setShowDupOnly(!showDupOnly)} />{' '}
                重複のみを表示
              </span>
            </TableHead>
            {dupChecked.map((e, idx) => {
              return (
                <TableRow className="text-sm w-full text-left" style={{ width: '100%' }} key={idx}>
                  <span className={e.isDup ? 'line-through text-red-500' : 'text-black'}>
                    {' '}
                    {e.r.reduce((pre, cur) => pre + cur + ' ', '')}
                  </span>
                </TableRow>

                // <li className="px-12 text-xs font-medium w-full" style={{ width: '100%' }} key={idx}>
                //   {idx}, {e.reduce((pre, cur) => pre + cur + ', ', '')}
              );
            })}
          </TableContainer>
        </>
      ) : (
        <div
          className="flex-col w-full h-full flex justify-center items-center"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-gray-400">ここにドロップする。</p>
          ) : (
            <p className="text-gray-400">
              CSVを選択するかここにドラッグアンドドロップしてください。
            </p>
          )}
        </div>
      )}
    </>
  );
}

function parseCSV(arrayBuffer: ArrayBuffer): string[][] {
  // ArrayBufferをTypedArrayに変換して、文字コードを判定
  const encoding = Encoding.detect(new Uint8Array(arrayBuffer));
  const encodedCsv = new TextDecoder(encoding || 'SJIS').decode(arrayBuffer);

  // 文字列を行ごとに分割
  const rows = encodedCsv.split('\n');

  // 各行をカンマで分割して、データを取得
  const data = rows.map((row) => row.split(','));

  return data;
}

function findDuplicates(arr: any[]): string[] {
  const elementCount = new Map<string, number>();
  const duplicates: string[] = [];

  for (const item of arr) {
    const value = item[0].toString(); // 文字列に変換
    elementCount.set(value, (elementCount.get(value) || 0) + 1);
  }

  for (const [value, count] of elementCount.entries()) {
    if (count > 1) {
      duplicates.push(value);
    }
  }

  return duplicates;
}

export const UploadDialog: React.FC<UploadDialogType> = ({ isOpen2, setIsOpen2, orderAndFilterController }) => {
    const [csvString, setCsvString] = React.useState<string[][]>([]);
  
    const { clinic_id } = useContext(ClinicContext);
    const mutates = useContext(MutateContext);
    const { addPatientsFromCsv } = mutates;
 
    const queryClient = useQueryClient();

    async function onSubmit(): Promise<string> {
      // // ここでcsvStringをAPIに送る
      // console.log(csvString);
      const response = await addPatientsFromCsv.mutate({
        clinic_id,
        csv: JSON.stringify(csvString),
      });
  
      // console.log(response);
      setIsOpen2(!isOpen2);
  
      // 初期化
      setCsvString([]);

      // ページネーションの初期化
      await queryClient.invalidateQueries('listPatientsByIdPaginated');
      orderAndFilterController.setFilterOption({});
      return '';
    }
  
    const dups = findDuplicates(csvString);
    // console.log(dups);
  
    return (
      <Dialog
        open={isOpen2}
        PaperProps={{
          style: {
            maxWidth: 'none',
            width: '80%',
            transition: 'all .2s',
            height: '80vh',
          },
        }}
      >
        <DialogContent
          dividers
          style={{
            padding: '0px 0px 0px 0px',
            maxWidth: 'none',
            maxHeight: 'none',
            height: '200px',
            position: 'relative',
          }}
        >
          <Paper
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80%',
              width: '90%',
              margin: '5%',
              padding: '12px',
            }}
          >
            <CsvDropzone csvString={csvString} setCsvString={setCsvString} dups={dups} />
          </Paper>
        </DialogContent>
        <DialogActions>
          <button
            disabled={dups.length !== 0}
            type="button"
            onClick={() => {
              onSubmit()
                .then((res) => console.log(res))
                .catch((err) => console.error(err));
            }}
            className={`my-2 rounded-sm border border-transparent h-7 px-12 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 
            ${
              dups.length !== 0
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-indigo-600 hover:bg-indigo-700 text-white'
            }`}
          >
            登録
          </button>
  
          <button
            type="button"
            onClick={() => {
              setIsOpen2(!isOpen2);
              // 初期化
              setCsvString([]);
            }}
            className="my-2 rounded-sm border border-transparent bg-gray-300 h-7 px-12 text-xs font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            閉じる
          </button>
        </DialogActions>
      </Dialog>
    );
  };
  
  