import { Loader } from "@mantine/core"
import { RefreshIndicatorProps } from "../../../../entities";

const defaultStyle = 'delay-1000 opacity-0';
const isRefreshStyle = 'opacity-100';

export const RefreshIndicator: React.FC<RefreshIndicatorProps> = ({
    isRefresh
}) => {

    return (
        <div className={`${isRefresh ? isRefreshStyle : defaultStyle}`}>
            <Loader size="xs" />
        </div>
    )
}
