import { SearchBoxProps } from "@/components/Common/entities"
import { SearchCircleIcon } from "@heroicons/react/solid"

export const SearchBox: React.FC<SearchBoxProps> = ({
    searchWord,
    setSearchWord,
}) => {

    return (
        <div className="relative w-1/2">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <SearchCircleIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
            </div>

            <input
                type="text"
                onChange={(e) => setSearchWord(e.target.value)}
                value={searchWord}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="検索"
            />
        </div>
    )
}
