import { AllotmentHandle } from "allotment"
import { useEffect } from "react";

//Allotoment.Paneのレイアウト初期化
export const useResetPaneSize = (
    ref: React.RefObject<AllotmentHandle>
) => {
    useEffect(() => {
        if (ref?.current) {
            ref.current.reset();
        }
    }, [ref?.current])
}
//AllotmentにpreferredSizeプロパティ(優先サイズ)
//が指定されている場合はそちらのサイズが適用される