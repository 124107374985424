import { ReferenceGroupRowProps } from "../../entities";
import { Checkbox, Flex } from "@mantine/core";
import { $styledTR, _styledTD, BasicButton, PermissionActionButton } from "@/components/Common/components/Config";

// 参照用施設グループ行
export const ReferenceGroupRow: React.FC<ReferenceGroupRowProps> = ({
    groupId,//施設グループid
    groupFacilities,//施設グループに紐づく施設
    groupName,//施設グループ名
    editingGroupId,//編集中の行のid
    openEditForm,//編集フォーム表示
    deleteFacilityGroup,//削除mutation
    setAllowedDeletes,//削除許可フラグセット
    isAllowed
}) => {

    return (
        //施設グループ行
        <$styledTR border={false}>
            <_styledTD>
                {/* チェックボックス */}
                <Checkbox
                    checked={isAllowed}
                    onChange={() => setAllowedDeletes()}
                    radius="xs"
                    size="xs"
                />
            </_styledTD>

            {/* 施設グループ名 */}
            <_styledTD>{groupName}</_styledTD>

            {/* 所属施設 */}
            <_styledTD>
                <Flex
                    miw={400}
                    maw={660}
                    gap="xs"
                    justify="flex-start"
                    align="center"
                    direction="row"
                    wrap="wrap"
                >
                    {
                        groupFacilities.map((facility: any) => <div>{facility.name}</div>)
                    }
                </Flex>
            </_styledTD>

            <_styledTD>
                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    gap='sm'
                    align="center"
                >
                    {/* 編集ボタン */}
                    {/* FIXME
                    tailwindとmantineのボタンスタイルが競合しているため一旦自前で用意
                    対処法あり: 後日要相談 */}
                    <BasicButton
                        label={editingGroupId === groupId ? 'キャンセル' : '編集'}
                        width="small"
                        color="gray"
                        disabled={false}
                        onClickEvent={() => {
                            openEditForm()
                        }}
                    />

                    {/* 削除ボタン */}
                    {/* FIXME */}
                    <PermissionActionButton
                        isAllowed={isAllowed}
                        action={() => deleteFacilityGroup()}
                        label="削除"
                    />
                </Flex>
            </_styledTD>
        </$styledTR>
    );
};

export default ReferenceGroupRow;
