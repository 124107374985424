import React, { useState, useEffect, useRef } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { Arrow } from './Arrow'
import { PrimaryButton } from '@/feature/phr-29/components/Button/PrimaryButton'
import {
  formatDate,
  weeks,
  getAddDay
} from '@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon'
import {
  daysType,
  HolidaysType,
  MonthCalendarType
} from '@/feature/phr-28/components/common/type'
import { isHoliday } from '@holiday-jp/holiday_jp'
import { Navigate, useNavigate } from '@tanstack/react-location'
import { getUnixTime, getWeek, setWeek, startOfDay, startOfMonth, startOfToday } from 'date-fns'
import { useQueryClient } from 'react-query'

function classNames(...classes: Array<string | boolean | undefined>): string {
  return classes.filter(Boolean).join(' ')
}

function getIsHoliday(timestamp: Date, holidays: HolidaysType | undefined) {
  if (holidays === undefined) return false;

  const holiday = holidays[getUnixTime(startOfDay(timestamp))]
  const isHoliday = holiday !== undefined
  return isHoliday;
}

export const MonthCalendar: React.FC<MonthCalendarType> = ({
  nowDate,
  setNowDate,
  holidays
}): JSX.Element => {
  const container = useRef<HTMLDivElement>(null)
  const containerNav = useRef<HTMLDivElement>(null)
  const containerOffset = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if ((container.current != null) && (containerNav.current != null) && (containerOffset.current != null)) {
      // Set the container scroll position based on the current time.
      const currentMinute = new Date().getHours() * 60
      container.current.scrollTop =
        ((container.current.scrollHeight -
          containerNav.current.offsetHeight -
          containerOffset.current.offsetHeight) *
          currentMinute) /
        1440
    }
  }, [])

  const [days, setDays] = useState<daysType[]>()
  const queryClient = useQueryClient()

  // 日付移動
  function calcDate(value: number): void {
    const _nowDate = new Date(nowDate.setDate(nowDate.getDate() + value))
    setDays([])
    setNowDate(_nowDate)
  }

  // 月移動
  function calcMonth(value: number): void {
    // 計算前日付
    // const preDate = nowDate.getDate()
    const _nowDate: Date = startOfMonth(new Date(nowDate.setMonth(nowDate.getMonth() + value)))

    // if (_nowDate.getDate() !== preDate) {
    //   // 計算後日付が一致しない場合、前月月末指定
    //   // _nowDate = nowDate.setDate(0);
    // }
    setDays([])
    setNowDate(_nowDate)
  }

  function createCalendar(): void {
    const date = nowDate
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const lastMonth = date.getMonth()
    const nextMonth = date.getMonth() + 2
    const startDate = new Date(year, month - 1, 1) // 月の最初の日を取得
    const endDate = new Date(year, month, 0) // 月の最後の日を取得
    const endDayCount = endDate.getDate() // 月の末日
    const lastMonthEndDate = new Date(year, month - 1, 0) // 前月の最後の日の情報
    const lastMonthendDayCount = lastMonthEndDate.getDate() // 前月の末日
    const startDay = startDate.getDay() // 月の最初の日の曜日を取得
    // 予約不可日数と定休日をoffにする値
    const limitCancellationNum: number = -1
    const reservationNotPossibleDays: number = limitCancellationNum
    const regularHoliday: number = limitCancellationNum
    let dayCount = 1 // 日にちのカウント
    let numCount

    // week分ループ
    const days: daysType[] = []
    for (let weekLine = 0; weekLine < 6; weekLine++) {
      // console.log(`${w}ループ目, ${dayCount}カウント`)
      for (let dayBox = 0; dayBox < 7; dayBox++) {
        // 先月の日程
        if (weekLine === 0 && dayBox < startDay) {
          // 1行目で1日の曜日の前
          const num = lastMonthendDayCount - startDay + dayBox + 1
          numCount = num
          const contents: daysType = {
            date: `${year}/${lastMonth}/${numCount}`,
            isDisabled: true,
            isCurrentMonth: false,
            isHoliday: false
          }
          days.push(contents)
        } else if (dayCount > endDayCount) {
          // 来月の日程
          const num = dayCount - endDayCount
          numCount = num
          const contents: daysType = {
            date: `${year}/${nextMonth}/${numCount}`,
            isDisabled: true,
            isCurrentMonth: false,
            isHoliday: false
          }
          days.push(contents)
          dayCount++
        } else {
          // 今月の日程
          const roopDate: Date = new Date(`${year}/${month}/${dayCount}`)
          // isDisabledには予約不可日数後で定休日でない、今月の日程のみfalseになる
          const contents: daysType = {
            date: `${year}/${month}/${dayCount}`,
            isDisabled:
              getAddDay(new Date(), reservationNotPossibleDays) < roopDate
                ? roopDate.getDay() === regularHoliday
                : true,
            isCurrentMonth: true,
            // isSelected: new Date(nowDate).getMonth() === new Date().getMonth() && new Date(nowDate).getFullYear() === new Date().getFullYear() ? formatDate(new Date()) === formatDate(new Date(`${year}-${month}-${dayCount}`)) : dayCount === 1,
            isSelected: new Date(nowDate).getDate() === dayCount,
            isToday:
              formatDate(new Date()) ===
              formatDate(roopDate),
            isHoliday: roopDate.getDay() === 0 || getIsHoliday(roopDate, holidays)
          }
          days.push(contents)
          dayCount++
        }
      }
    }

    setDays(days)
  }

  const navigate = useNavigate();

  useEffect(() => {
    // date更新の度にカレンダー修正
    createCalendar()
    navigate({ to: `?calendar-date=${getUnixTime(nowDate)}` })
  }, [nowDate])

  return (
    <>
      <div className="hidden max-w-md flex-none md:block px-1">
        <div className="grid grid-cols-5 items-center text-center text-gray-900">
          <button
            onClick={() => calcMonth(-1)}
            type="button"
            className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-l text-[0.7rem] h-7 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
          >
            先月
          </button>
          <div className="col-span-3 text-[0.7rem] flex-auto font-semibold leading-3">
            <span>{`${nowDate.getFullYear()}`}</span>
            <br />
            <span>{`${new Date(nowDate).getMonth() + 1}月${new Date(nowDate).getDate()}日(${weeks[nowDate.getDay()]})`}</span>
          </div>
          <button
            onClick={() => calcMonth(1)}
            type="button"
            className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-r text-[0.7rem] h-7 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
          >
            翌月
          </button>
        </div>
        <div className="mt-1 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
          <div>日</div>
          <div>月</div>
          <div>火</div>
          <div>水</div>
          <div>木</div>
          <div>金</div>
          <div>土</div>
        </div>

        <div className="isolate mt-1 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          {days?.map((day, dayIdx) => (
            <button
              onClick={(e) => {
                if (day.isCurrentMonth ?? false) {
                  setDays([])
                  setNowDate(new Date(e.currentTarget.value))
                }
              }}
              value={day.date}
              key={day.date}
              type="button"
              className={classNames(
                'py-0.5 hover:bg-gray-100 focus:z-10',
                day.isDisabled && day.isCurrentMonth === false ? 'bg-gray-100' : day.isHoliday !== undefined && day.isHoliday ? 'bg-red-200' : 'bg-white',
                ((day.isSelected ?? false) || (day.isToday ?? false)) && 'font-semibold',
                (day.isSelected ?? false) && 'text-white',
                (day.isSelected === undefined || !day.isSelected) &&
                (day.isCurrentMonth ?? false) &&
                (day.isToday === undefined || !day.isToday) &&
                'text-gray-900',
                (day.isSelected === undefined || !day.isSelected) &&
                (day.isCurrentMonth === undefined || !day.isCurrentMonth) &&
                (day.isToday === undefined || !day.isToday) &&
                'text-gray-400',
                (day.isToday ?? false) && (day.isSelected === undefined || !day.isSelected) && 'text-indigo-600',
                dayIdx === 0 && 'rounded-tl-lg',
                dayIdx === 6 && 'rounded-tr-lg',
                dayIdx === days.length - 7 && 'rounded-bl-lg',
                dayIdx === days.length - 1 && 'rounded-br-lg'
              )}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                  (day.isSelected ?? false) && (day.isToday ?? false) && 'bg-indigo-600',
                  (day.isSelected ?? false) && day.isToday !== undefined && !day.isToday && 'bg-gray-900'
                )}
              >
                {day.date.split('/').pop()?.replace(/^0/, '')}
              </time>
            </button>
          ))}
        </div>
        <div className="flex flex-row justify-between">
          {/* <Arrow changeDay={calcDate} /> */}
          <div className="relative z-0 shadow-sm rounded-md w-full grid grid-cols-6 gap-0.5 h-8 mt-3">
            {/* <button
              onClick={() => calcDate(-1)}
              type="button"
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button> */}

            {/* <PrimaryButton
              text="当日"
              functionName={() => {
                setDays([])
                setNowDate(new Date())
              }}
            /> */}

            <button
              onClick={() => calcDate(-7)}
              type="button"
              className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-l text-[0.7rem] text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
            >
              先週
            </button>
            <button
              onClick={() => calcDate(-1)}
              type="button"
              className="text-white bg-gray-700 hover:bg-gray-800 font-medium text-[0.7rem] text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
            >
              前日
            </button>
            <button
              onClick={() => {
                setDays([])
                setNowDate(new Date())
              }}
              type="button"
              className="col-span-2 text-white bg-indigo-600 hover:bg-indigo-700 font-medium text-[0.7rem] text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
            >
              当日
            </button>
            <button
              onClick={() => calcDate(1)}
              type="button"
              className="text-white bg-gray-700 hover:bg-gray-800 font-medium text-[0.7rem] text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
            >
              翌日
            </button>
            <button
              onClick={() => calcDate(7)}
              type="button"
              className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-r text-[0.7rem] text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
            >
              翌週
            </button>

          </div>
        </div>
      </div>
    </>
  )
}
