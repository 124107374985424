import { listManegerType } from "@/components/Patient/components/util/common/type";
import { addMinutes, differenceInMinutes, fromUnixTime, getUnixTime } from "date-fns";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";

interface BlockFormValuesType {
    id: string;
    startTime: number;
    endTime: number;
    facilityId: string;
    color: string;
    remarks: string;
    treatmentTime: number;
}

export function getTreatmentTime(
    startTime: number,
    endTime: number
): number {
    const treatmentTime = differenceInMinutes(
        fromUnixTime(endTime || 0),
        fromUnixTime(startTime || 0)
    )
    return treatmentTime
}

export function getDefaultCheckedTimestamp(
    blockStartTime: number,
    blockingTreatmentTime: number
) {
    const blockStartDate = fromUnixTime(blockStartTime);
    const blockEndDate = addMinutes(blockStartDate, blockingTreatmentTime);
    const blockEndTime = getUnixTime(blockEndDate);
    return blockEndTime
}

//削除済み担当者optionを返却
export function getManegerOptionIsDeleted(manegerListsIncludeDelete: listManegerType[], manegerId: string, getManegerNameById: (manegerLists: listManegerType[], manegerId: string) => string) {
    let option;
    const deletedManeger = manegerListsIncludeDelete.find(maneger => maneger.id === manegerId && maneger.isDelete);
    if (deletedManeger) {
        option = <option value={deletedManeger.id} className="text-red-500">{`${getManegerNameById(manegerListsIncludeDelete, deletedManeger.id)}`}</option>
    }
    return option;
}

//staffIdsをindex順にソート&idのみmapして返却
export function sortAndExtractIds(staffIdsArray: { index: number, id: string }[]): string[] {
    return staffIdsArray
        .sort((a, b) => a.index - b.index) // 配列をindexプロパティに基づいてソート
        .map(staff => staff.id); // idプロパティのみを取り出して新しい配列を作成
};