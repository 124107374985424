import { Dialog, DialogContent } from '@mui/material'
import { ClinicContext, useGraphqlClient } from "@/App"
import { ClinicScheduleType, useValidateScheduleRuleQuery } from "@/_graphql/graphql-client"
import { useContext, useEffect } from "react"
import { onSubmit } from "../../utils"
import { AlertListItemType, AlertListWrapperType, CautionMessageType, ConfirmationButtonType, ConfirmationDialogType, DialogWrapperType, ErrorCancelButtonType, ErrorDialogType, ErrorMessageType, SubmitButtonType } from "../../entities"
import { scheduleMessageList } from '../../utils/libs/validateDialog'
import { ValidateScheduleDialogType } from '../../entities/components/validateDialog'

export const ValidateScheduleDialog: React.FC<ValidateScheduleDialogType> = ({
    // mutate引数
    clinic_schedule_id,
    rule_type,
    day_of_week,
    week,
    specific_date,
    day,
    service_day,
    start_date,
    end_date,

    setEditing,
    // 開閉&mutateタイプステート
    setOpenValidateRule,// ダイアログの開閉
    isUpdate,// 更新 || 登録,

    // isValid=false時の上書き同意チェック
    confirmed,
    setConfirmed,

    // mutate関数
    addScheduleSubmit,
    updateScheduleSubmit
}) => {

    const graphqlClient = useGraphqlClient()
    const clinic = useContext(ClinicContext)
    const text = isUpdate ? '変更' : '登録'

    const validateArgs = {
        clinic_id: clinic.clinic_id,
        clinic_schedule_id: clinic_schedule_id || '',
        rule_type: rule_type as ClinicScheduleType,
        day_of_week: day_of_week,
        week: week,
        specific_date: specific_date,
        day: day,
        service_day: service_day,
        start_date: start_date,
        end_date: end_date,
    }

    const alertMessageList = scheduleMessageList

    // FIXME: ↓ 要service関数化
    // NOTE: 診療ルールのvalidate 変更時に登録済み予約の有無を確認
    const validateResult = useValidateScheduleRuleQuery(graphqlClient, validateArgs)

    // NOTE: boolean | undefined
    // true = 更新可能 false = 更新不可 undefined = Queryエラー
    // *
    // 休診ルール登録時、休診日の予約がある場合はアラート
    // 日付指定の休診ルールの場合、変更/削除時にアラート
    const isValid = validateResult.data?.validateScheduleRule?.isValid
    const isError = validateResult.isError

    return (
        <DialogWrapper
            setEditing={setEditing}
            setOpenValidateRule={setOpenValidateRule}
        >
            {/* isValid=falseのダイアログ */}
            {
                (isError === false) &&

                <ConfirmationDialog>
                    {/* 注意文 */}
                    <CautionMessage text={text} isValid={isValid} />
                    {/* ルール変更による影響（箇条書き） */}
                    <AlertListWrapper>
                        {
                            isValid === false ?
                                alertMessageList.map((message, index) => (
                                    <AlertListItem
                                        key={index}
                                        message={message.message}
                                        isCaution={message.isCaution}
                                    />
                                ))
                                : <AlertListItem message={'追加・更新による予約情報への影響はありません'} isCaution={false} />
                        }
                    </AlertListWrapper>
                    {/* サブミット */}
                    <ConfirmationButtons
                        setConfirmed={setConfirmed}
                        isValid={isValid}
                    >
                        <SubmitButton
                            text={text}
                            confirmed={isValid ? true : confirmed}
                            onSubmit={() => onSubmit(isUpdate, setEditing, setOpenValidateRule, addScheduleSubmit, updateScheduleSubmit)}
                        />
                        <CancelButton
                            setOpenValidateRule={setOpenValidateRule}
                        />
                    </ConfirmationButtons>
                </ConfirmationDialog>
            }
            {/* クエリ失敗のダイアログ */}
            {
                isError &&
                <ErrorDialog
                    message={'登録時にエラーが発生しました。'}
                    setOpenValidateRule={setOpenValidateRule}
                />
            }
        </DialogWrapper>
    )
}


//各種コンポーネント---------------------------------------------
// ダイアログのラッパー
function DialogWrapper({
    children,
    setEditing,
    setOpenValidateRule
}: DialogWrapperType): JSX.Element {
    return (
        <Dialog
            transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
            onClose={() => {
                setEditing('')
                setOpenValidateRule(false)
            }}
            open={true}
            PaperProps={{
                style: {
                    maxWidth: 'none',
                }
            }}
        >
            <DialogContent
                dividers
                style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', position: 'relative', }}
            >
                <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6">
                    {children}
                </div>
            </DialogContent>
        </Dialog>
    )
}

// isValid=falseのダイアログ
function ConfirmationDialog({
    children
}: ConfirmationDialogType): JSX.Element {

    return (
        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6">
            {children}
        </div>
    )
}

function CautionMessage({
    text,
    isValid
}: CautionMessageType): JSX.Element {
    return (
        <>
            <div className="mb-2">
                <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                        <span className={`${isValid ? 'text-green-500' : 'text-red-500'} text-sm font-bold`}>{isValid ? 'ルールを追加しますか？' : '対象ルール範囲内に既に予約が存在します。'}</span>
                    </h3>
                </div>
            </div>
        </>
    )
}

function AlertListWrapper({
    children
}: AlertListWrapperType): JSX.Element {

    return (
        <ul className="mb-2">
            {children}
        </ul>
    )
}

function AlertListItem({
    message,
    isCaution
}: AlertListItemType): JSX.Element {
    const AllClearStyle = 'text-green-500 text-xs mb-1'
    const CautionStyle = 'text-red-500 text-xs mb-1'
    const style = isCaution ? CautionStyle : AllClearStyle;

    return (
        <li className={style}>{message}</li>
    )
}

// エラー時のダイアログ
function ErrorDialog({
    message,
    setOpenValidateRule
}: ErrorDialogType): JSX.Element {
    return (
        <>
            <ErrorMessage message={message} />
            <ErrorCancelButton setOpenValidateRule={setOpenValidateRule} />
        </>
    )
}

function ErrorMessage({
    message
}: ErrorMessageType): JSX.Element {
    return (
        <h3 className="flex justify-center">
            {message}
        </h3>
    )
}

function ErrorCancelButton({
    setOpenValidateRule
}: ErrorCancelButtonType): JSX.Element {
    return (
        <p
            onClick={() => { setOpenValidateRule(false) }}
        >キャンセル
        </p>
    )
}

function ConfirmationButtons({
    children,
    setConfirmed,
    isValid
}: ConfirmationButtonType): JSX.Element {
    return (
        <>
            {isValid === false &&
                <div className="flex items-center justify-center gap-2 mb-2">
                    <input
                        className="h-3 w-3"
                        onClick={() => setConfirmed(confirmed => !confirmed)}
                        type="checkbox" />
                    <p className="text-sm">{'上記内容に同意'}</p>
                </div>
            }
            <div className="flex items-center justify-center">
                {children}
            </div>
        </>
    )
}

function SubmitButton({
    text,
    confirmed,
    onSubmit
}: SubmitButtonType): JSX.Element {
    //スタイル
    const enableBtnCss: string = 'cursor-pointer leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'
    const disableBtnCss: string = 'opacity-50 pointer-events-none leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'

    return (
        <p
            className={confirmed ? enableBtnCss : disableBtnCss}
            onClick={onSubmit}
        >{text}</p>
    )
}

type CancelButtonType = {
    setOpenValidateRule: React.Dispatch<React.SetStateAction<boolean>>
}

function CancelButton({
    setOpenValidateRule
}: CancelButtonType): JSX.Element {
    //スタイル
    const enableBtnCss: string = 'cursor-pointer leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'
    const disableBtnCss: string = 'opacity-50 pointer-events-none leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'

    return (
        <p
            onClick={() => { setOpenValidateRule(false) }}
            className={enableBtnCss}
        >キャンセル
        </p>
    )
}