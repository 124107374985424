export interface SubmitButtonPropsType {
    disabled: boolean
    label: string
}

export const SubmitButton: React.FC<SubmitButtonPropsType> = ({
    disabled,
    label
}) => {
    return (
        <div className='cursor-pointermt-2 flex items-end justify-end gap-1'>
            <div className="flex flex-col">
                <button
                    //患者未登録なら登録させない
                    disabled={disabled}
                    type="submit"
                    className={`${disabled ? 'opacity-50 pointer-events-none' : ''} cursor-pointer py-1 w-20 hover:bg-opacity-70 text-white bg-blue-700 font-medium rounded-sm inline-flex justify-center items-center text-xs`}
                >
                    {label}
                </button>
            </div>
        </div>
    )
}
