import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import { SelectStaff } from './SelectStaff';
import { listManegerType } from '@/components/Staff/components/utils/common/type';

interface StaffSelectorProps {
    register: any;
    manegerListIncludeDelete: listManegerType[];
    selectedStaffIds: string[]
}

const containerStyle = "flex flex-col gap-1";
const headerContainerStyle = "flex items-end gap-2 pb-1 border-black border-b-1 text-xs";
const iconStyle = 'text-xs';
const labelStyle = "text-xs w-full";

export const StaffSelector: React.FC<StaffSelectorProps> = ({ register, manegerListIncludeDelete, selectedStaffIds }) => {

    return (
        <div className={containerStyle}>
            <div className={headerContainerStyle}>
                <GroupAddOutlinedIcon className={iconStyle} />
                <p className={labelStyle}>担当者選択</p>
            </div>

            {selectedStaffIds.map((selectedStaffId, index) => (
                <SelectStaff
                    key={index}
                    id={`staff${index}`}
                    registerProps={register(`appointData.staffIds.${index}.id`)}
                    manegerListIncludeDelete={manegerListIncludeDelete}
                    selectedStaffId={selectedStaffId || '0'}
                />
            ))}
        </div>
    );
}
