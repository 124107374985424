// PatientV2関連
import { useAddPatientByIdV2Mutation, useDeletePatientByIdV2Mutation, useUpdatePatientByIdV2Mutation, useAddPatientsFromCsvMutation } from '@/_graphql/graphql-client'

import { GraphQLClient } from 'graphql-request'
import { QueryClient } from "react-query"

export function patientMutations(graphqlClient: GraphQLClient, queryClient: QueryClient) {

    const patientOption = {
        onSettled: async () => {
            // 予約詳細関連へのキャッシュクリアはawaitしない
            queryClient.invalidateQueries('listAppointForAppointShift')
            queryClient.invalidateQueries('listAppointForAppointList');
            await queryClient.invalidateQueries('listAppointByIdV2')
            await queryClient.invalidateQueries('listPatientsV2')
            await queryClient.invalidateQueries('listPatientsByClinicIdV2')
            await queryClient.invalidateQueries('listPatientsByIdPaginated')
            await queryClient.invalidateQueries('getPatientByIdV2')
        },
        onSuccess: () => {
        }
    }

    const addPatient = useAddPatientByIdV2Mutation(graphqlClient, patientOption)
    const updatePatient = useUpdatePatientByIdV2Mutation(graphqlClient, patientOption)
    const deletePatient = useDeletePatientByIdV2Mutation(graphqlClient, patientOption)
    const addPatientsFromCsv = useAddPatientsFromCsvMutation(graphqlClient, patientOption)

    return {
        addPatient,
        updatePatient,
        deletePatient,
        addPatientsFromCsv
    }
}