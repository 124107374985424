import { useState } from 'react';
import { GraphQLClient } from 'graphql-request'
import { useBlockVariablesType } from '@/domain/Block/entities/types'
import { useGetBlockQuery } from '@/_graphql/graphql-client'
import { BlockMapper } from '@/domain/Block/services/mapper/blockListMapper';
import { UseQueryOptions } from 'react-query';

export const useBlock_service = (
    graphqlClient: GraphQLClient,
    variables: useBlockVariablesType,
    //FIXME: type定義
    options: any,
) => {
    const req = useGetBlockQuery(graphqlClient, variables, options);
    const data = BlockMapper(req)

    return { data, req }
}