import { useContext, useRef, useState } from "react";
import { useAppointList_service } from "@/domain/Appointment";
import { useBlockList_service, useMenuList_service, usePatientList_service, useStaffList_service } from "@/domain";
import { AllPanesContext } from "@/components/Main/provider/PanesProvider";
import { PaneVisibilityContext, SetDateContext } from "@/components/Main/provider/MainProvider";
import { useLocation, useNavigate } from "@tanstack/react-location";
import { getEndTimeOfToDayTimestamp, getStartTimeOfToDayTimestamp } from "@/components/Common";
import { useViewportSize } from "@mantine/hooks";
import { useQueryClient } from "react-query";
import { getOrderedFacilityList } from "@/components/Facility/utils";
import { endOfDay, getUnixTime, startOfDay } from "date-fns";
import { useGetActiveScheduleQuery, useGetLatestClinicConfigQuery, useListAppointForAppointShiftQuery, useListFacilitiesByFilterQuery, useListAppointForAppointListQuery, useListAppointForPatientInfoQuery } from "@/_graphql/graphql-client";
import { useFacilityGroupContext } from "@/domain/FacilityGroup/useFacilityGroup";
import { FacilityType } from "@/components/Facility/entities";
import { AppointPresenter } from "../presenter/AppointPresenter";
import { AppointViewsPropsType } from "../../entities";
import { useShiftTimeObserver } from "../../hooks/useShiftTimeObserver";
import { useAppointForAppointShift_service } from "@/domain/Appointment/services/hooks/Query/useAppointList_service";

export const AppointViews: React.FC<AppointViewsPropsType> = ({
    clinic,
    graphqlClient,

    search,
    pathname,
    operation,
    // パラメータ
    patientId,
    blockId,
    startTime,
    endTime,
    facilityId,
    appointId
}) => {
    // group_id
    const { group_id } = useFacilityGroupContext()

    // ペイン開閉状況ステート
    const { setIsPaneOpen } = useContext(PaneVisibilityContext)
    //シフト表示設定
    const [isAllTime, setIsAllTime] = useState<boolean>(false)
    const [facilitiesFilter, setFacilitiesFilter] = useState<string>('all')
    const viewSizes = useViewportSize();

    const { nowDate, setNowDate } = useContext(SetDateContext)
    const LatestClinicTime = useGetLatestClinicConfigQuery(graphqlClient, { clinic_id: clinic.clinic_id, current_date: getUnixTime(startOfDay(nowDate)), config_type: 'clinic_time' }, { retry: 0 })

    //診療時間とシフト枠数を管理
    const { shiftTime, shiftFrameCount } = useShiftTimeObserver(LatestClinicTime, nowDate, isAllTime)

    const appoints = useAppointForAppointShift_service(graphqlClient, { clinic_id: clinic.clinic_id, date: { startTime: getStartTimeOfToDayTimestamp(nowDate), endTime: getEndTimeOfToDayTimestamp(nowDate) } })

    // const appoints = useAppointList_service(graphqlClient, { clinic_id: clinic.clinic_id, startDate: getStartTimeOfToDayTimestamp(nowDate), endDate: getEndTimeOfToDayTimestamp(nowDate) })
    const allReservationList = appoints.appointList
    const { isRefetching } = appoints.req

    const allBlockList = useBlockList_service(graphqlClient, { clinic_id: clinic.clinic_id, startTime: getStartTimeOfToDayTimestamp(nowDate), endTime: getEndTimeOfToDayTimestamp(nowDate) })

    //施設一覧
    const listFacilitiesByFilter = useListFacilitiesByFilterQuery(graphqlClient, { inputs: { clinic_id: clinic.clinic_id, group_id: group_id || '', filterType: facilitiesFilter } })
    const allFacilityList = listFacilitiesByFilter.data?.listFacilitiesByFilter.Facilities || []
    const activeFacilityList = allFacilityList?.filter((facility: any) => !facility?.isDelete)
    const orderedFacilityList = getOrderedFacilityList(activeFacilityList as FacilityType[])

    const schedule = useGetActiveScheduleQuery(graphqlClient, { clinic_id: clinic.clinic_id, current_date: getUnixTime(startOfDay(nowDate)) })
    const isHoliday = schedule.data?.getActiveSchedule?.isHoliday
    const isBeforeDay = schedule.data?.getActiveSchedule?.isBeforeDay

    //context - Allotmentコンポーネントの参照
    const { subPanesRef } = useContext(AllPanesContext)
    // ページ遷移関数
    const navigate = useNavigate();

    //FIXME: HOCからpropsで渡す
    const location = useLocation();
    const searchStr = location.current.searchStr

    const queryClient = useQueryClient();

    // FIXME: 印刷のためのref暫定実装
    const printRef = useRef();

    return (
        <>
            <AppointPresenter

                //[共通]
                allReservationList={allReservationList}

                // [予約ヘッダー]-----
                //印刷ボタン
                printRef={printRef}
                //ペイン開閉ボタン
                subPanesRef={subPanesRef}
                //更新ボタン
                isRefetching={isRefetching}
                //日付選択
                nowDate={nowDate}
                setNowDate={setNowDate}
                //時刻表示方式の切り替え(暫定廃止中)
                isAllTime={isAllTime}
                setIsAllTime={setIsAllTime}
                //フロアセレクト
                setFacilitiesFilter={setFacilitiesFilter}
                //------------------

                // [予約シフト枠]-----
                isHoliday={isHoliday}
                isBeforeDay={isBeforeDay}
                setIsPaneOpen={setIsPaneOpen}
                shiftFrameCount={shiftFrameCount}
                queryClient={queryClient}
                search={search}
                pathname={pathname}
                operation={operation}
                shiftTime={shiftTime}
                patientId={patientId}
                blockId={blockId}
                startTime={startTime}
                endTime={endTime}
                facilityId={facilityId}
                appointId={appointId}

                viewSizes={viewSizes}
                navigate={navigate}
                searchStr={searchStr}
                clinic={clinic}
                allBlockList={allBlockList}
                orderedFacilityList={orderedFacilityList}
            />
        </>
    )
}