import { UseQueryResult } from "react-query";
import { GetCommentsQuery } from "@/domain/Comment/entities/types";

export function commentMapper(
    _comment: UseQueryResult<GetCommentsQuery, unknown>
) {
    let comment;
    if (_comment.isSuccess) {
        comment = _comment.data.getComments
    }
    return comment
}