import { BlockType } from "@/components/Block/entities";

export interface BlockCardProps {
    block: BlockType;
    firstCardTime: number;
    quarterTimeStamp: number;
    isBlockEdit: boolean;
    blockId: string;
    endTime: number;
    onClickHandler: () => void;
}

export const BlockCard: React.FC<BlockCardProps> = (props) => {
    const { block, firstCardTime, quarterTimeStamp, isBlockEdit, blockId, endTime, onClickHandler } = props;
    const { staticStyle, dynamicStyle } = computeStyles(block, firstCardTime, quarterTimeStamp, isBlockEdit, blockId, endTime);

    return (
        <div className="border-white">
            <div
                onClick={onClickHandler}
                className={staticStyle}
                style={dynamicStyle}
            >
                {block.remarks}
            </div>
        </div>
    );
}

//FIXME: デザイン変更までの暫定処理
function toPastelColor(hex: string) {
    if (hex === '#ddd') return hex
    // カラーコードからRGB値を取得
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // RGB値を白色に近づける
    const pastelR = Math.round((r + 255) / 2);
    const pastelG = Math.round((g + 255) / 2);
    const pastelB = Math.round((b + 255) / 2);

    // RGB値をカラーコードに変換
    return `#${pastelR.toString(16).padStart(2, '0')}${pastelG.toString(16).padStart(2, '0')}${pastelB.toString(16).padStart(2, '0')}`;
}

type BlockCardStyles = {
    staticStyle: string;
    dynamicStyle: React.CSSProperties;
};

export function computeStyles(block: BlockType, firstCardTime: number, quarterTimeStamp: number, isBlockEdit: boolean, blockId: string, endTime: number): BlockCardStyles {
    //静的スタイル
    const commonStyle = `px-3 absolute w-full h-full overflow-y-hidden text-xs text-black text-[0.925rem] leading-6`;
    const editStyle = "pointer-events-none opacity-50"
    const staticStyle = `${commonStyle} ${isBlockEdit ? editStyle : ''}`;

    const isSelected = block.id === blockId;
    const isActive = endTime !== block.endTime;

    //動的スタイル
    const pastelColor = block?.color ? toPastelColor(block?.color) : '#fff';
    const bgStyle = `repeating-linear-gradient(
        60deg,
        ${pastelColor} 0,
        ${pastelColor} 10px,
        #fff 10px,
        #fff 11px
    )`;
    const cardBoxNum = (block.endTime - block.startTime) / quarterTimeStamp;
    // １時間スロットがheight:200px
    // １５分スロットがheight:40px, margin-bottom:10px
    const height = cardBoxNum * 100 - 10;
    const positionTop = (block.startTime - firstCardTime) / quarterTimeStamp * 100;
    const dynamicStyle = {
        border: isSelected ? '3px solid #000000' : 'none',
        backgroundImage: bgStyle,
        opacity: isBlockEdit ? 0.5 : 1,
        top: `${positionTop}px`,
        height: `${height}px`,
        ...(isSelected && { boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.4) inset' }),
        ...(!isActive && { zIndex: 50 })
    };

    return { staticStyle, dynamicStyle };
};