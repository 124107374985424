import { $TimeSlot } from './$TimeSlot';

export interface TimesProps {
    shiftStartTime: number;
    shiftEndTime: number;
    shiftEndMinutes: number;
}

export function calculateTimeSlots(shiftStartTime: number, shiftEndTime: number): any[] {
    // シフトの開始時間から終了時間までの合計時間を計算し、
    // それを2倍して30分単位のブロック数を得る。最後に1を足して正確なブロック数を得る。
    const numberOfTimeSlot = (shiftEndTime - shiftStartTime) * 4 + 1;
    return [...Array(numberOfTimeSlot)];
}

const style = 'w-[5%] text-right min-w-[45px]';

export const $TimeBar: React.FC<TimesProps> = ({ shiftStartTime, shiftEndTime, shiftEndMinutes }) => {

    const timeSlots = calculateTimeSlots(shiftStartTime, shiftEndTime);

    return (
        <div className={style}>
            {
                timeSlots.map((_, index) => {
                    const hour = shiftStartTime + Math.floor(index / 4);

                    // 判定: 判定
                    const minLabel = getMinutes(index);                    
                    // 判定: シフトの終了分が0
                    const hasNoEndMinutes = shiftEndMinutes === 0;
                    // 判定: 現在時間がシフトの終了時間
                    const isEndOfShift = hour === shiftEndTime;
                    // 判定: シフトの終了時間 && 終了分が0 && 現在の時間が45分かチェック
                    const isEndOfShiftWithoutMinutes = isEndOfShift && hasNoEndMinutes && minLabel === "45";

                    return (
                        <$TimeSlot
                            key={index}
                            hour={hour}
                            min={minLabel}
                            isEndOfShift={isEndOfShift}
                            isEndOfShiftWithoutMinutes={isEndOfShiftWithoutMinutes}
                        />
                    );
                })
            }
        </div>
    );
}

// indexを4で割って余りから分のラベルを取得
function getMinutes(index: number): string {
    const mod = index % 4;
    switch (mod) {
        case 0:
            return '00';
        case 1:
            return '15';
        case 2:
            return '30';
        case 3:
            return '45';
        default:
            return '00'; // これは通常発生しないはずですが、安全のため
    }
}