import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGraphqlClient } from '@/App';
import { Maneger, useUpDateManegerMutation } from '.graphql/types';
import { ResponsibleCardPresenter } from '../presenter/ResponsibleCardPresenter';
import { openDialog } from '../utils/common/util';
import { ResponsibleCardViewsType, listManegerType } from '../utils/common/type';
import { ResponsibleEditModalViews } from './ResponsibleEditModalViews';
import { ResponsibleDeleteModalViews } from './ResponsibleDeleteModalViews';

export const ResponsibleCardViews: React.FC<ResponsibleCardViewsType> = ({
  currentManeger,
  lastIndexNum,
  manegerLists,
}) => {
  const graphqlClient = useGraphqlClient();
  const emptyManeger: Maneger = {
    id: '',
    name: '',
    email: '',
    rights: '',
    color: '',
    role: '',
    isDelete: false
  }
  const [editCurrentManeger, setEditCurrentManeger] = useState<Maneger>(emptyManeger);
  const [deleteCurrentManeger, setDeleteCurrentManeger] = useState<Maneger>(emptyManeger);
  const [isOpen, setIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);

  const queryClient = useQueryClient();
  const { mutate } = useUpDateManegerMutation(graphqlClient, {
    onSettled: () => {
      queryClient.invalidateQueries('listManeger').catch(() => console.log('on Settled'));
    },
    onSuccess: (res) => {
      console.log(res);
    },
    onError: (error) => {
      throw error;
    },
    retry: 3,
  });
  const updateManegerIndexExecute = (isUp: boolean = false): void => {
    let isRecord2: boolean = true;
    if (currentManeger.index === 1) {
      currentManeger.index++;
    } else if (currentManeger.index > 1 && currentManeger.index < lastIndexNum) {
      if (isUp) {
        currentManeger.index--;
      } else {
        currentManeger.index++;
      }
    } else if (currentManeger.index === lastIndexNum) {
      currentManeger.index--;
    } else {
      currentManeger.index = lastIndexNum + 1;
      isRecord2 = false;
    }
    // 2つ入ってきてる
    const record2: listManegerType[] = manegerLists.filter((maneger: listManegerType) => {
      return maneger.index === currentManeger.index;
    });
    if (isRecord2) {
      if (isUp) {
        record2[0].index++;
        mutate(record2[0]);
      } else {
        record2[1].index--;
        mutate(record2[1]);
      }
    }
    mutate(currentManeger);
  };
  return (
    <ResponsibleCardPresenter
      currentManeger={currentManeger}
      setDeleteCurrentManeger={setDeleteCurrentManeger}
      lastIndexNum={lastIndexNum}
      updateManegerIndexExecute={updateManegerIndexExecute}
      editIsOpen={editIsOpen}
      setEditIsOpen={setEditIsOpen}
      ResponsibleEditModalViews={ResponsibleEditModalViews}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      ResponsibleDeleteModalViews={ResponsibleDeleteModalViews}
      setEditCurrentManeger={setEditCurrentManeger}
      openDialog={openDialog}
    />
  );
};
