import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

//ログイン
export const useLogin = () => {

    const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0()

    useEffect(() => {
        (async () => {
            //認証処理中でない&未認証なら抜ける
            if (!isLoading && !isAuthenticated) {
                await loginWithRedirect()
            }
        }
        )()
    }, [isLoading, isAuthenticated])

    //認証結果を返却
    return {
        isAuthenticated
    }
}
