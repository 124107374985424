import { FilterRowType } from "@/components/ClinicSettings/entities"

export const FilterRow: React.FC<FilterRowType> = ({
    tableHeads,
    tableDates,
}
) => {

    return (
        <>
            <tr>
                <td></td>
                {
                    // テーブルヘッドに基づきテーブルデータをマッピング
                    tableHeads.map((head, index) => {
                        if (head.prop === 'end_date' || head.prop === 'updated_at') return

                        const data = tableDates.find((data, index) => {
                            return head.prop === data.prop
                        })

                        return data ? data.element : <td></td>
                    })
                }

            </tr>
        </>
    )

}
