import { BrowserQRCodeReader } from '@zxing/browser'
import { Link, Navigate } from '@tanstack/react-location'
import React, { useEffect, useRef, useState } from 'react'
import Result from '@zxing/library/esm/core/Result'

interface QrCodeReaderProps {
  onRead: (result: Result) => void
}

interface CameraDeviceInfo {
  id: string
  name: string
}

const QrCodeReader: React.FC<QrCodeReaderProps> = ({ onRead }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const mountedRef = useRef<boolean>(false)
  const [devices, setDevices] = useState<CameraDeviceInfo[]>([])
  const [currentCamera, setCurrentCamera] = useState<string | undefined>(
    undefined
  )

  const setDevicesList = async (): Promise<CameraDeviceInfo[]> => {
    const list = await BrowserQRCodeReader.listVideoInputDevices()
    const result: CameraDeviceInfo[] = []
    for (const device of list) {
      result.push({ id: device.deviceId, name: device.label })
    }
    setDevices([...result])
    return result
  }

  useEffect(() => {
    if ((videoRef?.current) == null) return
    mountedRef.current = true
    const codeReader = new BrowserQRCodeReader(undefined, undefined)
    setDevicesList().catch(() => console.log('on Settled'))
    codeReader.decodeFromVideoDevice(
      currentCamera,
      videoRef.current,
      function (result, _, controls) {
        if (!mountedRef.current) {
          controls.stop()
          return
        }
        if (typeof result !== 'undefined') {
          controls.stop()
          onRead(result)
        }
      }
    ).catch(() => console.log('on Settled'))
    return function cleanup () {
      mountedRef.current = false
    }
  }, [currentCamera])

  return (
    <div className="flex justify-center mt-20">
      <div className="w-2/3 h-2/3 flex flex-col items-center justify-center">
        <h2 className="p-2 font-bold text-lg">QRコードを読み込んでください</h2>
        {devices.length !== 0 && (
          <select
            value={currentCamera === undefined ? devices[0]?.id : currentCamera}
            onChange={(e) => {
              setCurrentCamera(e.target.value)
            }}
          >
            {devices.map((device, index) => (
              <option value={device.id} key={index.toString()}>
                {device.name}
              </option>
            ))}
          </select>
        )}
        <video className="rounded py-5 px-10" ref={videoRef} />
        <Link
          to={'/'}
          className="text-center w-40 p-2 block text-gray-100 text-sm font-medium rounded bg-gray-700 hover:bg-gray-800"
        >
          読み取りを停止
        </Link>
      </div>
    </div>
  )
}

export function QrRecept (): JSX.Element {
  const [transitionTarget, setTransitionTarget] = useState('')
  let isRedirect: boolean = false
  isRedirect = transitionTarget !== ''

  return (
    <>
      {isRedirect && <Navigate to={transitionTarget} replace={isRedirect} />}
      <QrCodeReader
        onRead={(e) => {
          setTransitionTarget(e.getText())
        }}
      />
    </>
  )
}
