import { AddAppointType, DeleteAppointType, UpdateAppointInfoType, UpdateAppointType } from "@/components/Common";

// 新規予約の成功処理
export function handleAddAppointSuccess(addAppoint: AddAppointType) {
    if (addAppoint.isSuccess) {
        const res = addAppoint.data.addAppoint;
        console.log('新規予約-追加成功', res);
        addAppoint.reset();
    }
}

// 予約更新の成功処理
export function handleUpdateAppointSuccess(updateAppoint: UpdateAppointType) {
    if (updateAppoint.isSuccess) {
        const res = updateAppoint.data.updateAppoint;
        console.log('予約日時-変更成功', res);
        updateAppoint.reset();
    }
}

// 予約情報更新の成功処理
export function handleUpdateAppointInfoSuccess(updateAppointInfo: UpdateAppointInfoType) {
    if (updateAppointInfo.isSuccess) {
        const res = updateAppointInfo.data.updateAppointInfo;
        console.log('予約更新-成功', res);
        updateAppointInfo.reset();
    }
}

// 予約削除の成功処理
export function handleDeleteAppointSuccess(deleteAppoint: DeleteAppointType) {
    if (deleteAppoint.isSuccess) {
        const res = deleteAppoint.data.deleteAppoint;
        console.log('予約削除-成功', res);
        deleteAppoint.reset();
    }
}

// 新規予約のエラー処理
export function handleAddAppointError(addAppoint: AddAppointType) {
    if (addAppoint.isError) {
        console.log('addAppoint失敗', addAppoint.error);
        alert('予約追加に失敗しました');
        addAppoint.reset();
    }
}

// 予約更新のエラー処理
export function handleUpdateAppointError(updateAppoint: UpdateAppointType) {
    if (updateAppoint.isError) {
        console.log('予約日時-変更失敗', updateAppoint.error);
        alert('予約日時の変更に失敗しました');
        updateAppoint.reset();
    }
}

// 予約情報更新のエラー処理
export function handleUpdateAppointInfoError(updateAppointInfo: UpdateAppointInfoType) {
    if (updateAppointInfo.isError) {
        console.log('予約更新-失敗', updateAppointInfo.error);
        alert('予約更新に失敗しました');
        updateAppointInfo.reset();
    }
}

// 予約削除のエラー処理
export function handleDeleteAppointError(deleteAppoint: DeleteAppointType) {
    if (deleteAppoint.isError) {
        console.log('予約削除-失敗', deleteAppoint.error);
        alert('予約削除に失敗しました');
        deleteAppoint.reset();
    }
}